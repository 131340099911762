import H220 from "../../view/components/common/Typograpgy/H220"
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect, useMemo, useState } from "react";
import { useApi } from "../../api/api";
import parse from 'html-react-parser';
import BackToPage from "../../view/components/common/BacktoPage/BackToPage";
import { Link } from "react-router-dom";
import P16 from "../../view/components/common/Typograpgy/P16";


function Help(){
    const [expanded, setExpanded] = useState(false);
    const [isLoading, setIsLoading] = useState(true); // State to track loading status


  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const { handleGetRequest, handlePostRequest } = useApi();
  const [helpData, setHelpData] = useState()
  
  useEffect(() => {
    setIsLoading(true);

      (async () => {  
        const helpRes = await handleGetRequest(`users/help`, false,false);
          if(helpRes?.flag){
            console.log("helpRes", helpRes);
            setHelpData(helpRes?.data)
        }
        setIsLoading(false);

      })();
    }, []);

    console.log("helpData",helpData);

      // Memoize the parsed HTML content
      const parsedContent = useMemo(() => {
        return helpData?.t_page_content?parse(helpData?.t_page_content):"";
    }, [helpData]);

    return(
        <div  className="px-2 lg:px-0 mb-10 ">
            {/* <BackToPage pageRoute="/" backTo="Home"/> */}
            {/* <H220 className="dark:text-white">Help</H220> */}
                 <div className=" mt-7 rounded-lg bg-[#F1B942] text-white lg:text-[46px] text-2xl font-bold py-10 w-full flex flex-col justify-center items-center">
                <p>How can we help you?</p>
                </div>
                <div className="bg-white drop-shadow-xl py-10 px-5 my-5 rounded-xl dark:bg-[#131313] dark:text-[#A1A1A1] ">
                {parsedContent}
                </div>
                <div className="flex justify-center items-center my-5">
                <Link to="/contact" className="mx-auto mt-5 text-center text-xs lg:text-xl font-semibold text-[#188ACA]">Need more help?</Link>
            </div>
            
        </div>
    )
}

export default Help
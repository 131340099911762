import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  payloadBell:[],
  payloadCommunity:[]
};

const notificationBellSlice = createSlice({
  name: "bell",
  initialState,
  reducers: {
    handleBellNotificationPayload: (state, action) => {
        const newNotification = {
            id: action.payload.id,
            title: action.payload.title,
            msg: action.payload.msg
          };
          state.payloadBell.push(newNotification);
    },

  //   handleBellNotificationPayload: (state, action) => {
  //       state.payloadBell.push(action.payload);
  // },

    handleBellNotificationCommunityPayload: (state, action) => {
        state.payloadCommunity = action.payload;
  },

    clearAllNotifications: (state) => {
      state.payloadBell = [];
    },

    removeNotification: (state, action) => {
      state.payloadBell = state.payloadBell.filter(notification => notification.id !== action.payload);
    }
   
  },
});

export const {handleBellNotificationPayload,removeNotification,clearAllNotifications,handleBellNotificationCommunityPayload} = notificationBellSlice.actions;

export default notificationBellSlice.reducer;

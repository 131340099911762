import Button1 from "../common/GlobalButtonLayout/Button1"
import GenderInput from "../common/Input/GenderInput"
import InputField from "../common/Input/InputField"
import DoneIcon from '@mui/icons-material/Done';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { Calendar } from 'primereact/calendar';
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DatePicker, Space } from 'antd';
import  moment from 'moment';
import dayjs from 'dayjs';

const SignUpModal = ({handleSubmit,onSubmit,verifyOtp,register,errors,cpassword,isOtpSend,setDate,date}) => {
    const isDark = useSelector((state) => state.dark.isDarkValue);
    const maxDate = moment();

    const isLengthValid = (password) => password?.length >= 8;
    const hasLowercase = (password) => /[a-z]/?.test(password);
    const hasUppercase = (password) => /[A-Z]/?.test(password);
    const hasNumber = (password) => /\d/?.test(password);
    const hasSpecialCharacter = (password) => /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/?.test(password);
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {;
            handleSubmit(onSubmit)
        }
    };
    const onChange = (date, dateString) => {
        console.log(date, dateString);
      };

    // let maxDate = new Date();

    console.log("cpassword",(cpassword && hasLowercase(cpassword))?true:false);

    const handleChange = (date) => {
        setDate(date ? date.valueOf() : null); // `valueOf` converts date to timestamp
      };
    
     const disabledDate = (current) => {
       // Can not select days before today and today
       return current && current > dayjs().endOf('day');
     };

    // Empty dependency array means this effect runs once on mount
    return(
        <>
          {verifyOtp&&<div>
                            <div className="flex justify-center gap-y-2 md:justify-between flex-wrap items-center w-[100%] px-12 mt-1 ">

                            {/* <Calendar type="date" className="lg:w-[50%] w-full dark:filter dark:invert custom-calendar"   name="d_dob" maxDate={maxDate}  onKeyDown={handleKeyDown}  onChange={(e) => setDate(e.value)} value={date} placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy"  required={true}  errors={errors}  showIcon showButtonBar hideOnDateTimeSelect={true}    /> */}

                            {/* <DatePicker onChange={(e) => setDate(e.valueOf)}  name="d_dob" format="DD-MM-YYYY" className="w-full"/> */}

                            <DatePicker
                                onChange={handleChange}
                                name="d_dob"
                                // value={date ? moment(date) : null} // Ensure moment object is passed to DatePicker
                                format="DD-MM-YYYY"
                                className={`w-full rounded-full py-2 ${isDark?"dark-mode-picker":""}  `}
                                placeholder="DD/MM/YYYY"
                                disabledDate={disabledDate}// Setting maxDate
                                // onKeyDown={handleKeyDown}
                                getPopupContainer={(trigger) => trigger.parentElement}
                                required
                                />

                            
                            {errors && errors['d_dob'] && errors['d_dob'].type === "required" && (
                            <p className="text-red-500 text-xs lg:text-sm">DOB is required</p>
                            )}

                            <GenderInput register={register} errors={errors} />
                            </div>
                            <InputField 
                                type="password" 
                                placeholder="Password" 
                                name="cpassword" 
                                register={register} 
                                errors={errors}
                                required={true}
                                pattern={{
                                    value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
                                    message: "Password must have at least 8 characters, one lowercase letter (a-z), one uppercase letter (A-Z), one number (0-9), and one special character (@, -, #)"
                                  }}
                                  onKeyDown={handleKeyDown}
                               
                            />
                               {errors && errors['cpassword'] && errors['cpassword'].type === "required" && (
                                <p className="text-red-500 text-sm"> Password is required</p>
                                )}

                                {errors && errors['cpassword'] && errors['cpassword'].type === 'pattern' && (
                                    <p className="text-red-500 text-xs lg:text-sm">{"Password must meet given criteria"}</p>
                                )}
                            <InputField 
                                type="password" 
                                placeholder="Verify password" 
                                name="v_password" 
                                register={register} 
                                errors={errors}
                                required={true}
                                onKeyDown={handleKeyDown}
                              
                            />
                            {errors && errors['v_password'] && errors['v_password'].type === "required" && (
                                <p className="text-red-500 text-sm">Verify Password is required</p>
                                )}

                                {errors && errors['v_password'] && errors['v_password'].type === 'pattern' && (
                                    <p className="text-red-500 text-xs lg:text-sm">{"Password must meet given criteria"}</p>
                                )}
                            <div className="text-left !px-12  w-[100%] pl-5 py-2 mt-2 mb-1 text-[12px] dark:text-white">
                            <p className={`${isLengthValid(cpassword)?"text-green-500":""}`}><DoneIcon className={` ${isLengthValid(cpassword)?"text-green-500":"text-[#DEDEDE]"}`}/>At least 8 characters.</p> 
                            <p className={`${(cpassword && hasLowercase(cpassword))?"text-green-500":""}`}><DoneIcon  className={` ${(cpassword && hasLowercase(cpassword))?"text-green-500":"text-[#DEDEDE]"}`}/>At least one lowercase letter (a-z).</p> 
                            <p className={`${hasUppercase(cpassword)?"text-green-500":""}`}><DoneIcon  className={` ${hasUppercase(cpassword)?"text-green-500":"text-[#DEDEDE]"}`}/>At least one uppercaseletter (A-Z)</p> 
                            <p className={`${hasNumber(cpassword)?"text-green-500":""}`}><DoneIcon  className={` ${hasNumber(cpassword)?"text-green-500":"text-[#DEDEDE]"}`}/>At least one number (0-9)</p> 
                            <p className={`${hasSpecialCharacter(cpassword)?"text-green-500":""}`}><DoneIcon  className={` ${hasSpecialCharacter(cpassword)?"text-green-500":"text-[#DEDEDE]"}`}/>At least one special characters.(@-#)</p> 
                            </div>
                            </div>}

                        {(!isOtpSend || verifyOtp)&&<Button1 type="submit" className="!my-5 lg:!w-[50%] !w-[80%] text-center dark:hover:!text-[#FDD169]">
                            Continue    
                        </Button1>}
        </>
    )
} 

export default SignUpModal


import React, { useEffect, useRef, useState } from 'react'
import { useForm, Controller } from 'react-hook-form';
import { useApi } from '../../../api/api';
import { MultiSelect } from 'primereact/multiselect';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { Calendar } from 'primereact/calendar';
import clsx from 'clsx';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import globalToast from '../../../hooks/globalToast';
import Multiselector from '../common/MultiSelector/Multiselector';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
// import { DatePicker, Space } from 'antd';
import DatePicker from "react-datepicker";

import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import "react-datepicker/dist/react-datepicker.css";

const CreateCalenderModal = ({setCalendarEvents,setIsCreateModal,calendarEvents,eventIndex,activityType,datefromclick,timeFromClick,isSchdule}) => {
  const preDate = calendarEvents?.data?.events[eventIndex]?.d_schedule_start_date? new Date(calendarEvents?.data?.events[eventIndex]?.d_schedule_start_date) : new Date(datefromclick)
   const isValidDate = (date) => {
      return date instanceof Date && !isNaN(date);
    };
  
    const getTodayDate = () => {
      return new Date();
    };

    const [startDate, setStartDate] = useState(() => {
    return isValidDate(new Date(preDate)) ? new Date(preDate) : getTodayDate();
  });


    const daysOfWeek = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"];
    const isDark = useSelector((state) => state.dark.isDarkValue);
    
    const [startTime, setStartTime] = useState('');
    const [isplaylistDropdownOpen, setIsplaylistDropdownOpen] = useState(false);
    const [selectedPlaylists, setSelectedPlaylists] = useState(
      calendarEvents?.data?.events[eventIndex]?.playlists.length > 0
        ? calendarEvents?.data?.events[eventIndex]?.playlists
        : []
    );
    const [errPlaylist, setErrPlaylist] = useState(false);
    const [errDate, setErrDate] = useState(false);
    const timee = new Date(datefromclick)
    const options = {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    };

    
    const now = new Date();
    const currentTime = now.toLocaleTimeString('en-US', {
      hour12: false, // 24-hour format
      hour: '2-digit',
      minute: '2-digit',
    });
  

    const preTime = timee?.toLocaleTimeString('en-US', options);

    const oneHourLater = new Date(timee.getTime() + 60 * 60 * 1000);
    const laterTime = oneHourLater?.toLocaleTimeString('en-US', options);

    const { register, handleSubmit, control, reset, watch, setValue, formState: { errors } } = useForm({
      defaultValues: {
        days: calendarEvents?.data?.events[eventIndex]?.days,
        frequency: calendarEvents?.data?.events[eventIndex]?.days,
        title:calendarEvents?.data?.events[eventIndex]?.t_title ,
        description: calendarEvents?.data?.events[eventIndex]?.t_description,
        activityType: calendarEvents?.data?.events[eventIndex]?.t_music_activity_type,
        date: startDate ,
        // starttime: calendarEvents?.data?.events[eventIndex]?.tm_schedule_start_time? calendarEvents?.data?.events[eventIndex]?.tm_schedule_start_time:currentTime ,
        starttime: preTime?preTime==="05:30:00"?currentTime:preTime:currentTime ,
        endtime: calendarEvents?.data?.events[eventIndex]?.tm_schedule_end_time?calendarEvents?.data?.events[eventIndex]?.tm_schedule_end_time:laterTime,
        allDay: calendarEvents?.data?.events[eventIndex]?.e_all_day,
        repeat: calendarEvents?.data?.events[eventIndex]?.repeat
      },
    });

    const watchedScheduleDate = watch('scheduleDate');


    

    useEffect(()=>{
      if(watchedScheduleDate){
      setStartDate(watchedScheduleDate)
      }
    },[watchedScheduleDate])


    const [playlists, setPlaylists] = useState([]);
    
    const { handleGetRequest, handlePostRequest } = useApi();
    // const [today, setToday] = useState(new Date());


  // useEffect(() => {
  //   // Set minDate to tomorrow so that only past dates can be selected
  //   const tomorrow = new Date();
  //   tomorrow.setDate(today.getDate() );
  //   setToday(tomorrow);
  // }, []);

  

  // useEffect(() => {

  //   if(watchedDate && watchedDate && !isNaN(new Date(watchedDate).getTime())){
  //     setValue("date", watchedDate); // Set the default value to today when component mounts
  //   }else{
  //     setValue("date", today); // Set the default value to today when component mounts

  //   }
  // }, [watchedDate]);

  

  const [currentMinTime, setCurrentMinTime] = useState('');

 
    
    useEffect(() => {
        (async () => {
            const playlistRes = await handleGetRequest(`${`playlist-data?e_platform_name=all&type=all&search_keyword=&sort_type=new`}`,false,false)
            setPlaylists(playlistRes?.data)
        })();
      }, []);

      useEffect(()=>{
        if(Object.keys(errors).length > 0 && !selectedPlaylists.length>0){
          setErrPlaylist(true)
        }else{
          setErrPlaylist(false)
        }

        if(Object.keys(errors).length > 0 && watchedScheduleDate?.toString()==="Invalid Date"){
          setErrDate(true)
        }else{
          setErrDate(false)
        }
      },[errors,selectedPlaylists,watchedScheduleDate])
   
  
   
      const onSubmit = async(data) => {
        console.log("testcalendar",data);
        // const currentDate = new Date(); // Get the current date
        //  const inputDate = new Date(data?.date); // Convert data.date to a Date object
       
        //  if (inputDate < currentDate) {
        //   globalToast("please Select present or any future date")
        //    return; // Return if the input date is in the past
        //  }
        
        
        if(!selectedPlaylists.length>0){
          setErrPlaylist(true)
          // toast.error("Please add Playlist");
          globalToast("Please add Playlist",true)
          return
        }

        // if(watchedScheduleDate?.toString()==="Invalid Date"){
        //   return
        // }

        const date = new Date(startDate);
        const day = date.getDate();
        const month = date.getMonth() + 1; // Months are zero-based in JavaScript
        const year = date.getFullYear();
        const formattedDate = `${year}-${month?.toString().padStart(2, '0')}-${day?.toString().padStart(2, '0')}`;
      // const datee = data?.date?.split('T')[0];

      if(data?.repeat && !data?.days){
        // toast.error("Please add Frequency");
        globalToast("Please add Frequency",true)
          return
      }

      if(data?.repeat && !data?.days?.length>0){
        // toast.error("Please add Frequency");
        globalToast("Please add Frequency",true)
        return
      }

     
        const payload = {
          t_title: data?.title,
          t_description: data?.description,
          e_all_day: data?.allDay?1:0,
          start: `${formattedDate} ${data?.starttime}`,
          end:`${formattedDate} ${data?.endtime}`,
          t_music_activity_type:data?.activityType,
          repeat:data?.repeat?true:false,
          e_user_events_color:"",
          e_transparency:"opaque",
          e_remind:1,
          ...(data?.repeat) && { days: data?.days?data?.days:[] } ,
          playlist_ids:selectedPlaylists?.map(item => item.id)
        }

        const payloadevents = {
          t_music_activity_type:activityType.toLowerCase()
        }

        setPlaylists([])
        // Handle form submission
        if(calendarEvents?.data?.events[eventIndex]?.t_title){
          const createEventRes = await handlePostRequest(`google-calendar/updateEvent/${calendarEvents?.data?.events[eventIndex]?.id}`,  payload, true, true);
         
          const getEvents = await handlePostRequest(`google-calendar/getEvents`,payloadevents, false,true);
          setCalendarEvents(getEvents)
          setIsCreateModal(false)
          reset()
        }else{
          const createEventRes = await handlePostRequest(`google-calendar/createEvent`,  payload, true, true);
          const getEvents = await handlePostRequest(`google-calendar/getEvents`,payloadevents, false,true);
          setCalendarEvents(getEvents)
          setIsCreateModal(false)
          reset()
        }
      };

      const days = watch("days");
      const frequency = watch("frequency");
      const isrepeat = watch("repeat")
    
      // const handleDayClick = (day) => {
      //   if (days?.includes(day)) {
      //     setValue("days", days.filter(d => d !== day));
      //   } else {
      //     setValue("days", [...days, day]);
      //     setValue("frequency", '');
      //   }
      // };

      const handleDayClick = (day) => {
        // Ensure 'days' is an array
        const currentDays = Array.isArray(days) ? days : [];
      
        if (currentDays.includes(day)) {
          setValue("days", currentDays.filter(d => d !== day));
        } else {
          setValue("days", [...currentDays, day]);
          setValue("frequency", '');
        }
      };


      const handleFrequencyClick = (freq) => {
        let selectedDays = [];
        switch (freq) {
          case 'daily':
            selectedDays = daysOfWeek;
            break;
          case 'wednesday':
            selectedDays = ["WE"];
            break;
          case 'weekend':
            selectedDays = ["SA", "SU"];
            break;
          default:
            selectedDays = [];
        }
        setValue("days", selectedDays);
        setValue("frequency", freq);
      };

      // const handlePlaylistChange = (e) => {
      //   const selected = e.value;
      //   const uniquePlaylists = selected.reduce((acc, curr) => {
      //     if (!acc.some(playlist => playlist.v_name === curr.v_name)) {
      //       acc.push(curr);
      //     } else {
      //       // Overwrite the existing playlist with the new one
      //       acc = acc.map(playlist => playlist.v_name === curr.v_name ? curr : playlist);
      //     }
      //     return acc;
      //   }, []);
      //   setSelectedPlaylists(uniquePlaylists);
      // };

      // useEffect(()=>{
      //   if(selectedPlaylists.length>0){
      //     setErrPlaylist(true)
      //   }
      // },[selectedPlaylists])


      // useEffect(() => {
      //   if(new Date().toDateString()===watchedDate){
      //    return
      //   }
      //    // Function to get the current time in HH:mm format
      //    const getCurrentTime = () => {
      //      const now = new Date();
      //      const hours = String(now.getHours()).padStart(2, '0');
      //      const minutes = String(now.getMinutes()).padStart(2, '0');
      //      return `${hours}:${minutes}`;
      //    };
         
      //    // Set the initial minimum selectable time to the current time
      //    setCurrentMinTime(getCurrentTime());
      //  }, []);

      function forDate(date) {
        const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        const dayName = days[date?.getDay()]; // Day of the week
        const monthName = months[date?.getMonth()]; // Month name
        const day = date?.getDate()?.toString()?.padStart(2, '0'); // Day of the month with leading zero if necessary
        const year = date?.getFullYear(); // Year
        return `${dayName} ${monthName} ${day} ${year}`;
      }


      const isFirstRender = useRef(true);

       useEffect(() => {
        if (isFirstRender.current) {
          isFirstRender.current = false;
          return;
        }

    
        if (new Date().toDateString() !== forDate(watchedScheduleDate)) {
          setCurrentMinTime('')
          return;
        }
    
        const getCurrentTime = () => {
          const now = new Date();
          const hours = String(now.getHours()).padStart(2, '0');
          const minutes = String(now.getMinutes()).padStart(2, '0');
          return `${hours}:${minutes}`;
        };
    
        setCurrentMinTime(getCurrentTime());
      }, [watchedScheduleDate, currentMinTime]);



      const [preSelectedPlaylists, setPreSelectedPlaylists] = useState([]);

      useEffect(() => {
        // Example logic to populate pre-selected playlists
        // This could come from props, context, or other state management solutions
        const initialPreSelectedPlaylists = calendarEvents?.data?.events[eventIndex]?.playlists;
        setPreSelectedPlaylists(initialPreSelectedPlaylists || []);
      }, [calendarEvents, eventIndex]);
      
      // Combine all playlists (including pre-selected ones) for the options prop
      // const allUniquePlaylists = [...preSelectedPlaylists,...playlists];

      const allUniquePlaylists = Array.from(new Set([...preSelectedPlaylists,...playlists].map(p => p.id)))
      .map(id => {
         return preSelectedPlaylists.find(p => p.id === id) || playlists.find(p => p.id === id) 
       });



        const dropdownRef = useRef(null);
       
         // Function to save current scroll position
        //  const saveScrollPosition = () => {
        //    if (dropdownRef.current) {
        //      localStorage.setItem('dropdownScrollPosition', dropdownRef.current.scrollTop);
        //    }
        //  };
       
         // Function to restore scroll position
         const restoreScrollPosition = () => {
           const savedPosition = localStorage.getItem('dropdownScrollPosition');
           if (savedPosition && dropdownRef.current) {
             dropdownRef.current.scrollTop = savedPosition;
           }
         };
       
         useEffect(() => {
           // Restore scroll position after component updates
           restoreScrollPosition();
         });

          const multiselectorRef = useRef(null);
            const selectPlaylistRef = useRef(null);

            const handleClickOutside = (e) => {
              if (
                multiselectorRef.current &&
                !multiselectorRef.current.contains(e.target) &&
                selectPlaylistRef.current &&
                !selectPlaylistRef.current.contains(e.target)
              ) {
                setIsplaylistDropdownOpen(false);
              }
            };

            useEffect(() => {
              if (isplaylistDropdownOpen) {
                document.addEventListener('mousedown', handleClickOutside);
              } else {
                document.removeEventListener('mousedown', handleClickOutside);
              }
              return () => {
                document.removeEventListener('mousedown', handleClickOutside);
              };
            }, [isplaylistDropdownOpen]);


          return (
              <form onSubmit={handleSubmit(onSubmit)} className="dark:!bg-[#151515] dark:!text-white">
                <div className="mb-4">
                  <input
                    {...register("title", { required: true })}
                    className="mt-2 block w-full p-2  dark:!bg-[#151515]  border-b-[2px] border-[#F1B942]"
                    placeholder="Schedule to"
                    // defaultValue={calendarEvents?.data[eventIndex]?.t_title }
                  />
                  {errors.title && <div className='text-left'><span className="text-red-600 text-sm ">Please enter your music session name</span></div>}
                </div>

                <div className="mb-4">
                  <label className="block text-xs lg:text-sm text-left font-semibold text-gray-700 dark:!text-white">Description:</label>
                  <textarea
                    {...register("description", { required: true })}
                    className="mt-2 block w-full p-2 dark:!bg-black  rounded-xl bg-[#F1F1F1]"
                  ></textarea>
                  {errors.description && <div className='text-left'><span className="text-red-600 text-sm "> Please enter the description</span></div>}
                </div>

                {/* <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">Playlist</label>
                  <select
                    {...register("playlist", { required: true })}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                  >
                    <option value="">Select a playlist</option>
                    {playlists.map(playlist => (
                      <option className='text-black' key={playlist.id} value={playlist.id}>{playlist?.v_name}</option>
                    ))}
                  </select>
                  {errors.playlist && <span className="text-red-600 text-sm">This field is required</span>}
                </div> */}

                <div className="mb-4">
                  <div>
                  <div>
                  <div  ref={selectPlaylistRef} onClick={()=>setIsplaylistDropdownOpen(pre=>!pre)} className="block text-xs lg:text-sm text-center font-semibold py-3 rounded-xl text-white bg-[#F1B942]  dark:bg-gray-700 dark:!text-white cursor-pointer relative ">Select playlist ({selectedPlaylists?.length}) 
                 {!isplaylistDropdownOpen? <KeyboardArrowDownIcon className='absolute top-1/2 right-3 transform -translate-y-1/2'/>:<KeyboardArrowUpIcon className='absolute top-1/2 right-3 transform -translate-y-1/2'/>}
                  </div>
                  </div>
                  <div ref={multiselectorRef}>
                  <Multiselector isPlaylistDropdownOpen={isplaylistDropdownOpen} setSelectedPlaylists={setSelectedPlaylists} selectedPlaylists={selectedPlaylists} options={allUniquePlaylists} maxSelectedLabels={allUniquePlaylists?.length}/>
                  </div>
                  </div>

                  {/* <label className="block text-xs lg:text-sm text-left font-semibold text-gray-700 dark:!text-white">Select playlist</label> */}
                  {/* <div ref={dropdownRef}>
                  <MultiSelect value={selectedPlaylists} onChange={handlePlaylistChange} options={allUniquePlaylists}  optionLabel="v_name"  filter placeholder="Select Playlists" maxSelectedLabels={3} className="w-full mt-2 md:w-full  bg-[#F1F1F1] !accent-[#F1B942] dark:!bg-black"/>
                  </div> */}

                  { errPlaylist && <div className={`text-left`}><span className="text-red-600 text-sm "> Please choose playlists </span></div>}
                  
                </div>

                <div className="mb-4">
                  <label className="block text-xs lg:text-sm text-left font-semibold text-gray-700 dark:!text-white">Select music activity type</label>
                  <div className="mt-2 grid lg:grid-cols-5 grid-cols-2 gap-x-8 gap-y-2">
                    {['Listen', 'Rehearsal', 'Perform', 'Dance', 'Sing', 'Study', 'Other'].map(type => (
                      <label key={type} className="flex items-center">
                        <input
                          {...register("activityType", { required: true })}
                          type="radio"
                          value={type}
                          className={`appearance-none w-4 h-4 border-[2px] ${errors && errors.activityType ?"border-red-700":"border-[#D9D9D9]"} border-[#D9D9D9] checked:bg-[#F1B942] rounded-full`}
                        />
                        <span className="ml-2"> {type.charAt(0).toUpperCase() + type.slice(1)}</span>
                      </label>
                    ))}
                  </div>
                  {errors.activityType && <div className='text-left'><span className="text-red-600 text-sm dark:!text-white"> Please select your music activity type</span></div>}
                </div>

                <div className="mb-4 grid lg:grid-cols-2 gap-5 content-center">
                  <div className=''>
                    <label className="block text-xs lg:text-sm text-left font-semibold text-gray-700 dark:!text-white">Date</label>
                    <div className='h-200px relative dark'>
                    {/* <Calendar
                      {...register("date", { required: true })}
                      value={ watchedDate < today? today : watchedDate}
                      // value={control?.watch("date")}
                      // type="date"
                      className="mt-2 !text-black !text-sm !py-3 lg:!py-1 w-full  dark:!filter dark:!invert "
                      showButtonBar
                      showIcon
                      minDate={today}
                      placeholder="DD/MM/YYYY" 
                      dateFormat="dd/mm/yy"    
                      
                    /> */}

                    <DatePicker 
                      {...register("scheduleDate")}
                      selected={startDate}
                      onChange={(date) => setValue('scheduleDate', date)}
                      dateFormat="dd/MM/yyyy"
                      className="w-full px-3  dark:!text-white dark:bg-black py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                      minDate={new Date()}
                    />

                    </div>
         
                    {errDate && <div className='text-left'><span className="text-red-600 text-sm ">Please select the date</span></div>}
                  </div>

                  <div className="mb-4">
                    <label className="block text-xs lg:text-sm text-left font-semibold text-gray-700 dark:!text-white">Time</label>
                    <div className='flex justify-between items-center gap-x-5  mt-2'>
                      {/* Start Time Input */}
                      <input
                       {...register("starttime", { required: true })}
                        type="time"
                        // value={startTime}
                        min={preTime} 
                        onChange={(e) => setStartTime(e.target.value)}
                        className="mt-1 block w-full !text-sm mx-0 p-0 lg:p-0 rounded-md  dark:bg-white dark:!text-black dark:filter  dark:invert "
                      />
                      {/* End Time Input */}
                      <input
                        {...register("endtime", { required: true })}
                        type="time"
                        min={startTime} // Dynamically set the minimum time based on the start time
                        className=" mt-1 block w-full !text-sm mx-0  p-0 lg:p-0 rounded-md  dark:bg-white dark:!text-black dark:filter  dark:invert "
                      />
                    </div>
                    {errors.endtime && <div className='text-left'><span className="text-red-600 text-sm "> Please select the time</span></div>}
                  </div>
                </div>

                {/* <div className="mb-4">
                  
                </div> */}

                <div className="flex justify-start items-center">
                  {/* <div className='col-span-1'>
                    <input
                      {...register("allDay")}
                      type="checkbox"
                      className="form-checkbox"
                    />
                    <span className="ml-2">All Day</span>
                  </div> */}

                  {/* <div className=''>
                    <input
                      {...register("repeat")}
                      type="checkbox"
                      className="form-checkbox items-center accent-[#F1B942]"
                    />
                    <span className="ml-2">Repeat</span>
                  </div> */}

                  <label className="flex items-center cursor-pointer">
                    <input
                      {...register("repeat")}
                      type="checkbox"
                      className="form-checkbox accent-[#F1B942]"
                    />
                    <span className="ml-2">Repeat</span>
                  </label>
                </div>

                {isrepeat && <div>
                <div className="block text-xs lg:text-sm text-left font-semibold text-gray-700 mt-10 dark:!text-white">Select frequency</div>

                <div className="flex flex-wrap gap-3 my-3">
                  <button type="button" onClick={() => handleFrequencyClick('daily')} className={clsx("text-xs lg:text-sm px-4 py-1 rounded-full", { 'bg-[#F1B942] text-white': frequency === 'daily', 'bg-white text-[#F1B942] border-[1px] border-[#F1B942] dark:bg-[#151515] dark:text-white': frequency !== 'daily' })}>Daily</button>

                  <button type="button" onClick={() => handleFrequencyClick('wednesday')} className={clsx("text-xs lg:text-sm px-4 py-1 rounded-full", { 'bg-[#F1B942] text-white': frequency === 'wednesday', 'bg-white text-[#F1B942] border-[1px] border-[#F1B942] dark:bg-[#151515] dark:text-white': frequency !== 'wednesday' })}>Every wednesday</button>

                  <button type="button" onClick={() => handleFrequencyClick('weekend')} className={clsx("text-xs lg:text-sm px-4 py-1 rounded-full", { 'bg-[#F1B942] text-white': frequency === 'weekend', 'bg-white text-[#F1B942] border-[1px] border-[#F1B942] dark:bg-[#151515] dark:text-white': frequency !== 'weekend' })}>Every weekend</button>
                </div>

                <div className="block text-xs lg:text-sm text-left font-semibold text-gray-700 mt-10 dark:!text-white">Or manually select</div>
                <div className="flex space-x-2 my-3">
                  {daysOfWeek?.map(day => (
                    <button key={day} type="button" onClick={() => handleDayClick(day)} className={clsx("text-xs lg:text-sm w-8 h-8 flex items-center justify-center rounded-full border dark:bg-[#151515] dark:text-white", { 'bg-[#F1B942] text-white': days?.includes(day), 'bg-white text-[#F1B942] border-[1px] border-[#F1B942]': !days?.includes(day)})}>{day}</button>
                  ))}
                </div>
                </div>}


                <div className="mt-4">
                  <button
                    type="submit"
                    
                    className="w-full inline-flex justify-center px-4 py-2 bg-[#F1B942] text-white font-medium rounded-md hover:bg-yellow-600"
                  >
                    {calendarEvents?.data?.events[eventIndex]?.t_title?"Update":"Save"}
                  </button>
                </div>
              </form>
  )
}

export default CreateCalenderModal
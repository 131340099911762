import { createSlice } from "@reduxjs/toolkit";
const isDarkLocal =  JSON.parse(localStorage.getItem("isDark"))

const initialState = {
    isDarkValue: isDarkLocal?isDarkLocal:false
};

// Slice
export const darkThemeSlice = createSlice({
  name: "dark",
  initialState,
  reducers: {
    handleDarkTheme(state,action){
        state.isDarkValue = action.payload;
        console.log("typeof", typeof(action.payload),action.payload )
        localStorage.setItem('isDark',action.payload)
      },
  },
});

export const {handleDarkTheme} = darkThemeSlice.actions;

export default darkThemeSlice.reducer;


import React, { useEffect, useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material'; // Import icons for visibility
import { useSelector } from 'react-redux';

const InputField = ({ register, disable, type, placeholder, name, required, errors, className,styleProp,initialValue, ...rest }) => {
  console.log("intitialValueEdit",initialValue);
  const isDark = useSelector((state) => state.dark.isDarkValue);

  const [showPassword, setShowPassword] = useState(false);
  const [value, setValue] = useState(initialValue || '');
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleChange = (e) => {
    setValue(e.target.value);
  };

  console.log("value",value);
  useEffect(() => {
    setValue(initialValue || ''); // Update value if initialValue changes
  }, [initialValue]);

  console.log("errors",errors);

  const inputProps = {
    ...(type === 'number' && { inputMode: 'numeric' }), // Set input mode to numeric for number type
    ...(type === 'number' && { pattern: '\\d*' }), // Pattern to allow only numbers
  };

  return (
    <>
      <div className={`relative ${styleProp?styleProp:""}`}>
        {(type !== 'password' && type !== 'date') &&
        <input
          type={type!=='password'?type:showPassword ? 'text' : 'password'}
          className={`w-[80%] ${isDark?"loginSignUp":""} mx-auto text-center border-[2px] border-white focus:border-[2px] dark:!border-black bg-[#FAFAF8] rounded-[50px] dark:!bg-[#000000] dark:!text-[#C1C1C1] text-[#312522] text-[16px] font-normal py-2 my-1 ${errors && errors[name] && (errors[name].type === 'required' || errors[name].type === 'pattern') ? "!border-[#D80027] focus:!border-[#D80027]" : "focus:!border-[#F1B942]"} ${type==="date"?"!uppercase":""} ${className}`}
          placeholder={placeholder}
         
          {...register(name, {
            required: required === true ? 'This field is required' : false,
            ...rest
          })}
           value={value}
          onChange={handleChange}
          disabled={disable}
          {...inputProps}
        />}

        {type==='password'&& <input
          type={type!=='password'?type:showPassword ? 'text' : 'password'}
          className={`w-[80%] ${isDark?"loginSignUp":""}  mx-auto text-center border-[2px] border-white focus:border-[2px] dark:!border-black bg-[#FAFAF8] rounded-[50px] dark:!bg-[#000000] dark:!text-[#C1C1C1] text-[#312522] text-[16px] font-normal py-2 my-1 ${errors && errors[name] && (errors[name].type === 'required' || errors[name].type === 'pattern') ? "!border-[#D80027] focus:!border-[#D80027]" : "focus:!border-[#F1B942]"} ${className}`}
          placeholder={placeholder}
          
          {...register(name, {
            required: required === true ? 'This field is required' : false,
            ...rest
          })}
          disabled={disable}
        />}
        {/* Button to toggle password visibility */}
        {type === 'password' && (
          <button
            type="button"
            className="absolute inset-y-0 right-[10%] flex items-center pr-1 text-[#DADADA] cursor-pointer"
            onClick={togglePasswordVisibility}
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </button>
        )}

{type === 'date' && (
  <input
    type="date"
    className={` w-full lg:w-[80%] uppercase mx-auto text-center border-[2px] dark:!border-black border-white focus:border-[2px]  bg-[#FAFAF8] rounded-[50px] text-[#312522] text-[16px] font-normal py-2 my-1 ${errors && errors[name] && (errors[name].type === 'required' || errors[name].type === 'pattern') ? "!border-[#D80027] focus:!border-[#D80027]" : "focus:!border-[#F1B942]"} ${className}`}
    placeholder={placeholder}
    {...register(name, {
      required: required === true ? 'This field is required' : false,
      validate: (value) => {
        const selectedDate = new Date(value);
        const currentDate = new Date();
        // Check if the selected date is after the current date
        if (selectedDate > currentDate) {
          return "Date of birth cannot be in the future";
        }
        return true;
      },
      ...rest
    })}
    max={new Date().toISOString().split("T")[0]} // Set max attribute to today's date
    value={value}
    onChange={handleChange}
    disabled={disable}
  />
)}
      </div>
    </>
  );
}

export default InputField;


import React from 'react'
import { Link } from 'react-router-dom'

const GlobalProfileListing = ({listing}) => {
    console.log("listing",listing)
  return (
    <div className='grid lg:grid-cols-6 md:grid-cols-3 grid-cols-2 gap-3 mt-10'>
        {listing?.map((profile)=>{
            return(
                <div>
                    <Link to={`/other-user/${profile?.id}`}className='text-center flex justify-center '>
                        <div className='h-32 w-32 flex justify-center'>
                            <img src={profile?.v_profile_pic} alt='' className='rounded-full w-full h-full object-cover'/>
                        </div>
                        
                    </Link>
                    <p className='my-3 text-center dark:text-[#D9D9D9] line-clamp-1'>{ profile?.v_name?.length > 7 ? profile?.v_name?.slice(0, 10) + "..." : profile?.v_name}</p>
                </div>
            )
        })}
    </div>
  )
}

export default GlobalProfileListing
import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import "./index.css";
import { BrowserRouter, Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import store from "./store";
import HomePage from "./pages/HomePage/HomePage";
import GlobalLayout from "./view/components/Layout/GlobalLayout";
import NotFound from "./pages/NotFound/NotFound";
import ProtectedRoutes from "./ProtectedRoutes";
import Profile from "./pages/Profile/Profile";
import Library from "./pages/Library/Library";
import Collection from "./pages/Collection/Collection";
import TrackPage from "./pages/Collection/SubPages/CollectionListing/TrackPage";
import PlaylistPage from "./pages/Collection/SubPages/CollectionListing/PlaylistPage";
import ArtistsPage from "./pages/Collection/SubPages/CollectionListing/ArtistsPage";
import AlbumsPage from "./pages/Collection/SubPages/CollectionListing/AlbumsPage";
import GenresPage from "./pages/Collection/SubPages/CollectionListing/GenresPage";
import DecadesPage from "./pages/Collection/SubPages/CollectionListing/DecadesPage";
import GeosPage from "./pages/Collection/SubPages/CollectionListing/GeosPage";
import PlaylistDetail from "./pages/Collection/SubPages/CollectionDetail/PlaylistDetail";
import ArtistsDetail from "./pages/Collection/SubPages/CollectionDetail/ArtistsDetail";
import AlbumsDetail from "./pages/Collection/SubPages/CollectionDetail/AlbumsDetail";
import GenresDetail from "./pages/Collection/SubPages/CollectionDetail/GenresDetail";
import DecadesDetail from "./pages/Collection/SubPages/CollectionDetail/DecadesDetail";
import GeosDetail from "./pages/Collection/SubPages/CollectionDetail/GeosDetail";
import Studio from "./pages/Studio/Studio";
import Inspiration from "./pages/Studio/Subpages/Inspiration";
import MoodRecolonization from "./pages/Studio/Subpages/MoodRecolonization";
import Schedule from "./pages/Studio/Subpages/Schedule";
import YoutubeLogin from "./pages/Youtube/YoutubeLogin";
import Premium from "./pages/Premium/Premium";
import Pro from "./pages/Pro/Pro";
import About from "./pages/FooterPages/About";
import Help from "./pages/FooterPages/Help";
import TermsConditions from "./pages/FooterPages/TermsConditions";
import Privacy from "./pages/FooterPages/Privacy";
import CopyrightPolicy from "./pages/FooterPages/CopyrightPolicy";
import Contact from "./pages/FooterPages/Contact";
import SelectPrefrences from "./pages/SelectPrefences/SelectPrefrences";
import UserAccount from "./pages/UserAccountPage/UserAccount";
import SettingPage from "./pages/Setting/SettingPage";
import ManagePlaylist from "./pages/ManagePlaylist/ManagePlaylist";
import TermsOfService from "./pages/TermsOfService/TermsOfService";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import PopupWindow from "./pages/PopupWindow/PopupWindow";
import Subscription from "./pages/Subscription/Subscription";
import Payment from "./pages/Subscription/Payment";
import Success from "./pages/Subscription/Success";
import { CreatePlaylist } from "./pages/CreatePlaylist/CreatePlaylist";
import GlobalSearch from "./pages/GlobalSearchPage/GlobalSearch";
import GlobalProfileDetail from "./pages/GlobalProfileDetail";
import { register } from './serviceWorker';
import MusicAnalyzer from "./pages/MusicAnalyzer/MusicAnalyzer";
import Community from "./pages/Community/Community";
import NavigationGuard from "./view/components/NavigationGuard";
import SentRequest from "./pages/Community/Following/SentRequest";
import InstagramFriendsFollowing from "./pages/Community/Following/InstagramFriendsFollowing";
import FacebookFriendsFollowing from "./pages/Community/Following/FacebookFriendsFollowing";
import CuriousHitFollowing from "./pages/Community/Following/CuriousHitFollowing";
import FollowingPage from "./pages/Community/Following/FollowingPage";
import FollowerPage from "./pages/Community/Followers/FollowerPage";
import CommunityLayoutGlobal from "./view/components/CommunityComponents/Layout/CommunityLayoutGlobal";
import PendingRequest from "./pages/Community/Followers/PendingRequest";
import InstagramFriendsFollower from "./pages/Community/Followers/InstagramFriendsFollower";
import FacebookFriendsFollower from "./pages/Community/Followers/FacebookFriendsFollower";
import CuriousHitFollower from "./pages/Community/Followers/CuriousHitFollower";
import CommunityLayoutFollower from "./view/components/CommunityComponents/Layout/CommunityLayoutFollower";

import setupLocatorUI from "@locator/runtime";

if (process.env.NODE_ENV === "development") {
  setupLocatorUI();
}


const router = createBrowserRouter(createRoutesFromElements(
  // <Route path='/' element={<MainLayout/>}>

  <Route path='/' element={<GlobalLayout/>}>
      <Route index element={<HomePage/>}/>
      <Route path="/user-callback-spotify" element={<PopupWindow />}/>
      <Route path="/callback-apple" element={<PopupWindow />}/>
      <Route path="/pro" element={<Pro />}/>

      <Route element={<ProtectedRoutes/>}>
        <Route path='terms-of-service' element={<TermsOfService/>}/>
        <Route path='privacy-policy' element={<PrivacyPolicy/>}/>
        <Route path='terms-conditions' element={<TermsConditions/>}/>
        <Route path='privacy' element={<Privacy/>}/>
        <Route path='profile' element={<Profile/>}/>
        <Route path='library' element={<Library/>}/>
        {/* <Route path='collection' element={<Collection/>}/> */}
        <Route path='collection/tracks' element={<TrackPage/>}/>
        <Route path='collection/playlists' element={<PlaylistPage/>}/>
        <Route path='collection/playlists/:id' element={<PlaylistDetail/>}/>
        <Route path='collection/artists' element={<ArtistsPage/>}/>
        <Route path='collection/artists/:id' element={<ArtistsDetail/>}/>
        <Route path='collection/albums' element={<AlbumsPage/>}/>
        <Route path='collection/albums/:id' element={<AlbumsDetail/>}/>
        <Route path='collection/genres' element={<GenresPage/>}/>
        <Route path='collection/genres/:id' element={<GenresDetail/>}/>
        <Route path='collection/decades' element={<DecadesPage/>}/>
        <Route path='collection/decades/:id' element={<DecadesDetail/>}/>
        <Route path='collection/geos' element={<GeosPage/>}/>
        <Route path='collection/geos/:id' element={<GeosDetail/>}/>
        <Route path='collection/manage-playlist' element={<ManagePlaylist/>}/>
        <Route path='studio' element={<Studio/>}/>
        <Route path='studio/inspiration' element={<Inspiration/>}/>
        <Route path='studio/mood-recolonization' element={<MoodRecolonization/>}/>
        <Route path='studio/schedule' element={<Schedule/>}/>
        <Route path='youtube-login' element={<YoutubeLogin/>}/>
        <Route path='premium' element={<Premium/>}/>
        <Route path='subscription' element={<Subscription/>}/>
        <Route path='about' element={<About/>}/>
        <Route path='help' element={<Help/>}/>
        <Route path='copyright-policy' element={<CopyrightPolicy/>}/>
        <Route path='contact' element={<Contact/>}/>
        <Route path='user-account' element={<UserAccount/>}/>
        <Route path='community' element={<Community/>}/>
        <Route path="prefrences" element={<SelectPrefrences/>}/>
        <Route path="setting" element={<SettingPage/>}/>
        <Route path="payment/:vcode" element={<Payment/>}/>
        <Route path="success/:id" element={<Success/>}/>
        <Route path="/callback/:platform" element={<PopupWindow />}/>
        <Route path="/create-playlist" element={<CreatePlaylist />}/>
        <Route path="/global-search" element={<GlobalSearch />}/>
        <Route path="/other-user/:id" element={<GlobalProfileDetail />}/>
        <Route path="music-analyzer" element={<MusicAnalyzer />}/>
        <Route path="/community/following" element={<CommunityLayoutGlobal />}>
          <Route index element={<FollowingPage />}/>
          <Route path="sent-request" element={<SentRequest />}/>
          <Route path="instagram-friends-following" element={<InstagramFriendsFollowing />}/>
          <Route path="facebook-friends-following" element={<FacebookFriendsFollowing />}/>
          <Route path="curiousHit-following" element={<CuriousHitFollowing />}/>
        </Route>

        <Route path="/community/follower" element={<CommunityLayoutFollower />}>
          <Route index element={<FollowerPage />}/>
          <Route path="pending-requests" element={<PendingRequest />}/>
          <Route path="instagram-friends-follower" element={<InstagramFriendsFollower />}/>
          <Route path="facebook-friends-follower" element={<FacebookFriendsFollower />}/>
          <Route path="curiousHit-follower" element={<CuriousHitFollower />}/>
        </Route>
      
        <Route path="*" element={<NotFound/>}/>
      </Route>
  </Route>
))

 if ('serviceWorker' in navigator) {
  window.addEventListener('load', function() {
     navigator.serviceWorker.register('/service-worker.js').then(function(registration) {
       // Registration was successful
       console.log('ServiceWorker registration successful with scope: ', registration.scope);
     }, function(err) {
       // registration failed :(
       console.log('ServiceWorker registration failed: ', err);
     });
  });
 }


const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <RouterProvider router={router}/>
  </Provider>
  // </React.StrictMode>
);

register();

// {
//   "name": "Mood Regulation",
//   "route": "/studio/mood-recolonization",
//   "icon":"heart"

// }

// {
//   "name": "Music Analyzer",
//   "route": "/music-analyzer",
//   "icon":"ri-equalizer-line"

// },



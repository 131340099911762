import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function FilterDropdownTitle({title}){
    return(
        <div className="w-full h-[45px] rounded-[50px] flex justify-between items-center bg-[#FAFAF8] px-3 my-4">
            <p className='text-[#312522] text-[16px] font-normal capitalize'>{title}</p>
            <div>
                <ExpandMoreIcon/>
            </div>
        </div>
    )
}

export default FilterDropdownTitle
import { useCallback, useEffect, useState } from "react";

function Stepper1({data,viewportHeight,setSelectedCategoryList,selectedCategoryList,setPage,lastPage,page}){
  
  useEffect(() => {
    const savedGenres = localStorage.getItem("selectedGenres");
    if (savedGenres) {
      const parsedGenres = JSON.parse(savedGenres);
      setSelectedCategoryList(parsedGenres);
    }
  }, []);

  const onSelectedCategoryChangeHandler = useCallback(
    (event, categoryListData) => {
      const { id } = categoryListData;
      setSelectedCategoryList((prevSelectedCategories) => {
        if (event.target.checked) {
          // Add the selected genre if checked
          return [...prevSelectedCategories, id];
        } else {
          // Remove the deselected genre if unchecked
          return prevSelectedCategories.filter((genre) => genre !== id);
        }
      });
    },
    []
  );


    return(
      <div>
          <h6 className="text-black text-[16px] lg:text-[24px] dark:text-white font-medium  my-3"> What music style do you like ?</h6>

          <div className="flex justify-between items-center h-full flex-col lg:flex-row">
            <button className="hidden lg:block" onClick={()=>page>1?setPage(pre=>pre-1):setPage(lastPage)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="31" viewBox="0 0 17 31" fill="none">
                <path d="M15 2L1.5 15.5L15 29" stroke="#D8D8D8" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
            <div className={`grid grid-cols-2  gap-y-2 gap-x-5 p-3 ${viewportHeight<1000?"lg:grid-cols-5":"lg:grid-cols-4"}  overflow-scroll`}>
            {data?.map((item)=>{
                return(
                    <div key={item?.id} className="p-2 ">
                        <div className="flex justify-between my-2 items-center h-10">
                            <p className="dark:text-white">{item?.t_genres}</p>
                            <label class="inline-flex items-center cursor-pointer">
                            <input type="checkbox"  checked={selectedCategoryList.includes(
                                    item?.id
                                )} value="" class="sr-only peer"   onChange={(event) =>
                                  onSelectedCategoryChangeHandler(
                                      event,
                                      item
                                  )
                                  } />
                            <div class="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-[#F1B942] dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#F1B942]"></div>
                          
                          </label>
                            {/* <div className="custom-control custom-switch">
                            <input
                                type="checkbox"
                                checked={selectedCategoryList.includes(
                                    item?.id
                                )}
                                onChange={(event) =>
                                onSelectedCategoryChangeHandler(
                                    event,
                                    item
                                )
                                }
                                className="custom-control-input"
                                id={item?.id}
                            />
                            <label
                                className="custom-control-label"
                                htmlFor={item?.id}
                            ></label>
                            </div> */}
                        </div>
                        <div className="w-full h-[150px] rounded-md">
                            <img src={item?.t_image} alt=""  className="w-full object-cover rounded-md h-full"/>
                        </div>
                    </div>
                )
            }) }
            </div>
            <button  className="hidden lg:block" onClick={()=>page<lastPage?setPage(pre=>pre+1):setPage(1)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="31" viewBox="0 0 17 31" fill="none">
                <path d="M2 2L15.5 15.5L2 29" stroke="#D8D8D8" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
        </div>
      </div>
       
       
    )
}

export default Stepper1


  
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
 isSideBar:false,
 isloginSidebar:false
};

// Slice
export const mobileSideBarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    handleCloseSidebar(state){
        state.isSideBar = false;
      },
      handleOpenSidebar(state){
        state.isSideBar = true;
      },
      handleLoginSidebar(state,action){
        state.isloginSidebar = action.payload;
      },
  },
});

export const {handleCloseSidebar,handleOpenSidebar,handleLoginSidebar} = mobileSideBarSlice.actions;

export default mobileSideBarSlice.reducer;
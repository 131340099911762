import { useDispatch } from "react-redux"
import { handleOpenStepper } from "../../../store/stepperSlice"
import StepperTitle from "../StepperComponents/StepperTitle"
import { useCallback, useEffect, useState } from "react"
import Stepper1 from "../StepperComponents/Stepper1"
import Stepper2 from "../StepperComponents/Stepper2"
import Stepper3 from "../StepperComponents/Stepper3"
import { useApi } from "../../../api/api"
import SkipButton from "../StepperComponents/Button/SkipButton"
import NextButton from "../StepperComponents/Button/NextButton"
import {  ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button1 from "../common/GlobalButtonLayout/Button1"
import { useNavigate } from "react-router"
import { Link } from "react-router-dom"
import globalToast from "../../../hooks/globalToast"
import { profileDetail } from "../../../store/authenticationSlice"


const Stepper = () => {
    const dispatch = useDispatch()
    const [step, setStep] = useState(1);
    const [inputValue, setInputValue] = useState('');
    const [ull, setUll]  = useState()
    const [isMusicProfessional, setIsMusicProfessional] = useState(false);

    const { handleGetRequest, handlePostRequest } = useApi();
    const[title,setTitle] = useState("")
    const [genres, setGenres] = useState([]);
    const [selectedCategoryList, setSelectedCategoryList] = useState([]);
    const [page, setPage] = useState(1);
    const [selectedWorldMapCountry, setSelectedWorldMapCountry] = useState([]);
    const [decadeList, setDecadeList] = useState([]);
    const [selectedDecade, setSelectedDecade] = useState([]);
    const [musicLanguageList, setMusicLanguageList] = useState([]);
    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const [genreStepper, setGenreStepper] = useState([]);
    const [selectedPlatformList, setSelectedPlatformList] = useState();
    const [platformList, setPlatformList] = useState()
    const [syncSpecificPlaylist, setSyncSpecificPlaylist] = useState([])
    const [selectedPlaylists, setSelectedPlaylists] = useState([]);
    const [otherDetails, setOtherDetails] = useState("");
    const [professionalMusic, setProfessionalMusic] = useState("No");
    const [playlistTrackCount, setPlaylistTrackCount] = useState();
    const [syncPlatform, setSyncPlatform] = useState([])

    const [selectedMusicProfessional, setSelectedMusicProfessional] = useState(
        []
      );
    const [isSync,setIsSync] = useState(false)
    const [isSyncCard, setIsSyncCard] = useState(false)
    const [isSyncSpecificPlaylistCard, setisSyncSpecificPlaylistCard] = useState(false)




    const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
    useEffect(()=>{
        if(step===1){
          setTitle("")
        }
    
        if(step===2){
          setTitle("")
        }
    
        if(step===3){
          setTitle("")
        }
      },[step])
    
    useEffect(() => {
        (async () => {
            if(step===1){
                const genresPlayload = {
                    type:"genres",
                    current_page: page,
                    per_page:viewportHeight<1000? 10: 12
                }
              const genresStepperRes = await handlePostRequest(`users/music-preferences/get`,genresPlayload, false, true);
              if(genresStepperRes?.flag){
                  setGenres(genresStepperRes)
              }
            }

            if(step===2){
                const decadesPlayload = {
                    type:"decades",
                    current_page: 1,
                    per_page:10000
                }

                const languagesPlayload = {
                    type:"languages",
                    current_page: 1,
                    per_page:10000
                }
              const languagesStepperRes = await handlePostRequest(`users/music-preferences/get`,languagesPlayload, false, true);
              console.log("languagesStepperRes",languagesStepperRes?.data?.data);
              
              if(languagesStepperRes?.flag){
                  setMusicLanguageList(languagesStepperRes?.data?.data)
              }

              const decadesStepperRes = await handlePostRequest(`users/music-preferences/get`,decadesPlayload, false, true);
              if(decadesStepperRes?.flag){
                  setDecadeList(decadesStepperRes)
              }
            }
           
        })();
      }, [step,page]);

      const onMusicPrefrenceHandler = async(steps) => {
        if (steps === 1) {
          const genresPayload={
            genres: selectedCategoryList
          }
          if(selectedCategoryList.length <1 ){
            // toast.error("Please select at least one music genre.");
            globalToast("Please select at least one music genre.",true)

            return;
          }
          const genresStepperRes = await handlePostRequest(`users/update-genres`,genresPayload, false, true);
          if (genresStepperRes?.flag) {
            // toast.success("Your genre saved successfully.") 
            globalToast("Your genre saved successfully.")

            setStep(2)
          }
      }

      if (steps === 2) {
        const genresPayload={
          geos: selectedWorldMapCountry,
          decades:selectedDecade,
          languages:selectedLanguages
        }
        // selectedLanguages.length===0 &&
        if( selectedDecade.length===0 && selectedWorldMapCountry.length===0){
          // toast.error("Select your music preference");
          globalToast("Select your music preference",true)

          return;
        }else if(selectedWorldMapCountry.length===0){
          // toast.error("Select your Country");
          globalToast("Please select your preferred music-listening country to continue",true)

          return;
        }else if(selectedDecade.length===0){
          // toast.error("Select your preferred Decade");
          globalToast("Select your preferred Decade",true)

          return;
        }else if(selectedLanguages.length===0){
          globalToast("Please select your preferred music-listening languages to continue",true);
          return;
        }
        const secondStepperRes = await handlePostRequest(`users/update-regions-languages-decades`,genresPayload, true, true);
        if (secondStepperRes?.flag) {
          // toast.success("Your music preference saved successfully.")
          globalToast("Your music preference saved successfully.")

          setStep(3)
        }
    }
    }

      // const onMusicPrefrenceHandler = useCallback(
      //   async (steps,payloads) => {
    
      //     if (selectedCategoryList.length <1 &&  steps === 1) {
      //       toast.error("Please select at least one music genre.");
      //     return;
      //       }
            
      //       if(steps===2){
      //         if (payloads.geos.length === 0 && payloads.languages.length=== 0 && payloads.decades.length === 0) {
      //           toast.error("Select your music preference");
      //           return;
      //         } else {
      //           if (payloads.geos.length === 0) {
      //             toast.error("Select your Country");
      //             return;
      //           }
      //           if (payloads.languages.length === 0) {
      //             toast.error("Select your preferred  Languages");
      //             return;
      //           }
      //           if (payloads.decades.length === 0) {
      //             toast.error("Select your preferred Decade");
      //             return;
      //           }
      //         }
      //       }
      //       try {
      //         dispatch(handleOpenLoader());
      //         const responseSlectedMusic = await onMusicPrefrenceSubmitHandler(
      //           storeData?.token,
      //           payloads,
      //           steps===1?"users/update-genres":"users/update-regions-languages-decades"
      //         );
    
      //         if (responseSlectedMusic.status === 200) {
      //           // setSignUpInterestedPage("worldMap");
      //            toast.success("Your genre saved successfully.", {
      //               position: toast.POSITION.TOP_RIGHT,
      //           }) 
    
      //           toast.success("Your music preference saved successfully.", {
      //             position: toast.POSITION.TOP_RIGHT,
      //         })
      //           steps===1? setStep(2) : setStep(3)
      //         }
      //       } catch (e) {
              
      //       }finally{
      //         dispatch(handleCloseLoader());
      //       }
          
      //   },
      //   [selectedCategoryList]
      // );

      const [selectedPlatform, setSelectedPlatform] = useState()
      const [popupWindow,setPopupWindow] = useState()
    const [syncList, setSyncList] = useState([])

    const navigate = useNavigate()



    const connectPlatform = async(platform) => {
        console.log("connectPlatform",selectedPlatform);
        if(!selectedPlatform){
          // toast.error("Please select platform")
          globalToast("Please select platform",true)
          return
        }

        // const options = "width=500,height=500 "
        const width = 500;
        const height = 500;
        const left = window.screen.width / 2 - width / 2;
        const top = window.screen.height / 2 - height / 2;
      
        const options = `width=${width},height=${height},left=${left},top=${top}`;
        const connectPlatformRes = await handleGetRequest(`${selectedPlatform}/login`, false,true);
        // const connectPlatformRes = await handleGetRequest(`dailymotion/login`, false);
        const redirectUrlRes = connectPlatformRes?.data?.url;
        console.log("redirectUrlRes", redirectUrlRes);
        setPopupWindow(window.open(redirectUrlRes,"_blank",options))
        };


        useEffect(() => {
          console.log("callbackUrlUseeffect",selectedPlatform);
        
          const handlePostMessage = async (event) => {
          const selectedPlatformCallback = JSON.parse(localStorage.getItem("platform"))
  
            const {state} = event.data 
            const {code} = event.data 
            const {uid} = event.data 
  
            const platformsPayload = {
              code:code,
              state:state
            }
        
            if (event.origin === "https://curioushit-frontend.nevinainfotech.in" && selectedPlatformCallback) {
              // Assuming handlePostRequest is defined elsewhere and takes appropriate arguments
              const callbackRes = await handlePostRequest(`${selectedPlatformCallback}/callback`, selectedPlatformCallback==="dailymotion"? event.data: platformsPayload, true,true);
              console.log("callbackRes", callbackRes?.flag);
              if(callbackRes?.flag){
              const syncRes = await handleGetRequest(`${selectedPlatformCallback}/sync?state=${state}${selectedPlatformCallback==="dailymotion"?`&uid=${uid}`:''}`, true, true);
              console.log("syncRes",syncRes);
              if(syncRes?.flag){
                // const syncedListRes = await handleGetRequest(`sync-result`, false, true);
                // if(syncedListRes?.flag){
                //     console.log("syncedListRes", syncedListRes?.data?.syn_list);
                //     setSyncList(syncedListRes?.data?.syn_list)
                //     dispatch(handleOpenStepper(false))
                // }

                setSyncSpecificPlaylist(syncRes?.data?.playlistSync)
                setIsSyncCard(false)
                setisSyncSpecificPlaylistCard(true)
                const profileDataReq = await handleGetRequest("profile-detail",false, true)
                dispatch(profileDetail(profileDataReq?.data))
              }
              }
            }
          };
        
          window.addEventListener("message", handlePostMessage, false);
        
          return () => {
            window.removeEventListener("message", handlePostMessage);
          };
        }, []); // Empty dependency array to run effect only once




        const handleSyncSpecificPlaylistSync = async() => {

          console.log("selectedPlaylists",selectedPlaylists);
  
             if(!selectedPlaylists?.length>0){
              alert("select playlist")
                return
              }
          
                const selectedPlatformCallback = JSON.parse(localStorage.getItem("platform"))
  
                const payload = {
                  playlist_ids:selectedPlaylists
                }
  
                const syncedKeepPlaylistRes = await handlePostRequest(`${selectedPlatformCallback}/keepSelectPlayList`,payload, false,true);
                console.log("syncedKeepPlaylistRes", syncedKeepPlaylistRes);
                if(syncedKeepPlaylistRes?.flag){
                  setPlaylistTrackCount(syncedKeepPlaylistRes?.data)
                  const syncedListRes = await handleGetRequest(`sync-result`, false,true);
                  if(syncedListRes?.flag){
                      console.log("syncedListRes", syncedListRes?.data?.syn_list);
                      setSyncList(syncedListRes?.data?.syn_list)
                  }
                  const syncListRes = await handleGetRequest(`sync`, false,true);
                  if(syncListRes?.flag){
                      console.log("syncListRes", syncListRes?.data);
                      setSyncPlatform(syncListRes?.data)
                  }
                  setisSyncSpecificPlaylistCard(false)
                  setSelectedPlaylists([])
                  dispatch(handleOpenStepper(false))
                  const profileDataReq = await handleGetRequest("profile-detail",false, true)
                  dispatch(profileDetail(profileDataReq?.data))
  
                }
               
        }



    const close = () => {
        dispatch(handleOpenStepper(false))
    }

    useEffect(() => {
      const disableBackButton = () => {
          window.history.pushState(null, null, window.location.href);
          window.onpopstate = () => {
              window.history.pushState(null, null, window.location.href);
          };
      };

      disableBackButton();

      return () => {
          // Cleanup function
          window.onpopstate = null;
      };
  }, []);

  const [professionalType, setProfessionalType] = useState({
    artist: false,
    curator: false,
    producer: false,
    educator: false,
    other: false,
  });

  const [selectedItems, setSelectedItems] = useState([]);
const getTrueKeys = (obj) => {
  return Object.entries(obj)
    .filter(([key, value]) => value === true)
    .map(([key]) => key);
};

    const handleSaveInstrument = async() => {
    console.log("professionalType",professionalType?.other);
    const anyTrue = Object.values(professionalType).some(value => value === true);
    console.log("isMusicProfessional",anyTrue,isMusicProfessional);
    

    if(!anyTrue && isMusicProfessional){
      globalToast("Please select one !!", true)
      return
    }

    //   const froff = Object.entries(professionalType)
    // .filter(([key, value]) => value === true)
    // .map(([key]) => key);

    const froff = Object.entries(professionalType)
    .filter(([key, value]) => value === true)
    .map(([key]) => (key === 'other' && inputValue ? inputValue : key));

      const payload={
        "music_instruments":selectedItems,
        "proffessions":froff,

      }
      const saveInstrumentRes = await handlePostRequest(`users/update-user-proffession-and-instruments`,payload, true, true);
      console.log("saveInstrumentRes",saveInstrumentRes);
      if(saveInstrumentRes?.flag){
        setIsSync(true)
        setInputValue("")
      }
    }

    const handlestepsOnTop = async(step) => {
      if (step === 2) {
        const genresPayload={
          genres: selectedCategoryList
        }
        if(selectedCategoryList.length <1 ){
          // toast.error("Please select at least one music genre.");
          globalToast("Please select at least one music genre.",true)

          return;
        }
        const genresStepperRes = await handlePostRequest(`users/update-genres`,genresPayload, true, true);
        if (genresStepperRes?.flag) {
          // toast.success("Your genre saved successfully.") 
          globalToast("Your genre saved successfully.")

          setStep(2)
        }
    }
      if(step===1){
        setStep(1)
      }
      
      if (step === 3) {
        const genresPayload={
          geos: selectedWorldMapCountry,
          decades:selectedDecade,
          languages:selectedLanguages
        }
        // selectedLanguages.length===0 &&
        if( selectedDecade.length===0 && selectedWorldMapCountry.length===0){
          // toast.error("Select your music preference");
          globalToast("Select your music preference",true)

          return;
        }else if(selectedWorldMapCountry.length===0){
          // toast.error("Select your Country");
          globalToast("Please select your preferred music-listening country to continue",true)

          return;
        }else if(selectedDecade.length===0){
          // toast.error("Select your preferred Decade");
          globalToast("Select your preferred Decade",true)

          return;
        }else if(selectedLanguages?.length===0){
          globalToast("Please select your preferred music-listening languages to continue");
          return;
        }
        const secondStepperRes = await handlePostRequest(`users/update-regions-languages-decades`,genresPayload, true, true);
        if (secondStepperRes?.flag) {
          // toast.success("Your music preference saved successfully.")
          globalToast("Your music preference saved successfully.")

          setStep(3)
        }
    }
     
    }
    return(
        <div className=" fixed bg-[#FAFAF8] z-[999] w-full h-full dark:bg-black">
            <ToastContainer />
            {/* <p className="text-white"  onClick={()=>close()}>Stepper</p> */}
            <div className="h-full overflow-hidden m-3 py-2 px-1 lg:px-5 filter drop-shadow-md rounded-xl dark:bg-[#151515] bg-white flex flex-col">
            <StepperTitle setStep={setStep} step={step} title={title} handlestepsOnTop={handlestepsOnTop} />
            <div className=" overflow-scroll flex-grow ">
                {step === 1 && <Stepper1 lastPage={genres?.data?.last_page} data={genres?.data?.data} setSelectedCategoryList={setSelectedCategoryList} selectedCategoryList={selectedCategoryList} viewportHeight={viewportHeight} page={page} setPage={setPage}  />}

                {step === 2 && <Stepper2 selectedWorldMapCountry={selectedWorldMapCountry} setSelectedWorldMapCountry={setSelectedWorldMapCountry} setSelectedDecade={setSelectedDecade} selectedDecade={selectedDecade} decadeList={decadeList?.data?.data}  musicLanguageList={musicLanguageList} setSelectedLanguages={setSelectedLanguages} selectedLanguages={selectedLanguages}/>}

                {step === 3 && <Stepper3 setSelectedItems={setSelectedItems} selectedItems={selectedItems} setProfessionalType={setProfessionalType} professionalType={professionalType} setSelectedPlatform={setSelectedPlatform} selectedPlatform={selectedPlatform} syncSpecificPlaylist={syncSpecificPlaylist} handleSyncSpecificPlaylistSync={handleSyncSpecificPlaylistSync} setSelectedPlaylists={setSelectedPlaylists} selectedPlaylists={selectedPlaylists} isSyncSpecificPlaylistCard={isSyncSpecificPlaylistCard} setisSyncSpecificPlaylistCard={isSyncSpecificPlaylistCard} playlistTrackCount={playlistTrackCount} setPlaylistTrackCount={setPlaylistTrackCount} setInputValue={setInputValue} inputValue={inputValue} isMusicProfessional={isMusicProfessional} setIsMusicProfessional={setIsMusicProfessional}/>}
            </div>
           {step !== 3 && <div className="flex justify-center mt-auto items-center gap-x-3 py-3">
              {step === 1 && <button className="block lg:hidden mx-2" onClick={()=>page>1?setPage(pre=>pre-1):setPage(genres?.data?.last_page)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="31" viewBox="0 0 17 31" fill="none">
                    <path d="M15 2L1.5 15.5L15 29" stroke="#D8D8D8" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </button>}

                {/* <SkipButton setStep={setStep} /> */}
                <NextButton step={step} onMusicPrefrenceHandler={onMusicPrefrenceHandler}/>

               {step === 1 && <button  className="block lg:hidden mx-2" onClick={()=>page<genres?.data?.last_page?setPage(pre=>pre+1):setPage(1)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="31" viewBox="0 0 17 31" fill="none">
                <path d="M2 2L15.5 15.5L2 29" stroke="#D8D8D8" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>}
            </div>}

            {step === 3 && <div className="flex justify-center items-center gap-x-3 py-3 w-[20%] mx-auto">
              {isSync ? <div>
              <Button1 className={`!w-24 !mx-auto !text-center dark:hover:!text-[#F3C25A] ${(syncSpecificPlaylist?.length>0 && isSyncSpecificPlaylistCard )?"hidden":"" }`} onClick={()=>connectPlatform(selectedPlatform)}>Sync</Button1>
              </div>:<div><Button1 className="!w-24 !mx-auto !text-center dark:hover:!text-[#F3C25A]" onClick={()=>handleSaveInstrument()}>Save</Button1></div>}
                {/* <Button1 onClick={()=> dispatch(handleOpenStepper(false))} className="!w-24 dark:hover:!text-[#F3C25A] !mx-auto !text-center" >Finish</Button1> */}
            </div>}
            {/* <NextButton onMusicPrefrenceHandler={onMusicPrefrenceHandler} step={step} payload={payload}/> */}
            </div>
        </div>
    )
}

export default Stepper
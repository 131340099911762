import React from 'react'
import CommonListing from '../../collectionPagesComponents/CommonListing'

const ArtistsComponent = ({albumList,handleDeletePlaylist}) => {
  return (
    <div>
       <CommonListing listing={albumList} title="artists" flag="profilePage" handleDeletePlaylist={handleDeletePlaylist} fromprofile="/collection/artists" />
    </div>
  )
}

export default ArtistsComponent
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { handleCurrentSongIndex, handleIsRecommended, handlePlayerStatus } from '../../../../../store/playerSlice';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import Slider from '@mui/material/Slider';
import RepeatIcon from '@mui/icons-material/Repeat';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AlbumOutlinedIcon from '@mui/icons-material/AlbumOutlined';
import MicIcon from '@mui/icons-material/Mic';
import HeadphonesIcon from '@mui/icons-material/Headphones';
import CurioushitPlayer from '../../TracksComponents/CurioushitPlayer';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { handleCountSongs, handleLikeSongs } from '../../../../../store/trackLikeSlice';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { useApi } from '../../../../../api/api';
import Button2 from '../../GlobalButtonLayout/Button2';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import QueueMusicIcon from '@mui/icons-material/QueueMusic';
import LyricsIcon from '@mui/icons-material/Lyrics';
import RecommendIcon from '@mui/icons-material/Recommend';
import QueueMusicOutlinedIcon from '@mui/icons-material/QueueMusicOutlined';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import {  recommendStatus } from '../../../../../store/trackSlice';
import { useLocation, useNavigate } from 'react-router';


const PlayerCurioushit = ({localVolume,songUrl,trackData,currentSongIndex,isPlaying,setIsPlaying,recommendations,handleRecommendedSong}) => {
  const likedTracks = useSelector((state) => state.likeSongs.likedSongs);
  const countTracks = useSelector((state) => state.likeSongs.countSongs);
  const player2Ref = useRef(null);
  const lyricsContainerRef = useRef(null)
  const containerRef = useRef(null);
  const dispatch = useDispatch();
  const [isMuted, setIsMuted] = useState(false);
  const [volume, setVolume] = useState(localVolume);
  const [repeatMode, setRepeatMode] = useState(false);
  const [isShuffle, setIsShuffle] = useState(false);
  const [played, setPlayed] = useState(0);
  const [seeking, setSeeking] = useState(false);
  const [duration, setDuration] = useState(0);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isMp3,setIsMp3] = useState()
  const [isMobilePlayer, setIsMobilePlayer] = useState(false);
  const recommendationContainerRef = useRef(null);
  const [showRecommend, setShowRecommend] = useState(false);
  const [isLayer, setIsLayer] = useState(false);
  const [showQueue, setShowQueue] = useState(false);
  const [isLyrics,setIsLyrics] = useState(false)
  const [parsedLyrics,setParsedLyrics] = useState(null)
  const [currentLyric,setCurrentLyric] = useState('')
  const { handleGetRequest } = useApi();

  const nextSongHandler = async(id) => {
    let newIndex;
    if (repeatMode) {
      // Repeat current song
      newIndex = currentSongIndex;
      player2Ref.current.seekTo(0);
    } else {
      newIndex = isShuffle
        ? Math.floor(Math.random() * trackData?.length)
        : (currentSongIndex + 1) % trackData?.length;
    }
    dispatch(handleCountSongs(countTracks.map(item =>
      item.id === songUrl?.id ? { ...item, play_count: item.play_count + 1 } : item
      )))
    localStorage.setItem('playingCurrentSong',  JSON.stringify(trackData[newIndex]))
    dispatch(handleCurrentSongIndex(newIndex))
    dispatch(handlePlayerStatus(trackData[newIndex]?.e_platform));
  };

  const handleProgress = (progress) => {
    const {playedSeconds} = progress
    updateLyric(playedSeconds)
    if (!seeking) {
      setPlayed(progress.played);
    }
  };

  const handleDuration = (duration) => {
    setDuration(duration);
  };

  const toggleFullScreen = () => {
    const player = player2Ref?.current?.getInternalPlayer();
    if (!isFullScreen) {
      if (player?.requestFullscreen) {
        player.requestFullscreen();
      } else if (player?.mozRequestFullScreen) { /* Firefox */
        player.mozRequestFullScreen();
      } else if (player?.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
        player.webkitRequestFullscreen();
      } else if (player?.msRequestFullscreen) { /* IE/Edge */
        player?.msRequestFullscreen();
      }
    } else {
      if (document?.exitFullscreen) {
        document?.exitFullscreen();
      } else if (document?.mozCancelFullScreen) {
        document?.mozCancelFullScreen();
      } else if (document?.webkitExitFullscreen) {
        document?.webkitExitFullscreen();
      } else if (document?.msExitFullscreen) {
        document?.msExitFullscreen();
      }
    }
    setIsFullScreen(!isFullScreen);
  };


  const handleVolumeChange = (event, newValue) => {
    localStorage.setItem('volume', newValue.toString());
    setVolume(newValue);
    setIsMuted(newValue === 0);
  };

  const handleMuteToggle = () => {
    setIsMuted(!isMuted);
    const storedVolume = localStorage.getItem('volume');
    setVolume(isMuted ?parseFloat(storedVolume) : 0);
  };

  const repeatHandler = () => {
    setRepeatMode(pre=>!pre);
    setIsShuffle(false);
  };

  const handleSeekChange = (e) => {
    setPlayed(parseFloat(e.target.value));
    player2Ref.current.seekTo(parseFloat(e.target.value));
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };


  useEffect(()=>{
    const fileExtension = songUrl?.t_song_link?.split('.')?.pop();
  // Check if it's mp3 or mp4
  if (fileExtension === 'mp3') {
    setIsMp3(true)
  } else if (fileExtension === 'mp4' || songUrl?.e_platform==="Youtube Video"  || songUrl?.e_platform==="Vimeo") {
    setIsMp3(false)
  } else {
    console.log('The file type is not supported or recognized.');
  }
  },[currentSongIndex,songUrl])



const toggleRecommend = () => {
  setShowRecommend(!showRecommend);
};
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (recommendationContainerRef.current && !recommendationContainerRef.current.contains(event.target)) {
        setShowRecommend(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const updateLikeStatus = async (trackId, isLiked) => {
    const trackLikeRes = await handleGetRequest(`song-favourite?id=${trackId}&type=${isLiked?"Like":"Dislike"}`, true,false);
  };

  const handleLike = async(trackId) => {
    const updatedLikedTracks = likedTracks?.includes(trackId)
        ? likedTracks.filter((id) => id !== trackId)
        : [...likedTracks, trackId];
        dispatch(handleLikeSongs(updatedLikedTracks))
    updateLikeStatus(trackId, updatedLikedTracks?.includes(trackId))
  };

  const shuffleHandler = () => {
    setIsShuffle(!isShuffle);
    setRepeatMode(false);
  };

  // const prevSongHandler = () => {
  //   dispatch(handleCurrentSongIndex((prevIndex) =>
  //     prevIndex === 0 ? trackData?.length - 1 : prevIndex - 1))
  // };

  const prevSongHandler = async (id) => {
    let newIndex;
    if (repeatMode) {
      // In repeat mode, just restart the current song
      newIndex = currentSongIndex;
      player2Ref.current.seekTo(0); // Assuming player2Ref is used for audio control
    } else {
      newIndex = isShuffle
       ? Math.floor(Math.random() * trackData?.length) // Pick a random song if shuffle is enabled
        : (currentSongIndex - 1 + trackData?.length) % trackData?.length; // Otherwise, go to the previous song, wrapping around if necessary
    }
    // Update the play count for the new song being played
    dispatch(handleCountSongs(countTracks.map(item =>
      item.id === id? {...item, play_count: item.play_count + 1 } : item
    )));
    localStorage.setItem('playingCurrentSong', JSON.stringify(trackData[newIndex]));
    dispatch(handleCurrentSongIndex(newIndex));
    dispatch(handlePlayerStatus(trackData[newIndex]?.e_platform));

  };

  const playPauseHandler = () => {
    setIsPlaying(!isPlaying);
    localStorage.setItem('isPlaying', !isPlaying);

  };

  useEffect(()=>{
    setIsPlaying(false);
    localStorage.setItem('isPlaying', false);
  },[])

const toggleQueue = () => {
  setShowQueue(!showQueue);
};

useEffect(()=>{

  const convertToSeconds = (timestamp)=>{
    const [hours,minutes,seconds] = timestamp.split(':').map(parseFloat);
    const totalSeconds = hours * 3600 + minutes * 60 +seconds;
    return totalSeconds;
  }

  if (songUrl.t_lyrics) {
    const newData = {};
    const parsed = JSON.parse(songUrl?.t_lyrics).time_stamp;
    
   
    if (parsed && typeof parsed === 'object') {
      Object.keys(parsed).forEach((key) => {
        const seconds = convertToSeconds(key);
        newData[seconds] = parsed[key];
      });
      setParsedLyrics(newData);
    }
  }else{
    setParsedLyrics(null);
  }

},[currentSongIndex])

const updateLyric = (currentTime) => {
  if(!parsedLyrics){
    return;
  }

  for(const [timestamp,lyrics] of Object?.entries(parsedLyrics)){
    const [startSeconds] = timestamp.split(',');
    if(timestamp<=currentTime){
      setCurrentLyric(lyrics)
    }else{
      break;
    }
  }
}



  useEffect(() => {
      if(showRecommend){
        containerRef.current.scrollTop = 0; // Scroll to the top
      }
  }, [currentSongIndex]);

 

  // useEffect(()=>{
  //   setCurrentSongIndex(isPlayer)
  //   dispatch(handleCurrentSongIndex(newIndex))
  //   setIsPlaying(true);
  // },[isPlayer])
  const location = useLocation();
  const navigate = useNavigate();
  // useEffect(()=>{
  //   setIsMobilePlayer(false)
  //   navigate(location.pathname)
  // },[location])

  useEffect(() => {
    // const newUrl = `${location.pathname}${location.search}?player=false`;

    // Use window.history.pushState to add to the URL without navigating
    // window.history.pushState({}, '', newUrl);
      setIsMobilePlayer(false);
  }, [location]);


  const handleMobilePlayer = () => {
    setIsMobilePlayer(true);
    
     // Extract the current URL and add a query parameter or hash to it
     const newUrl = `${location.pathname}`

    //  // Use window.history.pushState to add to the URL without navigating
     window.history.pushState({}, '', newUrl);

  }

  useEffect(() => {
    if (lyricsContainerRef.current) {
      lyricsContainerRef.current.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }, [parsedLyrics]); 

  return (
   

    <div ref={recommendationContainerRef}  className={`fixed  ${isMobilePlayer?"top-0  overflow-scroll ":""}   bottom-0 bg-white w-full  z-[999] drop-shadow-3xl dark:bg-[#0D0D0D]`}>
    {isMobilePlayer&&<div>
      <div className='py-2 px-3 flex justify-between items-center drop-shadow-xl dark:bg-[#0D0D0D]'>
        <div>
        <KeyboardArrowDownIcon className='dark:!text-white' onClick={()=>setIsMobilePlayer(false)}/> 
        </div>
        <div className='text-center mx-auto w-[80%] dark:text-white'>
          <p>Playing from playlist</p>
          <p className='line-clamp-1 w-'>{songUrl?.t_title}</p>
        </div>
        <div>
        <MoreVertIcon className='dark:!text-white' onClick={()=>setIsLayer(true)}/>
        </div>
      </div>
      </div>}

      {(isMobilePlayer&&isLayer)&&<div className='bg-black py-10 px-4 bg-opacity-70 h-full w-full fixed top-0 z-[9999999]'>
      <div className='text-white'>
        <div className='flex justify-start  gap-x-3 '>
          <div className='w-[100px] h-[100px]'>
            <img src={songUrl?.t_image_path} alt={songUrl?.t_title} className="rounded-lg object-cover" />
          </div>
          <div className={` `}>
            <h2 className={`lg:text-2xl text-sm  font-medium  ${isMobilePlayer?" ":" line-clamp-1"} `}>{songUrl.t_title}</h2>
            <p  className={`lg:text-xl text-sm  font-normal line-clamp-1 ${isMobilePlayer?" ":" line-clamp-1"}`}>{songUrl?.artistlist}</p>
          </div>
        </div>
        <div className='my-4'>
          <div className='flex justify-start items-center gap-x-3 my-3'>
            <div><AlbumOutlinedIcon/></div>
            <div>View album</div>
          </div>
          <div className='flex justify-start items-center gap-x-3 my-3'>
            <div><MicIcon/></div>
            <div>View artist</div>
          </div>
          <div className='flex justify-start items-center gap-x-3 my-3'>
            <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 12 12" fill="none">
            <path d="M7.8 1.5192V5.97C7.7998 6.35206 7.67804 6.72414 7.45234 7.0324C7.22664 7.34067 6.90871 7.56912 6.54456 7.68471C6.1804 7.80029 5.78892 7.79701 5.42675 7.67533C5.06459 7.55366 4.75053 7.31991 4.53003 7.0079C4.30954 6.6959 4.19403 6.32182 4.20024 5.93982C4.20644 5.55781 4.33404 5.18769 4.56456 4.88301C4.79507 4.57833 5.11656 4.3549 5.48248 4.24506C5.84841 4.13521 6.23979 4.14465 6.6 4.272V0C9.6318 0.3006 12 2.859 12 5.97C12 9.2838 9.3138 11.97 6 11.97C2.6862 11.97 0 9.2838 0 5.97C0 2.859 2.3682 0.3006 5.4 0V1.2072C4.18963 1.36121 3.08331 1.97032 2.30591 2.91073C1.52851 3.85114 1.13837 5.05228 1.21477 6.27002C1.29118 7.48775 1.8284 8.63071 2.71725 9.46657C3.6061 10.3024 4.77987 10.7685 6 10.77C7.1144 10.7706 8.1943 10.3835 9.05447 9.67494C9.91463 8.96641 10.5015 7.98067 10.7143 6.88678C10.9272 5.79289 10.7528 4.65903 10.221 3.67967C9.68927 2.70031 8.83333 1.93648 7.8 1.5192Z" fill="white"/>
            </svg>
            </div>
            <div>Genre</div>
          </div>
          <div className='flex justify-start items-center gap-x-3 my-3'>
            <div><HeadphonesIcon/></div>
            <div>{songUrl?.play_count} listeners</div>
        </div>
        </div>
        <div className='flex justify-center items-center'>
          <div onClick={()=>setIsLayer(false)} className='cursor-pointer text-center'>
          <HighlightOffOutlinedIcon className='text-[#FFAD00] !text-4xl cursor-pointer my-2'/>
          <p >close</p>
          </div>
        </div>
      </div>
      </div>}
    <div className={`lg:!p-3 ${isMp3?"":"py-3 px-2"}  drop-shadow-2xl bg-white rounded-lg ${isMobilePlayer?"block p-3 ":"flex"} justify-between items-center gap-x-5 lg:gap-x-14 relative dark:bg-[#0D0D0D]`}>
      <div className={`${isMp3?`lg:w-[15%] ${isMobilePlayer?"w-[100%]  ":"w-[40%]"} `:`lg:w-[25%] w-[45%] h-[50px] lg:h-[80px] ${isMobilePlayer?"!w-[100%] !h-[350px] ":""}`} flex justify-start items-center gap-x-3`}>
    

      <CurioushitPlayer trackData={trackData}  playerRef={player2Ref} songs={songUrl} currentSongIndex={currentSongIndex} isPlaying={isPlaying} isMuted={isMuted} volume={volume} nextSongHandler={nextSongHandler} handleProgress={handleProgress} handleDuration={handleDuration} toggleFullScreen={toggleFullScreen} isMp3={isMp3} prevSongHandler={prevSongHandler} setIsPlaying={setIsPlaying}/>

      
      {isMp3 &&<div className={`flex items-center justify-start ${isMobilePlayer?"w-[100%]  ":" w-[50%] h-[80px]"}`}>
        <img src={songUrl?.t_image_path} alt={songUrl?.t_title} className=" h-[80%] lg:block hidden rounded-lg object-cover" />
        <img src={songUrl?.t_image_path} alt={songUrl?.t_title} className=" h-[80%]  lg:hidden block rounded-lg object-cover" onClick={()=>handleMobilePlayer()}/>
      </div>}
      <div  onClick={()=>handleMobilePlayer()} className={`lg:!hidden  ${isMobilePlayer?"w-[100%] hidden ":" w-[50%] !block"}`} >
            <div className='w-[110px] overflow-hidden'>
            <h2 className={`text-sm whitespace-nowrap animation-marquee dark:text-[#FFFFFF]`}>
              {songUrl?.t_title}

            </h2>
            </div>
            <p  className={`lg:text-xs text-sm dark:text-[#FFFFFF]  font-normal line-clamp-1 ${isMobilePlayer?" ":" line-clamp-1"}`}>
            {songUrl?.artistlist}
            </p>
          </div>

          <div  className={`!hidden lg:!block  ${isMobilePlayer?"w-[100%] hidden ":" w-[50%] !block"}`} >
            <div className='w-[130px] overflow-hidden'>
            <h2 className={`text-sm whitespace-nowrap animation-marquee dark:text-[#FFFFFF]`}>
              {songUrl?.t_title}
            </h2>
            </div>
            <p  className={`lg:text-xs text-sm  font-normal line-clamp-1 ${isMobilePlayer?" ":" line-clamp-1"} dark:text-[#FFFFFF]`}>
              {songUrl?.artistlist}
            </p>
          </div>
      </div>

      <div className={`lg:w-[90%]   ${isMobilePlayer?"w-[100%] ":" w-[50%]"}`}>
        <div className={` justify-between ${isMobilePlayer?"block ":"flex"} gap-x-3 items-center lg:mt-0`}>
          <div className={`  ${isMobilePlayer?"w-[100%] my-3 flex justify-between items-center":" w-[50%] hidden"}`} >
            <div>
              <h2 className={`lg:text-sm text-sm  font-medium dark:text-white ${isMobilePlayer?"line-clamp-1 ":" line-clamp-1"} `}>{songUrl?.t_title}</h2>
              <p  className={`lg:text-xs text-sm  font-normal line-clamp-1 dark:!text-white ${isMobilePlayer?"line-clamp-1 ":" line-clamp-1"}`}>{songUrl?.artistlist}</p>
            </div>
            <div>
            {( !likedTracks?.includes(songUrl?.id))  ? <FavoriteBorderIcon className="hover:text-[#F1B942] cursor-pointer dark:!text-white" onClick={()=>handleLike(songUrl?.id)} />:
            <FavoriteIcon className="text-[#F1B942] cursor-pointer dark:!text-white" onClick={()=>handleLike(songUrl?.id)}  /> }
            </div>
          </div>
          <div className={`${isMobilePlayer?"":"hidden"}`}>
             <Slider value={played} step={0.01} min={0} max={1} onChange={handleSeekChange} className='w-[100%]  lg:static   accent-[#312522] dark:!text-white  h-[1px] '/>
          
          <div className='flex justify-between items-center dark:text-white  text-[10px] '>
          <p>
            {formatTime(duration * played)} 
          </p>
          <p>
            {formatTime(duration)}
          </p>
          </div>
          </div>
          <div className={`lg:hidden  ${isMobilePlayer?" w-[100%] my-4 !justify-between":"w-[50%] ml-auto mr-4"} flex  justify-center items-center gap-x-2`}>
            <div>
            <button onClick={shuffleHandler} className={`mx-2  ${isMobilePlayer?" ":"hidden "}`} ><ShuffleIcon  className={`!text-base ${isShuffle ? "!text-[#F1B942] " : "dark:!text-white "}`} /></button>
            </div>
            <div className='flex justify-center items-center'>
            <button onClick={()=>prevSongHandler(trackData?.id)} className="mx-2"><SkipPreviousIcon className='!text-3xl dark:!text-white'/></button>
            <div onClick={playPauseHandler} className="mx-2 ">{isPlaying ? <PauseCircleIcon  className={`!text-4xl lg:!text-5xl dark:!text-white ${isMobilePlayer?"!text-6xl ":"  "} cursor-pointer`}/> : <PlayCircleIcon className={`lg:!text-5xl dark:!text-white !text-3xl ${isMobilePlayer?" !text-6xl":"  "} cursor-pointer`}/>}</div>
            <button onClick={()=>nextSongHandler(trackData?.id)} className="mx-2"><SkipNextIcon  className='dark:!text-white'/></button>
            </div>
            <div>
            <button onClick={()=>repeatHandler()} className={`mx-2  ${isMobilePlayer?" ":"hidden "} `}><RepeatIcon className={`!text-base ${repeatMode ? "!text-[#F1B942]" : "dark:!text-white "}`}/></button>
            </div>
          </div>
        </div>
      <div className={` ${isMobilePlayer?" w-full ":"justify-between flex items-center"} `} >
     <div className='hidden lg:block'>
      {( !likedTracks?.includes(songUrl?.id))? <FavoriteBorderIcon className="hover:text-[#F1B942]  cursor-pointer dark:!text-white" onClick={()=>handleLike(songUrl?.id)} />:
            <FavoriteIcon className="text-[#F1B942]   cursor-pointer " onClick={()=>handleLike(songUrl?.id)}  /> }
     </div>

        <button onClick={shuffleHandler} className="mx-2 hidden lg:block"><ShuffleIcon  className={` !text-base ${isShuffle ? "!text-[#F1B942] " : "dark:!text-white"}`} /></button>
        <button onClick={prevSongHandler} className="mx-2 hidden lg:block"><SkipPreviousIcon className='!text-base dark:!text-white'/></button>
        <div onClick={playPauseHandler} className="mx-2  hidden lg:block">{isPlaying ? <PauseCircleIcon  className=' cursor-pointer !text-3xl dark:!text-white'/> : <PlayCircleIcon className='!text-3xl cursor-pointer dark:!text-white'/>}</div>
        <button onClick={()=>nextSongHandler(trackData?.id)} className="mx-2 hidden lg:block"><SkipNextIcon className='!text-base dark:!text-white'/></button>
        <button onClick={()=>repeatHandler()} className="mx-2  hidden lg:block"><RepeatIcon   className={`!text-base  ${repeatMode ? "!text-[#F1B942] " : "dark:!text-white"}`} /></button>

        <div  className={`${isMobilePlayer?"hidden ":"w-[100%]"} lg:mt-5 mx-3`}>
        <Slider value={played} step={0.01} min={0} max={1} onChange={handleSeekChange} className='w-[100%]   lg:!hidden !absolute left-0 top-[-20px] accent-[#312522] dark:!text-white h-[1px] '/>
        <Slider value={played} step={0.01} min={0} max={1} onChange={handleSeekChange} className='w-[100%]  lg:static   accent-[#312522] dark:!text-white h-[1px] !hidden lg:!block'/>
       
          <div className='lg:flex justify-between items-center dark:text-white hidden text-[10px] '>
          <p>
            {formatTime(duration * played)} 
          </p>
          <p>
            {formatTime(duration)}
          </p>
          </div>
        </div>
        <div className='lg:block hidden'>
          < QueueMusicIcon onClick={()=>{toggleQueue();setIsLyrics(false);setShowRecommend(false)}} className='ml-3 cursor-pointer !text-base dark:!text-white'/>
        </div>
        <div className='lg:block hidden'>
          < RecommendIcon onClick={()=>{toggleRecommend();setIsLyrics(false);setShowQueue(false)}} className={`ml-3 cursor-pointer !text-base dark:!text-white ${songUrl?.e_platform==="Default"?"":"mr-2"}`}/>
        </div>
        {songUrl?.e_platform==="Default" &&<div className='lg:block hidden '>
          <LyricsIcon onClick={()=>{setIsLyrics(pre=>!pre);setShowQueue(false); setShowRecommend(false)}} className='ml-3 mx-3 cursor-pointer !text-base  dark:!text-white'/>
        </div>}
        <div className={`absolute right-0 p-4 rounded-xl -top-[300px]  drop-shadow-xl lg:w-[83%] w-full  h-[300px] z-50 ${isLyrics?"":"hidden"} bg-[#F1B942] dark:bg-[#151515] dark:text-white`}>
          <div className=' flex justify-between items-center p-1'> 
          <p className='text-[19px] font-bold'>Lyrics</p>
          <div className='w-8 h-8 rounded-full bg-white dark:bg-black flex  justify-center cursor-pointer items-center'  onClick={()=>setIsLyrics(false)}>
            <KeyboardArrowDownIcon />
          </div>
          </div>
          <div ref={lyricsContainerRef} className='text-xl font-bold h-[200px] overflow-scroll'>
            {parsedLyrics ? 
            Object?.values(parsedLyrics)?.map((lyric,index)=>(
            <p key={index} className={`${lyric === currentLyric? "text-white dark:text-slate-950":"text-slate-950 dark:text-white"} text-center`} >
            {lyric?lyric:"No Lyrics Found"}
            </p>))
            : <p className='text-center'>No Lyrics Found</p>}
          </div>
        </div>
        {showRecommend && (
          <div className='absolute text-xs -top-[382%] z-[99999999] right-0 px-3   drop-shadow-xl overflow-hidden h-[400px] w-[364px] bg-white  rounded-[25px] dark:bg-[#151515]'>
            <div className='py-3 bg-white relative flex justify-start items-center gap-x-4 dark:bg-[#151515] dark:text-white'>
              <HighlightOffOutlinedIcon onClick={()=>setShowRecommend(false)}  className='absolute !text-[#A8A8A8] cursor-pointer top-5 right-5 '/>
                <div className='bg-[#F1B942] flex justify-center items-center p-4 w-[80px] rounded-lg'>
                <QueueMusicOutlinedIcon className='!text-white '/>
                </div>
                <p className='text-lg font-semibold'>Recommendation</p>
            </div>
            <ul className='h-[250px]  overflow-scroll' ref={containerRef}>
              {recommendations?.map((RecomSongs,index) => (
                <div onClick={() => handleRecommendedSong(RecomSongs,index)} className='bg-[#FAFAF8] cursor-pointer rounded-lg p-3 flex justify-between items-center my-3 dark:bg-[#0D0D0D] dark:text-white'>
                    <div className='w-[50px] h-[50px] rounded-lg'>
                      <img src={RecomSongs?.t_image_path} alt='' className='object-cover w-full h-full' />
                    </div>
                    <div className='w-[50%]'>
                      <p className='line-clamp-1'>{RecomSongs?.t_title}</p>
                      <p>{RecomSongs.artist_name}</p>
                    </div>
                    <div>
                      <p>{RecomSongs?.i_duration_display}</p>
                    </div>
                </div>
              ))}
            </ul>
          </div>
        )}
         {showQueue && (
          <div className='absolute text-xs -top-[382%] z-[99999999] right-0 px-3   drop-shadow-xl overflow-hidden h-[400px] w-[364px] bg-white  rounded-[25px] dark:bg-[#151515]'>
            <div className='py-3 bg-white relative flex justify-start items-center gap-x-4 dark:bg-[#151515]'>
              <HighlightOffOutlinedIcon onClick={()=>setShowQueue(false)}  className='absolute !text-[#A8A8A8] cursor-pointer top-5 right-5'/>
                <div className='bg-[#F1B942] flex justify-center items-center p-4 w-[80px]  rounded-lg'>
                <QueueMusicOutlinedIcon className='!text-white'/>
                </div>
                <p className='text-lg font-semibold dark:text-white'>Queue</p>
            </div>
            <ul className='h-[250px]  overflow-scroll'>
              {trackData.map((song,index) => (
                // <li className='cursor-pointer' onClick={()=>setCurrentSongIndex(index)} key={song.id}>{song.t_title}</li>
                <div onClick={()=> dispatch(handleCurrentSongIndex(index))} className='bg-[#FAFAF8] dark:bg-[#0D0D0D] cursor-pointer rounded-lg p-3 flex justify-between items-center my-3 dark:text-white'>
                    <div className='w-[50px] h-[50px] rounded-lg'>
                      <img src={song?.t_image_path} alt='' className='object-cover w-full h-full' />
                    </div> 
                    <div className='w-[50%]'>
                      <p className='line-clamp-1 dark:text-white'>{song?.t_title}</p>
                      <p>{song.artist_name}</p>
                    </div>
                    <div>
                      <p>{song?.i_duration_display}</p>
                    </div>
                </div>
              ))}
            </ul>
          </div>
        )}
        <div>

        {songUrl?.e_platform==="Default" &&<div  className={`${isMobilePlayer?" ":"hidden"} w-full bg-[#A8A8A8] rounded-2xl p-2`}>
          <div className='flex justify-between items-center '>
            <div className='flex justify-center items-center gap-x-3'>
              <MusicNoteIcon/>
              <p>Lyrics</p>
            </div>
            <div className='flex justify-center items-center gap-x-3'>
              <button className='bg-[#DCCDBD] p-2 rounded-full'>
                <OpenInFullIcon/> 
              </button>
            </div>
          </div>
          <div className='min-h-[200px] max-h-[380px] overflow-hidden my-4 text-center'>
          <div ref={lyricsContainerRef} className='text-xl font-bold h-[200px] overflow-scroll'>
            {parsedLyrics && 
            Object.values(parsedLyrics).map((lyric,index)=>(
            <p key={index} className={`${lyric === currentLyric? "text-white":"text-slate-950"} text-center text-lg`} >
            {lyric?lyric:"No Lyrics Found"}
            </p>))
            }
          </div>
          </div>
        </div>}

        <div  className={`${isMobilePlayer?" ":"hidden"} my-4 overflow-hidden !w-full relative h-[250px] rounded-2xl `}>
            <img src='/images/ArijitSingh.svg' alt='' className=' object-cover w-full' /> 
            <div className='flex absolute bottom-0 w-full rounded-b-2xl justify-between items-center py-2 px-3 bg-black text-white '>
              <div>
                <p>{songUrl?.artistlist}</p>
                <p>32500k</p>
              </div>
              <div>
                <Button2 className="!w-[100%] px-4 !border-white !text-white">Follow</Button2>
              </div>
            </div>
          </div>
        </div>

      
      <Box sx={{ width: 200 }} className={`${isMobilePlayer?"hidden":"hidden lg:block"}`}>
        <Stack spacing={2} direction="row" sx={{  }} alignItems="center">
        <button onClick={handleMuteToggle} className="mx-2">{isMuted ? <VolumeOffIcon className='!text-base dark:text-white'/> : <VolumeUpIcon className='!text-base dark:text-white'/>}</button>
          <Slider aria-label="Volume"  min={0}
            max={1}
            step={0.01} value={volume} className='!text-black !text-base !h-[2px] dark:!text-white' onChange={handleVolumeChange}  />
        </Stack>
      </Box>
      </div>
      </div>
    </div>
    </div>
  )
}

export default PlayerCurioushit
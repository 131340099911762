// import { useEffect, useState } from "react";
// import P16 from "../../view/components/common/Typograpgy/P16"
// import { useApi } from "../../api/api";
// import parse from 'html-react-parser';
// import BackToPage from "../../view/components/common/BacktoPage/BackToPage";
// import H220 from "../../view/components/common/Typograpgy/H220";

// function About(){
//     const { handleGetRequest } = useApi();
//     const [aboutUsData, setAboutUsData] = useState()

    
//     useEffect(() => {
//         // Skip the first render
//         (async () => {  
//           const aboutUsRes = await handleGetRequest(`users/about-us`, false,true);
//             if(aboutUsRes?.flag){
//               setAboutUsData(aboutUsRes?.data)
//           }
//         })();
//       }, []);

//     return(
//         <div className="px-2 lg:px-0 mb-10">
//             <BackToPage pageRoute="/" backTo="Home"/>
//             <H220 className="dark:text-white">About Us</H220>
//             <div className=" mt-7  rounded-lg bg-[#F1B942] text-white lg:text-[46px] text-2xl font-bold py-10 w-full flex flex-col justify-center items-center">
//                 <p>{aboutUsData?.v_name}</p>
//             </div>
//             <P16 className="!my-5 dark:text-[#A1A1A1]">{aboutUsData?.t_page_content?parse(aboutUsData?.t_page_content):""}</P16>
//         </div>
//     )
// }

// export default About

import { useEffect, useMemo, useState } from "react";
import P16 from "../../view/components/common/Typograpgy/P16";
import { useApi } from "../../api/api";
import parse from 'html-react-parser';
import BackToPage from "../../view/components/common/BacktoPage/BackToPage";
import H220 from "../../view/components/common/Typograpgy/H220";

function About() {
    const { handleGetRequest } = useApi();
    const [aboutUsData, setAboutUsData] = useState(null); // Initialize as null to indicate no data yet
    const [isLoading, setIsLoading] = useState(true); // State to track loading status

    useEffect(() => {
        // Set loading state to true before fetching data
        setIsLoading(true);

        // Fetch data
        (async () => {
            const aboutUsRes = await handleGetRequest(`users/about-us`, false, true);
            if (aboutUsRes?.flag) {
                setAboutUsData(aboutUsRes.data);
            }
            // Set loading state to false after data is fetched
            setIsLoading(false);
        })();
    }, []);

      // Memoize the parsed HTML content
      const parsedContent = useMemo(() => {
        return aboutUsData?.t_page_content? parse(aboutUsData.t_page_content) : "";
    }, [aboutUsData]);

    return (
        <div className="px-2 lg:px-0 mb-10">
            {/* <BackToPage pageRoute="/" backTo="Home" /> */}
            {/* <H220 className="dark:text-white">About Us</H220> */}
            <div className="mt-7 rounded-lg bg-[#F1B942] drop-shadow-xl text-white lg:text-[46px] text-2xl font-bold py-10 w-full flex flex-col justify-center items-center">
                <p>{aboutUsData?.v_name}</p>
            </div>
            <div className="bg-white drop-shadow-xl p-3 my-5 rounded-lg dark:bg-[#131313] dark:text-[#A1A1A1]">
            <P16 className="my-5 dark:text-[#A1A1A1] ">{parsedContent}</P16>
            </div>
        </div>
    );
}

export default About;

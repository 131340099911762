// import React from 'react'
// import H220 from '../../../view/components/common/Typograpgy/H220';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
// import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';

// const CalenderHeader = () => {

//   return (
//     <div className='flex justify-between items-center px-3 pb-2 pt-4 border-b-[1px] border-b-[#A8A8A8] mb-5'>
//         <H220>Calender</H220>
//         <div className='flex justify-between items-center gap-x-3'>
//         <LockOpenOutlinedIcon/> 
//         <p>Google Calender </p>
//         <label>
//             <input 
//             type="checkbox"  
//             checked={false}
//             value=""
//             className="sr-only peer"
//             />
//             <div class="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-[#F1B942] dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#F1B942]"></div>
//         </label>
//         </div>
//     </div>
//   )
// }

// export default CalenderHeader

import React, { useState, useEffect, useRef } from 'react';
import H220 from '../../../view/components/common/Typograpgy/H220';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import { useApi } from '../../../api/api';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import globalToast from '../../../hooks/globalToast';
import { useDispatch } from 'react-redux';
import { profileDetail } from '../../../store/authenticationSlice';

const CalenderHeader = ({isGoogleEvent,selectedCalendarCallback,setCalendarEvents}) => {
  // State to manage the checkbox
  console.log("isGoogleEvent",isGoogleEvent);
  const [isChecked, setIsChecked] = useState(isGoogleEvent);
  const { handleGetRequest, handlePostRequest } = useApi();


  // Function to handle checkbox change
  const handleChange = (event) => {
    setIsChecked(event.target.checked);
  };

  // Effect to call the API when isChecked changes
  const isFirstRender = useRef(true);

  const dispatch = useDispatch()

  useEffect(() => {
    // Skip the effect on the first render
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    // Define an async function inside the useEffect
    const fetchEvents = async () => {
      try {
        const payload = {
          "i_calender_auto_sync": isChecked
        };
      
        const updateSetting = await handlePostRequest(`google-calendar/updateSettings`, payload, true, true);
        console.log("updateSetting", updateSetting);
        if(updateSetting?.flag){
          const syncRes = await handleGetRequest(`google-calendar/sync`, true,true);
          console.log("!syncRes?.flag",syncRes);
          const profileDataReq = await handleGetRequest("profile-detail", false, true);
          dispatch(profileDetail(profileDataReq?.data));


          if(!syncRes?.flag){
            // toast.error(syncRes?.msg);
            globalToast(syncRes?.msg,true)

          }
          if(syncRes?.flag){
          const payload = {
            "i_calender_auto_sync":true
          }
          const getEvents = await handlePostRequest(`google-calendar/getEvents`,payload, false,true);
          console.log("succesyncalender",getEvents);
          setCalendarEvents(getEvents)
          }
          }
      } catch (error) {
        console.error('Failed to fetch events:', error);
      }
    };

    // Call the async function
    fetchEvents();
  }, [isChecked]); // This 

  return (
    <div className='flex justify-between items-center px-3 pb-2 pt-4 border-b-[1px] dark:!text-white border-b-[#A8A8A8] mb-5'>
      <H220 className=" border-b-[1px] border-[#A8A8A8]">Calendar</H220>
     
      <div className='flex justify-between items-center gap-x-3'>
        <LockOpenOutlinedIcon /> 
        <p className='text-sm'>Google Calendar </p>
        <label>
          <input 
            type="checkbox"  
            checked={isChecked}
            onChange={handleChange}
            value=""
            className="sr-only peer "
          />
          <div className="relative w-11 h-6 cursor-pointer bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-[#F1B942] dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#F1B942]"></div>
        </label>
      </div>
    </div>
  );
};

export default CalenderHeader;

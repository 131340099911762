import React, { useState } from 'react';

const Multiselector = ({ options, maxSelectedLabels = 3, setSelectedPlaylists,selectedPlaylists,isPlaylistDropdownOpen }) => {
//   const [selectedPlaylists, setSelectedPlaylists] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  // Handle adding/removing selected playlists
  const handleSelect = (playlist) => {
    if (selectedPlaylists.includes(playlist)) {
      setSelectedPlaylists(selectedPlaylists.filter((item) => item !== playlist));
    } else if (selectedPlaylists.length < maxSelectedLabels) {
      setSelectedPlaylists([...selectedPlaylists, playlist]);
    }
  };

  // Filter playlists based on the search input
  const filteredOptions = options.filter((playlist) =>
    playlist.v_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Select All Filtered Playlists
  const selectAll = () => {
    const newSelection = filteredOptions
      .filter((playlist) => !selectedPlaylists.includes(playlist)) // Avoid duplicates
      .slice(0, maxSelectedLabels - selectedPlaylists.length); // Respect the max limit
    setSelectedPlaylists([...selectedPlaylists, ...newSelection]);
  };

  // Deselect All Playlists
  const deselectAll = () => {
    setSelectedPlaylists([]);
  };

  return (
    <div className={`w-full mt-2 ${isPlaylistDropdownOpen?"max-h-72":" max-h-0"} overflow-hidden duration-700`}>
        <div className=''>
        <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search Playlists"
            className="w-full p-2 bg-gray-100 dark:bg-black border border-gray-300 dark:border-gray-600 rounded-md mb-2"        
        />

        <div className="flex justify-between mb-2">
            <button
            className="bg-[#F1B942] text-white px-3 py-1 rounded-md  disabled:bg-blue-300"
            onClick={selectAll}
            disabled={selectedPlaylists.length >= maxSelectedLabels || filteredOptions.length === 0}
            >
            Select All
            </button>
            <button
            className="bg-red-500 text-white px-3 py-1 rounded-md hover:bg-red-600"
            onClick={deselectAll}
            >
            Deselect All
            </button>
        </div>

        <div className="max-h-40 overflow-y-auto bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-md">
            {filteredOptions.map((playlist, index) => (
            <div
                key={index}
                className="p-2 flex items-center cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-700"
                onClick={() => handleSelect(playlist)}
            >
                <input
                    type="checkbox"
                    checked={selectedPlaylists.includes(playlist)}
                    onChange={() => handleSelect(playlist)}
                    className="mr-2 pointer-events-none form-checkbox accent-[#F1B942]"
                />
                <span>{playlist.v_name}</span>
            </div>

            ))}
        </div>
        </div>
    </div>
  );
};

export default Multiselector

import { createSlice } from "@reduxjs/toolkit";
const searchlocal =  localStorage.getItem('searchValue') 

const initialState = {
    searchValue: searchlocal?searchlocal:"",
    suggValue:""
};
console.log("suggValue",initialState.suggValue)


// Slice
export const globalSearchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    handleGlobalSearchValue(state,action){
        state.searchValue = action.payload;
      },

      handleGlobalSuggValue(state,action){
        state.suggValue = action.payload;
      },
  },
});

export const {handleGlobalSearchValue,handleGlobalSuggValue} = globalSearchSlice.actions;

export default globalSearchSlice.reducer;
import React from 'react'

const LanguageComponent = ({languageList}) => {
  return (
    <div  className='dark:text-[#DDD1CE] flex justify-start items-center gap-7'>
      {languageList?.map((items,index)=>{
        return(
          <div>
            <div className='w-10 h-10 rounded-full my-2 mx-auto'>
              <img src={items?.image} alt='' className='object-cover w-full h-full rounded-full mx-auto' /> 
            </div>
            <p>{items?.display_name}</p>
          </div>
        )
      })}
    </div>
  )
}

export default LanguageComponent
import Button1 from "../GlobalButtonLayout/Button1"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button2 from "../GlobalButtonLayout/Button2";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { useState } from "react";

const AddToPlaylist = ({setCreatePlaylistModal,trackId,playlistData,handleAddToPlaylist,handleClickOpenCreatePlaylistTrackModal}) => {
const [selectedPlaylist, setSelectedPlaylist] = useState(null);
const [searchQuery, setSearchQuery] = useState('');
const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
};

const filteredPlaylists = playlistData?.filter(playlist =>
    playlist.v_name.toLowerCase().includes(searchQuery.toLowerCase())
);

    return(
         <div className="fixed lg:hidden block  top-0 right-0 lg:top-[-21px] lg:right-[185px] z-[99] bg-white dark:bg-[#151515]  lg:w-[200px] lg:h-[175px] w-full h-dvh  rounded-[4px]  border-[2px] border-[#A8A8A8] overflow-hidden">
        <div className="lg:hidden block">
            <div className="relative px-3 py-4 bg-[#FAFAF8] dark:bg-[#151515]">
                <ArrowBackIcon onClick={()=>setCreatePlaylistModal(false)} className="absolute text-[#D8D8D8] top-1/2  transform -translate-y-1/2"/>
                <p className="text-center text-sm text-black dark:text-[#D8D8D8]">Add to Playlist</p>
            </div>
            <div className="flex justify-center items-center my-4">
                <Button2 onClick={()=>{handleClickOpenCreatePlaylistTrackModal(trackId);setCreatePlaylistModal(false)}}  className="!w-[150px] !text-[#312522] dark:!text-[#FFAD00] border-[#312522] !text-center !mx-auto">New Playlist</Button2>
            </div>
        </div>
      <div className="relative p-2  w-[90%] mx-auto mb-4">
           <input
               type="text"
               placeholder="Search Playlist"
               className="bg-[#FAFAF8] rounded-[50px] px-4 py-3  w-full  focus:outline-none focus:border-[#F1B942] text-[#C1C1C1] lg:text-xl  text-base  font-normal  focus:border-[1px] transition duration-300 dark:bg-[#131313] dark:!border-white dark:focus:!border-[#F1B942]   dark:text-white"
               onChange={handleSearchChange}
           />
           <SearchOutlinedIcon className="absolute !text-[#312522] dark:!text-white !text-lg mr-3  top-1/2 transform -translate-y-1/2 right-3"/>
       </div>
       <div className="h-[55%] overflow-scroll">
        <table className="table-fixed w-full mb-3 lg:hidden block  ">
        <tbody className="inline-table w-full">
            {
                filteredPlaylists?.map((playlist,index)=>{
                    return(
                        <tr key={index} >
                        <td className="!text-left py-3 flex justify-start items-center gap-x-2 px-3">
                            <div className="w-[50px] h-[50px]">
                            <img src={`${playlist?.t_image}`} alt='img' className='object-cover h-full w-full  rounded-md cursor-pointer' />
                            </div>

                            <div className="w-[50%] overflow-hidden cursor-pointer" >
                                <p className="text-[#312522] dark:text-white text-xs font-semibold mb-1 lg:mb-2 line-clamp-2 text-wrap">{playlist?.v_name}</p>
                            </div>
                        </td>
                        <td>
                            <p className="dark:text-white">{playlist?.v_total_songs} Songs</p>
                        </td>
                        <td className="w-[30%]">
                            <div className="flex justify-end pr-4 items-center">
                            <input
                                type="radio"
                                className="appearance-none w-4 h-4 border-[2px] border-[#D9D9D9] checked:bg-[#F1B942] rounded-full"
                                name="playlistSelection"
                                checked={selectedPlaylist === playlist?.v_code}
                                onChange={() => setSelectedPlaylist(playlist?.v_code)}
                                />
                            </div>
                        </td>
                        </tr>
                    )
                })
            }
        </tbody>
        </table>
        </div>
        <div className="flex justify-center">
        <Button1 onClick={()=>handleAddToPlaylist(selectedPlaylist)} className="!w-[120px] !text-center !mx-auto dark:hover:!text-white">Done</Button1>
        </div>
       </div>
    )
}

export default AddToPlaylist
import axios from "axios";
import { useDispatch } from "react-redux";
import { handleCloseLoader, handleOpenLoader } from "../store/loader";
// import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { handleBellNotificationCommunityPayload, handleBellNotificationPayload } from "../store/notificationBellSlice";
import toast, { Toaster } from 'react-hot-toast';
import globalToast from "../hooks/globalToast";
const baseUrl = "https://curioushit.nevinainfotech.in/api/v1.0";

export const useApi = () => {
    const dispatch = useDispatch();
    
    const handlePostRequest = async (url, payload,isNotify,isLoader) => {
        const authenticationToken = JSON.parse(localStorage.getItem("AuthToken")??  sessionStorage.getItem("AuthToken"))
        // const authenticationToken = JSON.parse(localStorage.getItem("AuthToken"))
        let contentType = "application/json"; // Default content type
        if (url === "users/update-user-details" || url==="upload" || url==="users/update-user-avatar" || url==="edit-song" || url==="edit-playlist") {
            contentType = "multipart/form-data";
        }
        const config = {
            headers: { Authorization: `Bearer ${authenticationToken} `, 'Content-Type':contentType}}
        try {
            isLoader && dispatch(handleOpenLoader());
            const response = await axios.post(`${baseUrl}/${url}`, payload ,config);
            console.log("sdfsfesponse",response);
            if(url==="filters-new"){
                return response.data;
            }


            if (response.data.flag ) {
                // isNotify&& toast.success(response?.data?.msg);
                // isNotify && dispatch(handleBellNotificationPayload(response?.data?.msg));
                (isNotify && response?.data?.msg) && toast.custom((t) => (
                  <div
                    className={`${
                      t.visible ? 'animate-enter' : 'animate-leave'
                    } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
                  >
                    <div className="flex-1 w-0 p-4">
                      <div className="flex justify-start items-center">
                        <div className="flex-shrink-0 pt-0.5">
                          <img
                            className="h-10 w-10 rounded-full"
                            src="/notificationLogo.jpg"
                            alt=""
                          />
                        </div>
                        <div className="ml-3 flex-1">
                          {/* <p className="text-sm font-medium text-white">
                            Emilia Gates
                          </p> */}
                          <p className="mt-1 text-sm text-black  flex justify-start items-center font-semibold">
                            {response?.data?.msg}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))

                if(isNotify){
                  // const notificationResponseOverview = await axios.get(`${baseUrl}/notifications/overview` ,config);
                  // if(notificationResponseOverview?.data?.flag){
                  //   dispatch(handleBellNotificationPayload(notificationResponseOverview?.data?.data))
                  // }
                  // console.log("notificationResponseOverview",notificationResponseOverview?.data);


                  const notificationResponseCommunity = await axios.get(`${baseUrl}/notifications/community` ,config);
                  console.log("notificationResponseCommunity",notificationResponseCommunity?.data);
                  if(notificationResponseCommunity?.data?.flag){
                    dispatch(handleBellNotificationCommunityPayload(notificationResponseCommunity?.data?.data))
                  }
                }
                (isNotify && response?.data?.msg) && dispatch(handleBellNotificationPayload({id: Date.now() + Math.floor(Math.random() * 10000) , title: "", msg: response?.data?.msg})); // To add a notification
                console.log("dsfresponse",response.data);
                return response.data;

                } else {
                // isNotify && toast.error(response?.data?.msg);
                // isNotify && dispatch(handleBellNotificationPayload(response?.data?.msg));
                // (isNotify && response?.data?.msg) && toast.custom((t) => (
                //   <div
                //     className={`${
                //       t.visible ? 'animate-enter' : 'animate-leave'
                //     } max-w-md w-full bg-red-800 shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-red-700 ring-opacity-5`}
                //   >
                //     <div className="flex-1 w-0 p-4">
                //       <div className="flex justify-start items-center">
                //         <div className="flex-shrink-0 pt-0.5">
                //           <img
                //             className="h-10 w-10 rounded-full"
                //             src="/notificationLogo.jpg"
                //             alt=""
                //           />
                //         </div>
                //         <div className="ml-3 flex-1">
                //           {/* <p className="text-sm font-medium text-white">
                //             Emilia Gates
                //           </p> */}
                //           <p className="mt-1 text-sm text-white  flex justify-start items-center font-semibold">
                //             {response?.data?.msg}
                //           </p>
                //         </div>
                //       </div>
                //     </div>
                //   </div>
                // ))
                globalToast(response?.data?.msg,true)
                if(isNotify){
                  // const notificationResponseOverview = await axios.get(`${baseUrl}/notifications/overview` ,config);
                  // console.log("notificationResponseOverview",notificationResponseOverview?.data);
                  // if(notificationResponseOverview?.flag){
                  //   dispatch(handleBellNotificationPayload(notificationResponseOverview?.data?.data))
                  // }
                  const notificationResponseCommunity = await axios.get(`${baseUrl}/notifications/community` ,config);
                  console.log("notificationResponseCommunity",notificationResponseCommunity?.data);
                  if(notificationResponseCommunity?.flag){
                    dispatch(handleBellNotificationCommunityPayload(notificationResponseCommunity?.data?.data))
                  }
                }
                (isNotify && response?.data?.msg) && dispatch(handleBellNotificationPayload({id: Date.now() + Math.floor(Math.random() * 10000) , title: "", msg: response?.data?.msg})); // To add a notification

            }
        } catch (error) {
            // Handle error
            // console.error("Error:", error);
            // isNotify && toast.error("Error occurred");
            // toast.custom((t) => (
            //   <div
            //     className={`${
            //       t.visible ? 'animate-enter' : 'animate-leave'
            //     } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
            //   >
            //     <div className="flex-1 w-0 p-4">
            //       <div className="flex justify-start items-center">
            //         <div className="flex-shrink-0 pt-0.5">
            //           <img
            //             className="h-10 w-10 rounded-full"
            //             src="/notificationLogo.jpg"
            //             alt=""
            //           />
            //         </div>
            //         <div className="ml-3 flex-1">
            //           {/* <p className="text-sm font-medium text-white">
            //             Emilia Gates
            //           </p> */}
            //           <p className="mt-1 text-sm text-black  flex justify-start items-center font-semibold">
            //             {error?.message?error?.message:"Something went wrong"}
            //           </p>
            //         </div>
            //       </div>
            //     </div>
            //   </div>
            // ))
            globalToast(error?.message?error?.message:"Something went wrong",true)
            if(isNotify){
              // const notificationResponseOverview = await axios.get(`${baseUrl}/notifications/overview` ,config);
              // console.log("notificationResponseOverview",notificationResponseOverview?.data);
              // if(notificationResponseOverview?.flag){
              //   dispatch(handleBellNotificationPayload(notificationResponseOverview?.data?.data))
              // }


              const notificationResponseCommunity = await axios.get(`${baseUrl}/notifications/community` ,config);
              console.log("notificationResponseCommunity",notificationResponseCommunity?.data);
              if(notificationResponseCommunity?.flag){
                dispatch(handleBellNotificationCommunityPayload(notificationResponseCommunity?.data?.data))
              }
            }
            isNotify && dispatch(handleBellNotificationPayload("Error occurred"));
            dispatch(handleCloseLoader());
            throw error;
        } finally {
            // Cleanup or dispatch further actions if needed
            dispatch(handleCloseLoader());
        }
    };

    const handleGetRequest = async (url,isNotify,isLoader) => {
        const authenticationToken = JSON.parse(localStorage.getItem("AuthToken")??  sessionStorage.getItem("AuthToken"))
        // const authenticationToken = JSON.parse(localStorage.getItem("AuthToken"))
    
        const config = {
            headers: { Authorization: `Bearer ${authenticationToken}` }
            };
        try {
            isLoader && dispatch(handleOpenLoader());
            const response = await axios.get(`${baseUrl}/${url}`, config)

            if (response.data.flag ) {
                // isNotify && toast.success(response?.data?.msg);
                console.log("isNotifyget",isNotify);
                
                // toast.success(<p className="bg-black text-white">response?.data?.msg</p>)
                (isNotify && response?.data?.msg) &&  toast.custom((t) => (
                    <div
                      className={`${
                        t.visible ? 'animate-enter' : 'animate-leave'
                      } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
                    >
                      <div className="flex-1 w-0 p-4">
                        <div className="flex justify-start items-center">
                          <div className="flex-shrink-0 pt-0.5">
                            <img
                              className="h-10 w-10 rounded-full"
                              src="/notificationLogo.jpg"
                              alt=""
                            />
                          </div>
                          <div className="ml-3 flex-1">
                            {/* <p className="text-sm font-medium text-white">
                              Emilia Gates
                            </p> */}
                            <p className="mt-1 text-sm text-black  flex justify-start items-center font-semibold">
                              {response?.data?.msg}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                // isNotify && dispatch(handleBellNotificationPayload(response?.data?.msg));
                if(isNotify){
                  // const notificationResponseOverview = await axios.get(`${baseUrl}/notifications/overview` ,config);
                  // console.log("notificationResponseOverview",notificationResponseOverview?.data);
                  // if(notificationResponseOverview?.flag){
                  //   dispatch(handleBellNotificationPayload(notificationResponseOverview?.data?.data))
                  // }

                  const notificationResponseCommunity = await axios.get(`${baseUrl}/notifications/community` ,config);
                  console.log("notificationResponseCommunity",notificationResponseCommunity?.data);
                  if(notificationResponseCommunity?.flag){
                    dispatch(handleBellNotificationCommunityPayload(notificationResponseCommunity?.data?.data))
                  }
                }
                (isNotify && response?.data?.msg) && dispatch(handleBellNotificationPayload({id: Date.now() + Math.floor(Math.random() * 10000) , title: "", msg: response?.data?.msg})); // To add a notification
                return response.data;

            } else {
                // isNotify && toast.error(response?.data?.msg);
                // isNotify && dispatch(handleBellNotificationPayload(response?.data?.msg));
                // (isNotify && response?.data?.msg) &&  toast.custom((t) => (
                //   <div
                //     className={`${
                //       t.visible ? 'animate-enter' : 'animate-leave'
                //     } max-w-md w-full bg-red-800 shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
                //   >
                //     <div className="flex-1 w-0 p-4">
                //       <div className="flex justify-start items-center">
                //         <div className="flex-shrink-0 pt-0.5">
                //           <img
                //             className="h-10 w-10 rounded-full"
                //             src="/notificationLogo.jpg"
                //             alt=""
                //           />
                //         </div>
                //         <div className="ml-3 flex-1 flex justify-start items-center">
                //           {/* <p className="text-sm font-medium text-white">
                //             Emilia Gates
                //           </p> */}
                //           <p className="mt-1 text-sm text-white font-semibold">
                //             {response?.data?.msg}
                //           </p>
                //         </div>
                //       </div>
                //     </div>
                //   </div>
                // ))
                (isNotify && response?.data?.msg) && globalToast(response?.data?.msg,true)
                if(isNotify){
                  // const notificationResponseOverview = await axios.get(`${baseUrl}/notifications/overview` ,config);
                  // console.log("notificationResponseOverview",notificationResponseOverview?.data);
                  // if(notificationResponseOverview?.flag){
                  //   dispatch(handleBellNotificationPayload(notificationResponseOverview?.data?.data))
                  // }

                  const notificationResponseCommunity = await axios.get(`${baseUrl}/notifications/community` ,config);
                  console.log("notificationResponseCommunity",notificationResponseCommunity?.data);
                  if(notificationResponseCommunity?.flag){
                    dispatch(handleBellNotificationCommunityPayload(notificationResponseCommunity?.data?.data))
                  }
                }
                (isNotify && response?.data?.msg) && dispatch(handleBellNotificationPayload({id: Date.now() + Math.floor(Math.random() * 10000) , title: "", msg: response?.data?.msg})); // To add a notification

            }
        } catch (error) {
            // Handle error
            console.error("Error:", error);
            // isNotify && toast.error("Error occurred");
            // isNotify && dispatch(handleBellNotificationPayload("Error occurred"));
            // toast.custom((t) => (
            //   <div
            //     className={`${
            //       t.visible ? 'animate-enter' : 'animate-leave'
            //     } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
            //   >
            //     <div className="flex-1 w-0 p-4">
            //       <div className="flex justify-start items-center">
            //         <div className="flex-shrink-0 pt-0.5">
            //           <img
            //             className="h-10 w-10 rounded-full"
            //             src="/notificationLogo.jpg"
            //             alt=""
            //           />
            //         </div>
            //         <div className="ml-3 flex-1">
            //           {/* <p className="text-sm font-medium text-white">
            //             Emilia Gates
            //           </p> */}
            //           <p className="mt-1 text-sm text-black font-semibold">
            //             {error?.message?error?.message:"Something went wrong"}
            //           </p>
            //         </div>
            //       </div>
            //     </div>
            //   </div>
            // ))

            globalToast(error?.message?error?.message:"Something went wrong",true)
            if(isNotify){
              // const notificationResponseOverview = await axios.get(`${baseUrl}/notifications/overview` ,config);
              // console.log("notificationResponseOverview",notificationResponseOverview?.data);
              // if(notificationResponseOverview?.flag){
              //   dispatch(handleBellNotificationPayload(notificationResponseOverview?.data?.data))
              // }

              const notificationResponseCommunity = await axios.get(`${baseUrl}/notifications/community` ,config);
              console.log("notificationResponseCommunity",notificationResponseCommunity?.data);
              if(notificationResponseCommunity?.flag){
                dispatch(handleBellNotificationCommunityPayload(notificationResponseCommunity?.data?.data))
              }
            }
            isNotify && dispatch(handleBellNotificationPayload({id: Date.now() + Math.floor(Math.random() * 10000) , title: "", msg:"Error occurred"})); // To add a notification
            dispatch(handleCloseLoader());
            throw error;
        } finally {
            // Cleanup or dispatch further actions if needed
            dispatch(handleCloseLoader());
        }
    };

    return {
        handlePostRequest,handleGetRequest
    };
};

import { createSlice } from "@reduxjs/toolkit";

// const stepper = JSON.parse(localStorage.getItem("stepper")) 

const initialState = {
    likedSongs:[],
    countSongs:[],
    
};

// Slice
export const trackLikeSlice = createSlice({
  name: "likeSongs",
  initialState,
  reducers: {
    handleLikeSongs(state,action){
        state.likedSongs = action.payload;
        // localStorage.setItem('stepper',  JSON.stringify(action.payload))
      },
    
      handleCountSongs(state,action){
        state.countSongs = action.payload;
        // localStorage.setItem('stepper',  JSON.stringify(action.payload))
      },
  },
});

export const {handleLikeSongs,handleCountSongs} = trackLikeSlice.actions;

export default trackLikeSlice.reducer;
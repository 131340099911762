import { initializeApp } from '@firebase/app';
import { getMessaging, getToken, onMessage } from '@firebase/messaging';
import { isSafari, getUserAgent } from 'react-device-detect';
import axios from 'axios';
import { useDispatch } from 'react-redux';

// const firebaseConfig = {
//     apiKey:  process.env.REACT_APP_FIREBASE_API_KEY,
//     authDomain:  process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//     projectId:  process.env.REACT_APP_FIREBASE_PROJECT_ID,
//     storageBucket:  process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//     messagingSenderId:  process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//     appId:   process.env.REACT_APP_FIREBASE_APP_ID,
// };

// const firebaseApp = initializeApp(firebaseConfig);
// const messaging = getMessaging(firebaseApp);
// console.log("poppp",firebaseApp);

// const setupNotifications = async () => {
//   try {
//     // Request permission for notifications
//     const permission = await Notification.requestPermission();
//     console.log('Notification per.',typeof(permission));
  
//     if (permission === 'granted') {

//       // Get the FCM token
//       console.log('FCM messaging:', permission === 'granted');
//       const token = await getToken(messaging);
//       if(token){
//         localStorage.setItem('fcmToken', token);
//     }
//       // const token = await getToken(messaging);
//       console.log('FCM Token:', token);
//       // if(token){
//       //     localStorage.setItem('fcmToken', token);
//       // }

//     } else {
//       console.log('Notification permission denied.');
//     }
//     // Handle foreground notifications
//     onMessage(messaging, (payload) => {
//       console.log('Foreground Message:', payload);
//       localStorage.setItem('ForegroundMessage',  JSON.stringify(payload))
//       window.dispatchEvent(new Event('foregroundMessageChange'));

//       // Handle the notification or update your UI
//     });
//   } catch (error) {
//     console.error('Error setting up notifications:', error);
//   }
// };
// export { messaging, setupNotifications };// Initialize Firebase


const firebaseConfig = {
    apiKey:  process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain:  process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId:  process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket:  process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId:  process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId:   process.env.REACT_APP_FIREBASE_APP_ID,
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

const setupNotifications = async () => {
  try {
    // Request permission for notifications
    const permission = await Notification.requestPermission();
    console.log('Notification permission:', permission);
  
    if (permission === 'granted') {
      console.log('FCM messaging:', permission === 'granted');

      // Check if the browser is Safari
      if (isSafari) {
        console.log('Safari detected');
        const permissionData = await navigator.permissions.query({ name: 'notifications' });
        console.log('Safari permission data:', permissionData);

        if (permissionData.state === 'granted') {
          console.log('Safari notifications granted');

          // Get the FCM token
          const token = await getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY });
          if (token) {
            localStorage.setItem('fcmToken', token);
            console.log('FCM Token:', token);
          } else {
            console.error('Failed to get FCM token');
          }
        } else {
          console.error('Safari notifications denied');
        }
      } else {
        // Get the FCM token for non-Safari browsers
        const token = await getToken(messaging);
        if (token) {
          localStorage.setItem('fcmToken', token);
          console.log('FCM Token:', token);
        } else {
          console.error('Failed to get FCM token');
        }
      }

      // Handle foreground notifications
      onMessage(messaging, (payload) => {
        console.log('Foreground Message:', payload);
        localStorage.setItem('ForegroundMessage', JSON.stringify(payload));
        window.dispatchEvent(new Event('foregroundMessageChange'));
      });
    } else {
      console.log('Notification permission denied.');
    }
  } catch (error) {
    console.error('Error setting up notifications:', error);
  }
};

export { messaging, setupNotifications };
import React, { useEffect, useRef, useState } from 'react'
import Followers from '../../../view/components/CommunityComponents/FollowersComponents/Followers';
import { useApi } from '../../../api/api';
import { useDispatch } from 'react-redux';
import { handleFollowerRequestList, handleFollowerUserList } from '../../../store/followerSlice';

const FollowerPage = () => {
  const { handleGetRequest,handlePostRequest } = useApi();
  const dispatch = useDispatch()
  useEffect(()=>{
    ;(async () => {
        const followerUserRes = await handleGetRequest(`followers`,false,true)
        console.log("followerUserRes",followerUserRes?.data);
        dispatch(handleFollowerUserList(followerUserRes?.data))
    })();
},[])

useEffect(()=>{
  ;(async () => {
      const followerUserRequestRes = await handleGetRequest(`pendingFollowers`,false,true)
      console.log("followingUserRes",followerUserRequestRes?.data);
      dispatch(handleFollowerRequestList(followerUserRequestRes?.data))
  })();
},[])

// useEffect(() => {
//   const fetchFollowerRequests = async () => {
//     try {
//       const followerUserRequestRes = await handleGetRequest(`pendingFollowers`, false, true);
//       console.log("followingUserRes", followerUserRequestRes?.data);
//       dispatch(handleFollowerRequestList(followerUserRequestRes?.data));
//     } catch (error) {
//       console.error("Error fetching follower requests:", error);
//     }
//   };
//   fetchFollowerRequests(); 
//   const intervalId = setInterval(fetchFollowerRequests, 120000);
//   return () => clearInterval(intervalId);
// }, [dispatch]);


    
  const acceptRejectUser = async(id,condition) => {
    const payload={
      follower_id:id,
      action:condition
    }
    if(id==="accepted"){
      const unFollowAcceptRes = await handlePostRequest(`acceptReject`,payload,true,true)
      if(unFollowAcceptRes?.flag){
        const followerUserRequestRes = await handleGetRequest(`pendingFollowers`,false,true)
        console.log("followingUserRes",followerUserRequestRes?.data);
        dispatch(handleFollowerRequestList(followerUserRequestRes?.data))
        const followerUserRes = await handleGetRequest(`followers`,false,true)
        console.log("followerUserRes",followerUserRes?.data);
        dispatch(handleFollowerUserList(followerUserRes?.data))
      }
    }else{
      const unFollowAcceptRes = await handlePostRequest(`acceptReject`,payload,true,true)
      if(unFollowAcceptRes?.flag){
        const followerUserRequestRes = await handleGetRequest(`pendingFollowers`,false,true)
        console.log("followingUserRes",followerUserRequestRes?.data);
        dispatch(handleFollowerRequestList(followerUserRequestRes?.data))
        const followerUserRes = await handleGetRequest(`followers`,false,true)
        console.log("followerUserRes",followerUserRes?.data);
        dispatch(handleFollowerUserList(followerUserRes?.data))
      }
    }
  }


  const cancelUser = async(id,condition) => {
      const unFollowAcceptRes = await handlePostRequest(`removeFollowers/${id}`,true,true)
      if(unFollowAcceptRes?.flag){
        const followerUserRequestRes = await handleGetRequest(`pendingFollowers`,false,true)
        console.log("followingUserRes",followerUserRequestRes?.data);
        dispatch(handleFollowerRequestList(followerUserRequestRes?.data))
        const followerUserRes = await handleGetRequest(`followers`,false,true)
        console.log("followerUserRes",followerUserRes?.data);
        dispatch(handleFollowerUserList(followerUserRes?.data))
      }
  }

  return (
    <div>
        <Followers acceptRejectUser={acceptRejectUser} cancelUser={cancelUser}/>
    </div>
  )
}

export default FollowerPage
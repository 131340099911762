import Button1 from "./common/GlobalButtonLayout/Button1"
import InputField from "./common/Input/InputField"
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';


const ForgotPasswordModal = ({handleForgotPassModal,handleSubmit,onSubmit,register,errors}) => {
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };
    return(
        <div>
            <div className="bg-white dark:lg:w-full  lg:w-3/5 lg:min-h-72 rounded-3xl  p-3 mx-auto text-center dark:bg-[#151515] ">
                        <div className="flex justify-end items-center my-2">
                            <CancelOutlinedIcon className=" text-[#D8D8D8] cursor-pointer !w-7 !h-7" onClick={()=>handleForgotPassModal()}/>
                        </div>
                        <h2 className="text-black lg:text-2xl text-sm font-semibold dark:text-white">Forgot Password ?</h2>
                        <p className="text-black lg:text-2xl text-xs font-normal my-3 dark:text-white">Enter your email to reset your password.</p>
                        <form onSubmit={handleSubmit(onSubmit)} className="text-center">
                        <input
                            placeholder="Your Email" 
                            {...register('Email', {
                              required: 'Email is required',
                              pattern: {
                                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                message: 'Please enter a valid email',
                              },
                            })}
                            type="email"
                            required
                            className={`w-[80%] mx-auto text-center border-[2px] border-white focus:border-[2px]  bg-[#FAFAF8] rounded-[50px] text-[#312522] text-[16px] font-normal py-2 my-1 ${errors && errors['Email'] && (errors['Email'].type === 'required' || errors['Email'].type === 'pattern') ? "!border-[#D80027] focus:!border-[#D80027]" : "focus:!border-[#F1B942]"} `}
                          />
                             {errors && errors['Email'] && errors['Email'].type === "required" && (
                            <p className="text-red-500 text-xs lg:text-sm">Email is required</p>
                            )}

                            {errors && errors['Email'] && errors['Email'].type === 'pattern' && (
                                <p className="text-red-500 text-xs lg:text-sm">{"Email must meet given criteria"}</p>
                            )}

                            
                            <Button1 type="submit" className="lg:!w-[30%] !w-[80%] !mt-5 text-center dark:hover:!text-[#F3C25A]">
                                Get code    
                            </Button1>
                        </form>
                    </div>
        </div>
    )
}

export default ForgotPasswordModal
import { useEffect, useMemo, useState } from "react";
import P16 from "../../view/components/common/Typograpgy/P16"
import { useApi } from "../../api/api";
import parse from 'html-react-parser';
import H220 from "../../view/components/common/Typograpgy/H220";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from "react-router-dom";
import BackToPage from "../../view/components/common/BacktoPage/BackToPage";


function TermsConditions(){
    const { handleGetRequest } = useApi();
    const [termsConditionsData, setTermsConditionsData] = useState()
    const [isLoading, setIsLoading] = useState(true); // State to track loading status

    
    useEffect(() => {
        // Skip the first render
        setIsLoading(true);
        (async () => {  
          const termsConditionsRes = await handleGetRequest(`users/terms-and-conditions`, false,false);
            if(termsConditionsRes?.flag){
              console.log("termsConditionsRes", termsConditionsRes);
              setTermsConditionsData(termsConditionsRes?.data)
          }
           // Set loading state to false after data is fetched
           setIsLoading(false);
        })();
      }, []);

      const parsedContent = useMemo(() => {
        return termsConditionsData?.t_page_content?parse(termsConditionsData?.t_page_content):"";
    }, [termsConditionsData]);

    return(
        <div  className="px-2 lg:px-0 mb-10">
        {/* <BackToPage pageRoute="/" backTo="Home"/> */}
        {/* <H220 className="dark:text-white">Terms & Conditions</H220> */}
     
                  <div className=" mt-7 rounded-lg bg-[#F1B942] text-white lg:text-[46px] text-2xl font-bold py-10 w-full flex flex-col justify-center items-center">
                      <p>{termsConditionsData?.v_name}</p>
                  </div>
                  <div className="bg-white drop-shadow-xl p-3 my-5 rounded-lg dark:bg-[#131313] dark:text-[#A1A1A1]">
                  <P16 className="!my-5 dark:text-[#A1A1A1]">{parsedContent}</P16>
                  </div>
        
        </div>
    )
}

export default TermsConditions
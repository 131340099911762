import React, { useEffect, useRef, useState } from 'react'
import H220 from '../../../view/components/common/Typograpgy/H220'
import Button2 from '../../../view/components/common/GlobalButtonLayout/Button2'
import CommunityLayout from '../../../view/components/CommunityComponents/CommunityLayout'
import { useDispatch, useSelector } from 'react-redux'
import { useApi } from '../../../api/api'
import { handleFollowingRequestList, handleFollowingUserList } from '../../../store/followingSlice'
import { Link } from 'react-router-dom'

const SentRequest = () => {
  const followingRequest = useSelector((state) => state.following.followingRequest);
  const { handleGetRequest,handlePostRequest } = useApi();
  const dispatch = useDispatch()
  useEffect(()=>{
    ;(async () => {
        const followingUserRes = await handleGetRequest(`pendingFollowings`,false,true)
        console.log("followingUserRes",followingUserRes?.data);
        dispatch(handleFollowingRequestList(followingUserRes?.data))
    })();
},[])

  const acceptRejectUser = async(id,condition) => {
    const payload={
      follower_id:id,
      action:condition
    }
    if(id==="accepted"){
      const unFollowAcceptRes = await handlePostRequest(`acceptReject`,payload,true,true)
    }else{
      const unFollowAcceptRes = await handleGetRequest(`removeFollowing/${id}`,true,true)
      if(unFollowAcceptRes?.flag){
        const followingUserRes = await handleGetRequest(`followings`,false,true)
        console.log("followingUserRes",followingUserRes?.data);
        dispatch(handleFollowingUserList(followingUserRes?.data))
        const pendingfollowingUserRes = await handleGetRequest(`pendingFollowings`,false,true)
        console.log("followingUserRes",pendingfollowingUserRes?.data);
        dispatch(handleFollowingRequestList(pendingfollowingUserRes?.data))
      }
    }
  }
  
  return (
    <div>
      <CommunityLayout>
      <H220 className="">Sent requests</H220>
      <div className='lg:grid lg:grid-cols-6   gap-x-5 my-5 text-center'>
          {followingRequest?.map((items, index)=>{
          return(
              <div className={`grid grid-cols-3  gap-x-5 my-2  lg:block`}>
                  <Link to={`/other-user/${items?.id}`} className='flex justify-start items-center gap-x-4 lg:block col-span-2'>
                  <div className='my-1 min-w-14 max-w-14 h-14 lg:min-w-16 lg:max-w-16 lg:h-16 lg:mx-auto rounded-full '>
                      <img src={items?.t_image} alt='' className=' w-full h-full object-cover rounded-full' />
                  </div>
                  <div className='max-w-[100px] lg:max-w-auto lg:mx-auto'>
                  <p className='mt-1 font-semibold lg:text-xl text-sm dark:text-white mb-3 capitalize line-clamp-1'>{items?.v_name?items?.v_name:"User Name"} </p>
                  </div>
                  </Link>
                  <Button2 onClick={()=>acceptRejectUser(items?.id,"rejected")} className="!text-center dark:text-[#F1B942]">Cancel</Button2>
              </div>
          )
          })}
      </div>
      </CommunityLayout>
    </div>
  )
}

export default SentRequest
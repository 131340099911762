import React, { useEffect, useState } from 'react'
import { Dialog } from 'primereact/dialog';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useDispatch, useSelector } from 'react-redux';
import H220 from '../Typograpgy/H220';
import { useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Button1 from '../GlobalButtonLayout/Button1';
import { useApi } from '../../../../api/api';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useParams } from 'react-router';
import { trackDetail } from '../../../../store/trackSlice';


const EditTrack = ({openEditTrackModal,setOpenEditTrackModal,image,trackInfo,setTrackInfo,setSelectedPlatform,selectedplatform}) => {
  const [imgUrl, setImgUrl] = React.useState(trackInfo?.t_image);
  const [selectedImage, setSelectedImage] = React.useState("");
  const { handleGetRequest,handlePostRequest } = useApi();
  const [imageFile, setImageFile] = useState(image);
  console.log("infotrack",trackInfo);
  const { id } = useParams();
  console.log("paramss",id);
  
  const isDark = useSelector((state) => state.dark.isDarkValue);
  const { register, handleSubmit, setValue,reset,  formState: { errors } } = useForm({
    defaultValues: {
      image:trackInfo?.t_image?trackInfo?.t_image:"aaa", 
      title: trackInfo?.t_title?trackInfo?.t_title:"Title",
      geoLocation: trackInfo?.t_country,
      year: 2020,
      genres: trackInfo?.t_genres,
      albumName: trackInfo?.albumlist,
      artistName: trackInfo?.artistlist
    }
  });
const dispatch = useDispatch()

const [imageUrl, setImageUrl] = useState(trackInfo?.t_image);
// console.log("imageFile",imageFile)

const handleImageChange = (event) => {
const file = event.target.files[0];
if (file) {
    setImageFile(file);
    const imageUrl = URL.createObjectURL(file);
    setImageUrl(imageUrl);
}
};
 

  const handleEditSubmit = async(data) => {
    console.log('Edited data:', data);

    const payload = {
      title:data?.title,
      albums:data?.albumName,
      artists:data?.artistName,
      regions:data?.geoLocation,
      genres:data?.genres,
      cover:imageFile?imageFile:imageUrl,
      id:trackInfo?.id,
      decades:data?.year
    }
    // Process the edited data (e.g., send to API)
   
    const genresRes = await handlePostRequest(`edit-song`,payload,true,true)
    console.log("sdsafsdgenresRes",genresRes);
    if(genresRes?.flag){
      reset()
      setTrackInfo({})

      if(id){

        const payloadrefresh = {
          is_global:false
        }
        const playlistSongsRes = await handlePostRequest(`${`playlist-song-data?type=all&search_keyword=&sort_type=alphabetic&perPage=10000000&page=1&v_code=${id}`}`, payloadrefresh,false,true)
        localStorage.setItem('TrackData', JSON.stringify(playlistSongsRes?.data?.data))
        dispatch(trackDetail(playlistSongsRes?.data?.data))
      }

      if(!id){
        
      setSelectedPlatform("Youtube Music")

      setTimeout(() => {
        setSelectedPlatform("default");
      }, 2000); // 1000 milliseconds = 1 second
      }


    }
    setOpenEditTrackModal(false)
  };

  const [geos,setGeos] = useState([])

  useEffect(()=>{
    ;(async () => {
      if(openEditTrackModal){
        const geosRes = await handleGetRequest(`get-all-contries`,false,false)
        console.log("geosRes",geosRes?.data);
        setGeos(geosRes?.data?geosRes?.data:[])
      }
    })();
  },[openEditTrackModal])

  const [genres,setGenres] = useState([])


  useEffect(()=>{
    ;(async () => {
      if(openEditTrackModal){
        const genresRes = await handleGetRequest(`users/get-all-genres?page=1&per_page=2000`,false,false)
        console.log("genresRes",genresRes?.data);
        setGenres(genresRes?.data)
        
      }
    })();
  },[openEditTrackModal])

  const closeedit = () => {
    setOpenEditTrackModal(false)
    reset()
    setTrackInfo({})
  }
 
  return (
    <Dialog  visible={openEditTrackModal} className={`dark:!bg-[#151515] w-full  p-5 lg:h-[450px] h-full lg:w-[40vw] fixed lg:static top-0    !bg-white !opacity-1 drop-shadow-xl rounded-xl ${isDark?"dark":""}`}  onHide={() => setOpenEditTrackModal(false)}>
    <HighlightOffIcon onClick={() =>closeedit() } className="absolute top-5 right-5 cursor-pointer dark:!text-[#A8A8A8] "/>
    <H220 className="!text-center !text-xl">Edit your track</H220>
      <div className="h-[60%] lg:h-full  dark:!bg-[#151515]">
           <form onSubmit={handleSubmit(handleEditSubmit)}>
               {/* <div>
                 <p>Track Cover:</p>
                 <input type="file" id="image" {...register('image')} />
               </div> */}

          <div className='my-3 '>
           <p className='text-sm font-semibold dark:text-white mb-4'>Track Cover:</p> 
           <div className=" w-24 h-24 relative ">
                <img
                src={imageUrl?imageUrl:imageFile}
                alt="Preview"
                className="w-full h-full object-cover rounded-2xl"
            />
             <div className={`absolute top-[-7px] right-[-7px]   w-7 h-7 border-[3px] border-[#312522] bg-white rounded-full flex justify-center items-center`}>
                    <div className="relative">
                    <input
                        type="file"
                        name="image"
                        accept="image/*"
                        onChange={handleImageChange}
                        className="!opacity-0 w-7 absolute top-0 rounded-2xl"
                    />
                
                    <EditOutlinedIcon className="!text-[#312522] !text-sm"/>
                    </div>
                </div>
                <div>
                </div>
                
            </div>
            {(!imageUrl) && <div className='text-left w-full'><span className="text-red-600 text-sm dark:!text-white">Please select image</span></div>}
            </div>
               <div className='grid grid-cols-2 lg:gap-5 gap-x-12 gap-y-7'>
               <div>
                  <p  className='text-sm font-semibold dark:text-white my-2'>Track Title:</p>
                 <input className={`bg-[#FAFAF8] text-base rounded-3xl w-full lg:w-auto py-2 px-2 dark:bg-black dark:text-white ${errors.title?"border-[1px] border-red-600":""}`} type="text" id="title" {...register('title', { required: true })} />
                 {errors.title && <div className='text-left'><span className="text-red-600 text-xs lg:text-sm dark:!text-white">Please enter title</span></div>}
               </div>
               <div>
                <p  className='text-sm font-semibold dark:text-white my-2'>Geo:</p>
                 <select defaultValue={geos[0]?.v_region} className={`bg-[#FAFAF8] ${errors.geoLocation?"border-[1px] border-red-600":""} dark:bg-black dark:text-white  text-base w-full rounded-3xl py-2 px-2 lg:w-full`} id="geoLocation" {...register('geoLocation', { required: true })}>
                  {geos?.map((item,index)=>{
                    return(
                   <option  value={item?.v_region}>{item?.v_region}</option>

                    )
                  })}
                  
                   {/* Add more options as needed */}
                 </select>
                 {errors.geoLocation && <div className='text-left'><span className="text-red-600 text-xs lg:text-sm dark:!text-white">Please select Geo</span></div>}
               </div>
               <div>
                 <p  className='text-sm font-semibold dark:text-white my-2'>Year:</p>
                 <select className={`bg-[#FAFAF8] ${errors.year?"border-[1px] border-red-600":""}  dark:bg-black dark:text-white text-base w-full  rounded-3xl py-2 px-2 lg:w-full`} id="year" {...register('year', { required: true })}>
                   {Array.from({ length: 50 }, (_, i) => (
                     <option key={i} value={2024 - i}>
                       {2024 - i}
                     </option>
                   ))}
                 </select>
                 {errors.year && <div className='text-left'><span className="text-red-600 text-xs lg:text-sm dark:!text-white">Please select year</span></div>}
               </div>
               <div>
                 <p  className='text-sm font-semibold dark:text-white my-2'>Genre:</p>
                 <select defaultValue={genres[0]?.t_genres} className={`bg-[#FAFAF8] ${errors.genres?"border-[1px] border-red-600":""}   dark:bg-black dark:text-white text-base  w-full rounded-3xl py-2 px-2 lg:w-full`} id="genres" {...register('genres', { required: true })}>
                 {genres?.data?.map((item,index)=>{
                    return(
                   <option value={item?.t_genres}>{item?.t_genres}</option>

                    )
                  })}
                  
                   {/* Add more options as needed */}
                 </select>
                 {errors.genres && <div className='text-left'><span className="text-red-600 text-xs lg:text-sm dark:!text-white">Please select genres</span></div>}
               </div>
               <div>
                 <p  className='text-sm font-semibold dark:text-white my-2'>Album:</p>
                 <input className={`bg-[#FAFAF8] ${errors.albumName?"border-[1px] border-red-600":""}  dark:bg-black dark:text-white text-base w-full lg:w-full rounded-3xl py-2 px-2`} type="text" id="albumName" {...register('albumName', { required: true })} />
                 {errors.albumName && <div className='text-left'><span className="text-red-600 text-xs lg:text-sm dark:!text-white">Please enter album name</span></div>}
               </div>
               <div>
                 < p  className='text-sm font-semibold dark:text-white my-2'>Artist:</p>
                 <input className={`bg-[#FAFAF8] ${errors.artistName?"border-[1px] border-red-600":""} dark:bg-black dark:text-white text-base w-full lg:w-full  rounded-3xl py-2 px-2`} type="text" id="artistName" {...register('artistName', { required: true })} />
                 {errors.artistName && <div className='text-left'><span className="text-red-600 text-xs lg:text-sm dark:!text-white">Please enter artist name</span></div>}
               </div>
               </div>
               <div className='flex justify-center items-center my-3 w-32 mx-auto'>
               <Button1 className="text-center dark:hover:!text-[#f1b942]" type="submit">Save</Button1>
               </div>
             </form>
      </div>
    </Dialog>

  )
}

export default EditTrack




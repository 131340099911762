import { useEffect, useMemo, useState } from "react";
import H220 from "../../view/components/common/Typograpgy/H220"
import P16 from "../../view/components/common/Typograpgy/P16"
import { useApi } from "../../api/api";
import parse from 'html-react-parser';
import BackToPage from "../../view/components/common/BacktoPage/BackToPage";

function CopyrightPolicy(){
    const { handleGetRequest, handlePostRequest } = useApi();
    const [copyrightData, setCopywriteData] = useState()
    const [isLoading, setIsLoading] = useState(true); // State to track loading status

    
    useEffect(() => {
      setIsLoading(true);

        (async () => {  
          const copyrightRes = await handleGetRequest(`users/copyright-policy`, false,true);
            if(copyrightRes?.flag){
              console.log("copyrightRes", copyrightRes);
              setCopywriteData(copyrightRes?.data)
          }
          setIsLoading(false);

        })();
      }, []);

      const parsedContent = useMemo(() => {
        return copyrightData?.t_page_content?parse(copyrightData?.t_page_content):"";
    }, [copyrightData]);
    return(
        <div  className="px-2 lg:px-0 mb-10">
        {/* <BackToPage pageRoute="/" backTo="Home"/> */}
        {/* <H220 className="dark:text-white">Copyright Policy</H220> */}
                <div className=" mt-7 rounded-lg bg-[#F1B942] text-white lg:text-[46px] text-2xl font-bold py-10 w-full flex flex-col justify-center items-center">
                    <p>{copyrightData?.v_name}</p>
                </div>
                <div className="bg-white drop-shadow-xl p-3 my-5 rounded-lg dark:bg-[#131313] dark:text-[#A1A1A1]">
                <P16 className="!my-5 dark:text-[#A1A1A1]">{parsedContent}</P16>
                </div>
        </div>
    )
}

export default CopyrightPolicy
import { useEffect, useRef, useState } from "react";
import H220 from "../../view/components/common/Typograpgy/H220"
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { useApi } from "../../api/api";
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import Button2 from "../../view/components/common/GlobalButtonLayout/Button2";
import CreatePlaylistModal from "../../view/components/common/TracksComponents/CreatePlaylistModal";
import DetailTopSection from "../../view/components/collectionPagesComponents/DetailTopSection";
// import { toast } from "react-toastify";
import toast, { Toaster } from 'react-hot-toast';

import BackToPage from "../../view/components/common/BacktoPage/BackToPage";
import { useDebouncedValue } from '@mantine/hooks';
import { Link, useLocation } from "react-router-dom";
import globalToast from "../../hooks/globalToast";


const ManagePlaylist = () => {
    const { handleGetRequest, handlePostRequest } = useApi();
    const [selectedplatform, setSelectedPlatform] = useState(["all"]);
    const [platforms, setPlatforms] = useState();
    const [managePlaylistData, setManagePlaylistData] = useState();
    const [type,setType] = useState("synced_playlists")
    const [searchQuery, setSearchQuery] = useState("")
    const [debounced] = useDebouncedValue(searchQuery, 300);
    const [sort, setSort] = useState(false);
    const [sortFilter, setSortFilter] = useState("new");
    const dropdownRef = useRef(null);
    const [create, setCreate] = useState(false);
    const detailTitles = ["Playlists","Artists","Tracks","Genres"]
    const [searchExtend,setSearchExtend] = useState(false)
    const imageRef = useRef(null);


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target) &&
            (imageRef.current && !imageRef.current.contains(event.target))) {
                setSort(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        ;(async () => {
            const platformDataReq = await handleGetRequest("playlist", false, true);
            setPlatforms(platformDataReq?.data)
        })();
    }, []);

    useEffect(()=>{
        ;(async () => {
            const managePlaylistRes = await handleGetRequest(`${`manage-playlist?type=${type}&search_keyword=${debounced}&sort_type=${sortFilter}`}`,false, true)
            console.log("managePlaylistRes",managePlaylistRes?.data);
            setManagePlaylistData(managePlaylistRes?.data)
        })();
    },[selectedplatform,type,debounced,sortFilter])

    const refreshPlaylist = async() => {
        const managePlaylistRes = await handleGetRequest(`${`manage-playlist?type=synced_playlists&search_keyword=${debounced}&sort_type=${sortFilter}`}`,false, true)
        console.log("managePlaylistRes",managePlaylistRes?.data);
        setManagePlaylistData(managePlaylistRes?.data)
    }

   

    const [openCreatePlaylistTrackModal, setOpenCreatePlaylistTrackModal] = useState(false);
    const [createPlaylistId, setCreatePlaylistId] = useState();
    const [selectedRows, setSelectedRows] = useState([]);

    console.log("selectedRows",selectedRows);

    const [editPlaylistData, setEditPlaylistData] = useState()
    const [isEdit, setIsEdit] = useState(false);


    useEffect(()=>{
        setSelectedRows([])
    },[type])


    const handleClickOpenCreatePlaylistTrackModal = (id) => {
        if(selectedRows.length>0){
            setIsEdit(false)
            setOpenCreatePlaylistTrackModal(true);
            setCreatePlaylistId([id])
        }else{
            // toast.error("Please Select Atleast one Playlist");
            globalToast("Please Select Atleast one Playlist",true)

        }
       
    };

    // useEffect(() => {
    //     localStorage.setItem('createPlaylistId', JSON.stringify(createPlaylistId));
    // }, [createPlaylistId]);

    const handleCreatePlaylist = (id) => {
        console.log("selectedRows",selectedRows);
        if(selectedRows.length>0){
            localStorage.setItem('createPlaylistId', JSON.stringify(selectedRows));
            localStorage.setItem('createPlaylistScope', JSON.stringify(type));
        }else{
            // toast.error("Please Select Atleast one Playlist");
            globalToast("Please Select Atleast one Playlist",true)

        }
    }

    const location  = useLocation()


    const handleClickEditPlaylistTrackModal = (id) => {
        setIsEdit(true)
        const edit = managePlaylistData.filter((item)=>item.id===id)
        console.log("edit",edit);
        setEditPlaylistData(edit)
        setOpenCreatePlaylistTrackModal(true);
        const newUrl = `${location.pathname}?${Date.now()}`;
        window.history.pushState({}, '', newUrl);
        setCreatePlaylistId([id])
    };

    useEffect(() => {
        const handlePopState = (event) => {
       //    callback(event);
       setOpenCreatePlaylistTrackModal(false)
          
        };
    
        window.addEventListener('popstate', handlePopState);
    
        return () => {
          window.removeEventListener('popstate', handlePopState);
        };
      }, [])



        // const handleRowSelect = (id) => {
        //     const selectedIndex = selectedRows.indexOf(id);
        //     let newSelected = [];
        //     console.log("xcvd",selectedRows);
    
        //     if (selectedIndex === -1) {
        //         newSelected = newSelected.concat(selectedRows, id);
        //     } else if (selectedIndex === 0) {
        //         newSelected = newSelected.concat(selectedRows.slice(1));
        //     } else if (selectedIndex === selectedRows.length - 1) {
        //         newSelected = newSelected.concat(selectedRows.slice(0, -1));
        //     } else if (selectedIndex > 0) {
        //         newSelected = newSelected.concat(
        //             selectedRows.slice(0, selectedIndex),
        //             selectedRows.slice(selectedIndex + 1),
        //         );
        //     }
    
        //     setSelectedRows(newSelected);
        // };

        const handleRowSelect = (id) => {
            // Check if the id is already in the selectedRows array
            const isSelected = selectedRows.includes(id);
        
            if (isSelected) {
              // If it is, remove it from the array
              setSelectedRows(selectedRows.filter(rowId => rowId !== id));
            } else {
              // If it's not, add it to the array
              setSelectedRows([...selectedRows, id]);
            }
         };

        const like = async() => {
            if(selectedRows.length<1){
            //    toast.error("Please Select Atleast one Playlist");
                globalToast("Please Select Atleast one Playlist",true)

               return
            }
            const playlistLikePayload = {
                type:"Like",
                playlist_ids:selectedRows
            }
            const managePlaylistLikeRes = await handlePostRequest(`${`playlists-fav`}`,playlistLikePayload,true, false)
            console.log("managePlaylistLikeRes",managePlaylistLikeRes?.data);
            setSelectedRows([])
            const managePlaylistRes = await handleGetRequest(`${`manage-playlist?type=${type}&search_keyword=${debounced}&sort_type=${sortFilter}`}`,false,false)
            console.log("managePlaylistRes",managePlaylistRes?.data);
            setManagePlaylistData(managePlaylistRes?.data)
        }

        const handlelike = async(id) => {
           
            const playlistLikePayload = {
                type:"Like",
                playlist_ids:[id]
            }
            const managePlaylistLikeRes = await handlePostRequest(`${`playlists-fav`}`,playlistLikePayload,true, false)
            console.log("managePlaylistLikeRes",managePlaylistLikeRes?.data);
            setSelectedRows([])
            const managePlaylistRes = await handleGetRequest(`${`manage-playlist?type=${type}&search_keyword=${debounced}&sort_type=${sortFilter}`}`,false,false)
            console.log("managePlaylistRes",managePlaylistRes?.data);
            setManagePlaylistData(managePlaylistRes?.data)
        }

        const deletePlaylist = async() => {
            if(selectedRows.length<1){
            //    toast.error("Please Select Atleast one Playlist");
               globalToast("Please Select Atleast one Playlist",true)

               return
            }
            const playlistLikePayload = {
                playlist_ids:selectedRows
            }
            const managePlaylistDeleteRes = await handlePostRequest(`${`playlists-delete`}`,playlistLikePayload,true, true)
            console.log("managePlaylistLikeRes",managePlaylistDeleteRes?.data);
            setSelectedRows([])
            const managePlaylistRes = await handleGetRequest(`${`manage-playlist?type=${type}&search_keyword=${debounced}&sort_type=${sortFilter}`}`,false,true)
            console.log("managePlaylistRes",managePlaylistRes?.data);
            setManagePlaylistData(managePlaylistRes?.data)
        }

        const [selectAll,setSelectAll] = useState()
        const [createPlaylistCode,setCreatePlaylistCode] = useState()

        console.log("managePlaylistData",managePlaylistData);

    return(
    <div className="">
    {/* <BackToPage pageRoute="/collection/playlists" backTo="Playlist"/> */}
     <CreatePlaylistModal setSelectAll={setSelectAll} setCreatePlaylistCode={setCreatePlaylistCode} openCreatePlaylistTrackModal={openCreatePlaylistTrackModal} setOpenCreatePlaylistTrackModal={setOpenCreatePlaylistTrackModal} id={selectedRows.length>0?selectedRows:createPlaylistId} setSelectedRows={setSelectedRows} setCreate={setCreate} image={editPlaylistData?.length>0?editPlaylistData[0].t_image:""} title={editPlaylistData?.length>0?editPlaylistData[0].v_name:""} des="description" vcode={editPlaylistData?.length>0?editPlaylistData[0].v_code:""} isEdit={isEdit}/>
        <div className="px-3 lg:px-0">
            <H220 className="">Manage Playlists</H220>
        </div>
        <div className="flex justify-between items-center flex-wrap px-2 lg:px-0">
            <div className="flex justify-start flex-wrap lg:flex-nowrap gap-x-4 gap-y-3 items-center">
                <button className={` lg:w-28 !py-2 !px-2 hover:bg-[#F1B942] rounded-[30px] ${type==="my_playlists"?"bg-[#F1B942] text-white":"bg-white text-[#312522]  dark:bg-[#151515]  dark:text-white"} !text-xs `} onClick={()=>setType("my_playlists")}>My Playlists</button>
                <button className={`  !py-2 !px-3 hidden lg:block hover:bg-[#F1B942] rounded-[30px] ${type==="synced_playlists"?"bg-[#F1B942] text-white":"bg-white text-[#312522] dark:bg-[#151515]  dark:text-white"} !text-xs `} onClick={()=>setType("synced_playlists")}>Synced</button>
                <button className={`  !py-2 !px-3 block lg:hidden hover:bg-[#F1B942] rounded-[30px] ${type==="synced_playlists"?"bg-[#F1B942] text-white":"bg-white text-[#312522] dark:bg-[#151515]  dark:text-white"} !text-xs `} onClick={()=>setType("synced_playlists")}>Synced</button>
                <button className={`  !py-2 !px-4 hover:bg-[#F1B942] rounded-[30px] ${type==="liked_playlists"?"bg-[#F1B942] text-white":"bg-white text-[#312522] dark:bg-[#151515]  dark:text-white"} !text-xs `} onClick={()=>setType("liked_playlists")}>Liked</button>
                <button className={`  !py-2 !px-4 hover:bg-[#F1B942] rounded-[30px] ${type==="shared_playlists"?"bg-[#F1B942] text-white":"bg-white text-[#312522] dark:bg-[#151515]  dark:text-white"} !text-xs `} onClick={()=>setType("shared_playlists")}>Shared</button>
               
                <FavoriteBorderOutlinedIcon className="cursor-pointer !hidden  dark:!text-[#DDD1CE]"  onClick={()=>like()}/>
                <DeleteForeverOutlinedIcon className="cursor-pointer lg:!hidden !block dark:!text-[#DDD1CE]" onClick={()=>deletePlaylist()}/>
              
                <div className="lg:flex hidden  justify-start items-center gap-x-3 relative my-3 lg:my-0">
                {/* Search input field */}
                <div className={`relative  ${searchExtend?"w-48":"w-0"} duration-700 `}>
                    <input
                        type="text"
                        placeholder="Search..."
                        // className={`bg-white ${searchExtend?"":"lg:pr-7 lg:pl-7"}  rounded-[50px] pl-2 pr-3 py-2 focus:outline-none focus:border-[#F1B942] border-[1px] dark:bg-[#151515] dark:border-[#151515]  dark:text-white `}
                        className={`bg-white ${searchExtend?"":"px-7"} rounded-[50px] py-2 pl-2 h-full w-full dark:bg-[#151515] dark:border-[#151515]  dark:text-white  focus:outline-none focus:border-[#F1B942]  border-[1px] text-xs transition duration-300 `}
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    {/* <SearchOutlinedIcon onClick={()=>setSearchExtend(pre=>!pre)} className={`absolute top-1/2 transform -translate-y-1/2 right-3 dark:!text-[#DDD1CE] ${searchExtend?"right-3":"-right-8"}`}/> */}
                    <SearchOutlinedIcon onClick={()=>setSearchExtend(pre=>!pre)} className={`absolute dark:!text-[#DDD1CE] top-1/2 transform -translate-y-1/2 ${searchExtend?"right-3":"-right-8"}  cursor-pointer`}/>
                </div>
                <div className={`cursor-pointer ${searchExtend?"ml-0":"ml-10"} `} ref={imageRef}>
                    <img className="dark:filter dark:invert" onClick={()=>setSort(pre=>!pre)} src="/images/TrackTable/sortby.svg" alt=""  />
                </div>
                {sort && <div ref={dropdownRef}  className={`absolute top-[100%] right-[0%]  z-50 min-w-36 text-sm bg-white rounded-lg drop-shadow-lg p-3 dark:border-[1px] dark:border-[#343434] dark:bg-[#151515] dark:text-[#DDD1CE]`}>
                    <p className="my-2 cursor-pointer hover:text-[#F1B942]" onClick={()=>{setSortFilter("new"); setSort(false)}} >New</p>
                    <p className="my-2 cursor-pointer hover:text-[#F1B942]" onClick={()=>{setSortFilter("popular");setSort(false)}} >Popular</p>
                    <p className="my-2 cursor-pointer hover:text-[#F1B942]" onClick={()=>{setSortFilter("listened");setSort(false)}} >Listened</p>
                    <p className="my-2 cursor-pointer hover:text-[#F1B942]" onClick={()=>{setSortFilter("favorite");setSort(false)}} >Favorites</p>
                </div>}
              
                </div>
            </div>
            <div className={`flex lg:hidden w-full rounded-lg bg-white dark:bg-black ${sort?"":""} py-2 px-2 justify-between items-center gap-x-1 relative my-3 lg:my-0`}>
                {/* Search input field */}
                <div className="relative w-[45%]  border-[1px] border-[#F1B942] rounded-[50px]">
                    <input
                        type="text"
                        placeholder="Search..."
                        className="bg-white dark:bg-black rounded-[50px] px-1 py-2 w-[75%] dark:border-[#151515]  dark:text-white "
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <SearchOutlinedIcon className="absolute top-1/2 transform -translate-y-1/2 right-3 dark:!text-[#DDD1CE]"/>
                </div>
                <div className="cursor-pointer lg:w-[10%]">
                    <img  className="dark:filter dark:invert"   onClick={()=>setSort(pre=>!pre)} src="/images/TrackTable/sortby.svg" alt=""  />
                </div>
                {sort && <div ref={dropdownRef}  className={`absolute top-[80%] left-[5%]  z-[999999999] w-[50%] text-sm bg-white rounded-lg drop-shadow-lg p-3 dark:border-[1px] dark:border-[#343434] dark:bg-[#151515] dark:text-[#DDD1CE]`}>
                    <p className="my-2 cursor-pointer hover:text-[#F1B942]" onClick={()=>{setSortFilter("new"); setSort(false)}} >New</p>
                    <p className="my-2 cursor-pointer hover:text-[#F1B942]" onClick={()=>{setSortFilter("popular");setSort(false)}} >Popular</p>
                    <p className="my-2 cursor-pointer hover:text-[#F1B942]" onClick={()=>{setSortFilter("listened");setSort(false)}} >Listened</p>
                    <p className="my-2 cursor-pointer hover:text-[#F1B942]" onClick={()=>{setSortFilter("favorite");setSort(false)}} >Favorites</p>
                </div>}
                {/* <div className="block w-[40%]" onClick={()=>handleClickOpenCreatePlaylistTrackModal()}>
                    <Button2 className="!w-full !text-center !px-7 !text-[11px] !bg-black !text-white !border-black hover:!text-black hover:!bg-white hover:!border-black ">Create Playlist</Button2>
                </div>  */}
                <Link to={`${selectedRows.length>0?"/create-playlist":"/collection/manage-playlist"}`} onClick={()=>handleCreatePlaylist()} className="block w-[40%]">
                    <Button2 className="!w-full !text-center !px-7 !text-[11px] !bg-black !text-white !border-black hover:!text-black hover:!bg-white hover:!border-black dark:!text-[#DDD1CE] dark:!border-[#DDD1CE]">Generate Playlist</Button2>
                </Link> 
                </div>
            <div className="lg:flex justify-start items-center gap-x-4 hidden">
                {/* <FavoriteBorderOutlinedIcon className="cursor-pointer dark:!text-[#DDD1CE]"  onClick={()=>like()}/> */}
                <DeleteForeverOutlinedIcon className="cursor-pointer dark:!text-[#DDD1CE]" onClick={()=>deletePlaylist()}/>
{/*               
                <div className="block" onClick={()=>handleClickOpenCreatePlaylistTrackModal()}>
                    <Button2 className="!w-full !px-7 !bg-black !text-white !border-black hover:!text-black hover:!bg-white hover:!border-black ">Create Playlist</Button2>
                </div>  */}

                <Link to={`${selectedRows.length>0?"/create-playlist":"/collection/manage-playlist"}`}   onClick={()=>handleCreatePlaylist()} className="block" >
                    <Button2 className="!w-full !px-7 !bg-black !text-white !border-black hover:!text-black hover:!bg-white hover:!border-black dark:!text-[#DDD1CE] dark:!border-[#DDD1CE]">Generate Playlist</Button2>
                </Link> 
               
            </div>
        </div>
       { managePlaylistData?.length<1 &&<div>
            {type && 
            <div className="py-16 bg-white dark:bg-[#151515] dark:text-white drop-shadow-lg w-full mt-7 mx-auto flex justify-center items-center rounded-xl">
           { !searchQuery?<p className="lg:text-xl text-lg font-semibold">
                    
                    {type==="my_playlists"  ? <p>No playlist created yet!</p> : type==="synced_playlists" ? <p>No playlist synced yet! to sync your playlist <Link to="/library" className="text-[#F1B942]">click</Link>  here
                </p>:type==="liked_playlists"?<p>No playlist added to favourites</p>:<p>No playlists are shared</p>}
            </p>:<div className="lg:text-xl text-lg font-semibold">
                 <p>No playlist Available </p>
            </div>}
            </div>
            }
        
        </div>}
        {managePlaylistData?.map((item)=>{
            const isSelected = selectedRows.includes(item.id);
            return(
                <div className="my-10 flex justify-between items-center bg-white rounded-xl dark:bg-[#151515]">
                    <DetailTopSection image={item?.data?.t_image} manageId={item?.data?.id} name={item?.data?.v_name} t_description={item?.data?.t_description} time={item?.data?.v_total_duration} decades={item?.data?.v_decade_range} detailtitles={detailTitles} details={item} tracksLength={item?.data?.v_total_songs} title="manage-playlist" Id={item?.data?.id} handleRowSelect={handleRowSelect} isSelected={isSelected} handleClickEditPlaylistTrackModal={handleClickEditPlaylistTrackModal} platform={item?.data?.e_platform} vCode={item?.data?.v_code} t_playlist_id={item?.data?.t_playlist_id} selectedRows={selectedRows} handlelike={handlelike} managePlaylistData={managePlaylistData} setManagePlaylistData={setManagePlaylistData} is_synced={item?.data?.is_synced} is_global={item?.data?.is_global} refreshPlaylist={refreshPlaylist}/>
                </div>
            )
        })}
    </div>
    )
}

export default ManagePlaylist
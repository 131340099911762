import { useEffect, useState } from "react";
import { Link } from "react-router-dom"
import { useApi } from "../../../../api/api";
import CommonListing from "../../../../view/components/collectionPagesComponents/CommonListing";
import BackToPage from "../../../../view/components/common/BacktoPage/BackToPage";
import H220 from "../../../../view/components/common/Typograpgy/H220";


function AlbumsPage(){
    const { handleGetRequest,handlePostRequest } = useApi();
    const [albumsData, setAlbumsData] = useState();
    useEffect(()=>{
        ;(async () => {
            const albumsRes = await handleGetRequest(`album`,false,true)
            setAlbumsData(albumsRes?.data)
        })();
    },[])

    const handleDeletePlaylist = async(id) => {
        const playlistLikePayload = {
            playlist_ids:[id]
        }
        const managePlaylistDeleteRes = await handlePostRequest(`${`playlists-delete`}`,playlistLikePayload,true, true)
        const albumsRes = await handleGetRequest(`album`,false,true)
        setAlbumsData(albumsRes?.data)
    }
      
    return(
        <div>
            {/* <Link  to={`/collection`} className="text-[#D8D8D8] bg-[#FAFAF8] w-full decoration-[#D8D8D8] gap-x-2 text-sm font-light flex  items-center sticky  z-[41] py-1 top-0  px-3">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 9 9" fill="none">
                <path d="M2.10643 3.95141H8.80432V5.05195H2.10643L5.05808 8.0036L4.28 8.78168L0 4.50168L4.28 0.22168L5.05808 0.999762L2.10643 3.95141Z" fill="#D8D8D8"/>
                </svg>
                <p className=""> Back To Collection</p>
            </Link> */}
           {/* <BackToPage pageRoute="/" backTo="Home"/> */}
           {albumsData?.length<1?
            <div className="!px-2 lg:px-0">
            <H220 className="">Albums</H220>
            <div className="py-16 bg-white dark:bg-[#151515] drop-shadow-lg w-full mx-auto flex justify-center items-center rounded-xl">
            <p className="lg:text-xl text-lg font-semibold text-center lg:text-left dark:text-white">
                    <Link to="/library" className="text-[#F1B942]">Upload</Link> or  <Link to="/library" className="text-[#F1B942]">Sync</Link> your music service to listen to your favorite Albums
                    </p>
            </div>
            </div>
            :
            <CommonListing listing={albumsData} flag="collectionPage" title="Albums" handleDeletePlaylist={handleDeletePlaylist}/>
            }
          
        </div>
    )
}

export default AlbumsPage

// function AlbumsPage(){
//     return(
//         <div>albums listing</div>
//     )
// }

// export default AlbumsPage
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { Pagination } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import { useCallback, useState } from 'react';
import CommonListing from '../../collectionPagesComponents/CommonListing';

const DecadesComponent = ({decades,setSelectedDecade,selectedDecade,nav,handleDeletePlaylist}) => {
 

    const onDecadeChangeHandler = useCallback((e, decade) => {
        // Prevent the default behavior of the click event
        e.preventDefault();
    
        setSelectedDecade((prev) => {
          // Check if the clicked decade is already in the array
          const isDecadeSelected = prev?.includes(decade.id);
    
          if (isDecadeSelected) {
            // If selected, remove it from the array
            return prev.filter((item) => item !== decade.id);
          } else {
            // If not selected, add it to the array
            return [...prev, decade.id];
          }
        });
    
      }, [setSelectedDecade]);
    return(
      <div>
      {nav===1?<div className="relative my-4">
        <div className="h-[2px] bg-black dark:bg-white dark:z-0 z-[-2] w-full absolute  top-[24%] left-1/2 transform -translate-x-1/2 -translate-y-1/2"></div>
            <Swiper  
           
              slidesPerView={12}
              spaceBetween={30}
              loop={true}
            //   pagination={{
            //     clickable: true,
            //   }}
              breakpoints={{
                250: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 6,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 12,
                  spaceBetween: 50,
                },
              }}
           
              modules={ [Navigation, Pagination]}
              className="mySwiper" 
              navigation={{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              }}
              >


              {decades?.map((decade,index)=>{
                return(
                  <SwiperSlide key={decade.id}  className=" cursor-pointer ">
                    <div className=" mb-4 flex justify-center w-full">
                    <div className={`w-[35px] h-[35px] bg-[#F1B942] rounded-full  z-50`}>
                    </div>
                  
                    </div>
                    
                    <div  className={`text-center  ${selectedDecade?.includes(decade.id)?"text-[#F1B942] ":"dark:!text-white !text-black"}`}>{decade.v_year}`s</div>
                        </SwiperSlide>
                        )
                    })}
                    </Swiper>
                    <div className="swiper-button-next absolute !right-[0px] top-[60%] lg:top-1/2 transform -translate-y-1/2 cursor-pointer text-white text-sm lg:text-[10px] ">
                        {/* Add your next arrow icon or text here */}
                    </div>
                    <div className="swiper-button-prev absolute !left-[0px] top-[60%] lg:top-1/2 transform -translate-y-1/2 cursor-pointer text-white text-sm lg:text-[10px] ">
                        {/* Add your previous arrow icon or text here */}
                    </div>
            </div>:<div>
            <CommonListing listing={decades} title="Decades" flag="profilepagelisting"  hidetitle={true} handleDeletePlaylist={handleDeletePlaylist} fromprofile="/collection/decades" />
              
              </div>}
      </div>
    )
}

export default DecadesComponent
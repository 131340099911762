import { createSlice } from "@reduxjs/toolkit";

const stepper = JSON.parse(localStorage.getItem("stepper")) 

const initialState = {
    isStepper:stepper
};

// Slice
export const stepperSlice = createSlice({
  name: "stepper",
  initialState,
  reducers: {
    handleOpenStepper(state,action){
        state.isStepper = action.payload;
        localStorage.setItem('stepper',  JSON.stringify(action.payload))
      },
  },
});

export const {handleOpenStepper} = stepperSlice.actions;

export default stepperSlice.reducer;
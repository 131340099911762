import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    songsId: []
};

// Slice
export const songsIdSlice = createSlice({
  name: "songId",
  initialState,
  reducers: {
    handleSongId(state,action){
        state.songsId = action.payload;
      },
  },
});

export const {handleSongId} = songsIdSlice.actions;

export default songsIdSlice.reducer;
import { createSlice } from "@reduxjs/toolkit";


// const trackDataLocal = JSON.parse(localStorage.getItem("TrackData")) || {};

const initialState = {
  playerOnOff:false,
  currentSongIndex:0,
  recommendedSongs:[],
  isRecommended:false
};

const playerSlice = createSlice({
  name: "player",
  initialState,
  reducers: {
    handlePlayerStatus: (state, action) => {
      state.playerOnOff = action.payload;
    },

    handleCurrentSongIndex: (state, action) => {
        state.currentSongIndex = action.payload;
      },

    handleRecommendedSongs: (state, action) => {
    state.recommendedSongs = action.payload;
    },

    handleIsRecommended: (state, action) => {
        state.isRecommended = action.payload;
        },
  },
});

export const {handlePlayerStatus,handleCurrentSongIndex,handleRecommendedSongs,handleIsRecommended} = playerSlice.actions;

export default playerSlice.reducer;

import React, { useEffect, useRef, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { useApi } from '../../../api/api';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { handleFollowerRequestList, handleFollowerUserList } from '../../../store/followerSlice';
import { handleFollowingRequestList, handleFollowingUserList } from '../../../store/followingSlice';


const SearchComponent = () => {
  const { handleGetRequest,handlePostRequest } = useApi();
  const inputRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState("")
  const [expanded, setExpanded] = useState(searchTerm?true:false);
  const [nav,setNav] = useState(2)  
  const [isFocused, setIsFocused] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch()

   const determineCondition = () => {
      if (location.pathname === '/community/following') return 'following';
      if (location.pathname === '/community/following/curiousHit-following') return 'curiousHit-following';
      if (location.pathname === '/community/follower/curiousHit-follower') return 'curiousHit-follower';
      if (location.pathname === '/community/follower') return 'followers';
      if (location.pathname === '/community/follower/pending-requests') return 'pendingFollowers';
      if (location.pathname === '/community/following/sent-request') return 'pendingFollowings';
      return '';
    };

    const getEndpoint = (condition) => {
      console.log("condition",condition);
      
    switch (condition) {
      case 'following':
        return 'followings';
      case 'curiousHit-following':
        return 'followings';
      case 'curiousHit-follower':
        return 'followers';
      case 'followers':
        return 'followers';
      case 'pendingFollowers':
        return 'pendingFollowers';
      case 'pendingFollowings':
        return 'pendingFollowings';
      default:
        return '';
    }
  };

    const handleSearch = async() => {
      console.log('Searching for:', searchTerm,location);
      const condition = determineCondition();
      console.log("condition",condition);
      const endpoint = getEndpoint(condition);
      console.log("endpoint",endpoint);
      const payload={
        search_keyword:searchTerm,
    
        }
      const searchRes = await handlePostRequest(endpoint, payload, false, false);
      console.log("searchRes",searchRes);
      if(searchRes?.flag){
        if(location.pathname === '/community/following' || location.pathname ===  '/community/following/curiousHit-following'){
          dispatch(handleFollowingUserList(searchRes?.data))
        }

        if(location.pathname === '/community/follower' || location.pathname ===  '/community/follower/curiousHit-follower'){
          dispatch(handleFollowerUserList(searchRes?.data))
        }

        if(location.pathname === '/community/following/sent-request'){
          dispatch(handleFollowingRequestList(searchRes?.data))
        }

        if(location.pathname === '/community/follower/pending-requests'){
          dispatch(handleFollowerRequestList(searchRes?.data))
        }
      }
    };

      const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
          handleSearch();
          setSearchTerm(localStorage.getItem('searchValue', searchTerm))
          setSearchTerm("")
      }
    };

  const handleSearchInputChange = (event) => {
    setSearchTerm(event.target.value);
}; 

const isFirstRender = useRef(true);


useEffect(()=>{
  if (isFirstRender.current) {
       isFirstRender.current = false;
       return;
     }
 
     handleSearch();
},[searchTerm])

const handleSearchIconClick = () => {
  if(searchTerm){
      return
  }
  setExpanded(pre=>!pre);
  setIsFocused(pre=>!pre);
  inputRef.current.focus();
}; 
  
  return (
    <div className='relative flex justify-end items-center mt-7'>
    <input
      ref={inputRef}
      type="text"
      placeholder={`${expanded?"Search":""}`}
      className={`bg-white ${expanded ? 'w-full' : 'w-[40px]'} rounded-[50px] h-[40px] pl-4 lg:px-5 text-[#A1A1A1] text-[12px]   duration-300  dark:bg-[#151515] dark:!text-white drop-shadow-lg`}
      defaultValue={""}
      value={searchTerm}
      disabled={!expanded}
      onChange={handleSearchInputChange}
      onBlur={() => setIsFocused(false)}
      onKeyPress={handleKeyPress}
    />

    {showSuggestions  && searchTerm ? <span
    onClick={() => setShowSuggestions(false)}
    className={`absolute top-1/2 transform right-[10px] duration-300 dark:!text-[#A1A1A1] text-[#A1A1A1] fill-[#A1A1A1] -translate-y-1/2 cursor-pointer `}
        >
    <CloseIcon />
    </span> 
    : 
    <span
    onClick={() => handleSearchIconClick()}
    className={`absolute top-1/2 transform right-[10px] duration-300 dark:!text-[#A1A1A1] text-[#A1A1A1] fill-[#A1A1A1] -translate-y-1/2 cursor-pointer `}
        >
    <SearchIcon />
    </span>
    }
    </div>
  )
}

export default SearchComponent

import Calendar from 'react-calendar';
import { useEffect, useRef, useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import TimelineCalender from '../../../view/components/CalenderComponents/TimelineCalender';
import CalenderHeader from '../../../view/components/CalenderComponents/CalenderHeader';
import H220 from '../../../view/components/common/Typograpgy/H220';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SyncCalendar from '../../../view/components/CalenderComponents/SyncCalendar';
import CalendarPrefrences from '../../../view/components/CalenderComponents/CalendarPrefrences';
import { useApi } from '../../../api/api';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';
import { TryRounded } from '@mui/icons-material';
import BackToPage from '../../../view/components/common/BacktoPage/BackToPage';
import DeleteIcon from '@mui/icons-material/Delete';
import { Dialog } from 'primereact/dialog'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import globalToast from '../../../hooks/globalToast';



const Schedule = () => {
  const ProfileData = useSelector((state) => state.auth.profileData);
  const [selectCalendar,setSelectCalendar] = useState("")
  const { handleGetRequest, handlePostRequest } = useApi();
  const [popupWindow,setPopupWindow] = useState()
  const [value, onChange] = useState(new Date());
  const [step, setStep] = useState(1);
  const [calendarEvents,setCalendarEvents] = useState()
  console.log("ProfileData?.user?.i_calender",ProfileData?.user?.i_calender_auto_sync);
  
  const [isGoogleEvent,setIsGoogleEvent] = useState(ProfileData?.user?.i_calender_auto_sync===0?false:true)
  const [activityType,setActivityType] = useState("all")
  const isDark = useSelector((state) => state.dark.isDarkValue);
  const [showEventModal, setShowEventModal] = useState()
  const [isDeleteModal, setIsDeleteModal] = useState(false)
  const [isCreateModal, setIsCreateModal] = useState("")




  useEffect(() => {
    // const selectedCalendarCallback = JSON.parse(localStorage.getItem("calendar"))
    (async () => {
      const calendarStatus = await handleGetRequest(`calendar/status`, false, true);
      console.log("calendarStatus",calendarStatus);
      setStep(calendarStatus?.data ? "" : 1);
      if(calendarStatus?.data){
        // const syncRes = await handleGetRequest(`google-calendar/sync`, false,true);
        // console.log("syncRes",syncRes);
       
      const getEvents = await handleGetRequest(`google-calendar/getEvents`, false,true);
      console.log("succesyncalender",getEvents);
      setCalendarEvents(getEvents)
      }
    })();
  }, []);

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    
    (async () => {
      const payload = {
        t_music_activity_type: activityType.toLowerCase()
      };
      const getEvents = await handlePostRequest(`google-calendar/getEvents`, payload, false, true);
      console.log("success in calendar", getEvents);
      setCalendarEvents(getEvents);
    })();
  }, [activityType]);
  

  const connectCalendar = async(platform) => {

    if(selectCalendar===""){
      //  toast.error("Please Select Platform");
       globalToast("Please Select Platform",true)
      return
    }
    console.log("connectPlatform");

    // const options = "width=500,height=500 "
    const width = 500;
    const height = 500;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;
  
    const options = `width=${width},height=${height},left=${left},top=${top}`;
    const connectPlatformRes = await handleGetRequest(`${selectCalendar}/login`, false,true);
    // const connectPlatformRes = await handleGetRequest(`dailymotion/login`, false);
    const redirectUrlRes = connectPlatformRes?.data?.url;
    console.log("redirectUrlRes", redirectUrlRes);
    setPopupWindow(window.open(redirectUrlRes,"_blank",options))
    };

    useEffect(() => {

      const handlePostMess = async (event) => {
        console.log("handlePostMessage");
        console.log("handlePostMessageEvent", event);
        console.log("handlePostMessageEventData", event.data);
        console.log("handlePostMessageEventData", event.origin);

      const selectedCalendarCallback = JSON.parse(localStorage.getItem("calendar"))
      console.log("selectedCalendarCallback",selectedCalendarCallback);

        const {state} = event.data 
        const {code} = event.data 

        const platformsPayload = {
          code:code,
          state:state
        }

        console.log("selectedCalendarPayload",platformsPayload);

    
        if (event.origin === "https://curioushit-frontend.nevinainfotech.in" && selectedCalendarCallback) {
          // Assuming handlePostRequest is defined elsewhere and takes appropriate arguments
          const callbackRes = await handlePostRequest(`${selectedCalendarCallback}/callback`,  platformsPayload, false, true);
          console.log("callbackRes", callbackRes?.flag);
          if(callbackRes?.flag){
          const syncRes = await handleGetRequest(`${selectedCalendarCallback}/sync?state=${state}`, true, true);
          console.log("syncRes",syncRes);
          if(syncRes?.flag){
          const payload = {
            "i_calender_auto_sync":true
          }
          const getEvents = await handlePostRequest(`google-calendar/getEvents`,payload, false,true);
          console.log("succesyncalender",getEvents);
          setCalendarEvents(getEvents)
          setStep();
          }
          }
        }
      };
    
        window.addEventListener("message", handlePostMess, false);
    
      return () => {
        window.removeEventListener("message", handlePostMess);
      };
    }, []); 

    const deleteEvent = async(id) => {
      setIsDeleteModal(false)
      const payload = {
      }
        const deleteEvent = await handlePostRequest(`google-calendar/deleteEvent/${id}`,payload, true, true);
        console.log("deleteEvent",deleteEvent);
        if(deleteEvent?.flag){
          const getEvents = await handleGetRequest(`google-calendar/getEvents`, false,false);
          console.log("succesyncalender",getEvents);
          setCalendarEvents(getEvents)
          setShowEventModal()
        }else{
          // toast.error(deleteEvent?.msg);
          globalToast(deleteEvent?.msg,true)

        }
    };



  return (
    <div>
      {/* <BackToPage pageRoute="/" backTo="Home"/> */}
       {/* {step===1 && <CalendarPrefrences setStep={setStep}/>} */}
       {step===1 && <SyncCalendar setStep={setStep} setSelectCalendar={setSelectCalendar} selectCalendar={selectCalendar} connectCalendar={connectCalendar}/>}

       <Dialog  visible={isCreateModal} className={`p-10 lg:h-auto w-[90vw] lg:w-[45vw] h-auto text-center !bg-white !opacity-1 ${isDark?"dark":""} dark:!bg-[#151515] drop-shadow-xl rounded-xl `} onHide={() => setIsCreateModal(false)}>
                <HighlightOffIcon onClick={() => setIsCreateModal(false)} className='cursor-pointer absolute top-3 right-3 dark:!text-white'/>
                <div className="flex flex-col justify-center h-full  ">
                <p className="lg:text-xl text-base font-semibold">Do you want to Delete?</p>
                <div className="flex justify-between items-center lg:px-32 mt-10">
                    <button  onClick={()=>setIsCreateModal(false)}  className="bg-white text-[#1F2121] py-2 rounded-3xl lg:w-32 border-[1px] w-24 border-[#1F2121]">No</button>
                    <button onClick={()=>{setIsCreateModal("");deleteEvent(isCreateModal)}} className="bg-[#1F2121] text-white py-2 rounded-3xl lg:w-32 w-24">Yes</button>
                </div>
                </div>
                </Dialog>
 
      {!step &&<div className='flex justify-start items-center overflow-x-auto gap-5 my-5 px-3 lg:px-0'>
        <button onClick={()=>setActivityType("all")} className={`${activityType==="all"?"bg-[#F1B942]  !text-white":"bg-white !text-[#F1B942] dark:bg-[#151515] dark:!text-white"} w-20 py-1 rounded-2xl  `}>All</button>
        <button onClick={()=>setActivityType("listen")} className={`${activityType==="listen"?"bg-[#F1B942] !text-white":"bg-white !text-[#F1B942] dark:bg-[#151515] dark:!text-white"} bg-[#F1B942] w-20 py-1 rounded-2xl  `}>Listen</button>
        <button onClick={()=>setActivityType("learn")} className={`${activityType==="learn"?"bg-[#F1B942] !text-white":"bg-white !text-[#F1B942] dark:bg-[#151515] dark:!text-white"} bg-[#F1B942] w-20 py-1 rounded-2xl  `}>Learn</button>
        <button onClick={()=>setActivityType("practice")} className={`${activityType==="practice"?"bg-[#F1B942] !text-white":"bg-white !text-[#F1B942] dark:bg-[#151515] dark:!text-white"} bg-[#F1B942] w-20 py-1 rounded-2xl  `}>Practice</button>
        <button onClick={()=>setActivityType("play")} className={` ${activityType==="play"?"bg-[#F1B942] !text-white":"bg-white !text-[#F1B942] dark:bg-[#151515] dark:!text-white"} bg-[#F1B942] w-20 py-1 rounded-2xl  `}>Play</button>
      </div>}

        {!step && <div className='bg-white dark:bg-[#151515] drop-shadow-lg rounded-xl'>
        <CalenderHeader isGoogleEvent={ProfileData?.user?.i_calender_auto_sync===0?false:true} selectedCalendarCallback={JSON.parse(localStorage.getItem("calendar"))} setCalendarEvents={setCalendarEvents} />
        <div className='lg:grid lg:grid-cols-12 p-2'>
          <div className='col-span-9 relative dark:!text-white'>
            <TimelineCalender setShowEventModal={setShowEventModal} showEventModal={showEventModal} setIsDeleteModal={setIsDeleteModal} isDeleteModal={isDeleteModal} activityType={activityType} dateChange={value} calendarEvents={calendarEvents} setCalendarEvents={setCalendarEvents}/>
          </div>
          <div className='lg:col-span-3 col-span-12 pl-2 my-5 lg:my-0'>
            <div className={`lg:block hidden ${isDark?"dark":""}`}>
              <Calendar onChange={onChange} value={value} className=" dark:!bg-black dark:!text-white "/>
            </div>
              <div>
                <div className='flex justify-between  items-center'>
                <H220 className="!text-xl !pl-0 !pr-0">Schedule</H220>
                </div>
                <div className='flex  flex-wrap justify-start items-center gap-4'>
                  {calendarEvents?.data?.music_activities_types?.map((type,index)=>{
                    return(
                      <p className='px-3 py-1 rounded-3xl bg-[#E9E9E9] text-xs lg:text-xs'>{type}</p>
                    )
                  })}
                </div>
              </div>
              <div>
                <div className='flex justify-between  items-center'>
                <H220  className=" !text-xl mt-10 lg:mt-7 !pl-0 !pr-0">Music</H220>
                <div className='flex justify-between items-center gap-2'>
                  <SettingsOutlinedIcon className='!text-[#A1A1A1] !text-base'/>
                </div>
                </div>
                <div className='dark:text-white h-52 overflow-y-auto'>
                  {calendarEvents?.data?.events?.map((playlist,index)=>{
                    return(
                      <div key={index} className='flex justify-between items-center dark:bg-[#161616] my-2 py-2 '>
                        {/* <p className='dark:text-[#3B3E45]'>{playlist?.t_title?playlist?.t_title:"Events"}</p> */}
                        <p className='dark:text-[#A1A1A1]'>
                          {playlist?.t_title? 
                            `${playlist.t_title.charAt(0).toUpperCase()}${playlist.t_title.slice(1)}` : 
                            "Events"
                          }
                        </p>
                        {/* <DeleteIcon onClick={()=>deleteEvent(playlist?.id)} className='dark:!text-[#A8A8A8] cursor-pointer'/> */}
                        <DeleteIcon onClick={()=>setIsCreateModal(playlist?.id)} className='dark:!text-[#A8A8A8] cursor-pointer'/>
                      </div>
                    )
                  })}
                </div>
              </div>
          </div>
        </div>
      </div>}
    </div>
  )
}

export default Schedule

import { useEffect, useRef, useState } from "react";
import PlatformSwiper from "../../../../view/components/collectionPagesComponents/PlatformSwiper";
import { useApi } from "../../../../api/api";
import CommonListing from "../../../../view/components/collectionPagesComponents/CommonListing";
import H220 from "../../../../view/components/common/Typograpgy/H220";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { Link, useLocation } from "react-router-dom";
import LibraryMusicOutlinedIcon from '@mui/icons-material/LibraryMusicOutlined';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import BackToPage from "../../../../view/components/common/BacktoPage/BackToPage";
import { useDebouncedValue } from '@mantine/hooks';



const PlaylistPage = ()=>{
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [searchExtend,setSearchExtend] = useState(false)

    const platform = searchParams.get('platform');
    const { handleGetRequest,handlePostRequest } = useApi();
    const [selectedplatform, setSelectedPlatform] = useState(platform?platform==="youtube-music"?"Youtube Music":platform==="spotify"?"Spotify":platform==="vimeo"?"Vimeo":platform==="dailymotion"?"DailyMotion":"Youtube Video":"all");
    const [platforms, setPlatforms] = useState([]);
    const [playlistData, setPlaylistData] = useState();
    const [type,setType] = useState("all")
    const [searchQuery, setSearchQuery] = useState("")
    const [debounced] = useDebouncedValue(searchQuery, 300);

    const [sort, setSort] = useState(false);
    const [sortFilter, setSortFilter] = useState("new");
    const dropdownRef = useRef(null);
    const imageRef = useRef(null);
    const [page,setpage] = useState(1)


    



    // useEffect(() => {
    //     const handleClickOutside = (event) => {
    //         if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
    //             setSort(false);
    //         }
    //     };

    //     document.addEventListener("mousedown", handleClickOutside);

    //     return () => {
    //         document.removeEventListener("mousedown", handleClickOutside);
    //     };
    // }, []);
 useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                (dropdownRef.current && !dropdownRef.current.contains(event.target)) &&
                (imageRef.current && !imageRef.current.contains(event.target))
            ) {
                setSort(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);


    useEffect(() => {
        ;(async () => {
            const platformDataReq = await handleGetRequest("playlist", false,true);
            setPlatforms(platformDataReq?.data)
        })();
    }, []);

    useEffect(()=>{
        ;(async () => {
            const playlistRes = await handleGetRequest(`${`playlist-data?e_platform_name=${selectedplatform}&type=${type}&search_keyword=${debounced}&sort_type=${sortFilter}`}`,false,true)
            setPlaylistData(playlistRes?.data)
        })();
    },[selectedplatform,type,debounced,sortFilter,platform])


    const handleDeletePlaylist = async(id) => {
        const playlistLikePayload = {
            playlist_ids:[id]
        }
        const managePlaylistDeleteRes = await handlePostRequest(`${`playlists-delete`}`,playlistLikePayload,true, true)
        const playlistRes = await handleGetRequest(`${`playlist-data?e_platform_name=${selectedplatform}&type=${type}&search_keyword=${debounced}&sort_type=${sortFilter}`}`,false,true)
            setPlaylistData(playlistRes?.data)
    }

    console.log("playlistData",playlistData,platforms);
 const [isScreenWidthLessThan768, setIsScreenWidthLessThan768] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsScreenWidthLessThan768(window.innerWidth < 768);
        };

        // Initial check
        handleResize();

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Cleanup event listener
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return(
        <div className="">
           {/* <BackToPage pageRoute="/" backTo="Home"/> */}
        
        {playlistData?.length<1 && type==="all" && searchQuery===""? 
        <div>
             <H220 className="!px-2 lg:px-0">Playlists</H220>
             <div className="my-4 px-2 lg:px-0">
                
             <div>
                    {(platforms?.Platforms?.length>6 || isScreenWidthLessThan768) ?<div>
                        <PlatformSwiper
                            setSelectedPlatform={setSelectedPlatform}
                            selectedplatform={selectedplatform}
                            platforms={platforms}
                            setpage={setpage}
                        />
                    </div>
                    :
                    <div className="grid grid-cols-6 gap-x-5">
                        {platforms?.Platforms?.map((items,index)=>{
                            return(
                                <div key={index} onClick={() => {
                                    // setSelectedPlatform(platformList);
                                    setSelectedPlatform(items.v_name);
                                    // setPlaylistData([]);
                                  }}
                                  className={`h-[146px] mx-auto w-[130px] ${ (items?.v_name === selectedplatform)?"border-black dark:!border-white dark:!bg-black bg-white":""} border-[2px] p-1 rounded-lg cursor-pointer`}
                                  style={{
                                    backgroundColor: items?.v_color_code,
                                  }}>

                                    <div 
                                            style={{
                                            backgroundColor: items?.v_color_code,
                                            }}
                                            className='flex justify-center items-center h-full rounded-lg'
                                            >
                                            <div>
                                            <img src={items?.t_image} alt='' className='m-auto'/>
                                                <p className='text-white text-[14px] font-semibold text-center my-2'> {items?.id === "default"
                                                ? "Uploads"
                                                : items?.v_name}</p>
                                            </div>
                                            </div>
                                  </div>
                            )
                        })}
                    </div>}
                </div>
            </div>
            <div className="py-16 bg-white dark:bg-[#151515] drop-shadow-lg w-full mx-auto flex justify-center items-center rounded-xl">
                <p className="lg:text-xl text-lg font-semibold text-center lg:text-left dark:text-white">
                No playlist created Yet! Create Your own playlist by <Link to="/library" className="text-[#F1B942]">Upload</Link> or <Link to="/library" className="text-[#F1B942]">Sync</Link>  your Music service
                </p>
            </div>
        </div>
        :
        <div>
            <div className="my-4 px-2 lg:px-0">
                <H220 className="!px-1">Playlists</H220>
                <div>
                    {(platforms?.Platforms?.length>6 || isScreenWidthLessThan768) ?<div>
                        <PlatformSwiper
                            setSelectedPlatform={setSelectedPlatform}
                            selectedplatform={selectedplatform}
                            platforms={platforms}
                            setpage={setpage}
                        />
                    </div>
                    :
                    <div className="grid grid-cols-6 gap-x-5">
                        {platforms?.Platforms?.map((items,index)=>{
                            return(
                                <div key={index} onClick={() => {
                                    // setSelectedPlatform(platformList);
                                    setSelectedPlatform(items.v_name);
                                    // setPlaylistData([]);
                                  }}
                                  className={`h-[146px] mx-auto w-[130px] ${ (items?.v_name === selectedplatform)?"border-black dark:!border-white dark:!bg-black bg-white":""} border-[2px] p-1 rounded-lg cursor-pointer`}
                                  style={{
                                    backgroundColor: items?.v_color_code,
                                  }}>

                                    <div 
                                            style={{
                                            backgroundColor: items?.v_color_code,
                                            }}
                                            className='flex justify-center items-center h-full rounded-lg'
                                            >
                                            <div>
                                            <img src={items?.t_image} alt='' className='m-auto'/>
                                                <p className='text-white text-[14px] font-semibold text-center my-2'> {items?.id === "default"
                                                ? "Uploads"
                                                : items?.v_name}</p>
                                            </div>
                                            </div>
                                  </div>
                            )
                        })}
                    </div>}
                </div>
            </div>
            {/* <H220 className="!capitalize !px-2">{selectedplatform}</H220> */}
            <div className="lg:flex justify-between gap-x-4 items-center my-2 mb-7 mt-20 px-2">
                <div className="flex justify-start flex-wrap lg:flex-nowrap gap-x-4 items-center">
                <button className={`w-20 py-1 hover:bg-[#F1B942] text-xs rounded-[30px] ${type==="all"?"bg-[#F1B942] text-white":"bg-white text-[#312522] dark:bg-[#151515]  dark:text-white"}`} onClick={()=>setType("all")}>All</button>
                <button className={`w-20 py-1 hover:bg-[#F1B942] text-xs rounded-[30px] ${type==="liked"?"bg-[#F1B942] text-white":"bg-white text-[#312522] dark:bg-[#151515]  dark:text-white"}`} onClick={()=>setType("liked")}>Liked</button>
                <button className={`w-20 py-1 hover:bg-[#F1B942] text-xs rounded-[30px] ${type==="shared"?"bg-[#F1B942] text-white":"bg-white text-[#312522] dark:bg-[#151515]  dark:text-white"}`} onClick={()=>setType("shared")}>Shared</button>
                <div className="flex justify-between w-full lg:w-auto lg:px-2 lg:justify-start items-center gap-x-3 relative my-3 lg:my-0">
                    {/* Search input field */}
                    {/* <div className={`relative  ${searchExtend?" lg:w-56":"w-0"} duration-500  `}>
                    <input
                        type="text"
                        placeholder="Search..."
                        // className="bg-white dark:bg-[#151515] rounded-[50px] w-full lg:w-auto px-2 pr-10 py-1 text-xs focus:outline-none focus:border-[#F1B942] dark:!text-white  border-[1px] transition duration-300"
                        className={`bg-white ${searchExtend?"":"px-7"} rounded-[50px] py-2 pl-2 h-full w-full dark:bg-[#151515] dark:border-[#151515]  dark:text-white  focus:outline-none focus:border-[#F1B942]  border-[1px] text-xs transition duration-300 `}
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                   
                    <SearchOutlinedIcon onClick={()=>setSearchExtend(true)} className={`absolute dark:!text-[#DDD1CE] top-1/2 transform -translate-y-1/2 ${searchExtend?"right-3":"-right-8"}  cursor-pointer`}/>
                    </div> */}

                <div className={`relative w-32 ${searchExtend?"lg:w-48 ":" lg:w-0"} duration-700 `}>
                    <input
                        type="text"
                        placeholder="Search..."
                        // className={`bg-white ${searchExtend?"":"lg:pr-7 lg:pl-7"}  rounded-[50px] pl-2 pr-3 py-2 focus:outline-none focus:border-[#F1B942] border-[1px] dark:bg-[#151515] dark:border-[#151515]  dark:text-white `}
                        className={`bg-white ${searchExtend?"":"lg:px-7 lg:pl-3"} rounded-[50px] py-2 pl-2 h-full w-full dark:bg-[#151515] dark:border-[#151515]   dark:text-white  focus:outline-none focus:border-[#F1B942]  border-[1px] text-xs transition duration-300 `}
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    {/* <SearchOutlinedIcon onClick={()=>setSearchExtend(pre=>!pre)} className={`absolute top-1/2 transform -translate-y-1/2 right-3 dark:!text-[#DDD1CE] ${searchExtend?"right-3":"-right-8"}`}/> */}
                    <SearchOutlinedIcon onClick={()=>setSearchExtend(pre=>!pre)} className={`absolute dark:!text-[#DDD1CE] top-1/2 transform -translate-y-1/2 right-[1px] ${searchExtend?"lg:right-3":"lg:-right-8"}  cursor-pointer bg-white rounded-3xl`}/>
                    </div>
                    <div className={`cursor-pointer ${searchExtend?"":"lg:ml-10"} `} ref={imageRef}>
                        <img className="dark:filter dark:invert"  onClick={()=>setSort(pre=>!pre)} src="/images/TrackTable/sortby.svg" alt=""  />
                    </div>
                    {sort && <div ref={dropdownRef}  className={`absolute top-[100%] left-[50%]  z-50 min-w-36  text-sm bg-white rounded-lg drop-shadow-lg p-3 dark:border-[1px] dark:border-[#343434] dark:bg-[#151515] dark:text-[#DDD1CE]`}>
                        <p className="my-2 cursor-pointer hover:text-[#F1B942]" onClick={()=>{setSortFilter("new"); setSort(false)}} >New</p>
                        <p className="my-2 cursor-pointer hover:text-[#F1B942]" onClick={()=>{setSortFilter("popular");setSort(false)}} >Popular</p>
                        <p className="my-2 cursor-pointer hover:text-[#F1B942]" onClick={()=>{setSortFilter("listened");setSort(false)}} >Listened</p>
                        <p className="my-2 cursor-pointer hover:text-[#F1B942]" onClick={()=>{setSortFilter("favorite");setSort(false)}} >Favorites</p>
                    </div>}
                    <div className="lg:hidden block ">
                    <Link to="/collection/manage-playlist"  className="hover:bg-[#312522] hover:text-white border-[1px] border-[#212121] py-2 px-3 rounded-[50px] text-xs !block hover:decoration-black my-1 dark:border-[#DDD1CE] dark:text-[#DDD1CE]"><LibraryMusicOutlinedIcon className="mx-2 !text-xs dark:!text-[#DDD1CE]"/><span>Manage Playlists</span></Link>
                    </div>
                </div>
                </div>

                <div className="lg:block hidden group dark:!text-[#DDD1CE] dark:hover:!text-black">
                    <Link to="/collection/manage-playlist"  className="hover:bg-[#312522] hover:text-white border-[1px] border-[#212121] py-2 px-3 rounded-[50px] hover:decoration-black my-1 dark:hover:bg-white  dark:border-[#DDD1CE] dark:text-[#DDD1CE] dark:hover:!text-black"><LibraryMusicOutlinedIcon className="mx-2 "/>Manage Playlists</Link>
                </div>
            </div>
            <CommonListing type={type} listing={playlistData} flag="collectionPage" handleDeletePlaylist={handleDeletePlaylist} title="playlists" />
        </div>
        
        }
         
           
        </div>

    )
}

export default PlaylistPage
import { createSlice } from "@reduxjs/toolkit";


// const trackDataLocal = JSON.parse(localStorage.getItem("TrackData")) || {};

const initialState = {
  trackData:null,
  recommendData:null,
  isPlayer:-1,
  trackTitle:"",
  isrecommended: false,
  isFilter: false
};

const trackSlice = createSlice({
  name: "tracks",
  initialState,
  reducers: {
    trackDetail: (state, action) => {
      state.trackData = action.payload;
      localStorage.setItem('TrackData', JSON.stringify(action.payload))
    },
    recommendDetail: (state, action) => {
      state.recommendData = action.payload;
      localStorage.setItem('RecommendData', JSON.stringify(action.payload))
    },

    recommendStatus: (state, action) => {
      state.isrecommended = action.payload;
    },

    filterStatus: (state, action) => {
      state.isFilter = action.payload;
    },


    playerOn: (state, action) => {
        state.isPlayer = action.payload;
      },

    playerOff: (state, action) => {
    state.isPlayer = false;
    },

    trackTitleSave: (state, action) => {
      state.trackTitle = action.payload;
    },
  },
});

export const {trackDetail,playerOn,playerOff,trackTitleSave,recommendDetail,recommendStatus,filterStatus} = trackSlice.actions;

export default trackSlice.reducer;

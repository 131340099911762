import { createSlice } from "@reduxjs/toolkit";

// const stepper = JSON.parse(localStorage.getItem("stepper")) 

const initialState = {
   followingUser:[],
   followingRequest:[]
};

// Slice
export const followingSlice = createSlice({
  name: "following",
  initialState,
  reducers: {
    handleFollowingUserList(state,action){
        state.followingUser = action.payload;
      },
    handleFollowingRequestList(state,action){
      state.followingRequest = action.payload;
    },
  },
});

export const {handleFollowingUserList,handleFollowingRequestList} = followingSlice.actions;

export default followingSlice.reducer;
import { useEffect, useState } from "react";
import { useApi } from "../../api/api";
import CommonAccountUI from "../../view/components/UserAccountComponents/CommonAccountUI"
import H220 from "../../view/components/common/Typograpgy/H220"
import { useSelector } from "react-redux";
import BackToPage from "../../view/components/common/BacktoPage/BackToPage";

const UserAccount =  () => {
    // const { handleGetRequest } = useApi();
    // const [profileData,setProfileData] = useState()
    const userData = useSelector((state) => state.auth.profileData);



    // useEffect(()=>{
    //     ;(async()=>{
    //         const profileDataReq = await handleGetRequest("profile-detail")
    //         setProfileData(profileDataReq.data)
    //         console.log("profileDataReq",profileDataReq);
    //     })()
    // },[])

    // console.log("profileData",profileData);




    return(
        <div className="" >
            {/* <BackToPage pageRoute="/" backTo="Home"/> */}
            <div className="px-3 lg:px-0">
             <H220 className="mb-7">My account</H220>
            </div>
             <div className="bg-white px-4 py-6 dark:bg-[#151515] rounded-2xl">
               <CommonAccountUI title="Personal information" name={userData?.v_name} dob={userData?.user?.d_dob}  phoneNumber={userData?.user?.v_mobile_number} emailId={userData?.user?.v_email_id} profilePic={userData?.user?.t_image}/>
               <CommonAccountUI title="Password"/>
             </div>
        </div>
    )
}

export default UserAccount






// import { useEffect, useState } from "react";
// import { useApi } from "../../../../api/api";
// import CommonListing from "../../../../view/components/collectionPagesComponents/CommonListing";
// import BackToPage from "../../../../view/components/common/BacktoPage/BackToPage";
// import H220 from "../../../../view/components/common/Typograpgy/H220";
// import { Link } from "react-router-dom";


// function GeosPage(){
//     const { handleGetRequest } = useApi();
//     const [geosData, setGeosData] = useState();
//     useEffect(()=>{
//         ;(async () => {
//             const geosRes = await handleGetRequest(`geos`,false,true)
//             setGeosData(geosRes?.data?.data)
//         })();
//     },[])
//     console.log("geosData",geosData);
      
//     return(
//         <div>
//         <BackToPage pageRoute="/" backTo="Home"/>
//           {geosData?.length<1?
//          <div>
//          <H220 className="">Geos</H220>
//         <div className="py-16 bg-white drop-shadow-lg w-full mx-auto flex justify-center items-center rounded-xl">
//         <p className="lg:text-xl text-lg font-semibold text-center lg:text-left">
//                     <Link to="/library" className="text-[#F1B942]">Upload</Link> or  <Link to="/library" className="text-[#F1B942]">Sync</Link> your music service to listen to your favorite geos
//                     </p>
//         </div>
//         </div>
//         :

//         <CommonListing listing={geosData} title="Geos" />
        
//         }
//         </div>
//     )
// }

// export default GeosPage


import React, { useEffect, useState } from "react";
import { useApi } from "../../../../api/api";
import CommonListing from "../../../../view/components/collectionPagesComponents/CommonListing";
import BackToPage from "../../../../view/components/common/BacktoPage/BackToPage";
import H220 from "../../../../view/components/common/Typograpgy/H220";
import { Link } from "react-router-dom";

// Assuming these components are memoized elsewhere
const MemoizedBackToPage = React.memo(BackToPage);
const MemoizedH220 = React.memo(H220);
const MemoizedCommonListing = React.memo(CommonListing);

function GeosPage() {
    const { handleGetRequest,handlePostRequest } = useApi();
    const [geosData, setGeosData] = useState();

    useEffect(() => {
        (async () => {
            const geosRes = await handleGetRequest(`geos`, false, true);
            setGeosData(geosRes?.data?.data);
        })();
    }, []);

    console.log("geosData", geosData);

    const handleDeletePlaylist = async(id) => {
        const playlistLikePayload = {
            playlist_ids:[id]
        }
        const managePlaylistDeleteRes = await handlePostRequest(`${`playlists-delete`}`,playlistLikePayload,true, true)
        const geosRes = await handleGetRequest(`geos`, false, true);
        setGeosData(geosRes?.data?.data);
    }

    return (
        <div>
            <MemoizedBackToPage pageRoute="/" backTo="Home" />
            {geosData?.length < 1? (
                <div className="!px-2 lg:px-0">
                    <MemoizedH220 className="dark:!text-white">Geos</MemoizedH220>
                    <div className="py-16 bg-white dark:bg-[#151515] drop-shadow-lg w-full mx-auto flex justify-center items-center rounded-xl">
                        <p className="lg:text-xl text-lg font-semibold text-center lg:text-left dark:text-white">
                            <Link to="/library" className="text-[#F1B942]">Upload</Link> or  <Link to="/library" className="text-[#F1B942]">Sync</Link> your music service to listen to your favorite geos
                        </p>
                    </div>
                </div>
            ) : (
                <MemoizedCommonListing listing={geosData} flag="collectionPage" title="Geos" handleDeletePlaylist={handleDeletePlaylist}/>
            )}
        </div>
    );
}

export default GeosPage;



import React, { useEffect } from 'react'
import H220 from '../../../view/components/common/Typograpgy/H220'
import Button2 from '../../../view/components/common/GlobalButtonLayout/Button2'
import CommunityLayout from '../../../view/components/CommunityComponents/CommunityLayout'
import { useApi } from '../../../api/api'
import { useDispatch, useSelector } from 'react-redux'
import { handleFollowerRequestList, handleFollowerUserList } from '../../../store/followerSlice'
import { Link } from 'react-router-dom'

const PendingRequest = () => {
  const followerRequestUser = useSelector((state) => state.follower.followerRequest);

  // /acceptReject
  const { handleGetRequest,handlePostRequest } = useApi();
  const dispatch = useDispatch()
  useEffect(()=>{
    ;(async () => {
        const followerUserRequestRes = await handleGetRequest(`pendingFollowers`,false,true)
        console.log("followingUserRes",followerUserRequestRes?.data);
        dispatch(handleFollowerRequestList(followerUserRequestRes?.data))
    })();
  },[])
  const acceptRejectUser = async(id,condition) => {
    const payload={
      follower_id:id,
      action:condition
    }
    if(id==="accepted"){
      const unFollowAcceptRes = await handlePostRequest(`acceptReject`,payload,true,true)
      if(unFollowAcceptRes?.flag){
        const followerUserRequestRes = await handleGetRequest(`pendingFollowers`,false,true)
        console.log("followingUserRes",followerUserRequestRes?.data);
        dispatch(handleFollowerRequestList(followerUserRequestRes?.data))
        const followerUserRes = await handleGetRequest(`followers`,false,true)
        console.log("followerUserRes",followerUserRes?.data);
        dispatch(handleFollowerUserList(followerUserRes?.data))
      }
    }else{
      const unFollowAcceptRes = await handlePostRequest(`acceptReject`,payload,true,true)
      if(unFollowAcceptRes?.flag){
        const followerUserRequestRes = await handleGetRequest(`pendingFollowers`,false,true)
        console.log("followingUserRes",followerUserRequestRes?.data);
        dispatch(handleFollowerRequestList(followerUserRequestRes?.data))
        const followerUserRes = await handleGetRequest(`followers`,false,true)
        console.log("followerUserRes",followerUserRes?.data);
        dispatch(handleFollowerUserList(followerUserRes?.data))
      }
    }
  }

  return (
    <div>
    <CommunityLayout>
    <H220 className="">Pending requests</H220>
    <div className='lg:grid lg:grid-cols-6   gap-x-5 my-5 text-center'>
             {followerRequestUser?.map((items, index)=>{
              return(
                  <div className={`grid grid-cols-4  gap-x-5 my-2  lg:block`}>
                      <Link to={`/other-user/${items?.id}`} className='flex justify-start items-center gap-x-4 lg:block col-span-2'>
                      <div className='my-1 min-w-14 max-w-14 h-14 lg:min-w-16 lg:max-w-16 lg:h-16 lg:mx-auto rounded-full '>
                          <img src={items?.t_image} alt='' className=' w-full h-full object-cover rounded-full' />
                      </div>
                      <div className='max-w-[70px] lg:max-w-auto lg:mx-auto'>
                      <p className='mt-1 font-semibold lg:text-xl text-sm dark:text-white mb-3 capitalize line-clamp-1'>{items?.v_name}</p>
                      </div>
                      </Link>
                     
                      <div className='my-2'>
                        <Button2 onClick={()=>acceptRejectUser(items?.id,"reject")} className="!text-center dark:text-[#F1B942] !py-0 !w-20 lg:!w-32">Cancel</Button2>
                      </div>
                      <div className='my-2'>
                        <Button2 onClick={()=>acceptRejectUser(items?.id,"accepted")} className="!text-center dark:text-[#F1B942] !py-0 !w-20  lg:!w-32">Accept</Button2>
                      </div>
                  </div>
              )
             })}
          </div>
    </CommunityLayout>
  </div>
  )
}

export default PendingRequest
import { useForm } from "react-hook-form";
import H220 from "../../view/components/common/Typograpgy/H220";
import P16 from "../../view/components/common/Typograpgy/P16";
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import Button1 from "../../view/components/common/GlobalButtonLayout/Button1";
import { useApi } from "../../api/api";
import { useEffect, useState } from "react";
import BackToPage from "../../view/components/common/BacktoPage/BackToPage";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function Contact(){
    const { register, handleSubmit, reset, setValue,  formState: { errors }, } = useForm();
    const { handleGetRequest, handlePostRequest } = useApi();
    const [contactData, setContactData] = useState()
    const userData = useSelector((state) => state.auth.profileData);

    const getUserDetails = async () => {
        // Replace this with actual logic to fetch user details
        return {
          name: userData?.v_name,
          email: userData?.user?.v_email_id,
          phoneNumber: userData?.user?.v_mobile_number,
        };
     };

     useEffect(() => {
        (async () => {
          const userDetails = await getUserDetails();
          setValue("firstName", userDetails.name);
          setValue("email", userDetails.email);
          setValue("phoneNumber", userDetails.phoneNumber);
    
          
        })();
     }, []);

    
    useEffect(() => {
        // Skip the first render
        (async () => {  
          const contactRes = await handleGetRequest(`users/contact-us`, false,true);
            if(contactRes?.flag){
              setContactData(contactRes?.data)
          }
        })();
      }, []);

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
          handleSubmit(onSubmit)();
        }
      };

    const onSubmit = async(data) => {

      const contactPayload = {
        v_name:data?.firstName,
        v_mobile_number:data?.phoneNumber,
        v_email:data?.email,
        t_reason:data?.message,
        i_complaint_type:1
      }
      const contactFormRes = await handlePostRequest(`users/contact-us-save`,contactPayload, true, true);
      if(contactFormRes?.flag){
    }
    setValue('message', '');
   
    };

    
   
    return(
        <div  className="px-2 lg:px-0 mb-10"> 
            {/* <BackToPage pageRoute="/" backTo="Home"/> */}
            {/* <H220 className="dark:!text-white">Contact Us</H220> */}
            <div className=" my-5 rounded-lg bg-[#F1B942] text-white lg:text-[46px] text-2xl font-bold py-10 w-full flex flex-col justify-center items-center">
                <p>We’d love to connect</p>
                <div className="mt-3 text-sm">
                    <p>Just drop us a  message </p>
                </div>
            </div>
            <div className="grid lg:grid-cols-12 gap-4 bg-white p-3 dark:bg-[#151515] drop-shadow-xl rounded-xl">
                <div className="lg:col-span-8 ">
                <div className="  mt-8">
                    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
                    <div className="lg:flex justify-between items-center gap-x-3 lg:gap-x-20 my-3 lg:my-0 ">
                        <label htmlFor="firstName" className="block text-gray-700 !m-0 w-[78%] lg:w-[90%] text-sm lg:text-lg font-semibold dark:text-[#DDD1CE]">Individual/company name</label>
                        <div className="w-full">
                        <input type="text" placeholder="Enter Full Name" className=" px-3 border-[1px] focus:!border-[#F1B942] form-input mt-1 block w-full rounded-[50px] py-2 bg-[#FAFAF8] dark:bg-[#131313] dark:text-[#DDD1CE]" {...register("firstName", { required: true, maxLength: 20 })}  />
                        {errors && errors['firstName'] && errors['firstName'].type === 'required' && (
                            <p className="text-red-500 text-xs lg:text-sm">Name is required</p>
                            )}
                        </div>
                    </div>
                    <div className="lg:flex justify-between items-center gap-x-3 lg:gap-x-20 my-3 lg:my-0">
                        <label htmlFor="phoneNumber" className="block text-gray-700 !m-0 w-[78%] lg:w-[90%] text-sm lg:text-lg font-semibold dark:text-[#DDD1CE]">Phone no:</label>
                        <div className="w-full">
                        <input type="number"  inputmode="numeric" placeholder="+1 222 222 2222" id="phoneNumber"  className="px-3 border-[1px] focus:!border-[#F1B942] form-input mt-1 block w-full rounded-[50px] py-2 bg-[#FAFAF8] dark:bg-[#131313] dark:text-[#DDD1CE]"  {...register("phoneNumber", {required: true,  pattern:{
                           
                            // value: /([0-9\s\-]{7,})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/,
                            value: /^[0-9]{10,15}$/,
                            message: "Invalid Number Format"
                        }})}/>
                         {errors && errors['phoneNumber'] && errors['phoneNumber'].type === 'required' && (
                            <p className="text-red-500 text-xs lg:text-sm">Phone Number is required</p>
                            )}

                        {errors && errors['phoneNumber'] && errors['phoneNumber'].type === 'pattern' && (
                            <p className="text-red-500 text-xs lg:text-sm">{"Phone Number must meet given criteria"}</p>
                        )}
                        </div>
                    </div>
                    <div className="lg:flex justify-between items-center gap-x-3 lg:gap-x-20 my-3 lg:my-0">
                        <label htmlFor="email" className="block text-gray-700 !m-0 w-[78%] lg:w-[90%] text-sm lg:text-lg font-semibold dark:text-[#DDD1CE]">Email:</label>
                        <div className="w-full">
                        <input type="email" readOnly placeholder="Enter email address" id="email" className="px-3 form-input mt-1 block w-full border-[1px] focus:!border-[#F1B942] rounded-[50px] py-2 bg-[#FAFAF8] dark:bg-[#131313] dark:text-[#DDD1CE]"  {...register("email", {required: true,  pattern:{
                            value:  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "Invalid Email Format"
                            }  }, )}/>
                        {errors && errors['email'] && errors['email'].type === 'required' && (
                        <p className="text-red-500 text-xs lg:text-sm">Email is required</p>
                        )}    
                        
                        {errors && errors['email'] && errors['email'].type === 'pattern' && (
                            <p className="text-red-500 text-xs lg:text-sm">{"Email must meet given criteria"}</p>
                        )}
                        </div>
                    </div>
                    <div>
                    <P16 className="!font-semibold  dark:!text-[#DDD1CE]">Address</P16>
                    <p className="dark:text-white">{contactData?.contact_info?.address}:</p>
                    <P16 className="!font-semibold !mt-3 dark:!text-[#DDD1CE]">Corporate identity number</P16>
                    <p className="dark:text-white">{contactData?.contact_info?.cin}:</p>
                    <P16 className="!font-semibold !mt-3 dark:!text-[#DDD1CE]">Write to us</P16>
                    <p className="dark:text-white">curioushit@gmail.com</p>
                    <p className="dark:text-white">If you have any feedback or concerns with respect to any content available on Airtel Digital Ltd., please contact us here.</p>
                    </div>

                    <div className="lg:flex justify-between gap-x-3 my-3 lg:my-0">
                     
                        <label htmlFor="message" className="block text-gray-700 !m-0 w-[78%] lg:w-[90%] text-sm lg:text-lg font-semibold dark:text-[#DDD1CE]">Add your message</label>
                        <textarea id="message" placeholder="How can we help?" className="px-3 border-[1px] focus:!border-[#F1B942] form-textarea mt-1 block w-full rounded-[20px] py-2 bg-[#FAFAF8]  dark:bg-[#131313] dark:text-[#DDD1CE]" rows="4"  {...register("message", {required: true  })}></textarea>
                        
                    </div>
                    <div>
                        {errors && errors['message'] && errors['message'].type === 'required' && (
                            <p className="text-red-500 text-xs lg:text-sm pl-[20%]">Message is required</p>
                            )}
                        </div>
                   
                    <div className="flex justify-end items-center">
                        <Button1 onClick={handleSubmit(onSubmit)} className="!text-center !mx-auto lg:!mx-0 !w-[30%] dark:hover:!text-[#F3C25A]"  type="submit">Submit</Button1>
                    </div>
                    </form>
                </div>
                </div>








                
                {/* <div className="bg-white drop-shadow-xl rounded-xl lg:col-span-5 p-3 dark:bg-[#151515] dark:text-[#A1A1A1]">
                    <H220 className="dark:!text-[#DDD1CE]">Contact Info</H220>
                   
                      <P16 className="!font-semibold !mt-3 dark:!text-[#DDD1CE]">Address:</P16>
                      <p>{contactData?.contact_info?.address}:</p>

                      <P16 className="!font-semibold !mt-3 dark:!text-[#DDD1CE]">CIN:</P16>
                      <p>{contactData?.contact_info?.cin}:</p>

                      <P16 className="!font-semibold !mt-3 dark:!text-[#DDD1CE]">Email:</P16>
                     <a href={`mailto:${contactData?.contact_info?.email}`}>
                        {contactData?.contact_info?.email}
                        </a>

                    <p className="mt-3">If you have any feedback or concerns with respect to any content available on Air tel Digital Ltd., please contact us here.</p>

                    <div className="bg-black py-3 px-2 flex justify-around items-center  mx-auto my-3">
                       
                       <Link 
                            href={contactData?.social_links?.facebook?.link} 
                            className="w-[30px] h-[30px] rounded-full flex justify-center items-center bg-white"
                            target="_blank"
                            rel="noopener noreferrer"
                            >
                            <img src={contactData?.social_links?.facebook?.icon} alt="" className=""/>
                        </Link>
                        <Link 
                            href={contactData?.social_links?.instagram?.link} 
                            className="w-[30px] h-[30px] rounded-full flex justify-center items-center bg-white"
                            target="_blank"
                            rel="noopener noreferrer"
                            >
                            <img src={contactData?.social_links?.instagram?.icon} alt="" className=""/>
                        </Link>
                        <Link 
                            href={contactData?.social_links?.linkedin?.link} 
                            className="w-[30px] h-[30px] rounded-full flex justify-center items-center bg-white"
                            target="_blank"
                            rel="noopener noreferrer"
                            >
                            <img src={contactData?.social_links?.linkedin?.icon} alt="" className=""/>
                        </Link>
                        <Link 
                            href={contactData?.social_links?.tiktok?.link} 
                            className="w-[30px] h-[30px] rounded-full flex justify-center items-center bg-white"
                            target="_blank"
                            rel="noopener noreferrer"
                            >
                            <img src={contactData?.social_links?.tiktok?.icon} alt="" className=""/>
                        </Link>
                        
                        <Link 
                            to={contactData?.social_links?.twitter?.link} 
                            className="w-[30px] h-[30px] rounded-full flex justify-center items-center bg-white"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <a href={contactData?.social_links?.twitter?.link} target="_blank" rel="noopener noreferrer">
                                <img src={contactData?.social_links?.twitter?.icon} alt="Twitter" className="" />
                            </a>
                        </Link>
                        <div>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default Contact
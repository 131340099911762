import { createSlice } from "@reduxjs/toolkit";


// const trackDataLocal = JSON.parse(localStorage.getItem("TrackData")) || {};

const initialState = {
  isCreatePlaylist:false,
  isGeneratePlaylist:false
};

const createplaylistSlice = createSlice({
  name: "createplaylist",
  initialState,
  reducers: {
    handleCreatePlaylistStatus: (state, action) => {
      state.isCreatePlaylist = action.payload;
    },
    handleGeneratePlaylistStatus: (state, action) => {
        state.isGeneratePlaylist = action.payload;
      },
  },
});

export const {handleCreatePlaylistStatus,handleGeneratePlaylistStatus} = createplaylistSlice.actions;

export default createplaylistSlice.reducer;

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import DisplayProfile from '../../view/components/ProfileComponents/DisplayProfile';
import { useApi } from '../../api/api';
import { profileDetail } from '../../store/authenticationSlice';

const Profile = () => {
    const profileData = useSelector((state) => state.auth.profileData);
    const [nav,setNav] = useState(1)
  const { handleGetRequest,handlePostRequest } = useApi();


    const dispatch = useDispatch();


  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const profileDataReq = await handleGetRequest("profile-detail", false, true);
        dispatch(profileDetail(profileDataReq?.data));
      } catch (error) {
        console.error('Error fetching profile data:', error);
      }
    };

    fetchProfileData();
  }, [dispatch]);

  return (
    <DisplayProfile nav={nav} setNav={setNav} profileData={profileData}/>
  )
}

export default Profile
import React, { useEffect, useRef, useState } from 'react'
import Following from '../../../view/components/CommunityComponents/FollowingComponents/Following';
import { useApi } from '../../../api/api';
import { useDispatch } from 'react-redux';
import { handleFollowingRequestList, handleFollowingUserList } from '../../../store/followingSlice';
import { handleFollowerUserList } from '../../../store/followerSlice';

const FollowingPage = () => {
  const { handleGetRequest,handlePostRequest } = useApi();
  const dispatch = useDispatch()
    useEffect(()=>{
        ;(async () => {
            const followingUserRes = await handleGetRequest(`followings`,false,true)
            console.log("followingUserRes",followingUserRes?.data);
            dispatch(handleFollowingUserList(followingUserRes?.data))
        })();
    },[])

    useEffect(()=>{
      ;(async () => {
          const followingUserRes = await handleGetRequest(`pendingFollowings`,false,true)
          console.log("followingUserRes",followingUserRes?.data);
          dispatch(handleFollowingRequestList(followingUserRes?.data))
      })();
  },[])

  

  const unFollowUser = async(id) => {
    const unFollowUserRes = await handleGetRequest(`unfollow/${id}`,true,true)
    console.log("unFollowUserRes",unFollowUserRes);
    if(unFollowUserRes?.flag){
      const followingUserRes = await handleGetRequest(`followings`,false,true)
            console.log("followingUserRes",followingUserRes?.data);
            dispatch(handleFollowingUserList(followingUserRes?.data))
    }
  }

  const followUser = async(id) => {
    const followUserRes = await handleGetRequest(`follow/${id}`,true,true)
    console.log("followUserRes",followUserRes);
    if(followUserRes?.flag){
      const followingUserRes = await handleGetRequest(`followings`,false,true)
      console.log("followingUserRes",followingUserRes?.data);
      dispatch(handleFollowingUserList(followingUserRes?.data))
    }
  }

  const acceptRejectUser = async(id,condition) => {
    const payload={
      follower_id:id,
      action:condition
    }
    if(id==="accepted"){
      const unFollowAcceptRes = await handlePostRequest(`acceptReject`,payload,true,true)
    }else{
      const unFollowAcceptRes = await handleGetRequest(`removeFollowing/${id}`,true,true)
      if(unFollowAcceptRes?.flag){
        const followingUserRes = await handleGetRequest(`followings`,false,true)
        console.log("followingUserRes",followingUserRes?.data);
        dispatch(handleFollowingUserList(followingUserRes?.data))
        const pendingfollowingUserRes = await handleGetRequest(`pendingFollowings`,false,true)
        console.log("followingUserRes",pendingfollowingUserRes?.data);
        dispatch(handleFollowingRequestList(pendingfollowingUserRes?.data))
      }
    }

    
    // console.log("unFollowUserRes",unFollowUserRes);
    // if(unFollowUserRes?.flag){
    //   const followerUserRes = await handleGetRequest(`followers`,false,true)
    //   console.log("followerUserResdasa",followerUserRes?.data);
    //   dispatch(handleFollowerUserList(followerUserRes?.data))
    // }

  }

  
  return (
    <div>
        <Following unFollowUser={unFollowUser} acceptRejectUser={acceptRejectUser} followUser={followUser}/>
    </div>
  )
}

export default FollowingPage
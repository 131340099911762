import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router"
import { handleAuthModalStatus } from "./store/authenticationSlice";

const ProtectedRoutes = () => {
    const dispatch = useDispatch()
    const tokenStatus = useSelector((state) => state.auth.tokenStatus);
    if(!tokenStatus){
        return(
            <Navigate to="/" />
        ) 
        // return(
           
        // )
    }else{
        return(
            <Outlet/>
        )
    }
}

export default ProtectedRoutes
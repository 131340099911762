import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  musicAnalyzerState: "selection",
  uploadData: [],
  selectedPlaylistData: [],
  //   musicAnalyzerState: "instrumentSelection",
  selectedRowChanges: {},
  selectedRowTable: {},
  generateType: "",
  spotifyResponse: {},
  threeDotPopupStatus: false,
  addToPlaylistPanelPopupStatus: false,
  addPanelStatus: "",
};

// Slice
export const globalSlice = createSlice({
  name: "globalSlice",
  initialState,
  reducers: {
    updateMusicAnalyzerState(state, action) {
      state.musicAnalyzerState = action.payload;
    },
    updateUploadData(state, action) {
      state.uploadData = action.payload;
    },
    // THIS IS FOR STORING SELECTED PLAYLIST(MANAGE PLAYLIST).
    selectedPlaylist(state, action) {
      state.selectedPlaylistData = action.payload;
    },
    // THIS IS FOR CURIOSHITTABLE SELECTION.
    getSelectedRowsCharges(state, action) {
      state.selectedRowChanges = action.payload;
    },
    getSelectedRowsTable(state, action) {
      state.selectedRowTable = action.payload;
    },
    getSpotifyResponse(state, action) {
      state.spotifyResponse = action.payload;
    },
    updateThreeDotPopup(state, action) {
      state.threeDotPopupStatus = action.payload;
    },
    updateAddToPlaylistPanelPopup(state, action) {
      state.addToPlaylistPanelPopupStatus = action.payload;
    },
    // THis is for generate playlist.
    generatePlaylistType(state, action) {
      state.generateType = action.payload;
    },
    addToPlaylistPanelStatus(state, action) {
      state.addPanelStatus = action.payload;
    },
  },
});

export const globalSliceActions = globalSlice.actions;

export default globalSlice.reducer;

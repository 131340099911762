import { useState } from "react";
import SentimentSatisfiedOutlinedIcon from '@mui/icons-material/SentimentSatisfiedOutlined';
import SentimentVerySatisfiedOutlinedIcon from '@mui/icons-material/SentimentVerySatisfiedOutlined';
import SentimentDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentDissatisfiedOutlined';
import Face5OutlinedIcon from '@mui/icons-material/Face5Outlined';

const MoodRecolonization = () => {
    const feelings = [
        { label: 'Okay', value: 'okay', icon: '🙂' },
        { label: 'Calm', value: 'calm', icon: '😌' },
        { label: 'Hopeless', value: 'hopeless', icon: '😞' },
        { label: 'Angry', value: 'angry', icon: '😡' },
      ];
    const [selectedFeeling, setSelectedFeeling] = useState(null);

    const handleFeelingClick = (feeling) => {
        setSelectedFeeling(feeling);
    };


    return(
        <div className="bg-white dark:bg-[#151515] w-full h-auto drop-shadow-xl rounded-2xl p-5">
        <h2 className=" text-[#312522] text-xl font-semibold dark:text-white">How are you feeling today?</h2>
        <div className="grid grid-cols-4 gap-2 lg:gap-10 my-5">
          {feelings.map((feeling) => (
            <div>
            <div
              key={feeling.value}
              onClick={() => handleFeelingClick(feeling.value)}
              className={`flex flex-col items-center p-1 lg:p-4 border-2 rounded-lg cursor-pointer ${
                selectedFeeling === feeling.value ? 'bg-[#F1B942] border-[#F1B942]' : 'border-[#F1B942]'
              }`}
            >
              <div className="!text-6xl lg:my-3 !text-[#F1B942]">
                {feeling.value==="okay" && <SentimentSatisfiedOutlinedIcon className={`!text-6xl ${selectedFeeling === feeling.value?"!text-white":"!text-[#F1B942]"}`}/>}
                {feeling.value==="calm" && <SentimentVerySatisfiedOutlinedIcon className={`!text-6xl ${selectedFeeling === feeling.value?"!text-white":"!text-[#F1B942]"}`}/>}
                {feeling.value==="hopeless" && <SentimentDissatisfiedOutlinedIcon className={`!text-6xl ${selectedFeeling === feeling.value?"!text-white":"!text-[#F1B942]"}`}/>}
                {feeling.value==="angry" && <Face5OutlinedIcon className={`!text-6xl ${selectedFeeling === feeling.value?"!text-white":"!text-[#F1B942]"}`}/>}
                </div>
              <div className={`mt-2  ${selectedFeeling === feeling.value ? 'text-white' : 'text-[#F1B942]'}`}>{feeling.label}</div>
            </div>
            {selectedFeeling === feeling.value && (
                <select className="mt-3 bg-[#FAFAF8] dark:bg-black dark:text-white py-3  px-2 rounded w-full" defaultValue="a_little">
                  <option className="bg-[#FAFAF8]" value="">Select...</option>
                  <option className="bg-[#FAFAF8]" value="a_little">A little</option>
                  <option className="bg-[#FAFAF8]" value="somewhat">Somewhat</option>
                  <option className="bg-[#FAFAF8]" value="a_lot">A lot</option>
                </select>
              )}
            </div>

          ))}
        </div>
      </div>
    )
}

export default MoodRecolonization
import { useEffect } from "react";
import { useParams } from "react-router";
import { useApi } from "../../api/api";

const PopupWindow = () => {
    const {platform  } = useParams();
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    const state = urlParams.get("state");
    const id = urlParams.get("uid");
    console.log("platformpopupwindow",platform);
    // console.log("codepopupwindow",code);
    console.log("statepopupwindow",state);
    console.log("idpopupwindow",id);

    useEffect(()=>{
        (async () => {
            const callbackPayload = {
                code:code,
                uid:id,
                state:state
            }
                window.opener.postMessage(callbackPayload, "*");
                console.log("codepopupwindow",code);
                window.close();
            // }
          })();
    },[platform])
    return(
        <div>

        </div>
    )
}

export default PopupWindow

// https://curioushit-frontend.nevinainfotech.in/callback/dailymotion?code=fed6b15895e3924bc532c7a6d41c5290495b675d&scope=email+feed+manage_analytics+manage_app_connections+manage_applications+manage_claim_rules+manage_domains+manage_features+manage_history+manage_likes+manage_player+manage_players+manage_playlists+manage_podcasts+manage_records+manage_subscriptions+manage_subtitles+manage_user_settings+manage_videos+read_insights+userinfo&state=135d10a8e45a28f863959a3bbd2972e1&uid=x2v1d37&sig=35d79ee1b5cec15833256bf6dd5c08f6#" 


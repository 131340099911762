// import StripeCheckout from "react-stripe-checkout";
// import {Elements} from '@stripe/react-stripe-js';
// import {loadStripe} from '@stripe/stripe-js';
// import { useState, useEffect} from "react";
// import { useNavigate } from 'react-router-dom';
// import axios from "axios";
import H220 from "../../view/components/common/Typograpgy/H220";
// import { useApi } from "../../api/api";
// import { CheckoutForm } from "../../view/components/Payment/CheckoutForm";
import { useEffect, useState } from 'react';
import { useApi } from '../../api/api';
import { useNavigate, useParams } from 'react-router';
import StripeCheckout from 'react-stripe-checkout';
import parse from 'html-react-parser';
import { profileDetail } from "../../store/authenticationSlice";
import { useDispatch } from "react-redux";
import BackToPage from "../../view/components/common/BacktoPage/BackToPage";

const KEY = 'pk_test_5njt0xhdKgBCvqyPtda8cPB900o2t04bJu'

// const stripePromise = loadStripe('pk_test_51OvvZzSF27Bv0iL68pnFxmivHQEskXDjnbBY1vquvcK5ZzrLlcUwdZLfKsIT4b0sXYAMls9KAwhLmUxnENhcXeKf002buvCfMl');

const Payment = () => {
  const { vcode } = useParams()
  const dispatch = useDispatch()
  const [clientKey,setClientKey] = useState()
  const { handleGetRequest, handlePostRequest } = useApi();
  const ProfileData = JSON.parse(localStorage.getItem("ProfileData"))
  console.log("ProfileData",ProfileData?.user?.v_email_id);

  // const options = {
  //   // passing the client secret obtained from the server
  //   clientSecret: clientKey?clientKey:"",
  // };

  // useEffect(() => {
  //   // Skip the first render
  //   (async () => {  
  //     const clientSecretRes = await handleGetRequest(`payment/generateClientSecret/1`, false);
  //     console.log("clientSecretRes", clientSecretRes?.clientSecret);
  //     setClientKey(clientSecretRes?.clientSecret)
  //   })();
  // }, []);

  const [planData, setPlanData] = useState()

    
    useEffect(() => {
        // Skip the first render
        (async () => {  
          const subscriptionRes = await handleGetRequest(`subscription/fetch/${vcode}`, false, true);
            if(subscriptionRes?.flag){
              console.log("subscriptionRes", subscriptionRes);
              setPlanData(subscriptionRes?.data)
          }
        })();
      }, []);

  console.log("planData",planData)
    // const { handlePostRequest } = useApi();
    // const stripe = useStripe();
    // const elements = useElements();
    // const [errorMessage, setErrorMessage] = useState('');
    // const [emailInput, setEmailInput] = useState('');

    const [stripeToken, setStripToken] = useState(null);
    const navigate = useNavigate();
    const onToken = (token) => {
        console.log(token);
        setStripToken(token);
      };

      useEffect(() => {
        const makeRequest = async () => {
          try {
            const payload = {
                stripeToken: stripeToken.id,
                i_plan_id: planData?.id,
              }
            const callbackRes = await handlePostRequest(`payment/process`,  payload, true, true);
            console.log("callbackRespayment", callbackRes?.data?.transaction_id);
            const profileDataReq = await handleGetRequest("profile-detail",false, true)
              dispatch(profileDetail(profileDataReq?.data))
            navigate(`/success/${callbackRes?.data?.transaction_id}?v_code=${vcode}`);
          } catch (err) {
            console.log(err);
          }
        };
    
        stripeToken && makeRequest();
      }, [stripeToken]);


    // if(!clientKey){
    //   return;
    // }else{

  return(
      
      <div>
          {/* <BackToPage pageRoute="/premium" backTo="Premium"/> */}
          <div className="px-3 lg:px-0">
          <H220 className="!my-7 ">Payment</H220>
          </div>
          <div className="drop-shadow-xl rounded-lg w-full bg-white my-2 py-10 px-8 text-black dark:bg-[#151515]">
          <div className="rounded-xl my-3 py-7 px-5 lg:py-10 lg:px-5 flex justify-between items-center bg-[#EA6F54] text-white text-lg lg:text-3xl font-semibold ">
              <p>{planData?.v_plan_name}</p>
              <p>$ {planData?.v_discounted_price}</p>
          </div>
          <div className='text-[#312522] my-7 dark:text-[#DDD1CE]'>
              <p className='lg:text-lg text-sm !font-semibold'>Description</p>
              <p  className='lg:text-base text-xs my-2'>{planData?.t_long_description?parse(planData?.t_long_description):""}</p>
          </div>
          <div className="py-3 my-2 lg:text-lg text-sm font-normal text-[#888]  flex justify-between items-center">
              <p>{planData?.duration} Plan <br/>access ends: {planData?.d_end_date}</p>
              <p>$ {planData?.v_discounted_price}</p>
          </div>
          <hr className="h-[1px] bg-[#888] "/>
          <div className="py-3 my-2 lg:text-lg text-sm font-normal text-[#888]  flex justify-between items-center">
              <p>Total:</p>
              <p>$ {planData?.v_discounted_price}</p>
          </div>
          <hr className="h-[1px] bg-[#888]"/>
          <div className="text-[#312522] relative lg:text-lg text-sm font-semibold text-center mt-7 dark:text-white">
         <p>Credit or debit card</p>
          <div className="container ">
            {stripeToken ? (
              <span>Processing. please wait</span>
            ) : (
              <StripeCheckout
                // name="Tech Geek."
                // image="https://avatars.githubusercontent.com/u/1486366?v=4"
                // billingAddress
                // shippingAddress
                // description=" Your total is $20"
                // amount={2000}
                email={ProfileData?.user?.v_email_id}
                token={onToken}
                stripeKey={KEY}
                
              >
                {/* <button>Pay now</button>/ */}
                <button className='bg-[#F1B942] px-3 py-2 text-white rounded-[50px] my-3'  >
                Pay now
                </button>
              </StripeCheckout>
            )}
          </div>
          </div>
          {/* <div className='w-full'>
            <Elements stripe={stripePromise} options={options}>
                <CheckoutForm clientKey={clientKey}/>
              </Elements>
            </div> */}
          </div>
        </div>
    
  )
}
// }

export default Payment







import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    scrollValue: 0,
    isScrollBottom: false
};

// Slice
export const scrollValueSlice = createSlice({
  name: "scroll",
  initialState,
  reducers: {
    handleScrollValue(state,action){
        state.scrollValue = action.payload;
      },

      handleScrollStatus(state,action){
        state.isScrollBottom = action.payload;
      },
  },
});

export const {handleScrollValue,handleScrollStatus} = scrollValueSlice.actions;

export default scrollValueSlice.reducer;
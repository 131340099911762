import React, { useEffect } from 'react'
import CommunityLayout from '../CommunityLayout'
import Button1 from '../../common/GlobalButtonLayout/Button1'
import CommunityCardHeader from '../CommunityCardHeader'
import Button2 from '../../common/GlobalButtonLayout/Button2'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Tooltip } from '@mui/material'

const Following = ({unFollowUser,acceptRejectUser,followUser}) => {   
    const followingUser = useSelector((state) => state.following.followingUser);
    const followingRequest = useSelector((state) => state.following.followingRequest);
    console.log("followingUser",followingUser);

  
  return (
    <div>
         <div className='my-10'>
        { followingRequest?.length>0 && <CommunityLayout >
            <CommunityCardHeader count={followingRequest?.length>4?true:false} title="Sent requests" routeUrl="/community/following/sent-request"/>
            <div className='grid lg:grid-cols-2 grid-cols-1  gap-x-40 my-5'>
            {followingRequest?.map((items,index)=>{
                return(
                    <div className={` ${index>3?"hidden":"flex justify-between items-center gap-5"}  my-5`}>
                        <Link to={`/other-user/${items?.id}`} className='flex justify-start gap-x-3 items-center'>
                        <div className='w-14 h-14 lg:w-16 lg:h-16 rounded-full'>
                            <img src={items?.t_image} alt='UserImage' className='w-full h-full object-cover rounded-full' />
                        </div>
                        <div className='w-20'>
                        <Tooltip title={items?.v_name?items?.v_name:"User Name"}><p className='capitalize font-semibold line-clamp-1'>{items?.v_name?items?.v_name:"User Name"}</p></Tooltip>
                        </div>
                        </Link>
                        <div>
                            {/* <Button1 onClick={()=>acceptRejectUser(items?.id,"rejected")}  className="dark:hover:!text-[#F1B942]">Cancel</Button1> */}
                            <Button2 onClick={()=>acceptRejectUser(items?.id,"rejected")} className="!text-center dark:text-[#F1B942]">Cancel</Button2>
                        </div>
                    </div>
                )
            })}
            
            </div>
        </CommunityLayout>}

       {followingUser?.length>0 && <CommunityLayout >
            <CommunityCardHeader  count={followingUser?.length>6?true:false} title="Your curiousHit following" routeUrl="/community/following/curiousHit-following"/>
            <div className='lg:grid lg:grid-cols-6   gap-x-5 my-5 text-center'>
               {followingUser.map((items, index)=>{
                return(
                    <div className={`grid grid-cols-3 ${index>5?"hidden":" lg:block"} ${items?.is_removed?"!hidden":""} gap-x-5 my-2`}>
                        <Link to={`/other-user/${items?.id}`} className='flex justify-start items-center gap-x-2 lg:block col-span-2'>
                        <div className='my-1 min-w-14 max-w-14 h-14 lg:min-w-32 lg:max-w-auto lg:h-32 lg:mx-auto rounded-full '>
                            <img src={items?.t_image} alt='' className=' w-full h-full object-cover rounded-full' />
                        </div>
                        <div className='max-w-[100px] lg:max-w-auto lg:mx-auto'>
                        <Tooltip title={items?.v_name?items?.v_name:"User Name"}><p className='mt-1 font-semibold lg:text-xl text-sm capitalize line-clamp-1 text-left lg:text-center'>{items?.v_name?items?.v_name:"User Name"}</p></Tooltip>
                        {!items?.unfollow_date && <p className='mb-1  lg:text-sm  text-xs text-left lg:text-center'>{items?.total_followers} Followers</p>}
                        {items?.unfollow_date &&<p className='mb-1  lg:text-sm  text-xs'>unfollow on {items?.unfollow_date?items?.unfollow_date:""}</p>} 
                        </div>
                        </Link>
                        {items?.unfollow_date?<Button2 onClick={()=> followUser(items?.id)} className="!text-center dark:text-[#F1B942]">Follow</Button2> :
                        <Button2 onClick={()=>unFollowUser(items?.id)} className="!text-center dark:text-[#F1B942]">Unfollow</Button2>}
                    </div>
                )
               })}
            </div>
        </CommunityLayout>}

        {/* <CommunityLayout>
            <CommunityCardHeader title="Your instagram Friends on CuriousHit !" routeUrl="/community/following/instagram-friends-following"/>
            <div className='lg:grid lg:grid-cols-6   gap-x-5 my-5 text-center'>
               {curioushitFollowers.map((items, index)=>{
                return(
                    <div className='grid grid-cols-3 gap-x-5 my-2 lg:block'>
                        <div className='flex justify-start items-center gap-x-4 lg:block col-span-2'>
                        <div className='my-1 w-14 h-14 lg:w-32 lg:h-32 lg:mx-auto rounded-full '>
                            <img src='/images/communityimg.png' alt='' className=' w-full h-full object-cover' />
                        </div>
                        <div>
                        <p className='mt-1 font-semibold lg:text-xl text-sm '>Udit Narayan</p>
                        <p className='mb-1  lg:text-lg  text-xs'>12.3K Followers</p>
                        </div>
                        </div>
                        <Button2 className="!text-center dark:text-[#F1B942]">See Profile</Button2>
                    </div>
                )
               })}
            </div>
        </CommunityLayout> */}

        {/* <CommunityLayout>
            <CommunityCardHeader title="Your Facebook Friends on CuriousHit !" routeUrl="/community/following/facebook-friends-following"/>
            <div className='lg:grid lg:grid-cols-6   gap-x-5 my-5 text-center'>
               {curioushitFollowers.map((items, index)=>{
                return(
                    <div className='grid grid-cols-3 gap-x-5 my-2 lg:block'>
                        <div className='flex justify-start items-center gap-x-4 lg:block col-span-2'>
                        <div className='my-1 w-14 h-14 lg:w-32 lg:h-32 lg:mx-auto rounded-full '>
                            <img src='/images/communityimg.png' alt='' className=' w-full h-full object-cover' />
                        </div>
                        <div>
                        <p className='mt-1 font-semibold lg:text-xl text-sm '>Udit Narayan</p>
                        <p className='mb-1  lg:text-lg  text-xs'>12.3K Followers</p>
                        </div>
                        </div>
                        <Button2 className="!text-center dark:text-[#F1B942]">See Profile</Button2>
                    </div>
                )
               })}
            </div>
        </CommunityLayout> */}
         </div>
    </div>
  )
}

export default Following
import React, { useEffect, useState } from 'react'
import H220 from '../common/Typograpgy/H220'
import Button1 from '../common/GlobalButtonLayout/Button1'
import CheckIcon from '@mui/icons-material/Check';

import { useApi } from '../../../api/api';

const SyncCalendar = ({setStep,setSelectCalendar,selectCalendar,connectCalendar}) => {

    return (
    <div  className='h-[70vh] flex flex-col justify-center'>
          <div className='flex flex-col drop-shadow-xl rounded-xl justify-center p-5 lg:h-auto w-[90vw] lg:w-full h-auto text-center !bg-white mx-auto dark:!bg-[#151515]'>
          <H220 className=" text-sm lg:!text-2xl ">Connect your calendar to plan your sessions more easily</H220>
          <div className='flex justify-center items-center lg:px-52 lg:mt-10'>
            <div>
              <div className='relative cursor-pointer w-24 h-24 mx-auto' onClick={()=>{setSelectCalendar("google-calendar"); localStorage.setItem('calendar', JSON.stringify("google-calendar"))}}>
                <img src='/images/googlecalender.webp' alt='' className='mx-auto w-full h-full object-cover'/>
                {selectCalendar==="google-calendar" && <div className='bg-black flex justify-center items-center rounded-full bg-opacity-65 w-full h-full absolute top-0 '><CheckIcon className='!text-7xl !text-white'/></div>}
              </div>
              <p className='my-3 dark:text-white'>Google calendar</p>
            </div>
            {/* <div>
              <div  className='relative cursor-pointer'  onClick={()=>{setSelectCalendar("outlook-calendar"); localStorage.setItem('calendar', JSON.stringify("outlook-calendar"))}}>
                <img src='/images/outlookcalendar.webp' alt='' className='mx-auto w-full h-full object-cover'/>
               { selectCalendar==="outlook-calendar" &&  <div className='bg-black flex justify-center items-center rounded-full bg-opacity-65 w-full h-full absolute top-0 '><CheckIcon className='!text-7xl !text-white'/></div>}
              </div>
              <p className='my-3'>Outlook calendar</p>
            </div> */}
          </div>
            <div className='flex justify-center items-center my-4 gap-x-5'>
              <Button1 className="!text-center dark:hover:!text-[#F3C25A]" onClick={()=>connectCalendar()}>Connect</Button1>
            </div>
            </div>
          </div>
  )
}

export default SyncCalendar
import React, { useRef, useState } from 'react'
import BackToPage from '../../view/components/common/BacktoPage/BackToPage'
import H220 from '../../view/components/common/Typograpgy/H220'
import EmojiEmotionsOutlinedIcon from '@mui/icons-material/EmojiEmotionsOutlined';
import Button1 from '../../view/components/common/GlobalButtonLayout/Button1';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import SearchComponent from '../../view/components/CommunityComponents/SearchComponent';
import Following from '../../view/components/CommunityComponents/FollowingComponents/Following';
import Followers from '../../view/components/CommunityComponents/FollowersComponents/Followers';


const Community = () => {
    const inputRef = useRef(null);
    const [searchTerm, setSearchTerm] = useState("")
    const [expanded, setExpanded] = useState(searchTerm?true:false);
    const [nav,setNav] = useState(2)  
    const [isFocused, setIsFocused] = useState(false);
    const [showSuggestions, setShowSuggestions] = useState(false);
    
    const handleSearch = () => {
      // Implement your search functionality here
      console.log('Searching for:', searchTerm);

      // You can perform search operations based on the searchTerm
      // For example, you can make an API call to fetch search results
  };
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
          handleSearch();
          setSearchTerm(localStorage.getItem('searchValue', searchTerm))
          setSearchTerm("")
      }
  };

    const handleSearchInputChange = (event) => {
      setSearchTerm(event.target.value);
  }; 
  const handleSearchIconClick = () => {
    if(searchTerm){
        return
    }
    setExpanded(pre=>!pre);
    setIsFocused(pre=>!pre);
    inputRef.current.focus();
}; 

  return (
    <div>
        {/* <BackToPage pageRoute="/" backTo="Home"/> */}

        <div className="">
            <div className="grid lg:grid-cols-10 grid-cols-3  content-center text-center  text-[#312522] text-lg font-light gap-x-4 my-4">
                {/* <p className={`cursor-pointer ${nav===1?"text-[#F1B942]  font-bold":"dark:text-white"}`} onClick={()=>setNav(1)}>Publication</p> */}
                <p className={`cursor-pointer ${nav===2?"text-[#F1B942]  font-bold":"dark:text-white"}`} onClick={()=>setNav(2)}>Following</p>
                <p className={`cursor-pointer ${nav===3?"text-[#F1B942]  font-bold":"dark:text-white"}`} onClick={()=>setNav(3)}>Followers</p>
               
            </div>
            <div className="w-full h-[5px] bg-[#D9D9D9] grid  lg:grid-cols-10 grid-cols-3 ">
                {/* <div className={`${nav===1?"bg-[#F1B942]":""}`}></div> */}
                <div className={`${nav===2?"bg-[#F1B942]":""}`}></div>
                <div className={`${nav===3?"bg-[#F1B942]":""}`}></div>
            </div>

            {nav===2&&<div>
              <Following inputRef={inputRef} expanded={expanded} searchTerm={searchTerm} handleSearchInputChange={handleSearchInputChange} setIsFocused={setIsFocused}  handleKeyPress={handleKeyPress} showSuggestions={showSuggestions} setShowSuggestions={setShowSuggestions} handleSearchIconClick={handleSearchIconClick}/>
            </div>}
            {nav===3&&<div>
              <Followers inputRef={inputRef} expanded={expanded} searchTerm={searchTerm} handleSearchInputChange={handleSearchInputChange} setIsFocused={setIsFocused}  handleKeyPress={handleKeyPress} showSuggestions={showSuggestions} setShowSuggestions={setShowSuggestions} handleSearchIconClick={handleSearchIconClick}/>
            </div>}
            
        </div>

        <div className='h-[30vh] lg:h-[70vh] flex flex-col '>
            <div className="flex flex-col drop-shadow-xl rounded-xl justify-center p-5 lg:h-auto w-[90vw] lg:w-full h-52 text-center !bg-white mx-auto dark:!bg-[#151515]">
                <H220 className="!text-base lg:!text-xl ">Connect to people you know to discover their music collection</H220>
                <div className='lg:flex justify-center items-center my-4 gap-x-5'>
                <Button1 className="!text-center  !bg-[#3871E4] !text-white !w-64 hover:!text-white !py-2" ><FacebookIcon/> Connect Facebook</Button1>
                <Button1 className="!text-center  !bg-[#C837AB] !text-white !w-64 hover:!text-white !py-2" ><InstagramIcon/> Connect Instagram</Button1>
            </div>
          </div>
        </div>
    </div>
  )
}

export default Community
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import Button2 from '../common/GlobalButtonLayout/Button2';
import DownloadIcon from '@mui/icons-material/Download';

const PricingCards = ({title,subtitle,className,buttonColor,featureList,isactive,bgColor,vcode,pdf,deactivatePlan,isSubscription,expireDate,upgradePlan,downgradePlan,activatedPrice}) => {
    console.log("isactive",activatedPrice);
    const handleDownloadPdf = () => {
        // Download PDF logic here
        if (pdf) {
            window.open(pdf, "_blank");
        } else {
            console.error("PDF URL is not available.");
        }
    };
    return(
        <div>
        <div className={`rounded-lg py-5 text-center my-3 text-black  ${className}`} style={{backgroundColor:`${bgColor}`}}>
            <p className={`${isSubscription?"text-3xl lg:text-[46px] font-semibold capitalize mt-4 ":""}`}>{title}</p>
            <p className={`lg:my-4 my-2 !bg-white !bg-opacity-30 py-2 ${isSubscription?"text-base lg:text-3xl font-semibold capitalize ":""}`}>$ {subtitle} / Month</p>
            {/* <ul className="my-2">
                <li>Lorem Ipsum is simply Ipsum</li>
                <li>Lorem Ipsum is simply Ipsum</li>
                <li>Lorem Ipsum is simply Ipsum</li>
                <li>Lorem Ipsum is simply Ipsum</li>
                <li>Lorem Ipsum is simply Ipsum</li>
            </ul> */}
            <div className={`${isSubscription?"text-xs lg:text-base font-normal capitalize ":""}`}>
                {parse(featureList)}
            </div>
            <div className='my-5'>
           { !isSubscription && <Link to={`${!isactive?`/payment/${vcode}`:`#`}`} className={`min-w-[150px] my-3 px-3 py-2 text-white  rounded-[50px] ${buttonColor} !bg-[#1F2121]`} >{isactive?"Activated":subtitle>activatedPrice ?"Upgrade":"Buy Now"}</Link>}
            {isSubscription &&<Link to={`#`} className={`min-w-[150px] my-3 px-3 py-2 text-white  rounded-[50px] ${buttonColor} !bg-[#1F2121]`} >Activated</Link>}
            </div>
            {(isactive && isSubscription) &&<p className={`text-center ${isSubscription?"text-sm lg:text-lg font-normal capitalize ":""}`}>Expires on : {expireDate}</p>}
            {isSubscription &&<p className={`cursor-pointer my-3  ${isSubscription?"text-sm lg:text-lg font-normal  ":""}`} onClick={()=>handleDownloadPdf()}><DownloadIcon/> Downloads receipt</p>}

           
        </div>
        { isSubscription && <div className="flex mt-4 justify-center items-center gap-x-2 px-3 lg:gap-x-4 ">
                <Button2 onClick={()=>upgradePlan()} className="!border-[3px] !rounded-[50px] !border-[#A8A8A8] text-[#A8A8A8] hover:!border-[#FFAD00] !bg-[#1F2121] text-xs lg:text-base !text-center  lg:!w-40 lg:!h-12">Upgrade</Button2>
                <Button2 onClick={()=>downgradePlan()} className="!border-[3px] !rounded-[50px] !border-[#A8A8A8] text-[#A8A8A8] hover:!border-[#796e57] text-xs lg:text-base !text-center lg:!w-40 lg:!h-12">Downgrade</Button2>
                <Button2  onClick={()=>deactivatePlan(vcode)} className="!border-[3px] !rounded-[50px] !border-[#A8A8A8] text-[#A8A8A8] hover:!border-[#FFAD00] text-xs lg:text-base !text-center lg:!w-40 lg:!h-12">Deactivate</Button2>
            </div>}
        </div>
    )
}

export default PricingCards

// onclick={()=>deactivatePlan(vcode)}
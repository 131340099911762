import {Search, SearchOff, SearchOffOutlined, ExpandMoreRounded, SearchTwoTone}  from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { useRef, useState } from 'react';
import FilterButtons from './FilterButtons';
import { useSelector } from 'react-redux';

function FilterList({title,panel,expanded,setExpanded,filterList,filterListMobile,selectKey,selectedFilterData,setSelectedFilterData,setSelectedFilterName,selectedFilterName,isSelectedFilters, handleChange}){

    const [searchTerm, setSearchTerm] = useState('');

    const handleInputChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredOptions = filterList?.filter(option =>
        expanded==="panel5" ?option?.v_region?.toLowerCase().includes(searchTerm.toLowerCase()): option?.label.toLowerCase().includes(searchTerm.toLowerCase())
    );

    console.log("searchTerm",searchTerm);
    console.log("filteredOptions",filteredOptions);
    

const handleCheckboxChange = (selectKey, optionId,optionName) => {
    // Create a copy of the current state
    const updatedSelectedFilterData = { ...selectedFilterData };
    const updatedSelectedFilterName = { ...selectedFilterName };
    // Determine the key based on the provided selectKey
    const key = `${selectKey.toLowerCase()}`;
  
    // Ensure that the array is initialized as an empty array
    updatedSelectedFilterData[key] = updatedSelectedFilterData[key] || [];
    updatedSelectedFilterName[key] = updatedSelectedFilterName[key] || [];
  
    // Check if the optionId is already present in the array
    const index = updatedSelectedFilterData[key].indexOf(optionId);
    const indexName = updatedSelectedFilterName[key].indexOf(optionName);
  
    if (index === -1) {
      // If not present, add it to the array
      updatedSelectedFilterData[key] = [...updatedSelectedFilterData[key], optionId];
    } else {
      // If present, remove it from the array
      updatedSelectedFilterData[key] = [
        ...updatedSelectedFilterData[key].slice(0, index),
        ...updatedSelectedFilterData[key].slice(index + 1),
      ];
    }

    if (indexName === -1) {
        // If not present, add it to the array
        updatedSelectedFilterName[key] = [...updatedSelectedFilterName[key], optionName];
      } else {
        // If present, remove it from the array
        updatedSelectedFilterName[key] = [
          ...updatedSelectedFilterName[key].slice(0, index),
          ...updatedSelectedFilterName[key].slice(index + 1),
        ];
      }
  
    // Update the state
    setSelectedFilterData(updatedSelectedFilterData);
    setSelectedFilterName(updatedSelectedFilterName);
  };

//   const handleChange = (panel) => (event, isExpanded) => {
//     setExpanded(isExpanded ? panel : false);

//     // Scroll up when an accordion is expanded
//     if (isExpanded) {
//         const accordionDetails = document.getElementById('accordion-details');
//         if (accordionDetails) {
//             accordionDetails.scrollTop = 0;
//         }
//     }
// };
const isDark = useSelector((state) => state.dark.isDarkValue);




    return(
        <div className=' relative h-full lg:h-auto overflow-scroll '>
            <div className={`hidden lg:block ${isDark?"dark":""} `}>
           { (filteredOptions?.length>0 || searchTerm) &&  <Accordion  expanded={expanded === panel} onChange={handleChange(panel,title)} className='!shadow-none !my-2 dark:!bg-[#0D0D0D] dark:!text-white'>
                    <AccordionSummary
                    expandIcon={<ExpandMoreRounded className='dark:!text-white' />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    className={`w-full mt-2 h-[45px] overflow-y-auto !min-h-0 !rounded-[50px] !drop-shadow-none flex justify-between items-center !bg-[#FAFAF8] px-3 my-1 dark:!bg-[#0D0D0D] dark:!text-white  ${isSelectedFilters?"!bg-[#fff1d4]":""}`}
                    >
                        <p className='text-[#312522] text-[16px] font-normal dark:!text-white capitalize'>{title}</p>
                    </AccordionSummary>
                    <AccordionDetails 
                        className='w-full !mt-3  max-h-[200px] overflow-scroll  !rounded-lg flex flex-col   items-center !bg-[#FAFAF8] dark:!bg-[#131313] px-3  pt-3 pb-4'
                    >
                    <div className='relative w-full '>
                    <input
                        type="text"
                        placeholder="Search..."
                        className="w-full p-2 dark:bg-[#0D0D0D] rounded-[50px] border-[2px] focus:border-[2px] focus:border-[#f1b942] dark:border-[#0D0D0D] drop-shadow-sm"
                        value={searchTerm}
                        onChange={handleInputChange}
                    />
                    <div className='absolute  top-1/2 transform -translate-y-1/2 right-2'>
                        <SearchTwoTone/>
                    </div>
                    </div>
                    {filteredOptions.length>0?<div className='grid grid-cols-2 gap-2 w-full content-center justify-items-start p-2'>
                        {filteredOptions?.map((option) => (
                            <div key={option?.id} className='flex justify-between items-center relative'>
                                <div>
                                <input
                                    type="checkbox"
                                    className='!mx-2  h-3 w-3 accent-gray-600 bg-gray-700 rounded cursor-pointer'
                                    id={`checkbox-${option?.label}`}
                                    onChange={() => handleCheckboxChange(selectKey, option?.label,option?.label)}
                                    checked={selectedFilterData[`${selectKey.toLowerCase()}`]?.includes(option?.label)}
                                />
                                </div>
                             
                            <label  htmlFor={`checkbox-${option?.label}`} key={option?.label} className='!mb-0 text-[12px] text-clip break-all text-wrap line-clamp-1'>
                            {selectKey==="filter_geos"?option?.v_region:selectKey==="filter_decades"?`${option?.label.substring(2)}'s`:option?.label}
                            </label>
                            </div>
                        ))}
                    </div>:<div className='my-3'>No Options Found</div>}
                    </AccordionDetails>
                </Accordion>}
            </div>
            {/* <div className='grid lg:hidden grid-cols-2 gap-2 w-full  content-center justify-items-start p-2'>
                        {filterList?.map((option) => (
                            <div className='flex justify-between items-center '>
                             <input
                             type="checkbox"
                             className='!mx-2'
                             onChange={() => handleCheckboxChange(option?.label)}
                         />
                            <label key={option?.label} className='!mb-0'>
                            {option?.name}
                            </label>
                            </div>
                        ))}
                    </div> */}
                     {filteredOptions?.length>0 &&<div className='relative w-full  px-2 lg:hidden'>
                        <input
                            type="text"
                            placeholder="Search..."
                            className="w-full p-2 rounded-[50px] border-[2px] focus:border-[2px] focus:border-[#f1b942] drop-shadow-sm"
                            value={searchTerm}
                            onChange={handleInputChange}
                        />
                        <div className='absolute  top-1/2 transform -translate-y-1/2 right-2'>
                            <SearchTwoTone/>
                        </div>
                    </div>}
                  { filteredOptions?.length>0 &&<div className='grid lg:hidden grid-cols-2 gap-x-7 !px-3   gap-y-3 w-full content-center justify-items-start p-2 mb-16 lg:mb-0'>
                        {filteredOptions?.map((option) => (
                            <div className='flex justify-between items-start ' key={option?.id}>
                            <input
                                type="checkbox"
                                className='!mx-2 mt-1 min-w-6  h-3 w-3 accent-gray-600 bg-gray-700 rounded cursor-pointer'
                                id={`checkbox-${option?.label}`}
                                onChange={() => handleCheckboxChange(selectKey, option?.label)}
                                checked={selectedFilterData[`${selectKey.toLowerCase()}`]?.includes(option?.label)}
                            />
                            <label htmlFor={`checkbox-${option?.label}`}  className='!mb-0 text-[12px] text-clip break-all text-wrap line-clamp-1 '>
                                {selectKey==="filter_geos"?option?.v_region:option?.label}
                            </label>
                            </div>
                        ))}
                    </div>}
        </div>
       
    )
}

export default FilterList
import React, { useEffect } from 'react'
import H220 from '../../../view/components/common/Typograpgy/H220'
import Button2 from '../../../view/components/common/GlobalButtonLayout/Button2'
import CommunityLayout from '../../../view/components/CommunityComponents/CommunityLayout'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useApi } from '../../../api/api'
import { handleFollowerRequestList, handleFollowerUserList } from '../../../store/followerSlice'

const CuriousHitFollower = () => {
  const followerUser = useSelector((state) => state.follower.followerUser);
  const { handleGetRequest,handlePostRequest } = useApi();
  const dispatch = useDispatch()

  useEffect(()=>{
    ;(async () => {
        const followerUserRes = await handleGetRequest(`followers`,false,true)
        console.log("followerUserRes",followerUserRes?.data);
        dispatch(handleFollowerUserList(followerUserRes?.data))
    })();
},[])

  console.log("followerUser",followerUser);
  const cancelUser = async(id,condition) => {
    const unFollowAcceptRes = await handlePostRequest(`removeFollowers/${id}`,true,true)
    if(unFollowAcceptRes?.flag){
      const followerUserRequestRes = await handleGetRequest(`pendingFollowers`,false,true)
      console.log("followingUserRes",followerUserRequestRes?.data);
      dispatch(handleFollowerRequestList(followerUserRequestRes?.data))
      const followerUserRes = await handleGetRequest(`followers`,false,true)
      console.log("followerUserRes",followerUserRes?.data);
      dispatch(handleFollowerUserList(followerUserRes?.data))
    }
}
  return (
    <div>
    <CommunityLayout>
    <H220 className="">Your CuriousHit followers</H220>
    <div className='lg:grid lg:grid-cols-6   gap-x-5 my-5 text-center'>
             {followerUser.map((items, index)=>{
              return(
                <div className={`grid grid-cols-3  gap-x-5 my-2  lg:block`}>
                <Link to={`/other-user/${items?.id}`} className='flex justify-start items-center gap-x-4 lg:block col-span-2'>
                <div className='my-1 min-w-14 max-w-14 h-14 lg:min-w-32 lg:max-w-32 lg:h-32 lg:mx-auto rounded-full '>
                    <img src={items?.t_image} alt='' className=' w-full h-full object-cover rounded-full' />
                </div>
                <div className='max-w-[100px] lg:max-w-auto lg:mx-auto'>
                <p className='mt-1 font-semibold lg:text-xl text-sm  dark:text-white capitalize line-clamp-1 text-left lg:text-center'>{items?.v_name?items?.v_name:"User Name"}</p>
                <p className='mb-1  lg:text-lg  text-xs dark:text-white capitalize text-left lg:text-center'>{items?.total_followers} Followers</p>
                </div>
                </Link>
                <Button2  onClick={()=>cancelUser(items?.id)}   className="!text-center !w-full dark:text-[#F1B942]">{items?.remove_date?"Invite to follow":"Remove"}</Button2>
            </div>
              )
             })}
          </div>
    </CommunityLayout>
  </div>
  )
}

export default CuriousHitFollower
import React from 'react'
import H220 from '../../../view/components/common/Typograpgy/H220'
import Button2 from '../../../view/components/common/GlobalButtonLayout/Button2'
import CommunityLayout from '../../../view/components/CommunityComponents/CommunityLayout'

const InstagramFriendsFollowing = () => {
  const curioushitFollowing = [{img:"", name:"Bunbury Malaya"},{img:"", name:"Bunbury Malaya"},{img:"", name:"Bunbury Malaya"},{img:"", name:"Bunbury Malaya"},{img:"", name:"Bunbury Malaya"},{img:"", name:"Bunbury Malaya"},{img:"", name:"Bunbury Malaya"},{img:"", name:"Bunbury Malaya"},{img:"", name:"Bunbury Malaya"},{img:"", name:"Bunbury Malaya"},{img:"", name:"Bunbury Malaya"}]
  return (
    <div>
       <CommunityLayout>
      <H220 className="">Your Instagram Followers</H220>
      <div className='lg:grid lg:grid-cols-6   gap-x-5 my-5 text-center'>
        {curioushitFollowing.map((items, index)=>{
        return(
          <div className={`grid grid-cols-3  gap-x-5 my-2  lg:block`}>
              <div className='flex justify-start items-center gap-x-4 lg:block col-span-2'>
              <div className='my-1 w-14 h-14 lg:w-32 lg:h-32 lg:mx-auto rounded-full '>
                  <img src='/images/communityimg.png' alt='' className=' w-full h-full object-cover' />
              </div>
              <div>
              <p className='mt-1 font-semibold lg:text-xl text-sm  dark:text-white'>Udit Narayan</p>
              <p className='mb-1  lg:text-lg  text-xs dark:text-white'>12.3K Followers</p>
              </div>
              </div>
              <Button2 className="!text-center dark:text-[#F1B942]">Unfollow</Button2>
          </div>
        )
        })}
    </div>
  </CommunityLayout>
    </div>
  )
}

export default InstagramFriendsFollowing
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

const Pro = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = async (data) => {
        // try {
        //   const response = await axios.post('/your-api-endpoint', data);
        //   console.log(response.data);
        // } catch (error) {
        //   console.error(error);
        // }
      };

    const handleInputChange = (event) => {
        const value = event.target.value;
        // Fetch suggestions based on the input value
        // For example, you can use axios to get suggestions from an API
        // axios.get(`/your-suggestions-api?q=${value}`).then(response => {
        //   setSuggestions(response.data);
        // });

        // Dummy suggestions for demonstration
       
    };


    const calculateTimeLeft = () => {
      
        const difference = +new Date('2024-12-31T00:00:00') - +new Date();
        let timeLeft = {};
    
        if (difference > 0) {
          timeLeft = {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60)
          };
        }
    
        return timeLeft;
      };
        const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

        useEffect(() => {
            const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
            }, 1000);

            return () => clearTimeout(timer);
        });

        const timerComponents = [];

        Object.keys(timeLeft).forEach(interval => {
            if (!timeLeft[interval]) {
            return;
            }

            timerComponents.push(
            <span key={interval} className="mx-2 text-xl flex ">
                <div>
                    <div className="text-xs font-normal capitalize">{interval}{" "}</div>
                    <div className="text-2xl font-semibold capitalize">{timeLeft[interval]} </div>
                </div>
            </span>
            );
        });


        

    return(
       <div>
        <div className="bg-[#F1B942] pt-10 pr-10 pb-10  relative m-3 rounded-xl">
          <div className="absolute w-[150px] sm:w-[190px]  lg:w-280px] xl:w-[340px] top-0 right-0">
            <img src="/commingsoon.png" alt="" className="" />
          </div>

            <p className="mt-5 py-3 text-sm lg:text-2xl font-normal bg-white min-w-12 max-w-40 text-center rounded-tr-3xl rounded-br-3xl text-[#F1B942]">Coming soon</p>
            <div className="pl-5">
                <p className="text-white  text-xl lg:text-3xl font-bold mt-20 my-5">Get Notified When We Launch</p>
                 <div className="countdown flex justify-between lg:justify-start items-center bg-white p-5 lg:w-96 w-full rounded-2xl">
                {timerComponents.length ? timerComponents : <span>Time's up!</span>}
                </div>
                <p className="text-white  text-sm lg:text-lg font-semibold my-5">As a pro , what features would you like to see added in curiousHit?</p>
                <div className="flex justify-start items-center gap-x-3 mb-5">
                  <input type="checkbox" className=""/>
                  <p className="text-white text-sm">I do not wish to receive news, offers and promotions from curioushit company by Email or by Phone.</p>
                </div>
                <form onSubmit={handleSubmit(onSubmit)} className="lg:w-[40%] w-full">
                      <div>
                        <input
                          type="text"
                          {...register('suggestion')}
                          onChange={handleInputChange}
                          className="w-full rounded-2xl h-24 bg-white p-2"
                        />
                      
                      </div>
                      <div className="flex lg:justify-end justify-center items-center">
                        <div>
                        <Link to="/" className="bg-[#312522] py-2 text-white px-4 my-5 ml-auto  rounded-3xl" type="submit">Notify me</Link>
                        </div>
                      </div>
                    </form>
            </div>
        </div>
       </div>
    )
}

export default Pro
import { useEffect, useRef, useState } from "react";
import PlatformSwiper from "../../../../view/components/collectionPagesComponents/PlatformSwiper"
import { useApi } from "../../../../api/api";
import { useDispatch, useSelector } from "react-redux";
import { trackDetail } from "../../../../store/trackSlice";
import Tracks from "../../../../view/components/common/TracksComponents/Tracks";
import { Link } from "react-router-dom";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import SongRecommendation from "../../../../view/components/Recommendation/SongRecommendation";
import BackToPage from "../../../../view/components/common/BacktoPage/BackToPage";
import { useDebouncedValue } from '@mantine/hooks';
import H220 from "../../../../view/components/common/Typograpgy/H220";
import { handleOpenLoader } from "../../../../store/loader";
import InfiniteScroll from 'react-infinite-scroll-component';
import { handleScrollStatus } from "../../../../store/scrollValueSlice";


const TrackPage = ()=>{
    const isFirstRender = useRef(true);
    const { handleGetRequest,handlePostRequest } = useApi();
    // const [selectedplatform, setSelectedPlatform] = useState(localStorage.getItem('selectedPlatformTrack') || "all");
    const [selectedplatform, setSelectedPlatform] = useState(localStorage.getItem('selectedPlatformTrack') ?? "all"
    );
    const [songId, setSongId ] = useState("")
    const [isliked, setIsliked ] = useState("")
    const [platforms, setPlatforms] = useState();
    const dispatch = useDispatch()
    const [searchQuery, setSearchQuery ] = useState('');
    const [debounced] = useDebouncedValue(searchQuery, 300);
    const [type, setType ] = useState("all")
    const [sort, setSort ] = useState("alphabetic")
    const isFirstDeleteRender = useRef(true);
    const [songDeleteId, setSongDeleteId ] = useState("")
    const trackData = useSelector((state) => state.tracks.trackData);
    const isScrollBottom = useSelector((state) => state.scroll.isScrollBottom);
    const [isFilterApplied, setIsFilterApplied] = useState(0)
    const [filter, setFilter] = useState()
    const [isLoading, setIsLoading] = useState(true);
    const [page, setpage] = useState(1);
    const [totalTracks, setTotalTracks] = useState(0);

    useEffect(()=>{
      
        localStorage.setItem('selectedPlatformTrack', selectedplatform);
      
    },[selectedplatform])


    useEffect(() => {
        ;(async () => {
            const platformDataReq = await handleGetRequest("playlist", false,true);
            setPlatforms(platformDataReq?.data)
        })();
    }, []);

    console.log("adasdaselectedplatform",selectedplatform);
    const isFirstRenderSetPage = useRef(true);

    useEffect(()=>{
      if (isFirstRenderSetPage.current) {
          // Skip the first render
          isFirstRenderSetPage.current = false;
          return;
        }

        console.log("totalTrackspage", totalTracks,page, totalTracks/10>page);
        

      if(totalTracks/10>page){
        setpage(page+1)

      }
      dispatch(handleScrollStatus(false))
    },[isScrollBottom])

    console.log("isScrollBottom",isScrollBottom);
    

    const isFirstRenderChangePlatform = useRef(true);

    useEffect(()=>{
      if (isFirstRenderChangePlatform.current) {
        // Skip the first render
        isFirstRenderChangePlatform.current = false;
        return;
      }

      dispatch(trackDetail([]))
      setpage(1)

    },[selectedplatform,type,searchQuery,sort])

    const isFirstRenderDebounced = useRef(true);


    // useEffect(()=>{
    //   if (isFirstRenderDebounced.current) {
    //     // Skip the first render
    //     isFirstRenderDebounced.current = false;
    //     return;
    //   }

    //   if(!debounced){
    //   dispatch(trackDetail([]))
    //   }
    // },[debounced])

   
    
    useEffect(()=>{
        if(page===0){
        dispatch(trackDetail([]))
        return
        }
        ;(async () => {
          if(isFilterApplied>0){

            const filterPlayload = {
              apply_special_filter:isFilterApplied>0?1:0,
              page:page,
              per_page:10,
              type:type,
              sort_type:sort,
              search_keyword:debounced,
              ...filter,
              // ...selectedFilterData
              }
              // const trackSongsRes = await handleGetRequest(`${selectedplatform==='all'?`track?page=1&per_page=1000&type=${type}&sort_type=${sort}&search_keyword=${debounced}`:`track?page=1&per_page=1000&type=${type}&sort_type=${sort}&search_keyword=${debounced}&e_platform_name=${selectedplatform}`}`,false,true)
              // localStorage.setItem('TrackData', JSON.stringify(trackSongsRes?.data?.data))
              // dispatch(trackDetail(trackSongsRes?.data?.data))
              const filterTrackRes = await handlePostRequest(`track`,filterPlayload, false, false);
              console.log("filterTrackRes", filterTrackRes?.data?.data);
              localStorage.setItem('TrackData', JSON.stringify(filterTrackRes?.data?.data))
              dispatch(trackDetail(filterTrackRes?.data?.data))
              setTotalTracks(filterTrackRes?.data?.total)

              setIsLoading(false);
          }else{
// console.log("count1",type,debounced,sort,selectedplatform,page );

            const filterPlayload = {
              apply_special_filter:isFilterApplied>0?1:0,
              page:page,
              per_page:10,
              type:type,
              sort_type:sort,
              search_keyword:debounced,
              ...filter,
              ...(selectedplatform !== 'all' && { e_platform_name: selectedplatform })
              }

              const filterTrackRes = await handlePostRequest(`track`,filterPlayload, false, false);
              const newTracks = filterTrackRes?.data?.data;
              setTotalTracks(filterTrackRes?.data?.total)
              const existingTracks = trackData || [];
              let updatedTracks;
              if(debounced){
                updatedTracks = newTracks;
                // localStorage.setItem('TrackData', JSON.stringify(updatedTracks))
                dispatch(trackDetail(updatedTracks))
              }else{
                localStorage.setItem('TrackData', JSON.stringify([]))
                updatedTracks = [...existingTracks, ...newTracks];
                // localStorage.setItem('TrackData', JSON.stringify(updatedTracks))
                dispatch(trackDetail(updatedTracks))
              }
              // const updatedTracks = [...existingTracks, ...newTracks];
              console.log("filterTrackRes", filterTrackRes?.data?.data);
              // localStorage.setItem('TrackData', JSON.stringify(updatedTracks))

              // setIsLoading(false);
          }
        })();
    },[type,debounced,sort,page])

    useEffect(() => {
        // Skip the first render
        if (isFirstRender.current) {
          isFirstRender.current = false;
          return;
        }
        
        (async () => {
          const trackLikeRes = await handleGetRequest(`song-favourite?id=${songId}&type=${isliked}`, true,false);
          if(trackLikeRes?.flag){

            if(isFilterApplied>0){
              const filterPlayload = {
                apply_special_filter:isFilterApplied>0?1:0,
                page:1,
                per_page:10,
                type:type,
                sort_type:sort,
                search_keyword:debounced,
                ...filter,
                }
              const filterTrackRes = await handlePostRequest(`track`,filterPlayload, false, false);
              console.log("filterTrackRes", filterTrackRes?.data?.data);
              localStorage.setItem('TrackData', JSON.stringify(filterTrackRes?.data?.data))
              dispatch(trackDetail(filterTrackRes?.data?.data))
              setIsLoading(false);
            }else{
              const trackSongsRes = await handleGetRequest(`${`track?page=1&limit=1000&type=${type}&sort_type=${sort}&search_keyword=${debounced}&e_platform_name=${selectedplatform}`}`,false,false)
              localStorage.setItem('TrackData', JSON.stringify(trackSongsRes?.data?.data))
              dispatch(trackDetail(trackSongsRes?.data?.data))
              setIsLoading(false);
            }
          }
        })();
      }, [isliked,songId]);

      const [isScreenWidthLessThan768, setIsScreenWidthLessThan768] = useState(false);



    useEffect(() => {
        const handleResize = () => {
            setIsScreenWidthLessThan768(window.innerWidth < 768);
        };

        // Initial check
        handleResize();

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Cleanup event listener
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

      useEffect(() => {
        // Skip the first render
        if (isFirstDeleteRender.current) {
          isFirstDeleteRender.current = false;
          return;
        }
        
        (async () => {
          const trackDeleteRes = await handleGetRequest(`remove-song/${songDeleteId}`, true,true);
          if(trackDeleteRes?.flag){
              const trackSongsRes = await handleGetRequest(`${`track?page=1&limit=1000&type=${type}&sort_type=${sort}&search_keyword=${debounced}&e_platform_name=${selectedplatform}`}`,false,true)
                localStorage.setItem('TrackData', JSON.stringify(trackSongsRes?.data?.data))
                dispatch(trackDetail(trackSongsRes?.data?.data))
                setIsLoading(false);
          }
        })();
      }, [songDeleteId]);


      console.log("trackData",filter);

if(!trackData){
  dispatch(handleOpenLoader())
  return(
    <div>
    {/* <BackToPage pageRoute="/" backTo="Home"/> */}
    <H220 className="">Music Platform</H220>
    <div className="my-4 ">
    <div>
                    {(platforms?.Platforms?.length>6 || isScreenWidthLessThan768) ?<div>
                        <PlatformSwiper
                            setSelectedPlatform={setSelectedPlatform}
                            selectedplatform={selectedplatform}
                            platforms={platforms}
                            setpage={setpage}
                        />
                    </div>
                    :
                    <div className="grid grid-cols-6 gap-x-5">
                        {platforms?.Platforms?.map((items,index)=>{
                            return(
                                <div key={index} onClick={() => {
                                    // setSelectedPlatform(platformList);
                                    setpage(0)
                                    setSelectedPlatform(items.v_name);
                                    // setPlaylistData([]);
                                  }}
                                  className={`h-[146px] mx-auto w-[130px] ${ (items?.v_name === selectedplatform)?"border-black dark:!border-white dark:!bg-black bg-white":""} border-[2px] p-1 rounded-lg cursor-pointer`}
                                  style={{
                                    backgroundColor: items?.v_color_code,
                                  }}>

                                    <div 
                                            style={{
                                            backgroundColor: items?.v_color_code,
                                            }}
                                            className='flex justify-center items-center h-full rounded-lg'
                                            >
                                            <div>
                                            <img src={items?.t_image} alt='' className='m-auto'/>
                                                <p className='text-white text-[14px] font-semibold text-center my-2'> {items?.id === "default"
                                                ? "Uploads"
                                                : items?.v_name}</p>
                                            </div>
                                            </div>
                                  </div>
                            )
                        })}
                    </div>}
                </div>
      </div>

    </div>
  )
}else{


  return(
      <div  className="px-2 lg:px-0">
           {/* <BackToPage pageRoute="/" backTo="Home"/> */}
           <H220 className="">Music Platform</H220>

           <div className="my-4 ">
          <div>
                    {(platforms?.Platforms?.length>6 || isScreenWidthLessThan768) ?<div>
                        <PlatformSwiper
                            setSelectedPlatform={setSelectedPlatform}
                            selectedplatform={selectedplatform}
                            platforms={platforms}
                            setpage={setpage}
                        />
                    </div>
                    :
                    <div className="grid grid-cols-6 gap-x-5">
                        {platforms?.Platforms?.map((items,index)=>{
                            return(
                                <div key={index} onClick={() => {
                                    // setSelectedPlatform(platformList);
                                    setpage(0)
                                    setSelectedPlatform(items.v_name);
                                    // setPlaylistData([]);
                                  }}
                                  className={`h-[146px] mx-auto w-[130px] ${ (items?.v_name === selectedplatform)?"border-black dark:!border-white dark:!bg-black bg-white":""} border-[2px] p-1 rounded-lg cursor-pointer`}
                                  style={{
                                    backgroundColor: items?.v_color_code,
                                  }}>

                                    <div 
                                            style={{
                                            backgroundColor: items?.v_color_code,
                                            }}
                                            className='flex justify-center items-center h-full rounded-lg'
                                            >
                                            <div>
                                            <img src={items?.t_image} alt='' className='m-auto'/>
                                                <p className='text-white text-[14px] font-semibold text-center my-2'> {items?.id === "default"
                                                ? "Uploads"
                                                : items?.v_name}</p>
                                            </div>
                                            </div>
                                  </div>
                            )
                        })}
                    </div>}
                </div>
          </div>

           {(trackData?.length<1 && type==="all" &&  searchQuery==="" && filter?.length<1) ? 
           <div>
          <div className="py-16 bg-white drop-shadow-lg w-full mx-auto flex justify-center dark:bg-[#151515] items-center rounded-xl">
              <p className="lg:text-xl text-lg font-semibold text-center lg:text-left dark:text-white">No Tracks are available! <Link to="/library" className="text-[#F1B942]">Upload</Link> or  <Link to="/library" className="text-[#F1B942]">Sync</Link> your Music service</p>
          </div>
          </div>
           :
           <div>
         
          <Tracks sort={sort} isLoading={isLoading} setIsFilterApplied={setIsFilterApplied} setFilter={setFilter} setSongDeleteId={setSongDeleteId} setSort={setSort} type={type} setType={setType} setSearchQuery={setSearchQuery} searchQuery={searchQuery} setIsliked={setIsliked} setSongId={setSongId} selectedplatform={selectedplatform}  setSelectedPlatform={setSelectedPlatform} setpage={setpage}/>
          </div>
           }
      </div>
  )
}

}

export default TrackPage




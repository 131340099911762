import React, { useEffect, useState } from 'react'
import BackToPage from '../../view/components/common/BacktoPage/BackToPage'
import { useDispatch, useSelector } from 'react-redux';
import { useApi } from '../../api/api';
import { trackDetail } from '../../store/trackSlice';
import Tracks from '../../view/components/common/TracksComponents/Tracks';
import CommonListing from '../../view/components/collectionPagesComponents/CommonListing';
import GlobalProfileListing from '../../view/components/GlobalProfileComponents/GlobalProfileListing';
import { useNavigate } from 'react-router';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import globalToast from '../../hooks/globalToast';

const GlobalSearch = () => {
    const [nav,setNav] = useState(1)
    const {handlePostRequest } = useApi();
    const [songId, setSongId ] = useState("")
    const [isliked, setIsliked ] = useState("")
    const [songDeleteId, setSongDeleteId ] = useState("")
    const [searchQuery, setSearchQuery ] = useState("")
    const [type, setType ] = useState("all")
    const [sort, setSort ] = useState("alphabetic")
    const [filter, setFilter] = useState()
    const [isFilterApplied, setIsFilterApplied] = useState()
    const [globalPlaylist, setGlobalPlaylist] = useState()
    const [globalProfile, setGlobalProfile] = useState()
    const [globalAlbums, setGlobalAlbums] = useState()
    const [globalArtists, setGlobalArtists] = useState()
    const trackData = useSelector((state) => state.tracks.trackData);

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const searchKeyword = useSelector((state) => state.search.searchValue);
    const suggValue = useSelector((state) => state.search.suggValue);

    console.log("sdadada",suggValue);
    useEffect(()=>{
          ;(async () => {
            
                const filterPlayload = {
                    search_keyword:searchKeyword || suggValue
                }

                const globalTrackRes = await handlePostRequest(`track/search`,filterPlayload, false, true);
                console.log("globalTrackRes", globalTrackRes);
                if(globalTrackRes?.flag){
                    localStorage.setItem('TrackData', JSON.stringify(globalTrackRes?.data?.tracks))
                    setGlobalPlaylist(globalTrackRes?.data?.playlists)
                    setGlobalProfile(globalTrackRes?.data?.profiles)
                    setGlobalAlbums(globalTrackRes?.data?.albums)
                    setGlobalArtists(globalTrackRes?.data?.artists)
                    dispatch(trackDetail(globalTrackRes?.data?.tracks))
                }else{
                    // toast.error(globalTrackRes?.msg)
                    globalToast(globalTrackRes?.msg,true)

                }
          })();
      },[searchKeyword,suggValue])




  return (
    <div>
    {/* <BackToPage pageRoute="/" backTo="Home"/> */}
    <div className="min-h-[300px]">
            <div className="grid lg:grid-cols-12 grid-cols-5  content-center text-center  text-[#312522] dark:!text-white text-xl font-light gap-x-8 my-4 px-3 ">

                <div className={`${trackData?.length>0?"":trackData?.length===0?"hidden":"animate-pulse w-10 h-5 bg-slate-400 rounded-2xl"}`}>
                <p className={`cursor-pointer ${nav===1?"text-[#F1B942] text-sm lg:text-base  font-bold":" text-sm lg:text-base  "} ${trackData?.length>0?"":"hidden"}`} onClick={()=>setNav(1)}>Tracks</p>
                </div>

                <div className={`${globalPlaylist?.length>0?"":globalPlaylist?.length===0?"hidden":"animate-pulse w-10 h-5 bg-slate-400 rounded-2xl"}`}>
                <p className={`cursor-pointer ${nav===2?"text-[#F1B942] text-sm lg:text-base  font-bold":" text-sm lg:text-base  "} ${globalPlaylist?.length>0?"":"hidden"}`} onClick={()=>setNav(2)}>Playlists</p>
                </div>

                <div className={`${globalArtists?.length>0?"":globalArtists?.length===0?"hidden":"animate-pulse w-10 h-5 bg-slate-400 rounded-2xl"}`}>
                <p className={`cursor-pointer ${nav===3?"text-[#F1B942] text-sm lg:text-base  font-bold":" text-sm lg:text-base  "} ${globalArtists?.length>0?"":"hidden"}`} onClick={()=>setNav(3)}>Artists</p>
                </div>


                <div className={`${globalAlbums?.length>0?"":globalAlbums?.length===0?"hidden":"animate-pulse w-10 h-5 bg-slate-400 rounded-2xl"}`}>
                <p className={`cursor-pointer ${nav===4?"text-[#F1B942]  text-sm lg:text-base font-bold":" text-sm lg:text-base  "} ${globalAlbums?.length>0?"":"hidden"}`} onClick={()=>setNav(4)}>Albums</p>
                </div>

                <div className={`${globalProfile?.length>0?"":globalProfile?.length===0?"hidden":"animate-pulse w-10 h-5 bg-slate-400 rounded-2xl"}`}>
                <p className={`cursor-pointer ${nav===5?"text-[#F1B942] text-sm lg:text-base  font-bold":" text-sm lg:text-base  "} ${globalProfile?.length>0?"":"hidden"}`} onClick={()=>setNav(5)}>Profiles</p>
                </div>
            </div>




            {globalProfile?.length<1 && globalAlbums?.length<1 && globalArtists?.length<1 && globalPlaylist?.length<1 && trackData?.length<1 ? <div className="py-16 bg-white dark:bg-[#151515] dark:text-white drop-shadow-lg w-full mx-auto flex justify-center items-center rounded-xl">
                <p className="lg:text-xl text-lg font-semibold text-center lg:text-left">
                   No Data Found </p>
            </div> :
            <div className="w-full h-[5px] my-5 bg-[#D9D9D9] grid  lg:grid-cols-12 grid-cols-5 ">
            <div className={`${nav===1?"bg-[#F1B942]":""} ${trackData?.length>0?"":"hidden"}`}></div>
            <div className={`${nav===2?"bg-[#F1B942]":""} ${globalPlaylist?.length>0?"":"hidden"}`}></div>
            <div className={`${nav===3?"bg-[#F1B942]":""} ${globalArtists?.length>0?"":"hidden"}`}></div>
            <div className={`${nav===4?"bg-[#F1B942]":""} ${globalAlbums?.length>0?"":"hidden"}`}></div>
            <div className={`${nav===5?"bg-[#F1B942]":""} ${globalProfile?.length>0?"":"hidden"}`}></div>
            </div>
            }

            {(nav===1&&trackData?.length>0) &&<Tracks filterId="" scope="globalSearch" setIsFilterApplied={setIsFilterApplied} setFilter={setFilter} sort={sort} setSongDeleteId={setSongDeleteId} setSort={setSort} type={type} setType={setType} setSearchQuery={setSearchQuery} searchQuery={searchQuery} setIsliked={setIsliked} setSongId={setSongId} />}

            {nav===2&& <CommonListing flag="collectionPage" listing={globalPlaylist} title="playlists" />}
            {nav===3&& <CommonListing flag="collectionPage" listing={globalArtists} title="artists" />}
            {nav===4&& <CommonListing flag="collectionPage" listing={globalAlbums} title="albums" />}
            {nav===5&& <GlobalProfileListing listing={globalProfile} title="" />}

            {/* {nav===1&&<CommonListing listing={details} title="Albums" prop="Artists"/>}
            {(nav===2)&&<Tracks filterId={filterId} scope={scope} setIsFilterApplied={setIsFilterApplied} setFilter={setFilter} sort={sort} setSongDeleteId={setSongDeleteId} setSort={setSort} type={type} setType={setType} setSearchQuery={setSearchQuery} searchQuery={searchQuery} setIsliked={setIsliked} setSongId={setSongId} />}
            {nav===3 && 
            <div className="rounded-lg bg-white mt-5 w-full h-auto drop-shadow-xl p-3">
                <h1 className="font-semibold"> {name} Bio:</h1>
                <p className="my-4">{parse(bio)}</p>
            </div>} */}

          
        </div>
    </div>
  )
}

export default GlobalSearch
import { createSlice } from "@reduxjs/toolkit";

// const stepper = JSON.parse(localStorage.getItem("stepper")) 

const initialState = {
   bluetoothDevice:""
};

// Slice
export const bluetoothDeviceSlice = createSlice({
  name: "bluetooth",
  initialState,
  reducers: {
    handleBluetoothDevice(state,action){
        state.bluetoothDevice = action.payload;
      },
  },
});

export const {handleBluetoothDevice} = bluetoothDeviceSlice.actions;

export default bluetoothDeviceSlice.reducer;

import { useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';


const CurioushitPlayer = ({playerRef,songs,currentSongIndex,isPlaying,isMuted,volume,nextSongHandler,handleProgress,handleDuration,toggleFullScreen,isMp3,trackData,prevSongHandler,setIsPlaying}) => {

  // const songUrl = JSON.parse(localStorage.getItem('playingCurrentSong'));

      // useEffect(()=>{
      //   if(songs[currentSongIndex]){
      //     localStorage.setItem('playingCurrentSong', JSON.stringify(songs[currentSongIndex]))
      //   }
      // },[currentSongIndex])


      // useEffect(() => {
      //   if ('mediaSession' in navigator) {
      //     console.log("navigator",navigator);
      //     const metadata = {
      //       title: songs?.t_title || '',
      //       artist: songs?.artistlist || '',
      //       album: songs?.albumlist || '',
      //       image: songs?.t_image ||""
      //     };
    
      //     if (songs?.t_image) {
      //       metadata.artwork = [
      //         { src: songs?.t_image, sizes: '96x96', type: 'image/png' },
      //         { src: songs?.t_image, sizes: '128x128', type: 'image/png' },
      //         { src: songs?.t_image, sizes: '192x192', type: 'image/png' },
      //         { src: songs?.t_image, sizes: '256x256', type: 'image/png' },
      //         { src: songs?.t_image, sizes: '384x384', type: 'image/png' },
      //         { src: songs?.t_image, sizes: '512x512', type: 'image/png' },
      //       ];
      //     }
    
      //     navigator.mediaSession.metadata = new MediaMetadata(metadata);

      //     // console.log("playerRef", playerRef.current.getCurrentTime());
    
      //     navigator.mediaSession.setActionHandler('play', () => playerRef.current?.getInternalPlayer().play());
      //     navigator.mediaSession.setActionHandler('pause', () => playerRef.current?.getInternalPlayer().pause());
      //     navigator.mediaSession.setActionHandler('previoustrack', () => prevSongHandler());
      //     navigator.mediaSession.setActionHandler('nexttrack', () => nextSongHandler());
      //     navigator.mediaSession.playbackState = playerRef.current.paused ? "paused" : "playing";
      //     // navigator.mediaSession.setPositionState({
      //     //   duration: playerRef.current.getDuration(),
      //     //   // playbackRate: playerRef.current.getCurrentTime(),
      //     //   position: playerRef.current.getCurrentTime()
      //     // });
      //     navigator.mediaSession.setActionHandler('seekto', (details) => {
      //             if (details.fastSeek && 'fastSeek' in playerRef.current) {
      //               playerRef.current.fastSeek(details.seekTime);
      //               return;
      //             }
      //             playerRef.current.currentTime = details.seekTime;
      //           });
      //       navigator.mediaSession.setActionHandler('seekbackward', (details) => {
      //         playerRef.current.currentTime = Math.max(playerRef.current.getCurrentTime() - (details.seekOffset || 10), 0);
      //       });
      
      //       navigator.mediaSession.setActionHandler('seekforward', (details) => {
      //         playerRef.current.currentTime = Math.min(playerRef.current.getCurrentTime() + (details.seekOffset || 10), playerRef.current.getDuration());
      //       });
      //   }

          
      // }, [ nextSongHandler, prevSongHandler,trackData]);




      const prevSongIndex = currentSongIndex - 1 >= 0? currentSongIndex - 1 : trackData?.length - 1;
      const nextSongIndex = (currentSongIndex + 1) % trackData?.length;
    
      // References for the preload players
      const prevPlayerRef = useRef(null);
      const nextPlayerRef = useRef(null);
    
      useEffect(() => {
        // Preload the next and previous songs
        if (prevPlayerRef?.current && nextPlayerRef?.current) {
          prevPlayerRef?.current?.getInternalPlayer()?.preload();
          nextPlayerRef?.current?.getInternalPlayer()?.preload();
        }
      }, [currentSongIndex]);

      const handleVisibilityChange = () => {
        if (document.hidden) {
          console.log("Document is now hidden");
          // Optionally, handle actions when the document is hidden
        } else {
          console.log("Document is now visible");
          setIsPlaying(true);
          localStorage.setItem('isPlaying', true);
           // Ensure playback continues when visible
        }
      };

      useEffect(() => {
        // const handleVisibilityChange = () => {
        //   if (document.hidden) {
        //     console.log("Document is now hidden");
        //     // Handle the case where the document is hidden
        //   } else {
        //     console.log("Document is now visible");
        //     // Handle the case where the document is visible
        //   }
        // };

        document.addEventListener("visibilitychange", handleVisibilityChange);

        return () => {
          document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
      }, []);

    
console.log("isPlaying",isPlaying);


    return(
      <>
        <ReactPlayer
        key={1}
        ref={playerRef}
        url={songs?.t_song_link}
        // url={songs[currentSongIndex]?.t_song_link}
        pip={true}
        playing={isPlaying}
        // onReady={handlePlayerReady}
        volume={isMuted ? 0 : volume}
        // controls={(songs?.e_platform==="Youtube Video" || songs?.e_platform==="DailyMotion" || songs?.e_platform==="Vimeo")?true:false}
        controls={true}
        onEnded={()=>nextSongHandler(songs[currentSongIndex]?.id)}
        onProgress={handleProgress}
        onDuration={handleDuration}
        progressInterval={100}
        onClick={toggleFullScreen}
        width={`${isMp3?"0%":"100%"}`}
        height={`${isMp3?"0px":"100%"}`}
      />

    <div style={{ display: 'none' }}>
        <ReactPlayer
          ref={prevPlayerRef}
          url={songs[prevSongIndex]?.t_song_link}
          playing={false}
        />
        <ReactPlayer
          ref={nextPlayerRef}
          url={songs[nextSongIndex]?.t_song_link}
          playing={false}
        />
      </div>
      </>
    )
}

export default CurioushitPlayer

import { lazy, Suspense, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
const Button2 = lazy(() => import('../../view/components/common/GlobalButtonLayout/Button2'));
const TableCard = lazy(() => import('../../view/components/HomepageComponents/TableCard'));
const PlayCards = lazy(() => import('../../view/components/HomepageComponents/PlayCards'));
const ImageCard = lazy(() => import('../../view/components/HomepageComponents/ImageCard'));
const HomeCard = lazy(() => import('../../view/components/HomepageComponents/HomeCard'));

function HomePage(){

    const forYou = [
        { 
            id:1,
            tracknumber:11,
            title:"Rewind The...",
            image: "COLLECTION_129"
        },

        { 
            id:2,
            tracknumber:15,
            title:"Rewind The...",
            image: "COLLECTION_390"
        },

        { 
            id:3,
            tracknumber:11,
            title:"Rewind The...",
            image: "COLLECTION_416"
        },

    ]

    const PopularNewReleases = [
        { 
            id:1,
            tracknumber:11,
            title:"Faith In The...",
            image: "image (20)"
        },

        { 
            id:2,
            tracknumber:15,
            title:"Faith In The...",
            image: "image (21)"
        },

        { 
            id:3,
            tracknumber:11,
            title:"Faith In The...",
            image: "image (23)"
        },

    ]

    const TodayTopHitsTable = [
        { 
            id:"01",
            listened:'1.2k',
            title:'Kesariya',
            subTitle:"Arijit Singh...",
            image: "kesar"
        },

        { 
            id:"02",
            listened:'1.2k',
            title:"Dil Se Re",
            subTitle:"Arijit Singh...",
            image: "Dilse1"
        },

        { 
            id:"03",
            listened:'1.2k',
            title:'Kesariya',
            subTitle:"Arijit Singh...",
            image: "kesar"
        },

    ]

    const PopularArtists = [
        { 
            id:"01",
            listened:'1.2k',
            title:'Udit Narayan',
            subTitle:"India",
            image: "udit-narayan"
        },

        { 
            id:"02",
            listened:'1.2k',
            title:"Anu Malik",
            subTitle:"India",
            image: "Anu Malik"
        },

        { 
            id:"03",
            listened:'1.2k',
            title:'Sonu Nigam',
            subTitle:"India",
            image: "Sonu Nigam-5"
        },

    ]

    useEffect(() => {
        const imageUrls = [
            '/images/HomeImages/spin-wheel.webp',
            // Add other image URLs to preload here
        ];

        imageUrls.forEach((url) => {
            const img = new Image();
            img.src = url;
        });
    }, []);
    return(
        <div className='p-3 '>
            <div className="lg:grid lg:grid-cols-12 gap-4 my-3">
            <Suspense fallback={<div>Loading...</div>}>
                <HomeCard title="Music For" className="col-span-7">
                <div className='grid grid-cols-3 my-3 gap-x-2 gap-y-2 '>
                    <Suspense fallback={<div>Loading...</div>}>
                    <Button2 className="lg:!w-[155px] !h-[55px] text-xs text-center !border-[#312522] hover:!border-[#F1B942] !border-[2px]  lg:!text-[18px] dark:!border-[#DDD1CE] dark:text-[#DDD1CE]">Energy</Button2>
                    </Suspense>
                    <Suspense fallback={<div>Loading...</div>}>
                    <Button2 className="lg:!w-[155px] !h-[55px] !border-[#312522] hover:!border-[#F1B942] !border-[2px] text-xs text-center lg:!text-[18px] dark:!border-[#DDD1CE] dark:text-[#DDD1CE]">Productivity</Button2>
                    </Suspense>
                    <Suspense fallback={<div>Loading...</div>}>
                    <Button2 className="lg:!w-[155px] !h-[55px] !border-[#312522] hover:!border-[#F1B942] !border-[2px] text-xs text-center lg:!text-[18px] dark:!border-[#DDD1CE] dark:text-[#DDD1CE]">Creativity</Button2>
                    </Suspense>
                    <Suspense fallback={<div>Loading...</div>}>
                    <Button2 className="lg:!w-[155px] !h-[55px] !border-[#312522] hover:!border-[#F1B942] !border-[2px] text-xs text-center lg:!text-[18px] dark:!border-[#DDD1CE] dark:text-[#DDD1CE]">Motivation</Button2>
                    </Suspense>
                    <Suspense fallback={<div>Loading...</div>}>
                    <Button2 className="lg:!w-[155px] !h-[55px]  !border-[#312522] hover:!border-[#F1B942] !border-[2px]text-xs text-center lg:!text-[18px] dark:!border-[#DDD1CE] dark:text-[#DDD1CE]">Wellness</Button2>
                    </Suspense>
                    <Suspense fallback={<div>Loading...</div>}>
                    <Button2 className="lg:!w-[155px] !h-[55px] !border-[#312522] hover:!border-[#F1B942] !border-[2px] text-xs text-center lg:!text-[18px] dark:!border-[#DDD1CE] dark:text-[#DDD1CE]">Therapy</Button2>
                    <Button2 className="lg:!w-[155px] !h-[55px] !border-[#312522] hover:!border-[#F1B942] !border-[2px] text-xs text-center lg:!text-[18px] dark:!border-[#DDD1CE] dark:text-[#DDD1CE]">Motivation</Button2>
                    <Button2 className="lg:!w-[155px] !h-[55px] !border-[#312522] hover:!border-[#F1B942] !border-[2px] text-xs text-center lg:!text-[18px] dark:!border-[#DDD1CE] dark:text-[#DDD1CE]">Wellness</Button2>
                    <Button2 className="lg:!w-[155px] !h-[55px] !border-[#312522] hover:!border-[#F1B942] !border-[2px] text-xs text-center lg:!text-[18px] dark:!border-[#DDD1CE] dark:text-[#DDD1CE]">Therapy</Button2>
                    </Suspense>
                    </div>
                </HomeCard>
                </Suspense>
                <Suspense fallback={<div>Loading...</div>}>
                <HomeCard title="Positive" className="col-span-5">
                    <div className='flex w-full justify-center items-center my-3 gap-x-3'>
                    <div className='w-[50%]'>
                    <LazyLoadImage
                        alt='' 
                        className='w-full h-full'
                        src='/images/HomeImages/spin-wheel.webp'
                        />
                    </div>
                    <div className='w-[50%]'>
                        <p className='text-sm font-normal text-black dark:text-[#FFFFFF]'>Lorem ipsum is placeholder text commonly used in the graphic.</p>
                    </div>
                </div>
                </HomeCard>
                </Suspense>
            </div>

            <div className="lg:grid lg:grid-cols-12 gap-4 my-3">
                <Suspense fallback={<div>Loading...</div>}>
                <HomeCard title="For you" className="col-span-7">
                    <PlayCards listing={forYou}/>
                </HomeCard>
                </Suspense>
                <Suspense fallback={<div>Loading...</div>}>
                 <HomeCard title="Today’s Top Hits" className="col-span-5">
                 <TableCard rowItems={TodayTopHitsTable}/>
                </HomeCard>
                </Suspense>
            </div>
            
            <div className="lg:grid lg:grid-cols-12 gap-4 my-3">
                <Suspense fallback={<div>Loading...</div>}>
                <HomeCard title="Popular New Releases" className="col-span-7">
                    <PlayCards listing={PopularNewReleases}/>
                </HomeCard>
                </Suspense>

                <Suspense fallback={<div>Loading...</div>}>
                 <HomeCard title="Popular Artists" className="col-span-5">
                 <TableCard rowItems={PopularArtists}/>
                </HomeCard>
                </Suspense>
            </div>

            <div className="lg:grid lg:grid-cols-12 gap-4 my-3">
                <Suspense fallback={<div>Loading...</div>}>
                <HomeCard title="Iconic Music" className="col-span-7">
                    <ImageCard image="isolated" className="bg-[#F1B942]"/>
                </HomeCard>
                </Suspense>
                <Suspense fallback={<div>Loading...</div>}>
                 <HomeCard title="Moods of Artists" className="col-span-5">
                    <ImageCard image="isolated" className="bg-[#EA6F54]"/>
                </HomeCard>
                </Suspense>
            </div>
        </div>
    )
}

export default HomePage
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
 filterValue:"all",
 selectedFilters:{},
 isFilterModal:false
};

// Slice
export const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    handleFilterValue(state,action){
        state.filterValue = action.payload;
      },

    handleSelectedFilter(state,action){
      state.selectedFilters = action.payload;
    },
    handleCloseFilterModal(state){
      state.isFilterModal = false;
    },
    handleOpenFilterModal(state){
      state.isFilterModal = true;
    },
  },
});

export const {handleFilterValue,handleSelectedFilter,handleCloseFilterModal,handleOpenFilterModal} = filterSlice.actions;

export default filterSlice.reducer;
import React, { useEffect, useRef, useState } from 'react'
import { useApi } from '../../api/api';
import Tracks from '../../view/components/common/TracksComponents/Tracks';
import { useDispatch } from 'react-redux';
import { trackDetail } from '../../store/trackSlice';
import { useDebouncedValue } from '@mantine/hooks';
import H220 from '../../view/components/common/Typograpgy/H220';
import BackToPage from '../../view/components/common/BacktoPage/BackToPage';


export const CreatePlaylist = () => {
    const storedPlaylistId = JSON.parse(localStorage.getItem('createPlaylistId'));
    const storedPlaylistScope = JSON.parse(localStorage.getItem('createPlaylistScope'));
    console.log("storedPlaylistId",storedPlaylistId)
    // const {  handlePostRequest } = useApi();
    const { handleGetRequest,handlePostRequest } = useApi();

    const dispatch = useDispatch()
    const [searchQuery, setSearchQuery ] = useState("")
  const [debounced] = useDebouncedValue(searchQuery, 300);
  const [type, setType ] = useState("all")
  const [sort, setSort ] = useState("alphabetic")
  const [isliked, setIsliked ] = useState("")
  const [isFilterApplied, setIsFilterApplied] = useState()
  const [filter, setFilter] = useState()
  const [songId, setSongId ] = useState("")
  const [songDeleteId, setSongDeleteId ] = useState("")

  console.log("type",type);
    useEffect(() => {
        const filterPlayload = {
            apply_special_filter: 1,
            scope:storedPlaylistScope,
            filter_id:storedPlaylistId,
            page: 1,
            type:type,
            sort_type:sort,
            search_keyword:debounced,
            per_page: 1000,
        };

        const fetchData = async () => {
            try {
                const filterTrackRes = await handlePostRequest(`track`, filterPlayload, false, true);
                // Handle the response here
                console.log("filterTrackRes",filterTrackRes?.data?.data);
                localStorage.setItem('TrackData', JSON.stringify(filterTrackRes?.data?.data))
                dispatch(trackDetail(filterTrackRes?.data?.data))
            } catch (error) {
                // Handle errors
                console.error('Error fetching data:', error);
            }
        };

        fetchData(); // Call the fetchData function when the component mounts

        // If you need to clean up, return a function from useEffect
        // Example: return () => cleanupFunction();
    }, [type,debounced,sort]);


    const isFirstDeleteRender = useRef(true);
    // const { handleGetRequest,handlePostRequest } = useApi();

    
    useEffect(() => {
        // Skip the first render
        if (isFirstDeleteRender.current) {
          isFirstDeleteRender.current = false;
          return;
        }
        
        (async () => {
          const trackDeleteRes = await handleGetRequest(`remove-song/${songDeleteId}`, true,true);
          if(trackDeleteRes?.flag){
              const trackSongsRes = await handleGetRequest(`${`track?page=1&limit=1000&type=${type}&sort_type=${sort}&search_keyword=${debounced}`}`,false,true)
                localStorage.setItem('TrackData', JSON.stringify(trackSongsRes?.data?.data))
                dispatch(trackDetail(trackSongsRes?.data?.data))
                // setIsLoading(false);
          }
        })();
      }, [songDeleteId]);
    
  return (
    <div>
        {/* <BackToPage pageRoute="/collection/manage-playlist" backTo="Manage Playlist"/> */}
        <H220 className="!px-2 ">Generate Playlist</H220>
        <Tracks filterId={storedPlaylistId} scope="multiple_playlists" setIsFilterApplied={setIsFilterApplied} setFilter={setFilter} sort={sort} setSongDeleteId={setSongDeleteId} setSort={setSort} type={type} setType={setType} setSearchQuery={setSearchQuery} searchQuery={searchQuery} setIsliked={setIsliked} setSongId={setSongId}/>
    </div>

  )
}

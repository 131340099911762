import React from 'react'
import { Link } from 'react-router-dom'

const CommunityCardHeader = ({title,routeUrl,count}) => {

  return (
    <div className='flex justify-between items-center'>
        <p className='text-base lg:text-xl text-[#312522] dark:text-white font-semibold'>{title} </p>
       { count && <Link to={routeUrl} className='text-sm lg:text-base text-[#A1A1A1] cursor-pointer'>See all</Link>}
    </div>
  )
}

export default CommunityCardHeader
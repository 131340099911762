import { createSlice } from "@reduxjs/toolkit";


// const trackDataLocal = JSON.parse(localStorage.getItem("TrackData")) || {};

const initialState = {
  payload:{}
};

const pushNotificationSlice = createSlice({
  name: "push",
  initialState,
  reducers: {
    handlePushNotificationPayload: (state, action) => {
      state.payload = action.payload;
    },
   
  },
});

export const {handlePushNotificationPayload} = pushNotificationSlice.actions;

export default pushNotificationSlice.reducer;

import { useForm } from "react-hook-form";
import H324 from "../common/Typograpgy/H324"
import InputField from "../common/Input/InputField";
import Label from "../common/Typograpgy/Label";
import Button1 from "../common/GlobalButtonLayout/Button1";
import DoneIcon from '@mui/icons-material/Done';
import { useApi } from "../../../api/api";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { profileDetail } from "../../../store/authenticationSlice";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { Calendar } from 'primereact/calendar';
import GlobalToastComponent from "../common/GlobalToastComponent/GlobalToastComponent";
import globalToast from "../../../hooks/globalToast";
import { DatePicker, Space } from 'antd';
import dayjs from 'dayjs';



const CommonAccountUI = ({title,name,dob,phoneNumber,emailId,profilePic}) => {
    const dispatch = useDispatch()
    const { register, handleSubmit, reset,watch, formState: { errors } } = useForm();
    const newPassword = watch("New Password")
    const confirmPassword = watch("Confirm Password")
    const [newPasswordMatch, setNewPasswordMatch] = useState()
    const [oldPasswordMatch, setOldPasswordMatch] = useState()
    const { handlePostRequest,handleGetRequest } = useApi();
    const isLengthValid = (password) => password?.length >= 8;
    const hasLowercase = (password) => /[a-z]/?.test(password);
    const hasUppercase = (password) => /[A-Z]/?.test(password);
    const hasNumber = (password) => /\d/?.test(password);
    const hasSpecialCharacter = (password) => /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/?.test(password);
    const [imageUrl, setImageUrl] = useState("");
    const [dateSave, setDateSave] = useState(null);
    const isDark = useSelector((state) => state.dark.isDarkValue);

    const [formatDate,setFormatDate] = useState()

    useEffect(()=>{
        const date = new Date(dateSave);
        const year = date.getFullYear();
        let month = date.getMonth() + 1; // Months are zero-based
        let day = date.getDate();
        month = month < 10 ? '0' + month : month;
        day = day < 10 ? '0' + day : day;
        setFormatDate(`${year}-${month}-${day}`)
    },[dateSave])

    // const handleImageChange = (event) => {
    //     const file = event.target.files[0];
    //     if (file) {
    //       const imageUrl = URL.createObjectURL(file);
    //       setImageUrl(imageUrl);
    //     }
    //   };

    const [imageFile, setImageFile] = useState(null);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setImageFile(file);
            const imageUrl = URL.createObjectURL(file);
            setImageUrl(imageUrl);
        }
    };

    useEffect(()=>{
        setNewPasswordMatch(newPassword)
    },[newPassword])

    useEffect(()=>{
        setOldPasswordMatch(confirmPassword)
    },[confirmPassword])

    const onSubmit = async (data) => {
        if(confirmPassword!==newPassword){
            // toast.error("Confirm Password Not Match!");
            // <GlobalToastComponent toastcontent="Confirm Password Not Match!"/>
            globalToast("Confirm Password Not Match!",true)
            return
        }
        // users/update-user-details
        const numString = data['Phone Number']?.toString(); // Convert the number to a string
            const digitCount = numString?.length;
        if(digitCount>13){
            // toast.error("Check your phone number")
            // <GlobalToastComponent toastcontent="Check your phone number"/>
            globalToast("Check your phone number",true)

            return
        }
        // const formData = new FormData();
        const profileUpdatePayload = {
            v_name:data.Name,
            d_dob:formatDate,
            v_mobile_number:data['Phone Number'],
            v_profile_pic:imageFile
          }

        //   formData.append('v_profile_pic', imageFile);

          const changePasswordPayload = {
            v_new_password:data['New Password'],
            v_old_password:data['Old Password']
          }

          if(title==="Personal information"){
            const profileUpdateRes = await handlePostRequest(`${'users/update-user-details'}`,profileUpdatePayload,true,true)
            const profileDataReq = await handleGetRequest("profile-detail",false,false)
            dispatch(profileDetail(profileDataReq?.data))
            reset()
          }

        if(title==="Password"){
            if(newPasswordMatch!==oldPasswordMatch){
                // toast.error("Password Not Match");
                globalToast("Password Not Match",true)

                    return;
              }
            const changePasswordRes = await handlePostRequest(`${'users/change-password'}`,changePasswordPayload,true,true)
            reset()
          }
    };

    const handlePhoneNumberChange = (event) => {
        const inputValue = event.target.value;
        if (/^\d*$/.test(inputValue)) {
            event.target.value = inputValue;
        } else {
            event.preventDefault();
        }
    };


    const handleChange = (date) => {
        setDateSave(date ? date.valueOf() : null); // `valueOf` converts date to timestamp
      };

      const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current > dayjs().endOf('day');
      };

    return(
        <div className="my-7 dark:bg-[#151515]">
                <H324 className="dark:text-[#DDD1CE] lg:!text-xl ">{title}</H324>
                <hr className="my-3 text-[#D8D8D8] w-[90%] dark:hidden"/>
                <div className="grid  lg:grid-cols-12  ">
                    <div className="lg:col-span-8 col-span-6 order-2 lg:!order-1">
                    {title==="Personal information"&&<div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="my-2 lg:flex justify-start gap-x-3 items-center w-full">
                            <Label className="lg:w-[30%] w-[40%] dark:text-[#DDD1CE]">Your name</Label>
                            <div className="lg:w-[70%] w-full">
                                <InputField 
                                    type="text" 
                                    placeholder="Your name" 
                                    name="Name" 
                                    register={register} 
                                    errors={errors}
                                    className="!w-full !bg-[#FAFAF8] !text-left px-3 "
                                    required={true}
                                    initialValue={name}
                                    styleProp="w-[100%]"
                                />
                                {errors && errors['Name'] && errors['Name'].type === 'required' && (
                                <p className="text-red-500 text-xs lg:text-sm">Please enter your name</p>
                                )}

                                {errors && errors['Name'] && errors['Name'].type === 'pattern' && (
                                    <p className="text-red-500 text-xs lg:text-sm">{"Name must meet given criteria"}</p>
                                )}
                            </div>
                        </div>
                        <div className="my-2 lg:flex justify-start gap-x-3 items-center w-full">
                            <Label className="lg:w-[30%] w-[40%] dark:text-[#DDD1CE]">Date of birth </Label>
                            <div className="lg:w-[70%] w-full">
                            {/* <InputField 
                                type="date" 
                                placeholder="" 
                                name="Date Of Birth" 
                                register={register} 
                                errors={errors}
                                required={true}
                                initialValue={dob}
                                className="!w-full !bg-[#FAFAF8] !text-left px-3  "
                                styleProp="w-[100%]"
                               
                            />
                                {errors && errors['Date Of Birth'] && errors['Date Of Birth'].type === 'required' && (
                                <p className="text-red-500 text-xs lg:text-sm">Date Of Birth is required</p>
                                )}

                                {errors && errors['Date Of Birth'] && errors['Date Of Birth'].type === 'pattern' && (
                                    <p className="text-red-500 text-xs lg:text-sm">{"Invalid Format"}</p>
                                )} */}
                            <div className="accountDate">
                            {/* <Calendar className=" !text-black  !py-3 dark:filter dark:invert" type="date" id="buttondisplay"  dateFormat="dd/mm/yy" value={dateSave} onChange={(e) => setDateSave(e.value)}   placeholder={dob}  showIcon showButtonBar /> */}

                            <DatePicker
                                onChange={handleChange}
                                name="d_dob"
                                // value={date ? moment(date) : null} // Ensure moment object is passed to DatePicker
                                format="DD-MM-YYYY"
                                className={`w-full rounded-full py-2 ${isDark?"dark-mode-picker":""}  text-center border-[2px] border-white focus:border-[2px] dark:!border-black bg-[#FAFAF8] rounded-[50px] dark:!bg-[#000000] dark:!text-[#C1C1C1] text-[#312522] text-[16px] font-normal py-2 my-1`}
                                placeholder="DD/MM/YYYY"
                                disabledDate={disabledDate}// Setting maxDate
                                // onKeyDown={handleKeyDown}
                                defaultValue={dayjs(dob, "YYYY-MM-DD")} 
                                getPopupContainer={(trigger) => trigger.parentElement}
                                required
                                />

                            {errors && errors['d_dob'] && errors['d_dob'].type === "required" && (
                            <p className="text-red-500 text-xs lg:text-sm">DOB is required</p>
                            )}
                                </div>
                            </div>
                        </div>

                        <div className="my-2 lg:flex justify-start gap-x-3 items-center w-full">
                            <Label className="lg:w-[30%] w-[40%] dark:text-[#DDD1CE]">Your email</Label>
                            <InputField 
                                type="text" 
                                placeholder="Your email" 
                                name="Email" 
                                register={register} 
                                errors={errors}
                                disable={true}
                                initialValue={emailId}
                                className="!w-full !bg-[#FAFAF8] !text-left px-3 "
                                styleProp="lg:w-[70%] w-[100%]"
                            />
                            
                        </div>
                        <div className="my-2 lg:flex justify-start gap-x-3 items-center w-full">
                            <Label className="lg:w-[30%] w-[40%] dark:text-[#DDD1CE]">Your phone no </Label>
                            <div className="lg:w-[70%] w-full">
                                <InputField 
                                    type="number" 
                                    inputmode="numeric"
                                    placeholder="Your phone number" 
                                    name="Phone Number" 
                                    register={register} 
                                    errors={errors}
                                    required={true}
                                    initialValue={phoneNumber}
                                    className="!w-full !bg-[#FAFAF8] !text-left px-3 "
                                    styleProp="w-[100%]"
                                    pattern={{
                                        value: /^(?:\+?\d{1,3}\s?)?(?:\d{10,13})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/,
                                        message: "Invalid Number Format"
                                    }}
                                    
                                />
                                {errors && errors['Phone Number'] && errors['Phone Number'].type === 'required' && (
                                <p className="text-red-500 text-xs lg:text-sm">Please enter your mobile number</p>
                                )}

                                {errors && errors['Phone Number'] && errors['Phone Number'].type === 'pattern' && (
                                    <p className="text-red-500 text-xs lg:text-sm">{"Phone Number must meet given criteria"}</p>
                                )}
                            </div>
                        </div>
                        
                        <div className="flex justify-end items-center">
                        <Button1 type="submit" className="lg:!w-[20%] !w-[40%] text-center ml-auto dark:hover:!text-[#F3C25A]">
                            Update    
                        </Button1>
                        </div>
                    </form>
                    </div>}

                    {title==="Password"&&<div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="my-2 lg:flex justify-start gap-x-3 items-center w-full">
                        <Label className="lg:w-[30%] w-[40%] dark:text-[#DDD1CE]">Old password</Label>
                        <div className="lg:w-[70%] w-full">
                        <InputField 
                            type="password" 
                            placeholder="Password" 
                            name="Old password" 
                            register={register} 
                            errors={errors}
                            required={true}
                            className="!w-full !bg-[#FAFAF8] "
                            styleProp=" w-[100%]"
                            pattern={{
                                value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
                                message: "Password must have at least 8 characters, one lowercase letter (a-z), one uppercase letter (A-Z), one number (0-9), and one special character (@, -, #)"
                                }}
                            />
                        {errors && errors['Old Password'] && errors['Old Password'].type === 'required' && (
                            <p className="text-red-500 text-xs lg:text-sm">Please enter the old password</p>
                        )}

                        {errors && errors['Old Password'] && errors['Old Password'].type === 'pattern' && (
                            <p className="text-red-500 text-xs lg:text-sm">{"Password must meet given criteria"}</p>
                        )}
                        </div>
                        </div>

                        <div className="my-2 lg:flex justify-start gap-x-3 items-center w-full">
                        <Label className="lg:w-[30%] w-[40%] dark:text-[#DDD1CE]">New password</Label>
                        <div className="lg:w-[70%] w-full">
                        <InputField 
                                type="password" 
                                placeholder="New password" 
                                name="New Password" 
                                register={register} 
                                errors={errors}
                                required={true}
                                className="!w-full !bg-[#FAFAF8] "
                                styleProp=" w-[100%]"
                                pattern={{
                                    value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
                                    message: "Password must have at least 8 characters, one lowercase letter (a-z), one uppercase letter (A-Z), one number (0-9), and one special character (@, -, #)"
                                    }}
                            />
                        {errors && errors['New Password'] && errors['New Password'].type === 'required' && (
                            <p className="text-red-500 text-xs lg:text-sm">Please enter new password</p>
                        )}

                        {errors && errors['New Password'] && errors['New Password'].type === 'pattern' && (
                            <p className="text-red-500 text-xs lg:text-sm">{"Password must meet given criteria"}</p>
                        )}
                        </div>
                        </div>
                        <div className="my-2 lg:flex justify-start gap-x-3 items-center w-full">
                        <Label className="lg:w-[30%] w-[40%] dark:text-[#DDD1CE]">Confirm password</Label>
                        <div  className="lg:w-[70%] w-full">
                            <InputField 
                                type="password" 
                                placeholder="Confirm password" 
                                name="Confirm Password" 
                                register={register} 
                                errors={errors}
                                required={true}
                                className="!w-full !bg-[#FAFAF8] "
                                styleProp=" w-[100%]"
                            />
                            {errors && errors['Confirm Password'] && errors['Confirm Password'].type === 'required' && (
                                <p className="text-red-500 text-xs lg:text-sm">Confirm Password is required</p>
                            )}

                            {errors && errors['Confirm Password'] && errors['Confirm Password'].type === 'pattern' && (
                                <p className="text-red-500 text-xs lg:text-sm">{"Password must meet given criteria"}</p>
                            )}
                        </div>
                        </div>
                        <div className="text-left w-full lg:w-[44%] mx-auto lg:pl-5 py-2 mt-2 mb-1 text-[12px]">
                            <p className={`  ${isLengthValid(newPassword)?"text-green-500":"dark:text-[#FFFFFF]"} `}><DoneIcon className={` ${isLengthValid(newPassword)?"text-green-500":"text-[#DEDEDE]"}`}/>At least 8 characters.</p> 
                            <p className={`  ${hasLowercase(newPassword)?"text-green-500":"dark:text-[#FFFFFF]"} `}><DoneIcon  className={` ${hasLowercase(newPassword)?"text-green-500":"text-[#DEDEDE]"}`}/>At least one lowercase letter (a-z).</p> 
                            <p className={`  ${hasUppercase(newPassword)?"text-green-500":"dark:text-[#FFFFFF]"} `}><DoneIcon  className={` ${hasUppercase(newPassword)?"text-green-500":"text-[#DEDEDE]"}`}/>At least one uppercaseletter (A-Z)</p> 
                            <p className={`  ${hasNumber(newPassword)?"text-green-500":"dark:text-[#FFFFFF]"} `}><DoneIcon  className={` ${hasNumber(newPassword)?"text-green-500":"text-[#DEDEDE]"}`}/>At least one number (0-9)</p> 
                            <p className={`  ${hasSpecialCharacter(newPassword)?"text-green-500":"dark:text-[#FFFFFF]"} `}><DoneIcon  className={` ${hasSpecialCharacter(newPassword)?"text-green-500":"text-[#DEDEDE]"}`}/>At least one special characters.(@-#)</p> 
                            </div>
                        <div className="flex justify-end items-center">
                            <Button1 type="submit" className="lg:!w-[20%] !w-[40%] text-center ml-auto dark:hover:!text-[#F3C25A]">
                                Save    
                            </Button1>
                        </div>
                    </form>
                    
                    </div>}
                    </div>
                    {/* {title==="View profile"&&<div className="lg:col-span-4 my-5 min-h-28 col-span-6 order-1 px-5 lg:order-2 relative">
                    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full">
                            <div className=" w-[150px] h-[150px] relative rounded-full">
                                <img
                                    src={imageUrl?imageUrl:profilePic}
                                    alt="Preview"
                                    className="w-full h-full object-cover rounded-full"
                                />
                                <div className="absolute bottom-0 left-[110px] min-w-10">
                                    <div className="relative">
                                    <input
                                        type="file"
                                        name="image"
                                        accept="image/*"
                                        onChange={handleImageChange}
                                        className="!opacity-0 w-7 absolute top-0"
                                    />
                                    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
                                    <circle cx="17.5" cy="17.5" r="16.5" fill="#F5F2F2" stroke="#312522" stroke-width="2"/>
                                    <path d="M15.871 12.5L14.371 14H11.5V23H23.5V14H20.629L19.129 12.5H15.871ZM15.25 11H19.75L21.25 12.5H24.25C24.4489 12.5 24.6397 12.579 24.7803 12.7197C24.921 12.8603 25 13.0511 25 13.25V23.75C25 23.9489 24.921 24.1397 24.7803 24.2803C24.6397 24.421 24.4489 24.5 24.25 24.5H10.75C10.5511 24.5 10.3603 24.421 10.2197 24.2803C10.079 24.1397 10 23.9489 10 23.75V13.25C10 13.0511 10.079 12.8603 10.2197 12.7197C10.3603 12.579 10.5511 12.5 10.75 12.5H13.75L15.25 11ZM17.5 22.25C16.406 22.25 15.3568 21.8154 14.5832 21.0418C13.8096 20.2682 13.375 19.219 13.375 18.125C13.375 17.031 13.8096 15.9818 14.5832 15.2082C15.3568 14.4346 16.406 14 17.5 14C18.594 14 19.6432 14.4346 20.4168 15.2082C21.1904 15.9818 21.625 17.031 21.625 18.125C21.625 19.219 21.1904 20.2682 20.4168 21.0418C19.6432 21.8154 18.594 22.25 17.5 22.25ZM17.5 20.75C18.1962 20.75 18.8639 20.4734 19.3562 19.9812C19.8484 19.4889 20.125 18.8212 20.125 18.125C20.125 17.4288 19.8484 16.7611 19.3562 16.2688C18.8639 15.7766 18.1962 15.5 17.5 15.5C16.8038 15.5 16.1361 15.7766 15.6438 16.2688C15.1516 16.7611 14.875 17.4288 14.875 18.125C14.875 18.8212 15.1516 19.4889 15.6438 19.9812C16.1361 20.4734 16.8038 20.75 17.5 20.75Z" fill="#312522"/>
                                    </svg>
                                    </div>
                                </div>
                                <div>
                                </div>
                            </div>
                    </div>
                    </div>} */}
                </div>
        </div>
    )
}

export default CommonAccountUI
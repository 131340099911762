import { useCallback, useEffect, useState } from "react";
// import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { useApi } from "../../../api/api";
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import H220 from "../common/Typograpgy/H220";
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import H130 from "../common/Typograpgy/H130";
import Button1 from "../common/GlobalButtonLayout/Button1";
import CheckIcon from '@mui/icons-material/Check';




function Stepper3({setSelectedPlatform,selectedPlatform,setProfessionalType,professionalType,selectedItems,setSelectedItems,syncSpecificPlaylist,isSyncSpecificPlaylistCard,selectedPlaylists,setSelectedPlaylists,handleSyncSpecificPlaylistSync,setInputValue,inputValue, isMusicProfessional,setIsMusicProfessional}){
  const { handleGetRequest, handlePostRequest } = useApi();

  console.log("professionalType",professionalType);
  

  const handleInputChange = (e) => {
    console.log("e.target.value",e.target.value);
    
    setInputValue(e.target.value);
    // setUll(e.target.value);
  }

  // console.log("ull",ull);
  

  const handleRowSelect = (id) => {
    console.log("plllid", id);

    setSelectedPlaylists(prev =>
      prev.includes(id) ? prev.filter(item => item !== id) : [...prev, id]
    );
  };

    // const navigate = useNavigate();
    // const storeData = useSelector((state) => state.counter);

    // const onOtherDetailsChnageHandler = useCallback((e) => {
    //   setOtherDetails(e.target.value);
    // }, []);

    // const onPlatformChangeHandler = useCallback(
    //     (e, name) => {
    //       if (!!e.target.checked && !selectedPlatform.includes(name)) {
    //         setPlatFormsSelected((prev) => {
    //           return [...prev, name];
    //         });
    //       } else if (!e.target.checked && selectedPlatform.includes(name)) {
    //         setPlatFormsSelected((prev) => {
    //           const prevPlatformData = [...prev];
    
    //           const platformResponse = prevPlatformData.filter((items) => {
    //             return items !== name;
    //           });
    
    //           return platformResponse;
    //         });
    //       }
    //     },
    //     [selectedPlatform]
    //   );

    //   const onProfessionalMusicHandle = (details) => {
    //     if (details === "yes") {
    //       setProfessionalMusic("Yes");
    //     } else if (details === "no") {
    //       setProfessionalMusic("No");
    //     }
    //   };

    //   const onMusicProfessionalChange = useCallback(
    //     (e, name) => {
    //       if (!!e.target.checked && !selectedMusicProfessional?.includes(name)) {
    //         setSelectedMusicProfessional((prev) => {
    //           return [...prev, name];
    //         });
    //       } else if (
    //         !e.target.checked &&
    //         selectedMusicProfessional?.includes(name)
    //       ) {
    //         setSelectedMusicProfessional((prev) => {
    //           const prevMusicProfessional = [...prev];
    //           const musicProfessional = prevMusicProfessional.filter((items) => {
    //             return items !== name;
    //           });
    //           return musicProfessional;
    //         });
    //       }
    //     },
    //     [selectedMusicProfessional]
    //   );

    const [syncList, setSyncList] = useState([])
  

    useEffect(() => {
      (async () => {
        const syncedListRes = await handleGetRequest(`sync`, false, true);
        if(syncedListRes?.flag){
            setSyncList(syncedListRes?.data)
        }
      })();
    }, []);

    const platformSelected = (platform) => {
      console.log("platform",platform);
      setSelectedPlatform(platform)
      localStorage.setItem('platform', JSON.stringify(platform));

    }



   

 const handleRadioChange = (event) => {
  console.log("ulllsdcs",event.target.value );
    setIsMusicProfessional(event.target.value === 'yes');
    if(event.target.value === 'no'){
      setProfessionalType({
        artist: false,
        curator: false,
        producer: false,
        educator: false,
        other: false,
      });
    }
  
 };

 const handleSwitchChange = (event) => {
    setProfessionalType({
      ...professionalType,
      [event.target.name]: event.target.checked,
    });
 };
 console.log("professionalType",professionalType);



 const instruments = [
   { id: 2, src: '/guitar-instrument1.png', alt: 'guitar-instrument1' },
   { id: 5, src: '/drum.png', alt: 'drum' },
   { id: 3, src: '/piano.png', alt: 'piano' },
   { id: 4, src: '/violin.png', alt: 'violin' }
 ];

 const handleSelect = (id) => {
   setSelectedItems((prevSelectedItems) =>
     prevSelectedItems.includes(id)
       ? prevSelectedItems.filter(item => item !== id)
       : [...prevSelectedItems, id]
   );
 };

 console.log("selectedItems",selectedItems);
 const isDark = useSelector((state) => state.dark.isDarkValue);

 const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#312522' : '#F1B942',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
    isDark ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const handleChange = (event,para) => {
  console.log("vvcpara",para,event);
  // para==="notification" && setNotificationEnabled(event.target.checked);
  // para==="communitynotification" && setCommunityEnabled(event.target.checked);
  // para==="dark" &&  setDarkThemeEnabled(event.target.checked)
  // para==="privacy" &&  setPrivacyEnabled(event.target.checked)
  setProfessionalType({
    ...professionalType,
    [event.target.name]: event.target.checked,
  });
};

console.log("proffessd",professionalType);


    return(
      <div>
        <div>
        <h2 className="text-[16px] lg:text-xl font-medium mb-4 dark:text-white"> What music services are you using ?</h2>
        <div className={`flex justify-between items-center  ${syncList?.length<2?"justify-center":"justify-between"} flex-wrap  gap-x-4 gap-y-3 px-2 max-w-2xl mx-auto my-16` }>
              {syncList?.map((item)=>{
                  return(
                      <div className="cursor-pointer rounded-full w-24 h-24 flex text-white text-xs  justify-center text-center items-center flex-col relative" style={{backgroundColor:`${item?.v_color_code}`}} onClick={()=>platformSelected(item?.v_name) }>
                          <div >
                          <img src={item?.t_image} alt="" className="mx-auto my-1" />
                          <p className="px-3">{item?.v_name}</p>
                          </div>
                          {item?.v_name===selectedPlatform && <div className="bg-black flex flex-col justify-center items-center absolute top-0 w-full h-full rounded-full bg-opacity-60">
                          <DoneOutlinedIcon className="!text-6xl"/>
                          </div>}
                      </div>
                  )
              })}
          </div>
        </div>

          {(syncSpecificPlaylist?.length>0 && isSyncSpecificPlaylistCard ) && <div className="w-full my-3 flex flex-col justify-center items-center  bg-white h-auto py-10 relative dark:bg-[#151515] rounded-2xl">
               <div className="w-full">
                    {/* <HighlightOffIcon className="absolute top-3 right-3 cursor-pointer dark:text-[#A1A1A1]" onClick={()=>setIsSyncCard(false)}/> */}
                    <H130 className="text-center text-xl lg:text-3xl my-4 dark:text-[#A1A1A1]">Select your Playlists</H130>
                    <div className={` px-2 lg:px-10 py-2 my-5 w-full max-h-72 overflow-y-auto dark:text-white`}>
                       {syncSpecificPlaylist?.map((item, index) => {
                               const isSelected = selectedPlaylists.includes(item.id);
                               return (
                                 <div key={index} className="flex justify-between items-center py-2 px-6 rounded-xl border-[1px] border-[#A8A8A8] w-full my-3 drop-shadow-lg">
                                    <div className="flex justify-start items-center gap-3">
                                    <div className="w-10 h-10 rounded-full ">
                                        <img src={item?.t_image} alt="" className="object-cover rounded-full w-full h-full"/>
                                    </div>
                                    <div>
                                    <p className="text-sm lg:text-xl font-semibold capitalize dark:text-white">{item?.v_name}</p>
                                    <p className="text-xs lg:text-base font-normal dark:text-white">{item?.e_platform} : {item?.v_total_songs} Tracks</p>
                                    </div>
                                    </div>

                                   <label className="relative flex items-center p-1 rounded-full cursor-pointer">
                                     <input
                                       type="checkbox"
                                       className="peer hidden"
                                       id={item.id}
                                       onChange={() => handleRowSelect(item.id)}
                                       checked={isSelected}
                                     />
                                     <CheckIcon className="text-white dark:text-[#151515] peer-checked:!text-white border-[1px] border-black dark:border-white peer-checked:bg-[#F1B942] !text-base !rounded-sm absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4" />
                                   </label>
                                 </div>
                               );
                             })}
                    </div>

                    <div className="flex justify-between items-center w-full mx-auto gap-x-4 mt-10">
                        <Button1 onClick={()=>handleSyncSpecificPlaylistSync()} className="lg:!w-32 mx-auto text-center !w-[90%] dark:hover:!text-[#F1B942]">Sync</Button1>
                    </div>
                </div>
            </div>}

          <div>
          <h2 className="text-[16px] lg:text-xl font-medium mb-4 dark:text-white">What are your favorite Instruments ?</h2>
          {/* <div className="flex justify-start items-center gap-x-10 my-10">
            <div className="w-20 h-20 bg-[#F1B942] border-[1px] flex justify-center items-center border-[#F1B942] rounded-full">
              <img src="/guitar-instrument1.png" alt="guitar-instrument1" className="" />
            </div>
            <div className="w-20 h-20 bg-[#F1B942] border-[1px] flex justify-center items-center border-[#F1B942] rounded-full">
              <img src="/drum.png" alt="drum" className="" />
            </div>
            <div className="w-20 h-20 bg-[#F1B942] border-[1px] flex justify-center items-center border-[#F1B942] rounded-full">
              <img src="/piano.png" alt="piano" className="" />
            </div>
            <div className="w-20 h-20 bg-[#F1B942] border-[1px] flex justify-center items-center border-[#F1B942] rounded-full">
              <img src="/violin.png" alt="violin" className="" />
            </div>
          </div> */}
          <div className="flex justify-start items-center gap-x-5 lg:gap-x-10 my-10">
          {instruments.map((instrument) => (
            <div
              key={instrument.id}
              className={`w-20 h-20 border-[1px] flex justify-center items-center rounded-full cursor-pointer ${
                selectedItems.includes(instrument.id) ? 'bg-[#F1B942] border-[#F1B942]' : 'bg-transparent border-[#F1B942]'
              }`}
              onClick={() => handleSelect(instrument.id)}
            >
              <img src={instrument.src} alt={instrument.alt} className="dark:fliter dark:invert" />
            </div>
          ))}
          </div>
          </div>

          <div className="p-4">
          <h2 className="text-[16px] lg:text-xl font-medium mb-4 dark:text-white">Are you a music professional?</h2>
          <div className="mb-4">
            <label className="inline-flex items-center mr-6">
              <input
                type="radio"
                className="form-radio appearance-none w-4 h-4 border-[2px] border-[#D9D9D9] checked:bg-[#F1B942] rounded-full"
                name="musicProfessional"
                value="yes"
                checked={isMusicProfessional}
                onChange={handleRadioChange}
              />
              <span className="ml-2 dark:text-[#D9D9D9]">Yes</span>
            </label>
            <label className="inline-flex items-center">
              <input
                type="radio"
                className="form-radio form-radio appearance-none w-4 h-4 border-[2px] border-[#D9D9D9] checked:bg-[#F1B942] rounded-full"
                name="musicProfessional"
                value="no"
                checked={!isMusicProfessional}
                onChange={handleRadioChange}
              />
              <span className="ml-2 dark:text-[#D9D9D9]">No</span>
            </label>
          </div>
      {isMusicProfessional && (
        <div className="mb-4">
          <div className="grid grid-cols-1 gap-5">
            {Object.keys(professionalType).map((type) => (
              <div key={type} className="mr-4 mb-2 flex justify-between items-center h-10">
                 <span class="ms-3 text-sm mx-2 font-medium text-gray-900 dark:text-gray-300">{type.charAt(0).toUpperCase() + type.slice(1)}</span>

                 <AntSwitch  
                  checked={professionalType[type]}
                  name={type}
                  onChange={(e)=>handleChange(e,type)} 
                  className="dark:accent-slate-400"
                  // onChange={(e)=>handleSwitchChange(e)}
                  inputProps={{ 'aria-label': 'ant design' }} />

                 {/* <label class="inline-flex items-center cursor-pointer">
                  <input type="checkbox" name={type} checked={professionalType[type]} value="" class="sr-only peer"  onChange={handleSwitchChange} />
                  <div class="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#F1B942]"></div>
                 
                </label> */}
              </div>
            ))}
          </div>
        </div>
      )}
      {professionalType?.other && 
      <div className="flex justify-end items-center">
        {/* <input type="text" className="w-56 py-2 px-2 rounded-2xl border-[1px] border-black" /> */}
        <input
        type="text"
        className="w-56 py-2 px-2 mr-4 rounded-2xl border-[1px] border-black"
        value={inputValue}
        onChange={(e)=>handleInputChange(e)}
      />
      </div>
      }
    
    </div>
      </div>
    )
}

export default Stepper3




     //   <Row className="">
      //   <Col sm="12">
      //     <ul className="platform-list">
      //       {genreStepper?.map((platformList) => {
      //         return (
      //           <li class="spotify quiz_card_area">
      //             <input
      //               class="quiz_checkbox"
      //               onChange={(e) =>
      //                 onPlatformChangeHandler(e, platformList?.v_name)
      //               }
      //               checked={selectedPlatform.includes(
      //                 platformList?.v_name
      //               )}
      //               type="checkbox"
      //               name="t_preferred_platforms[]"
      //               id={platformList?.id_name}
      //               value="Spotify"
      //               autocomplete="off"
      //               data-gtm-form-interact-field-id="0"
      //             />
      //             <div
      //               class="spotify-bg single_quiz_card"
      //               style={{ backgroundColor: platformList?.v_color_code }}
      //             >
      //               <span class="platform-icon">
      //                 <img src={platformList?.t_image} alt="platform" />
      //               </span>
      //               <span class="check-icon">
      //                 <svg
      //                   xmlns="http://www.w3.org/2000/svg"
      //                   width="38"
      //                   height="29"
      //                   viewBox="0 0 38 29"
      //                   fill="none"
      //                 >
      //                   <path
      //                     d="M38 3.04922L11.9429 29L0 17.1059L3.06171 14.0567L11.9429 22.8799L34.9383 0L38 3.04922Z"
      //                     fill="white"
      //                   />
      //                 </svg>
      //               </span>
      //               <span class="platform-name">
      //                 {platformList?.v_name.split(" ")?.map((text) => {
      //                   return (
      //                     <>
      //                       {text} <br />
      //                     </>
      //                   );
      //                 })}
      //               </span>
      //             </div>
      //           </li>
      //         );
      //       })}
      //     </ul>

      //     {/* <div style={{display:'flex' , justifyContent:"center", alignItems:"center"}}>
      //       {platformList?.map((platform)=>{
      //         return(
      //             <div style={{borderRadius:'100%', backgroundColor:{platform.v_color_code}}} >
      //               <img src={platform.t_image} alt={platform.v_name}  />
      //             </div>
      //         )
      //       })}
      //     </div> */}

      //     <div style={{display:'flex', justifyContent:'center', alignItems:'center', flexWrap:'wrap', gap:'10px'}} className="my-16">
      //       {platformList?.map((platform) => (
      //         <div
      //           key={platform.v_name} // Make sure to provide a unique key for each element in the list
      //           onClick={()=>setSelectedPlatformList(platform.v_name)}
      //           style={{
      //             borderRadius: '50%', // or '100%' for a full circle
      //             backgroundColor: platform.v_color_code, // Assuming v_color_code is a valid color code
      //             width:'80px', // Add padding for better visual appearance
      //             marginLeft:'5px',
      //             marginRight:'5px',
      //             display:'flex',
      //             justifyContent:'center',
      //             height:'80px',
      //             alignItems:'center',
      //             cursor:'pointer',
      //             position: 'relative', // Ensure the backdrop position is relative to the selected platform
                 

      //         // Apply the backdrop style if the platform is selected
             

      //           }}
      //           className="platformlist-mobile"
      //         >
      //            {selectedPlatformList === platform.v_name && (
      //           <div
      //             style={{
      //               position: 'absolute',
      //               top: 0,
      //               left: 0,
      //               right: 0,
      //               bottom: 0,
      //               backgroundColor: '#00000080',
      //               borderRadius: '50%',
      //               zIndex: 1,
      //               display:'flex',
      //               justifyContent:'center',
      //               alignItems:'center'
      //             }}
      //           >
      //             <img src="/Vector.png" alt="hhj" /> 
      //           </div>
      //         )}
      //           <div style={{textAlign:'center'}}>
      //           <img src={platform.t_image} alt={platform.v_name} style={{  marginLeft:'auto',
      //             marginRight:'auto',}} />
      //           <p className="platformText text-[8px]">{platform.v_name}</p>
      //             </div>
      //         </div>
      //       ))}
      //     </div>
      //     <div className="music-platform-professional px-3" >
      //       <span className="stepper3Title">
      //       Are you a music Professional ?
      //       </span>
      //       <div className="music-professional-selection" style={{display:'flex', gap:'15px', marginTop:'30px', marginBottom:'30px'}}>
      //         <div
      //           className="music-professional-label"
      //           onClick={() => onProfessionalMusicHandle("yes")}
      //         >
      //           <input
      //             type="radio"
      //             id="yes"
      //             className="default-radio-btn"
      //             checked={professionalMusic === "Yes" ? true : false}
      //           />
      //           <label
      //             className="mb-0 title music-professional-selection-title mx-1"
      //             htmlFor="yes"
      //           >
      //             Yes
      //           </label>
      //           <div
      //             className={`professional-radio-btn ${
      //               professionalMusic === "Yes" &&
      //               "professional-radio-btn-checked"
      //             }`}
      //           ></div>
      //         </div>
      //         <div
      //           className="music-professional-label"
      //           onClick={() => onProfessionalMusicHandle("no")}
      //         >
      //           <input
      //             type="radio"
      //             id="no"
      //             className="default-radio-btn"
      //             checked={professionalMusic === "No" ? true : false}
      //           />
      //           <label
      //             className="mb-0 title music-professional-selection-title  mx-1"
      //             htmlFor="no"
      //           >
      //             No
      //           </label>
      //           <div
      //             className={`professional-radio-btn ${
      //               professionalMusic === "No" &&
      //               "professional-radio-btn-checked"
      //             }`}
      //           ></div>
      //         </div>
      //       </div>
      //       {professionalMusic === "Yes" && (
      //         <Row style={{marginBottom:'25px'}}>
      //           <Col
      //             sm="6"
      //             md="4"
      //             xs="6"
      //             className="musicprofessional-items-conatiner"
      //           >
      //             <div className="music-professional-items" style={{margin:'10px', justifyContent:'space-between', display:'flex', gap:'5px'}}>
      //               <span className="music-professional-switch-label">
      //                 Artist
      //               </span>
      //               <div class="custom-control custom-switch">
      //                 <input
      //                   type="checkbox"
      //                   onChange={(e) =>
      //                     onMusicProfessionalChange(e, "Artist")
      //                   }
      //                   checked={selectedMusicProfessional?.includes(
      //                     "Artist"
      //                   )}
      //                   class="custom-control-input"
      //                   id="Artist"
      //                 />
      //                 <label
      //                   class="custom-control-label"
      //                   htmlFor="Artist"
      //                 ></label>
      //               </div>
      //             </div>
      //             <div className="music-professional-items " style={{margin:'10px', justifyContent:'space-between', display:'flex', gap:'5px'}}>
      //               <span className="music-professional-switch-label">
      //                 Producer
      //               </span>
      //               <div class="custom-control custom-switch">
      //                 <input
      //                   type="checkbox"
      //                   onChange={(e) =>
      //                     onMusicProfessionalChange(e, "Producer")
      //                   }
      //                   checked={selectedMusicProfessional?.includes(
      //                     "Producer"
      //                   )}
      //                   class="custom-control-input"
      //                   id="Producer"
      //                 />
      //                 <label
      //                   class="custom-control-label"
      //                   htmlFor="Producer"
      //                 ></label>
      //               </div>
      //             </div>
      //           </Col>
      //           <Col
      //             sm="6"
      //             md="4"
      //             xs="6"
      //             className="musicprofessional-items-conatiner"
      //           >
      //             <div className="music-professional-items" style={{margin:'10px', justifyContent:'space-between', display:'flex', gap:'5px'}}>
      //               <span className="music-professional-switch-label">
      //                 curator
      //               </span>
      //               <div class="custom-control custom-switch">
      //                 <input
      //                   type="checkbox"
      //                   onChange={(e) =>
      //                     onMusicProfessionalChange(e, "Curator")
      //                   }
      //                   checked={selectedMusicProfessional?.includes(
      //                     "Curator"
      //                   )}
      //                   class="custom-control-input"
      //                   id="Curator"
      //                 />
      //                 <label
      //                   class="custom-control-label"
      //                   htmlFor="Curator"
      //                 ></label>
      //               </div>
      //             </div>
      //             <div className="music-professional-items" style={{margin:'10px', justifyContent:'space-between', display:'flex', gap:'5px'}}>
      //               <span className="music-professional-switch-label">
      //                 Educator
      //               </span>
      //               <div class="custom-control custom-switch">
      //                 <input
      //                   type="checkbox"
      //                   onChange={(e) =>
      //                     onMusicProfessionalChange(e, "Educator")
      //                   }
      //                   checked={selectedMusicProfessional?.includes(
      //                     "Educator"
      //                   )}
      //                   class="custom-control-input"
      //                   id="Educator"
      //                 />
      //                 <label
      //                   class="custom-control-label"
      //                   htmlFor="Educator"
      //                 ></label>
      //               </div>
      //             </div>
      //           </Col>
      //           <Col
      //             sm="6"
      //             md="4"
      //             className="musicprofessional-items-conatiner"
      //           >
      //             <div className="music-professional-items" style={{margin:'10px', justifyContent:'space-between', display:'flex', gap:'5px'}}>
      //               <span className="music-professional-switch-label">
      //                 other
      //               </span>
      //               <div class="custom-control custom-switch">
      //                 <input
      //                   type="checkbox"
      //                   onChange={(e) =>
      //                     onMusicProfessionalChange(e, "Other")
      //                   }
      //                   checked={selectedMusicProfessional?.includes(
      //                     "Other"
      //                   )}
      //                   class="custom-control-input"
      //                   id="Other"
      //                 />
      //                 <label
      //                   class="custom-control-label"
      //                   htmlFor="Other"
      //                 ></label>
      //               </div>
      //             </div>
      //             {selectedMusicProfessional?.includes("Other") && (
      //               <input
      //                 onChange={onOtherDetailsChnageHandler}
      //                 value={otherDetails}
      //                 className="form-control other-input-field"
      //                 placeholder="Other"
      //                 type="text"
      //               />
      //             )}
      //           </Col>
      //         </Row>
      //       )}
      //     </div>
      //   </Col>
      // </Row>
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
 isModalId:""
};

// Slice
export const shareModalGlobalSlice = createSlice({
  name: "shareModal",
  initialState,
  reducers: {
    handleShareModal(state,action){
        state.isModalId = action.payload
      },
  },
});

export const {handleShareModal} = shareModalGlobalSlice.actions;

export default shareModalGlobalSlice.reducer;
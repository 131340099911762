import { useState } from "react";
import Pills from "./Pills";
import CommonListing from "./CommonListing";
import Tracks from "../common/TracksComponents/Tracks";
import parse from 'html-react-parser';

function DetailBottomSection({songDetails,details,setType,setSort,setSearchString,fetchPlaylistData,bio,name,sort,setSongDeleteId,type,setSearchQuery,searchQuery,setIsliked,setSongId,setIsFilterApplied,setFilter,filterId,scope,setpage,page}){
    const [nav,setNav] = useState(bio?1:2)    
    return(
        <>
       
        <div className="min-h-[300px]">
            <div className="grid lg:grid-cols-12 grid-cols-3  content-center text-center  text-[#312522] text-xl font-light gap-x-4 my-4">
                {bio && <p className={`cursor-pointer ${nav===1?"text-[#F1B942]  font-bold":"dark:text-white"}`} onClick={()=>setNav(1)}>Bio</p>}
                <p className={`cursor-pointer ${nav===2?"text-[#F1B942]  font-bold":"dark:text-white"}`} onClick={()=>setNav(2)}>Albums</p>
                <p className={`cursor-pointer ${nav===3?"text-[#F1B942]  font-bold":"dark:text-white"}`} onClick={()=>setNav(3)}>Tracks</p>
               
               
            </div>
            <div className="w-full h-[5px] bg-[#D9D9D9] grid  lg:grid-cols-12 grid-cols-3 ">
                {bio && <div className={`${nav===1?"bg-[#F1B942]":""}`}></div>}
                <div className={`${nav===2?"bg-[#F1B942]":""}`}></div>
                <div className={`${nav===3?"bg-[#F1B942]":""}`}></div>
            </div>

            {nav===2&&<CommonListing flag="collectionPage" listing={details} title="Albums" prop="Artists"/>}
            {(nav===3)&&<Tracks filterId={filterId} scope={scope} setIsFilterApplied={setIsFilterApplied} setFilter={setFilter} sort={sort} setSongDeleteId={setSongDeleteId} setSort={setSort} type={type} setType={setType} setSearchQuery={setSearchQuery} searchQuery={searchQuery} setIsliked={setIsliked} setSongId={setSongId} setpage={setpage} page={page}/>}
            {(nav===1 && bio )&&
            <div className="rounded-lg bg-white dark:bg-[#151515] dark:text-white mt-5 w-full h-auto drop-shadow-xl p-3">
                <h1 className="font-semibold"> {name} Bio:</h1>
                <p className="my-4">{parse(bio)}</p>
            </div>}
        </div>
        </>
    )
}

export default DetailBottomSection
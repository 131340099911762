import { Link, useLocation, useNavigate } from "react-router-dom"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";


const BackToPage = ({pageRoute,backTo}) => {
    const isFilter = useSelector((state) => state.tracks.isFilter);
    const isScrolledDown = useSelector((state) => state.scroll.scrollValue);
    const loginSidebar = useSelector((state) => state.sidebar.isloginSidebar);
    const navigate = useNavigate()

    // const [isScrolledDown, setIsScrolledDown] = useState(false);

    // console.log("isScrolledDown",isScrolledDown);

    // useEffect(() => {
    //     const handleScroll = () => {
    //         const isDown = window.scrollY > 0;
    //         setIsScrolledDown(isDown);
    //     };

    //     window.addEventListener('scroll', handleScroll);
    //     return () => window.removeEventListener('scroll', handleScroll);
    // }, []);

    // console.log("loginSidebar",loginSidebar);
    
    // const location = useLocation();
    // const [routerhistory,setRouterHistory] = useState("/")

    // useEffect(()=>{
    //     console.log("adasdalocation",location);
    //     setRouterHistory(location.pathname)
    // },[location])

    const history = useNavigate();
    function handlenavigation(){
        navigate("/")
    }


    return(
        // <div  className={`flex ${loginSidebar?"hidden lg:block":"sticky"} top-[50px] lg:top-[55px] bg-[#FAFAF8] dark:bg-[#0D0D0D] ${(isFilter)?"z-0":"z-[21]"} justify-start items-center gap-2 !px-2 lg:px-0 text-[#D8D8D8] dark:text-[#808080]`}>
        //     {isScrolledDown ? (
        //         <Link className="cursor-pointer" to={pageRoute}><ArrowBackIcon /> {backTo}</Link>
        //     ) : (
        //         <Link className="cursor-pointer" to={pageRoute}><ArrowBackIcon /></Link>
        //     )}
        // </div>

        // <div  className={`flex ${loginSidebar?"hidden lg:block":"sticky"}  top-[50px] lg:top-[55px] bg-[#FAFAF8] dark:bg-[#0D0D0D] ${(isFilter)?"z-0":"z-[21]"} justify-start items-center gap-2 !px-2 lg:px-0 text-[#D8D8D8] dark:text-[#808080]`}>
        <div  className={`p-2 lg:w-[82%] dark:bg-[#0D0D0D] ${isFilter?"z-0 lg:z-10 dark:lg:z-[32]":"z-[1]"} right-0 top-[50px] fixed w-full lg:px-6  ml-auto bg-[#FAFAF8] lg:pl-5`}>
        {isScrolledDown ? (
            // <p onClick={()=>history(-1)} className="cursor-pointer"><ArrowBackIcon /> {backTo}</p>
            <p onClick={()=>pageRoute==="/"?handlenavigation():history(-1)} className="cursor-pointer"><ArrowBackIcon  className="dark:!text-[#808080]"/> </p>
            // <p onClick={()=>history(-1)}>back</p>
        ) : (
            <p className="cursor-pointer" onClick={()=>pageRoute==="/"?handlenavigation():history(-1)}><ArrowBackIcon  className="dark:!text-[#808080]"/></p>
            // <p  >back</p>
        )}
        </div>
    )
}

export default BackToPage
// GenderInput.js
import React from 'react';

const GenderInput = ({ register, errors }) => {
  return (
    <div className="flex justify-center items-center">
      <div className='flex justify-center items-center'>
        <input
          type="radio"
          id="male"
          value="Male"
          className={`appearance-none w-4 h-4 border-[2px] ${errors && errors['e_gender'] ?"border-red-700":"border-[#D9D9D9]"} border-[#D9D9D9] checked:bg-[#F1B942] rounded-full`}
          {...register('e_gender', { required: 'Gender is required' })}
        />
        <label htmlFor="male" className="mx-2 my-0 py-0 dark:text-white">Male</label>

        <input
          type="radio"
          id="female"
          value="Female"
          className={`appearance-none w-4 h-4 border-[2px]  ${errors && errors['e_gender'] ?"border-red-700":"border-[#D9D9D9]"} checked:bg-[#F1B942] rounded-full`}
          {...register('e_gender', { required: 'Gender is required' })}
        />
        <label htmlFor="female" className="mx-2 my-0 py-0 dark:text-white">Female</label>
      </div>
      {/* {errors && errors['e_gender'] && (
        <p className="text-red-500">{errors['e_gender'].message}</p>
      )} */}
    </div>
  );
}

export default GenderInput;

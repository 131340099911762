import React from 'react'
import CommonListing from '../../collectionPagesComponents/CommonListing'

const PlaylistsComponent = ({albumList,handleDeletePlaylist}) => {
  return (
    <div>
       <CommonListing fromprofile="/collection/playlists"  listing={albumList} title="playlists" flag="" handleDeletePlaylist={handleDeletePlaylist}/>
    </div>
  )
}

export default PlaylistsComponent
import { useState } from "react"

function StepperTitle({title,setStep,step,handlestepsOnTop}){
    return(
        <div className="flex justify-between items-center my-2 px-2 ">
            <h2 className="text-black text-lg lg:text-[24px] font-medium  my-1 dark:text-white">{title}</h2>
            <div className="flex justify-evenly items-center p-1 gap-2">
                <button onClick={()=>handlestepsOnTop(1)} className={`w-[25px] h-[25px] rounded-full ${step===1?"bg-[#F1B942] text-white":"bg-[#D8D8D8] text-black"}`}>1</button>
                <button onClick={()=>handlestepsOnTop(2)} className={`w-[25px] h-[25px] rounded-full ${step===2?"bg-[#F1B942] text-white":"bg-[#D8D8D8] text-black"}`}>2</button>
                <button onClick={()=>handlestepsOnTop(3)} className={`w-[25px] h-[25px] rounded-full ${step===3?"bg-[#F1B942] text-white":"bg-[#D8D8D8] text-black"}`}>3</button>

            </div>
        </div>
    )
}

export default StepperTitle
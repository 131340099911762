import React, { useEffect, useRef, useState } from 'react'
import BackToPage from '../../view/components/common/BacktoPage/BackToPage'
import H220 from '../../view/components/common/Typograpgy/H220'
import H130 from '../../view/components/common/Typograpgy/H130'
import Button1 from '../../view/components/common/GlobalButtonLayout/Button1'
import Button2 from '../../view/components/common/GlobalButtonLayout/Button2'
import CommonCard from '../../view/components/MusicAnalyzerComponent/CommonCard'
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import axios from 'axios'; // Import axios library
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux'
import { handleCloseLoader, handleOpenLoader } from '../../store/loader'
import { useApi } from '../../api/api'
import { trackDetail } from '../../store/trackSlice'
import Tracks from '../../view/components/common/TracksComponents/Tracks'
import globalToast from '../../hooks/globalToast'


const MusicAnalyzer = () => {
    const authenticationToken = JSON.parse(localStorage.getItem("AuthToken"))
    const [step,setStep] = useState(1)
    const jsmediatags = window.jsmediatags;
    const dispatch = useDispatch()
    const [files, setFiles] = useState([]);
    const { handleGetRequest } = useApi();
    const [metadata, setMetadata] = useState(null);
    const [isUploaded, setIsUploaded] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [uploadProgress, setUploadProgress] = useState(0);
    const [cancelTokenSource, setCancelTokenSource] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadSongsData,setUploadSongsData] = useState([])
    const [isFilterApplied, setIsFilterApplied] = useState()
    const [filter, setFilter] = useState()
    const sectionRef = useRef(null);
    const [sort, setSort] = useState("alphabetic")
    const [type, setType] = useState("all")
    const [searchQuery, setSearchQuery] = useState("")
    const [isliked, setIsliked] = useState("")
    const [songId, setSongId] = useState("")
    const [songDeleteId, setSongDeleteId] = useState("")



    useEffect(() => {
        // Reset the cancel token source when the component mounts or when necessary
        setCancelTokenSource(axios.CancelToken.source());
        return () => {
            // Cancel the request if the component unmounts
            if (cancelTokenSource) {
                cancelTokenSource.cancel();
            }
        };
    }, []);

    const handleFileChange = async(e) => {
        const selectedFiles = Array.from(e.target.files);
        if (selectedFiles?.length <= 2) {
            setFiles(selectedFiles);
            setStep(3)
            setErrorMessage("");

            selectedFiles.forEach((file) => {
                jsmediatags.read(file, {
                  onSuccess: function (tag) {
                    console.log("Metadata for", file.name, ":", tag.tags);
                    setMetadata(tag.tags)
                    // Here you can handle the metadata as needed
                    // For example, you might want to display it or store it in state
                  },
                  onError: function (error) {
                    console.log("Error reading metadata for", file.name, ":", error);
                    // Handle errors, e.g., by setting an error message
                  },
                });
              });
        } else {
            setErrorMessage("Please select only up to 2 files.");
        }

        
    };

    const handleUpload = async() => {
        setStep(4);
        // console.log("musiccccc",files[0].name);
        if (files[0].name.includes(".mp3") || files[0].name.includes(".mp4")) {
            console.log("File is an MP3 or MP4.");
            // Your code for handling MP3 or MP4 files
        } else {
            console.log("File is not an MP3 or MP4.");
            // toast.error("File is not an MP3 or MP4.");
            globalToast("File is not an MP3 or MP4.",true)

            setStep(2)
            return;
            // Your code for handling other file types
        }
        const formData = new FormData();
        formData.append(`music_files`, files[0]);
        formData.append(`i_upload_count`, files?.length);

        try {
            const uploadRes = await axios.post('https://curioushit.nevinainfotech.in/api/v1.0/upload', formData, {
                headers: {
                        Authorization: `Bearer ${authenticationToken} `,
                        'Content-Type': 'multipart/form-data',
                },
                cancelToken: cancelTokenSource.token, // Pass the cancel token here
                onUploadProgress: progressEvent => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress(percentCompleted);
                    if(percentCompleted>99){
                        dispatch(handleOpenLoader())
                    }
                },

               
            });

            if(!uploadRes?.data?.flag){
                // toast.error(uploadRes?.data?.msg);
               globalToast(uploadRes?.data?.msg,true)
                
                setStep(2)
            }

            console.log("uploadRes",uploadRes);
            if (uploadRes?.data?.flag) {
                const uploadTracksRes = await handleGetRequest(`track?e_platform_name=default`, false,true);
                if(uploadTracksRes?.flag){
                    setUploadSongsData(uploadTracksRes?.data)
                    dispatch(trackDetail(uploadTracksRes?.data?.data))
                }
                setIsUploading(false);
                setStep(5)
                setIsUploaded(true)
                const uploadedFile = files[0]; // Assuming only one file is uploaded
             

                // Handle successful upload
            }
            dispatch(handleCloseLoader())

        } catch (error) {
            console.error("Upload Error:", error);
            // toast.error(error);
            globalToast(error?.message?error?.message:"Something went wrong !!",true)

            
            setStep(2)
            dispatch(handleCloseLoader())

            // Handle upload error
        } finally {
            setIsUploading(false);
            dispatch(handleCloseLoader())

        }
    };
    const handleCancelUpload = () => {
        // Step 3: Create a cancel function
        if (cancelTokenSource) {
            cancelTokenSource.cancel("Upload canceled by the user.");
        }
    };

    console.log("setMetadata",metadata);

  return (
    <div>
         {/* <BackToPage pageRoute="/" backTo="Home"/> */}
         <H220 className="">Music Analyzer</H220>

         {step===1 && <CommonCard>
         <div className="">
                <H130 className="text-center !text-lg lg:!text-3xl dark:!text-[#FFFFFF] my-3">Hi, Ameer !</H130>
                <p className='text-base font-normal my-2 dark:!text-[#FFFFFF]'>Now  you can view  your favorite song music  notation </p>
                <p className='text-base font-medium my-2 dark:!text-[#FFFFFF]'>You are Just one step away</p>
                
                <div className='flex justify-center items-center gap-x-5 my-10'>
                    <Button2 onClick={()=>setStep(2)} className="!px-5 !w-36 !text-[#F1B942] hover:!text-white">New Music</Button2>
                    <Button2 onClick={()=>setStep(5)} className="!px-5 !w-36 !text-[#F1B942] hover:!text-white">Saved Music</Button2>
                </div>
                <div className='text-sm font-medium text-[#A1A1A1] my-10'>
                    <p>*Click on new Music to upload a new Songs </p>
                    <p>*Click on Saved Music to view your uploaded Songs </p>
                </div>
            </div>
         </CommonCard>}

         {step===2 && <CommonCard>
         <div className="">
                <H130 className="text-center !text-lg lg:!text-3xl dark:!text-[#FFFFFF] my-3">Upload Your Music</H130>
                
                <div className='flex justify-center items-center gap-x-5 my-10'>
                <label htmlFor="music-upload" className="">
                <div  className="lg:w-[100px]  lg:my-0 my-2 text-left lg:!text-center  lg:px-0  h-[40px]  hover:bg-[#F1B942] rounded-[50px] text-[16px] font-normal  border-[#F1B942] !px-5 !w-36 border-[1px] !text-[#F1B942] hover:!text-white flex justify-center items-center cursor-pointer">Select Files</div>
                        <input
                            id="music-upload"
                            type="file"
                            accept=".mp3,.mp4"
                            // accept="audio/mp3"
                            className="hidden"
                            onChange={handleFileChange}
                            multiple
                        />
                    </label>
                    <Button2 onClick={()=>setStep(1)} className="!px-5 !w-36 !text-[#F1B942] hover:!text-white">Cancel</Button2>
                </div>
                <div className='text-sm font-medium text-[#A1A1A1] my-10'>
                    <p>*Click on new Music to upload a new Songs </p>
                    <p>*Click on Saved Music to view your uploaded Songs </p>
                </div>
            </div>
         </CommonCard>}

         {step===3 && <CommonCard>
         <div className="">
                <H130 className="text-center !text-lg lg:!text-3xl dark:!text-[#FFFFFF] my-3">Upload 1 File To This Site ?</H130>
                <div className='flex justify-center items-center gap-x-5 my-10'>
                    <Button2 onClick={()=> handleUpload()} className="!px-5 !w-36 !text-[#F1B942] hover:!text-white">Upload</Button2>
                    <Button2 onClick={()=>setStep(2)} className="!px-5 !w-36 !text-[#F1B942] hover:!text-white">Cancel</Button2>
                </div>
                <div className='text-sm font-medium text-[#A1A1A1] my-10'>
                    <p>*Click on new Music to upload a new Songs </p>
                    <p>*Click on Saved Music to view your uploaded Songs </p>
                </div>
            </div>
         </CommonCard>}

         {step===4 && <CommonCard>
            <div className="mt-4 px-4">
                <H130 className="text-center text-lg lg:text-[32px] dark:text-white">Uploading your music...</H130>
                <div className="w-full bg-gray-200 mt-8">
                    <div
                        className="bg-[#F1B942] flex justify-between items-center text-xs leading-none py-[1px] text-center text-white px-5 "
                        style={{ width: `${uploadProgress}%` }}
                    >
                    </div>
                </div>
                <div className="flex justify-between items-center text-xs mt-1 lg:text-lg dark:text-white">
                    <div>{uploadProgress===100?1:0} of {files?.length} Tracks</div>
                    <div>{uploadProgress}%</div>
                </div>
                <div className="flex justify-center items-center my-4 dark:text-white">
                    <div>
                    <div className="line-clamp-1  text-clip"><span className="text-base font-bold ">Song Title : </span>{metadata?.title}</div>
                    <div className="line-clamp-1  text-clip"><span className="text-base font-bold  ">Artist Name : </span>{metadata?.artist}</div>
                    <div className="line-clamp-1 text-clip"><span className="text-base font-bold  ">Album Name: </span>{metadata?.album}</div>
                    <div><span className="text-base font-bold ">Year: </span>{metadata?.year}</div>
                    <div className=" line-clamp-1 text-clip"><span className="text-base font-bold ">Genre: </span>{metadata?.genre}</div>
                    {/* <div><span className="text-base font-bold">Decades:</span>{{metadata?.artist}}</div>
                    <div><span className="text-base font-bold">Geos:</span>{{metadata?.artist}}</div> */}
                    </div>
                </div>
                <div className="flex justify-center items-center">
                <Button2 onClick={() => {setStep(3); handleCancelUpload()}} className=" !w-[50%] text-center dark:text-white"><HighlightOffOutlinedIcon className="dark:!text-white"/> Cancel</Button2>
                </div>
            </div>    
        </CommonCard>}
        {step===5 &&<CommonCard><div ref={sectionRef}> <Tracks setIsFilterApplied={setIsFilterApplied} setFilter={setFilter} setSort={setSort} type={type} setType={setType} setSearchQuery={setSearchQuery} searchQuery={searchQuery} setIsliked={setIsliked} setSongId={setSongId} setStep={setStep} isMusicAnalyzer={true} setSongDeleteId={setSongDeleteId}/> </div></CommonCard>}

        {step===6 && <CommonCard>
        <div>
            <H130 className="text-center text-lg lg:text-[32px] dark:text-white">Analyzing Your Music Notation</H130>
            <div className="w-full  mt-8">
                    <div
                        className="bg-[#F1B942] flex justify-between items-center text-xs leading-none py-[1px] text-center text-white px-5 "
                        style={{ width: `${uploadProgress}%` }}
                    >
                    </div>
                    <div className="flex justify-between items-center text-xs lg:text-lg mt-1 dark:text-white">
                        <div>{uploadProgress===100?1:0} of {files?.length} Tracks</div>
                        <div>{uploadProgress}%</div>
                    </div>
                    <Button2 onClick={()=> setStep(7)} className="!px-5 mt-5 !w-36 !text-[#F1B942] hover:!text-white">View Result</Button2>
                </div>
        </div>    
        </CommonCard>}

        {step===7 && <CommonCard>
        <div>
            <H130 className="text-center text-lg lg:text-[32px] dark:text-white">This Song Contains</H130>
            <div className="w-full  mt-8">
                    <div>

                    </div>
                    <Button2 onClick={()=> setStep(8)} className="!px-5 mt-5 !w-36 !text-[#F1B942] hover:!text-white">Get Notes</Button2>
                </div>
        </div>    
        </CommonCard>}

        {step===8 && <CommonCard>
        <div>
        <H130 className="text-center text-lg lg:text-[32px] dark:text-white">Pdf</H130>

        </div>    
        </CommonCard>}

    </div>
  )
}

export default MusicAnalyzer
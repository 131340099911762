import React, { useRef, useState } from 'react'
import CommunityTabComponent from '../CommunityTabComponent'
import { Outlet } from 'react-router'
import SearchComponent from '../SearchComponent';
import BackToPage from '../../common/BacktoPage/BackToPage';
import H220 from '../../common/Typograpgy/H220';
import Button1 from '../../common/GlobalButtonLayout/Button1';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

const CommunityLayoutGlobal = () => {
 
  return (
    <div>
      {/* <BackToPage pageRoute="/" backTo="Home" /> */}
      <CommunityTabComponent/>
      <SearchComponent />
      <Outlet/>
      <div className='h-[30vh] lg:h-[70vh] flex flex-col '>
            <div className="flex flex-col drop-shadow-xl rounded-xl justify-center p-5 lg:h-auto w-[90vw] lg:w-full h-52 text-center !bg-white mx-auto dark:!bg-[#151515]">
                <H220 className="!text-base lg:!text-xl ">Connect to people you know to discover their music collection</H220>
                <div className='lg:flex justify-center items-center my-4 gap-x-5'>
                <Button1 className="!text-center  !bg-[#3871E4] !text-white !w-64 hover:!text-white !py-2" ><FacebookIcon/> Connect Facebook</Button1>
                <Button1 className="!text-center  !bg-[#C837AB] !text-white !w-64 hover:!text-white !py-2" ><InstagramIcon/> Connect Instagram</Button1>
            </div>
          </div>
        </div>
    </div>
  )
}

export default CommunityLayoutGlobal

import { useEffect, useRef, useState } from "react";
import { useApi } from "../../../../api/api";
import { useLocation, useParams } from "react-router";
import CommonDetail from "../../../../view/components/collectionPagesComponents/CommonDetail";
import { trackDetail } from "../../../../store/trackSlice";
import { useDispatch } from "react-redux";
import BackToPage from "../../../../view/components/common/BacktoPage/BackToPage";
import { useDebouncedValue } from '@mantine/hooks';
import { handleCurrentSongIndex, handlePlayerStatus } from "../../../../store/playerSlice";


function GenresDetail(){
  const location = useLocation();
  const parts = location.pathname.split("/");
  const { id } = useParams()
  const dispatch = useDispatch()
  const { handleGetRequest,handlePostRequest } = useApi();
  const [genresDetailData, setGenresDetailData ] = useState()
  const [searchQuery, setSearchQuery ] = useState("")
  const [debounced] = useDebouncedValue(searchQuery, 300);
  const [type, setType ] = useState("all")
  const [sort, setSort ] = useState("alphabetic")
  const [isliked, setIsliked ] = useState("")
  const [songId, setSongId ] = useState("")
  const [songDeleteId, setSongDeleteId ] = useState("")
  const detailTitles = ["Playlists","Artists","Tracks","Genres"]
  const isFirstRender = useRef(true);
  const isFirstDeleteRender = useRef(true);
  const [isFilterApplied, setIsFilterApplied] = useState()
  const [filter, setFilter] = useState()
  const [Like, setLike] = useState();


  console.log("isFilterApplied",isFilterApplied);
  console.log("filteration",filter);

   
  useEffect(() => {
    if(isFilterApplied>0){
      return
    }
    ;(async () => {
        const genresDetailDataReq = await handleGetRequest(`genres/${id}`, false,true);
        setGenresDetailData(genresDetailDataReq?.data)
        setLike(genresDetailDataReq?.data?.is_liked)
      })();
  }, [id]);

    useEffect(()=>{
        ;(async () => {
            const genresSongsRes = await handleGetRequest(`${`genre-song-data/${id}?type=${type}&search_keyword=${debounced}&sort_type=${sort}&limit=10`}`,false,true)
            localStorage.setItem('TrackData', JSON.stringify(genresSongsRes?.data?.data))
            dispatch(trackDetail(genresSongsRes?.data?.data))
            if(genresSongsRes?.flag){
              const params = new URLSearchParams(location.search);
              if (params.get('play') === 'true' && (type==="all" ) ) {
                localStorage.setItem('playingCurrentSong',  JSON.stringify(genresSongsRes?.data?.data?.length>0&&genresSongsRes?.data?.data[0]))
                dispatch(handlePlayerStatus(genresSongsRes?.data?.data?.length>0?genresSongsRes?.data?.data[0]?.e_platform:false));
                dispatch(handleCurrentSongIndex(genresSongsRes?.data?.data?.length>0?0:-1))
              }
            }
        })();
    },[type,debounced,sort])

    useEffect(() => {
      // Skip the first render
      if (isFirstRender.current) {
        isFirstRender.current = false;
        return;
      }
      
      (async () => {
        // const trackLikeRes = await handleGetRequest(`song-favourite?id=${songId}&type=${isliked}`, true,false);
        let trackLikeRes
        if(songId) {
          trackLikeRes = await handleGetRequest(`song-favourite?id=${songId}&type=${isliked}`, true,false);
          return
        }

        if(songDeleteId){
          const filterPlayload = {
            apply_special_filter:1,
            page:1,
            per_page:1000,
            scope:parts[parts.length - 2],
            filter_id:genresDetailData?.id,
            type:type,
            search_keyword:debounced,
            ...filter
            }

          const trackDeleteRes = await handleGetRequest(`remove-song/${songDeleteId}`, true,true);
          if(trackDeleteRes?.flag){
          const filterTrackRes = await handlePostRequest(`track`,filterPlayload, false, true);
          console.log("filterTrackRes", filterTrackRes?.data?.data);
          localStorage.setItem('TrackData', JSON.stringify(filterTrackRes?.data?.data))
          dispatch(trackDetail(filterTrackRes?.data?.data))
          }
        }

        if(trackLikeRes?.flag && !songDeleteId){

          if(isFilterApplied>0){
            const filterPlayload = {
              apply_special_filter:1,
              page:1,
              per_page:1000,
              scope:parts[parts.length - 2],
              filter_id:genresDetailData?.id,
              type:type,
              ...filter
              }
            const filterTrackRes = await handlePostRequest(`track`,filterPlayload, false, true);
            console.log("filterTrackRes", filterTrackRes?.data?.data);
            localStorage.setItem('TrackData', JSON.stringify(filterTrackRes?.data?.data))
            dispatch(trackDetail(filterTrackRes?.data?.data))
          }else{
            const genresSongsRes = await handleGetRequest(`${`genre-song-data/${id}?type=${type}&search_keyword=${debounced}&sort_type=${sort}&limit=10`}`,false,true)
            localStorage.setItem('TrackData', JSON.stringify(genresSongsRes?.data?.data))
            dispatch(trackDetail(genresSongsRes?.data?.data))
          }

          // const genresSongsRes = await handleGetRequest(`${`genre-song-data/${id}?type=${type}&search_keyword=${searchQuery}&sort_type=${sort}&limit=10`}`,false)
          //   localStorage.setItem('TrackData', JSON.stringify(genresSongsRes?.data?.data))
          //   dispatch(trackDetail(genresSongsRes?.data?.data))
        }
      })();
    }, [isliked,songId,type,debounced,sort,songDeleteId]);

    useEffect(() => {
      if(isFilterApplied>0){
        return
      }
      // Skip the first render
      if (isFirstDeleteRender.current) {
        isFirstDeleteRender.current = false;
        return;
      }
      
      (async () => {
        const trackDeleteRes = await handleGetRequest(`remove-song/${songDeleteId}`, true,true);
        if(trackDeleteRes?.flag){
          const genresSongsRes = await handleGetRequest(`${`genre-song-data/${id}?type=${type}&search_keyword=${debounced}&sort_type=${sort}&limit=10`}`,false,true)
          localStorage.setItem('TrackData', JSON.stringify(genresSongsRes?.data?.data))
          dispatch(trackDetail(genresSongsRes?.data?.data))
        }
      })();
    }, [songDeleteId]);

    if(Like===undefined){
      return
    }else{

    return(
        <div>
           {/* <BackToPage pageRoute="/collection/genres" backTo="Genres"/> */}
          <CommonDetail Id={id} setIsFilterApplied={setIsFilterApplied} setFilter={setFilter} fetchPlaylistData={genresDetailData} searchQuery={searchQuery} setSearchQuery={setSearchQuery} setType={setType} setSort={setSort} sort={sort} type={type} details={genresDetailData} setSongDeleteId={setSongDeleteId} setIsliked={setIsliked} setSongId={setSongId} Like={Like}  title="Genres" detailtitles={detailTitles}/>
        </div>
    )
  }
}

export default GenresDetail
import Button1 from "../common/GlobalButtonLayout/Button1"
import InputField from "../common/Input/InputField"
import DoneIcon from '@mui/icons-material/Done';

const ResetPasswordModal = ({register,errors,handleSubmit,onSubmit,cpassword}) => {
    const isLengthValid = (password) => password?.length >= 8;
    const hasLowercase = (password) => /[a-z]/?.test(password);
    const hasUppercase = (password) => /[A-Z]/?.test(password);
    const hasNumber = (password) => /\d/?.test(password);
    const hasSpecialCharacter = (password) => /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/?.test(password);
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSubmit(onSubmit)();
        }
    };
    return(
        <div className="lg:w-[75%] w-[100%]">
             <form onSubmit={handleSubmit(onSubmit)} className="text-center">
             <InputField 
                type="password" 
                placeholder="New password" 
                name="cpassword" 
                register={register} 
                errors={errors}
                required={true}
                className="w-full "
                pattern={{
                    value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
                    message: "Password must have at least 8 characters, one lowercase letter (a-z), one uppercase letter (A-Z), one number (0-9), and one special character (@, -, #)"
                    }}
                    onKeyDown={handleKeyDown}

                  
            />
              {errors && errors['cpassword'] && errors['cpassword'].type === "required" && (
                        <p className="text-red-500 text-xs lg:text-sm">Password is required</p>
                        )}

                        {errors && errors['cpassword'] && errors['cpassword'].type === 'pattern' && (
                            <p className="text-red-500 text-xs lg:text-sm">{"Password must meet given criteria"}</p>
                        )}
            <InputField 
                type="password" 
                placeholder="Repeat new password" 
                name="v_password" 
                register={register} 
                errors={errors}
                required={true}
                className="w-full "
                onKeyDown={handleKeyDown}
            />
            {errors && errors['v_password'] && errors['v_password'].type === "required" && (
                        <p className="text-red-500 text-sm">Verify Password is required</p>
                        )}

                        {errors && errors['v_password'] && errors['v_password'].type === 'pattern' && (
                            <p className="text-red-500 text-sm">{"Password must meet given criteria"}</p>
                        )}

                <div className="text-left w-[100%] lg:w-[80%]  py-2 mt-2 mb-1 text-[12px] dark:text-white">
                    <p className={`${isLengthValid(cpassword)?"text-green-500":""}`}><DoneIcon className={` ${isLengthValid(cpassword)?"text-green-500":"text-[#DEDEDE]"}`}/>At least 8 characters.</p> 
                    <p className={`${(cpassword && hasLowercase(cpassword))?"text-green-500":""}`}><DoneIcon  className={` ${(cpassword && hasLowercase(cpassword))?"text-green-500":"text-[#DEDEDE]"}`}/>At least one lowercase letter (a-z).</p> 
                    <p className={`${hasUppercase(cpassword)?"text-green-500":""}`}><DoneIcon  className={` ${hasUppercase(cpassword)?"text-green-500":"text-[#DEDEDE]"}`}/>At least one uppercaseletter (A-Z)</p> 
                    <p className={`${hasNumber(cpassword)?"text-green-500":""}`}><DoneIcon  className={` ${hasNumber(cpassword)?"text-green-500":"text-[#DEDEDE]"}`}/>At least one number (0-9)</p> 
                    <p className={`${hasSpecialCharacter(cpassword)?"text-green-500":""}`}><DoneIcon  className={` ${hasSpecialCharacter(cpassword)?"text-green-500":"text-[#DEDEDE]"}`}/>At least one special characters.(@-#)</p> 
                    </div>

            <Button1 type="submit" className="lg:!w-[50%] !w-[80%] text-center !my-4 dark:hover:!text-[#F3C25A]">
                Submit   
            </Button1>
            </form>
        </div>
    )
}

export default ResetPasswordModal
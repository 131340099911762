import toast, { Toaster } from 'react-hot-toast';


const globalToast = (toastcontent,isError) => {
    console.log("toastcontent",toastcontent);
    
    toast.custom((t) => (
        <div
          className={`${
            t.visible ? 'animate-enter' : 'animate-leave'
          } max-w-md w-full  ${isError?"bg-red-900 !text-white":"bg-white"} shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
        >
          <div className="flex-1 w-0 p-4">
            <div className="flex justify-start items-center">
              <div className="flex-shrink-0 pt-0.5">
                <img
                  className="h-10 w-10 rounded-full"
                  src="/notificationLogo.jpg"
                  alt=""
                />
              </div>
              <div className="ml-3 flex-1">
                {/* <p className="text-sm font-medium text-white">
                  Emilia Gates
                </p> */}
                <p className={`mt-1 text-sm  ${isError?"text-white":"text-black"} flex justify-start items-center font-semibold`}>
                  {toastcontent} 
                </p>
              </div>
            </div>
          </div>
        </div>
      ))
}

export default globalToast
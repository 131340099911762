

import React from 'react';
import { Link } from "react-router-dom";
import {  useDispatch, useSelector } from "react-redux"
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { handleAuthModalStatus } from '../../../store/authenticationSlice';


const FooterComponent = ({playerStatus}) => {
    const isPlayer = useSelector((state) => state.tracks.isPlayer);
    const authModal = useSelector((state) => state.auth.authModalStatus);
    const tokenStatus = useSelector((state) => state.auth.tokenStatus);
    const dispatch = useDispatch()

    const handleLoginModules = () => {
        if(!tokenStatus){
            dispatch(handleAuthModalStatus("login"))
        }
    }
    return (
        <footer className={`mt-10 ${playerStatus?"lg:mb-[7%] mb-[18%]":""} z-20 drop-shadow-lg right-0 fixed bottom-0 lg:w-[85%] w-full bg-white dark:bg-[#0D0D0D]`}>
             {/* { (!tokenStatus && authModal!=="login") && <div onClick={()=>dispatch(handleAuthModalStatus("login"))}  className="absolute top-0 w-full h-full bg-opacity-0 bg-slate-500 z-[100]"></div>} */}
            <nav className="flex text-xs text-gray-700   dark:text-[#A1A1A1]  lg:text-sm justify-center items-center gap-x-5 px-5 pt-5 pb-2 flex-wrap lg:flex-nowrap">
                <Link to="/about"   onClick={()=>handleLoginModules()}  className="hover:text-gray-900 dark:hover:text-[#FFB619] transition-colors duration-200" aria-label="About Page">About </Link>
                <p>|</p>
                <Link to="/help"   onClick={()=>handleLoginModules()}  className="hover:text-gray-900 dark:hover:text-[#FFB619] transition-colors duration-200">Help </Link>
                <p>|</p>
                <Link to="/terms-conditions"   onClick={()=>handleLoginModules()}   className=" transition-colors duration-200 hover:text-gray-900 dark:hover:text-[#FFB619]">Terms & Conditions </Link>
                <p>|</p>
                <Link to="/privacy"  onClick={()=>handleLoginModules()}  className="hover:text-gray-900 dark:hover:text-[#FFB619] transition-colors duration-200">Privacy </Link>
                <p>|</p>
                <Link to="/copyright-policy" onClick={()=>handleLoginModules()} className="hover:text-gray-900 dark:hover:text-[#FFB619] transition-colors duration-200">Copyright Policy </Link>
                <p>|</p>
                <Link to="/contact" onClick={()=>handleLoginModules()}   className="hover:text-gray-900 dark:hover:text-[#FFB619] transition-colors duration-200">Contact Us</Link>
            </nav>
            <div className="text-center pb-3 lg:flex lg:justify-center items-center gap-x-5">
                <p className=" text-xs lg:text-sm text-gray-500">©CuriousHit 2024 - all rights reserved</p>
                <div className='flex justify-center my-2 lg:my-0 lg:justify-start items-center gap-x-4 mx-auto lg:mx-0'>
                <FacebookIcon className='dark:text-white'/>
                <XIcon className='dark:text-white'/>
                <InstagramIcon className='dark:text-white'/>
                <LinkedInIcon className='dark:text-white'/>
                </div>
            </div>
        </footer>
    );
};

export default FooterComponent;



import { Carousel } from 'primereact/carousel';
import 'primeicons/primeicons.css';

import { useRef } from 'react';


function GenresSwiper({genresList,flag,flag2=""}){
  const viewportWidth = window.innerWidth;
    const swiperRef = useRef(null);
    const responsiveOptions = [
      {
        breakpoint: '2920px',
        numVisible: flag==="about" ?3:6,
        numScroll: 1
      },
      {
        breakpoint: '1920px',
        numVisible: flag==="about" ?3:6,
        numScroll: 1
    },

      {
          breakpoint: '1400px',
          numVisible: flag==="about" ? 2:6,
          numScroll: 1
      },
      {
          breakpoint: '1199px',
          numVisible:flag==="about" ? 2:6,
          numScroll: 1
      },
      {
          breakpoint: '767px',
          numVisible: flag==="about" ?2:3,
          numScroll: 1
      },
      {
          breakpoint: '575px',
          numVisible: 1,
          numScroll: 1
      }
  ];

  const productTemplate = (product) => {
    return (
      <div>
        <div className=' h-full rounded-lg flex justify-center gap-x-6 items-center'>
            <div className=''>
                <div className='lg:w-40 lg:h-28 w-full rounded-2xl h-40'>
                <img src={product?.t_image || product?.image} alt='' className={`${flag2==="instrument"?"object-contain dark:filter dark:invert":"object-cover"} rounded-2xl w-full h-full `}/>
                </div>
                <p className='text-black dark:text-white text-[16px] capitalize font-semibold text-center my-2'>{product?.t_genres || product?.name}</p>
            </div>
        </div>
        </div>
    );
};

const showIcons =flag==="about" ?viewportWidth>1400?( genresList?.length > 2):viewportWidth>1199?(genresList?.length > 2):viewportWidth>575?( genresList?.length > 1):true : viewportWidth>1400?( genresList?.length > 6):viewportWidth>1199?(genresList?.length > 6):viewportWidth>575?( genresList?.length > 1):true

console.log("showIcons",showIcons,viewportWidth);
  return(
    <div className='relative'>
       {genresList?.length>0 && <Carousel  nextIcon={showIcons ? 'pi pi-chevron-right' : 'null'} prevIcon={showIcons ? 'pi pi-chevron-left' : 'null'} value={genresList} numVisible={1} numScroll={1} responsiveOptions={responsiveOptions} className="custom-carousel" circular  itemTemplate={productTemplate} />}

    </div>
  )
}

export default GenresSwiper;



import { useCallback, useState } from "react";
import { ComposableMap, Geographies, Geography, ZoomableGroup } from "react-simple-maps";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { Pagination } from 'swiper/modules';
import { Carousel } from 'primereact/carousel';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';



function Stepper2({setSelectedWorldMapCountry,selectedWorldMapCountry,setSelectedDecade,selectedDecade,decadeList,musicLanguageList,setSelectedLanguages,selectedLanguages}){

  console.log("musicLanguageList",musicLanguageList);
  const responsiveOptions = [
    {
        breakpoint: '1400px',
        numVisible: 9,
        numScroll: 1
    },
    {
        breakpoint: '1199px',
        numVisible: 5,
        numScroll: 1
    },
    {
        breakpoint: '767px',
        numVisible: 3,
        numScroll: 1
    },
    {
        breakpoint: '575px',
        numVisible: 3,
        numScroll: 1
    }
];

  const onDecadeChangeHandler = useCallback((e, decade) => {
    // Prevent the default behavior of the click event
    e.preventDefault();

    setSelectedDecade((prev) => {
      // Check if the clicked decade is already in the array
      const isDecadeSelected = prev.includes(decade.id);

      if (isDecadeSelected) {
        // If selected, remove it from the array
        return prev.filter((item) => item !== decade.id);
      } else {
        // If not selected, add it to the array
        return [...prev, decade.id];
      }
    });

  }, [setSelectedDecade]);

    const onWorldMapHandler = useCallback((selectedWorldMap, geo) => {
        const id = selectedWorldMap?.id?.substring(0, 2);
    
    
        setSelectedWorldMapCountry((prev) => {
          const isItemAlreadySelected = prev.includes(id);
        
          if (isItemAlreadySelected) {
            // If item is already selected, remove it from the array
            return prev.filter(item => item !== id);
          } else {
            // If item is not selected, add it to the array
            return [...prev, id];
          }
        });
      }, []);

      const handleSelectedLanguages= (id) => {
        setSelectedLanguages((prev) => {
          const isItemAlreadySelected = prev.includes(id);
        
          if (isItemAlreadySelected) {
            // If item is already selected, remove it from the array
            return prev.filter(item => item !== id);
          } else {
            // If item is not selected, add it to the array
            return [...prev, id];
          }
        });
      }

      const [tooltipContain, setTooltipContent] = useState({
        show: false,
        x: 0,
        y: 0,
        content: "",
      });

      const decadeTemplate = (product) => {
        return (
          <div onClick={(e) => onDecadeChangeHandler(e, product)} className="cursor-pointer">
            <div className="relative mb-4 flex justify-center ">
            <div className={`w-[35px] h-[35px] ${selectedDecade?.includes(product?.id)?"bg-[#F1B942] ":"bg-[#E9E9E9] "} rounded-full  z-50`}>
            </div>
            <div className="h-[2px] bg-[#FAFAF8] z-[-2] w-full absolute  top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"></div>
            </div>
            <div className={`text-center ${selectedDecade?.includes(product?.id)?"text-[#F1B942] ":"text-black dark:text-white"}`}>{product?.v_year}`s</div>
          </div>
        );
    };
   
     

    return(
        <div className="relative">
            <div className={`  overflow-y-hidden w-[95%] mx-auto  `}>
            <h6 className="text-black text-[16px] lg:text-[24px] dark:text-white font-medium  my-3">Where does the music that inspires you come from ?</h6>
            <div id="map-container" className="h-full">
              <ComposableMap
                width={1300}
                height={500}
                projectionConfig={{ scale: 180 }}
              >
                <ZoomableGroup
                  zoom={true}
                  translateExtent={[
                    [0, -500],
                    [1300, 500],
                  ]}
                >
                  <Geographies geography="/features.json">
                    {({ geographies }) =>
                      geographies?.map((geo) => (
                        <Geography

                          key={geo.rsmKey}
                          geography={geo}
                          onMouseEnter={(event) => {
                            const mapContainer =
                              document.getElementById("map-container");
                            const rect = mapContainer.getBoundingClientRect();
                            const x = event.clientX - rect.left;
                            const y = event.clientY - rect.top;
                            setTooltipContent({
                              show: true,
                              x,
                              y,
                              content: geo?.properties?.name,
                            });
                          }}
                          onMouseLeave={() => {
                            setTooltipContent((tooltipValue) => {
                              return { ...tooltipValue, show: false };
                            });
                            setTooltipContent("");
                          }}
                          //   onClick={() => onWorldMapHandler(geo)}
                          onClick={(event) => {
                            // Call the onWorldMapHandler function
                            onWorldMapHandler(geo);
                            event.target.blur();
                          }}
                          style={{
                            default: {
                              fill: selectedWorldMapCountry?.includes(
                                geo?.id?.substring(0, 2)
                              )
                                ? "#F1B942"
                                : "#D8D8D8",
                            },
                            hover: {
                              fill: "#D8D8D8",
                              stroke: "#F1B942",
                              cursor: "pointer",
                            },
                            pressed: {
                              fill: "blue",
                            },
                            // Add additional CSS to remove outline/focus border
                            focus: {
                              outline: "none",
                            
                            },
                            
                          }}
                        />
                      ))
                    }
                  </Geographies>
                </ZoomableGroup>
              </ComposableMap>
              {tooltipContain.show && (
                <div
                  style={{
                    position: "absolute",
                    left: tooltipContain.x, // Adjust the tooltip's left position
                    top: tooltipContain.y, // Adjust the tooltip's top position
                    backgroundColor: "white",
                    padding: "4px",
                    borderRadius: "4px",
                    boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
                  }}
                >
                  {tooltipContain.content}
                </div>
              )}
            </div>
            </div>

            { (
              <div className="lg:px-10 px-3 my-14 lg:my-auto">
                <h6 className="text-black text-[16px] lg:text-[24px] dark:text-white font-medium  my-3">Please select language ?</h6>

                <div
                  className=""
                  style={{ display: "flex", gap: "20px", flexWrap:'wrap' , margin:'auto' }}
                >

                {musicLanguageList?.map((language, index)=>{
                  return(
                <div key={language?.id} className="language-img-wrap "  onClick={()=>handleSelectedLanguages(language.id)}>
                  <div className="relative w-[40px] h-[40px] lg:w-[60px] lg:h-[60px] rounded-full">
                      <img src={language?.image} alt={language.prefix} className=" rounded-full w-full h-full mx-auto"></img>
                      {selectedLanguages.includes(language.id) && <div className="w-[40px] h-[40px] lg:w-[60px] lg:h-[60px] bg-[#00000063] absolute top-0 rounded-full flex justify-center items-center">
                      <img src="/Vector.png" alt="checkmark" className="" />
                      </div>}
                  </div>
                      <button className="language-btn">
                        <span className="dark:text-white">{language.name}</span>
                      </button>
                    </div>
                  )
                })}
                </div>
              </div>
            )}

          <div className="mt-10 lg:px-10 px-3">
          <h6 className="text-black text-[16px] lg:text-[24px] font-medium  my-6 dark:text-white">What is your favorite generation of music ?</h6>
        
          { decadeList?.length>0 && 
          <Carousel 
          value={decadeList} 
          nextIcon="pi pi-chevron-right custom-carousel-next-icon"
          prevIcon="pi pi-chevron-left custom-carousel-prev-icon"
          numVisible={8} 
          numScroll={8} 
          responsiveOptions={responsiveOptions} 
          className="custom-carousel dark:text-white" 
          circular
          autoplayInterval={3000} 
          itemTemplate={decadeTemplate} 
          />}
          </div>
        </div>
     
    )
}

export default Stepper2





import React, { useState, useRef, useEffect } from 'react';
import { useApi } from '../../../../api/api';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import OtpInput from 'react-otp-input';
import { useDispatch } from 'react-redux';
import { handleAuthModalStatus } from '../../../../store/authenticationSlice';

const OTPInput = ({ email, setChangeEmail, setIsOtpSend, setVerifyOtp, authModal, setAuthModal, setResetEmail, reset,closeModal }) => {
  const dispatch = useDispatch()
  const [otp, setOtp] = useState(['', '', '', '']);
  const [resendDisabled, setResendDisabled] = useState(false); // State to control button disabled
  const [resendTimer, setResendTimer] = useState(120); // Initial timer value in seconds

  const inputRefs = useRef([]);
  const { handlePostRequest } = useApi();

  
  const [otpp, setOtpp] = useState('');

  useEffect(() => {
    let timerInterval;

    if (resendTimer > 0) {
      // Start countdown
      timerInterval = setInterval(() => {
        setResendTimer(prevTimer => prevTimer - 1);
      }, 1000);
    } else {
      // Timer finished, enable button
      setResendDisabled(false);
    }

    return () => clearInterval(timerInterval); // Cleanup interval on unmount or timer finish
  }, [resendTimer]);

  // const handleChange = (e, index) => {
  //   const { value } = e.target;
  //   // Check if the entered value is a number
  //   if (!isNaN(value) && value !== '') {
  //     const newOtp = [...otp];
  //     newOtp[index] = value;
  //     setOtp(newOtp);

  //     // Move to the next input field
  //     if (e.target.value !== '' && index < otp.length - 1) {
  //       inputRefs.current[index + 1].focus();
  //     }
  //   }
  // };



  // const handleChange = (e, index) => {
  //   let { value } = e.target;
    
  //   // Restrict input to only numbers
  //   value = value.replace(/\D/g, '');
    
  //   // Ensure only single-digit numbers are accepted
  //   if (value.length > 1) {
  //     value = value.slice(0, 1);
  //   }
  
  //   // Update state with the modified value
  //   const newOtp = [...otp];
  //   newOtp[index] = value;
  //   setOtp(newOtp);
  
  //   // Move to the next input field
  //   if (value !== '' && index < otp.length - 1) {
  //     inputRefs.current[index + 1].focus();
  //   }
  // };

  const handleChange = (e, index) => {
    let { value } = e.target;
     
    // Restrict input to only numbers
    value = value.replace(/\D/g, '');
     
    // Ensure only single-digit numbers are accepted
    if (value.length > 1) {
       value = value.slice(0, 1);
    }
   
    // Update state with the modified value
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
   
    // Move to the next input field if the current one is not empty
    if (value !== '' && index < otp.length - 1) {
       inputRefs.current[index + 1].focus();
    }
   };

  const handleKeyDown = (e) => {
    // if (e.key === 'Backspace' && index > -1) {
    //   e.preventDefault();
    //   setOtp(prevOtp => {
    //     const newOtp = [...prevOtp];
    //     newOtp[index] = '';
    //     return newOtp;
    //   });
    //   inputRefs.current[index - 1]?.focus();
    // }
    if (e.key === 'Enter') {
      e.preventDefault();
      handleOtp("Verify");
    }
  };


    useEffect(() => {
      let timerInterval;
      if (authModal === "forgotPassOtpModal") {
        setResendDisabled(true); // Disable the button initially
        setResendTimer(60); // Reset timer
  
        // Start the countdown
        timerInterval = setInterval(() => {
          setResendTimer(prevTime => {
            if (prevTime <= 1) {
              clearInterval(timerInterval);
              setResendDisabled(false); // Enable the button when the timer reaches zero
              return 0;
            }
            return prevTime - 1;
          });
        }, 1000);
      }
  
      // Cleanup interval on unmount or when authModal changes
      return () => clearInterval(timerInterval);
    }, [authModal]);


  const handleOtp = async (para) => {
    // if (para === "Verify" && otp.join("")) {
      if (para === "Verify") {
      const payload = {
        v_email_id: email,
        // otp: otp.join("")
        otp: otpp
      }
      const verifyOtpRes = await handlePostRequest('users/verify-code', payload,true, true)
      console.log("verifyOtpRes", verifyOtpRes);
      if (verifyOtpRes?.code === 200) {
        setVerifyOtp(verifyOtpRes?.flag)
        // setOtp(['', '', '', ''])
        setOtpp(['', '', '', ''])
        if (authModal === "forgotPassOtpModal") {
          // setAuthModal("resetPasswordModal")
          dispatch(handleAuthModalStatus('resetPasswordModal'))
          setResetEmail(email)
        }
      } else {
        setVerifyOtp(false)
        // setOtp(['', '', '', ''])
        setOtpp(['', '', '', ''])
      }
    }

   

    if (para === "Resend") {
      const payload = {
        v_email_id: email,
      }

      const sendCodeForgotPassPayload = {
        action: 'forgot_password',
        v_email_id: email,
      }


      if (authModal === "forgotPassOtpModal") {
        const emailExitRes = await handlePostRequest(`${'users/email-exists'}`, payload, true, true)
        console.log("emailExitRes", emailExitRes?.flag);
        if (emailExitRes?.flag) {
          const sendCodeForgotPassRes = await handlePostRequest(`${'users/send-code'}`, sendCodeForgotPassPayload,true,true)
          console.log("sendCodeForgotPassRes", sendCodeForgotPassRes?.flag);
          // setOtp(['', '', '', ''])
          setOtpp(['', '', '', ''])
          // setAuthModal('forgotPassOtpModal')
          dispatch(handleAuthModalStatus('forgotPassOtpModal'))
          
          setResendDisabled(true); // Disable the button
          setResendTimer(120); // Reset timer
        }

      }


      if (authModal === "signUp") {
        const resendOtpRes = await handlePostRequest('users/send-code', payload,true, true)
        console.log("resendOtpRes", resendOtpRes);
        // setOtp(['', '', '', ''])
        setOtpp(['', '', '', ''])
        setResendDisabled(true); // Disable the button
        setResendTimer(120); // Reset timer
      }

    }


    if (para === "Change") {
      reset({
        'Email':""
      })
      setIsOtpSend(false)
      setChangeEmail(true)
    
      
    }
  };

  const handlePaste = (e, index) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text');
    const otpArray = pastedData.split('').filter(char => !isNaN(char));
    const newOtp = [...otp];
    otpArray.forEach((char, i) => {
      if (index + i < otp.length) {
        newOtp[index + i] = char;
      }
    });
    setOtp(newOtp);
  };

  useEffect(()=>{
    handleOtp("Resend")
  },[])


  return (
    <div className='my-4 pb-4 relative'>
       {authModal === "forgotPassOtpModal"&&<div className={`absolute -top-[115px]  lg:-top-[125px] -right-3 lg:flex justify-end items-center my-2   `}>
            <CancelOutlinedIcon onClick={()=>closeModal()} className=" text-[#D8D8D8] cursor-pointer !w-7 !h-7" />
        </div>}
      <div className="flex justify-center">
        {/* {otp.map((digit, index) => (
          <input
            key={index}
            ref={(ref) => (inputRefs.current[index] = ref)}
            type="number"
            inputmode="numeric"
            maxLength="1"
            value={digit}
            onInput={(e) => handleChange(e, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            onPaste={(e) => handlePaste(e, index)} 
            className="w-[18%] h-12 mx-1 otp-input-container focus:border-[1px] focus:border-[#F1B942] text-center rounded-[50px] bg-[#FAFAF8]  "
          />
        ))} */}
         <OtpInput
          value={otpp}
          onChange={setOtpp}
          numInputs={4}
          onPaste={true}
          shouldAutoFocus={true}
          inputStyle={true}
          containerStyle="containerOTP"
          inputType='number'
          renderInput={(props) => <input {...props} onKeyDown={(e) => handleKeyDown(e)}  className="!w-[18%] !h-12 !mx-1 otp-input-container focus:!border-[1px] focus:!border-[#F1B942] !text-center !rounded-[50px] !bg-[#FAFAF8] dark:!bg-black dark:!text-white"/>}
         
        />
      </div>
      {/* {authModal === "forgotPassOtpModal" && <button disabled={resendDisabled} onClick={() => handleOtp("Resend")} className={`cursor-pointer text-xs w-[75%] mx-auto  text-right mt-4 font-normal text-[#F1B942] ${resendDisabled ? 'opacity-50 pointer-events-none' : ''}`}>Re-Send code {resendDisabled ? `(${Math.floor(resendTimer / 60)}:${resendTimer % 60 < 10 ? '0' : ''}${resendTimer % 60})` : ''}</button>} */}

      <button disabled={resendDisabled} onClick={() => handleOtp("Resend")} className={`cursor-pointer text-xs w-[75%] mx-auto  text-right mt-4 font-normal text-[#F1B942] ${resendDisabled ? 'opacity-50 pointer-events-none' : ''}`}>Re-Send code {resendDisabled ? `(${Math.floor(resendTimer / 60)}:${resendTimer % 60 < 10 ? '0' : ''}${resendTimer % 60})` : ''}</button>

      <div className='flex justify-between w-[75%] mx-auto items-center gap-x-3 mt-3'>
        <button onClick={() => handleOtp("Verify")} className={`bg-[#FDD169] ${authModal === "forgotPassOtpModal" ? "w-[70%] mx-auto text-white py-3  lg:!text-lg font-semibold" : ""} rounded-[50px] w-[30%] py-2  text-[#312522] text-sm `}>{authModal === "forgotPassOtpModal" ? "Next" : "Verify"}</button>
        {authModal !== "forgotPassOtpModal" && <button onClick={() => handleOtp("Resend")} disabled={resendDisabled} className='bg-[#FFC9A3] rounded-[50px]  w-[30%] py-2 text-[#312522] text-xs '>Resend</button>}
        {authModal !== "forgotPassOtpModal" && <button onClick={() => handleOtp("Change")} className='bg-[#E9ECEF] rounded-[50px]  w-[30%] py-2 text-[#312522] text-xs '>Change Email</button>}
      </div>
    </div>
  );
};

export default OTPInput


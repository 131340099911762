import React, { useEffect, useRef, useState } from 'react'
import PlayerCurioushit from './Curioushit/PlayerCurioushit'
import PlayerIframe from './OtherPlayer/PlayerIframe'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useApi } from '../../../../api/api';
import { recommendDetail } from '../../../../store/trackSlice';
import { handleCurrentSongIndex, handleIsRecommended, handlePlayerStatus, handleRecommendedSongs } from '../../../../store/playerSlice';

const CommonPlayer = ({platform,localVolume}) => {
  const dispatch = useDispatch()
  const songUrl = JSON.parse(localStorage.getItem('playingCurrentSong'));
  const tracks = useSelector((state) => state.tracks.trackData);
  const currentSongIndex = useSelector((state) => state.player.currentSongIndex);
  const isRecommended = useSelector((state) => state.player.isRecommended);
  const [isPlaying, setIsPlaying] = useState(true);
  // const [recommendations, setRecommendations] = useState([]);
  const recommendations = useSelector((state) => state.player.recommendedSongs);
  const {  handlePostRequest } = useApi();

  const location = useLocation();
  // Split the pathname by '/' and get the second last segment
  const pathSegments = location.pathname.split('/');
  const scope = pathSegments[pathSegments.length - 2];
  const modifiedString = scope.slice(0, -1);
  
  // Get the query parameters
  const [searchParams] = useSearchParams();
  // Retrieve the 'id' query parameter
  const id = searchParams.get('id');


  
  useEffect(() => {
    const fetchData = async () => {
      const payload = {
        "is_global":songUrl?.is_global,
        "song_id": songUrl.id || null,
        "scope":( modifiedString === "playlist" || modifiedString === "artist" || modifiedString === "album" || modifiedString === "genre" || modifiedString === "geo" || modifiedString === "decade")? modifiedString: "global",
        ...(modifiedString === "playlist" && {"playlist_id": id}),
        ...(modifiedString === "artist" && {"artist_id": id}),
        ...(modifiedString === "album" && {"album_id": id}),
        ...(modifiedString === "genre" && {"genre_id": id}),
        ...(modifiedString === "geo" && {"geo_id": id}),
        ...(modifiedString === "decade" && {"decade_id": id}),
        ...(modifiedString !== "playlist" && modifiedString !== "artist" && modifiedString !== "album" && modifiedString !== "genre" && modifiedString !== "geo" && modifiedString !== "decade" &&{"e_platform": songUrl?.e_platform})
      };
  
      const recommend = await handlePostRequest(`recommendations`, payload, false, false);
      dispatch(handleCurrentSongIndex(0));
      if(recommend?.flag){
        dispatch(handleIsRecommended(true))
        dispatch(handleRecommendedSongs(recommend?.data))
      }
    };

    if(!isRecommended){
      fetchData();
    }
  
  }, [ currentSongIndex]);

  const handleRecommendedSong = (songData,currentSongIndex) => {
    dispatch(handleIsRecommended(false))
    localStorage.setItem('playingCurrentSong',  JSON.stringify(songData))
    dispatch(handlePlayerStatus(songData?.e_platform));
    dispatch(handleCurrentSongIndex(currentSongIndex));
  }

  console.log("asadsd",currentSongIndex,isPlaying);

  const playingRef = useRef(false)
  useEffect(()=>{
    if (playingRef.current) {
      setIsPlaying(true);
      localStorage.setItem('isPlaying', true);
    } else {
      playingRef.current = true; // Skip first render
    }
  
  },[currentSongIndex])

 
  
  return (
    <div  >
    {platform==="DailyMotion" || platform==="Spotify" ?
    <PlayerIframe  songUrl={songUrl} isPlaying={isPlaying} setIsPlaying={setIsPlaying} trackData={!isRecommended?tracks:recommendations} localVolume={localVolume} currentSongIndex={currentSongIndex} recommendations={recommendations} handleRecommendedSong={handleRecommendedSong}/>
   :
    <PlayerCurioushit songUrl={songUrl} trackData={!isRecommended?tracks:recommendations} isPlaying={isPlaying} setIsPlaying={setIsPlaying} localVolume={localVolume} currentSongIndex={currentSongIndex} recommendations={recommendations } handleRecommendedSong={handleRecommendedSong}/>
    }
    </div>
  )
}

export default CommonPlayer
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
 isModal:false
};

// Slice
export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    // updateLoadingState(state, action) {
    //   state.isLoading = action.payload;
    // },
    handleCloseModal(state){
        state.isModal = false;
      },
      handleOpenModal(state){
        state.isModal = true;
      },
  },
});

export const {handleCloseModal,handleOpenModal} = modalSlice.actions;

export default modalSlice.reducer;
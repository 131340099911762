import React from 'react'
import GenresSwiper from '../GenresSwiper'
import CommonListing from '../../collectionPagesComponents/CommonListing';

const GenresComponent = ({genresList,flag,nav,handleDeletePlaylist}) => {
 
  
  console.log("genresList",genresList);
  return (
    <div className='dark:text-[#DDD1CE]'>

      {nav===1?<GenresSwiper genresList={genresList} flag={flag}/>: <CommonListing listing={genresList} title="genres" flag="profilepagelisting"  hidetitle={true} fromprofile="/collection/genres"   handleDeletePlaylist={handleDeletePlaylist}/>}
    </div>
  )
}

export default GenresComponent
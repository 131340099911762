import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material"
import FilterButtons from "./FilterButtons"
import FilterDropdownTitle from "./FiltersDropdrownTitle"
import FilterList from "./FiltersList"
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleCloseFilterModal, handleFilterValue, handleOpenFilterModal, handleSelectedFilter } from "../../../../store/filterSlice";
import { useLocation } from "react-router";
import { useApi } from "../../../../api/api";
import { filterStatus, trackDetail } from "../../../../store/trackSlice";
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import globalToast from "../../../../hooks/globalToast";

function Filters({filterList,setSelectedFilterData,selectedFilterData,selectedFilterName,setSelectedFilterName,filterRef,setIsFilter,selectedplatform,op, scope, filterId,setIsFilterOpen}){

 
  const isDark = useSelector((state) => state.dark.isDarkValue);

  const [allFiltersEmpty, setAllFiltersEmpty] = useState(true);
  const checkIfAllArraysEmpty = () => {
    return Object.values(selectedFilterData)?.every(array => array?.length === 0);
 };
 useEffect(() => {
  setAllFiltersEmpty(checkIfAllArraysEmpty());
}, [selectedFilterData]);

    const dispatch  = useDispatch()
    const [expanded, setExpanded] = useState(false);
    const {  handlePostRequest } = useApi();

  const filterPanelRef = useRef(null);
  const filterScrollRef = useRef(null);
  const filterPanelRefMobile = useRef(null);

  
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (filterPanelRef?.current && !filterPanelRef?.current?.contains(event.target) && !filterRef?.current?.contains(event.target) && !filterPanelRefMobile?.current?.contains(event.target)) {
         dispatch(handleCloseFilterModal())
      }
    };

    const handleDocumentClick = (event) => {
      handleOutsideClick(event);
    };

    // Attach the event listener when the component mounts
    document.addEventListener('click', handleDocumentClick);

    // Detach the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

    //   useEffect(()=>{
        
    //   },[selectedFilterData])

      // const applyFilters = () => {
      //   dispatch(handleSelectedFilter(selectedFilterData))
      //   dispatch(handleCloseFilterModal())
      // }

      const applyFilters = async() => {
        console.log("scope",scope);

        if(allFiltersEmpty){
         return(
          globalToast("Please Select atleast 1 Filter",true)
         )
        }
       console.log("count1",selectedFilterData);
       const filterPlayload = {
        apply_special_filter:1,
        scope:scope,
        filter_id:filterId,
        page:1,
        per_page:1000,
        type:"all",
        ...selectedFilterData,
        ...(selectedplatform !== 'all' && { e_platform_name: selectedplatform })
        }
      const filterTrackRes = await handlePostRequest(`track`,filterPlayload, false, true);
      console.log("selectedplatform1", selectedplatform);
      localStorage.setItem('TrackData', JSON.stringify(filterTrackRes?.data?.data))
      dispatch(trackDetail(filterTrackRes?.data?.data))
      setSelectedFilterName({
        scope:scope,
        filter_id:filterId,
        filter_genres: [],
        filter_decades: [],
        filter_sources: [],
        filter_artists: [],
        filter_geos: [],
        filter_playlists: [],
        filter_albums: [],
      })

      setIsFilter(false)
      // op.current.toggle(false)
      // dispatch(filterStatus(false))
      setIsFilterOpen(false)
      }

      const clearFilters = async() => {
      setIsFilter(false)
      // op.current.toggle(false)
      // dispatch(filterStatus(false))
      setIsFilterOpen(false)

        setSelectedFilterName({
          scope:scope,
            filter_id:filterId,
            filter_genres: [],
            filter_decades: [],
            filter_sources: [],
            filter_artists: [],
            filter_geos: [],
            filter_playlists: [],
            filter_albums: [],
        })
         setSelectedFilterData({
          scope:scope,
            filter_id:filterId,
            filter_genres: [],
            filter_decades: [],
            filter_sources: [],
            filter_artists: [],
            filter_geos: [],
            filter_playlists: [],
            filter_albums: [],
          })
        dispatch(handleSelectedFilter({}))

        if(!allFiltersEmpty){
          const filterPlayload = {
            apply_special_filter:1,
            scope:scope,
            filter_id:filterId,
            page:1,
            per_page:1000,
            type:"all",
            filter_genres: [],
            filter_decades: [],
            filter_sources: [],
            filter_artists: [],
            filter_geos: [],
            filter_playlists: [],
            filter_albums: [],
            ...(selectedplatform !== 'all' && { e_platform_name: selectedplatform })
            }
          const filterTrackRes = await handlePostRequest(`track`,filterPlayload, false,true);
          dispatch(handleCloseFilterModal())
          console.log("selectedplatform2", selectedplatform);
          localStorage.setItem('TrackData', JSON.stringify(filterTrackRes?.data?.data))
          dispatch(trackDetail(filterTrackRes?.data?.data))
        }
      }

      const [openFilter,setOpenFilter] = useState("genre")
      const genresTitle = selectedFilterName.filter_genres?.map((item)=>item)
      const DecadeTitle = selectedFilterName.filter_decades?.map((item)=>item)
      const SourceTitle = selectedFilterName.filter_sources?.map((item)=>item)
      const ArtistTitle = selectedFilterName.filter_artists?.map((item)=>item)
      const GeoTitle = selectedFilterName.filter_geos?.map((item)=>item)
      const PlaylistTitle = selectedFilterName.filter_playlists?.map((item)=>item)
      const AlbumsTitle = selectedFilterName.filter_albums?.map((item)=>item)

    const handleChange = (panel,title) => (event, isExpanded) => {
      console.log("title",title);
        setExpanded(isExpanded ? panel : false);
        if (isExpanded && title==="Albums") {
          setTimeout(() => {
            filterPanelRef.current.scrollTo({ top: filterPanelRef.current.scrollHeight, behavior: 'smooth' });
          }, 400); // You may need to adjust the timeout duration based on your specific scenario
        }
    };

    // useEffect(()=>{
    //   filterPanelRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    // },[expanded])
    // useEffect(() => {
    //   filterPanelRef.current.scrollTo({ bottom: 0, behavior: 'smooth' });
    // }, [expanded]);
    const isIOS = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    };

    return(
        <div className={`${isDark?"dark":""}`}>
        <div ref={filterPanelRef}  className={`p-3 max-h-[300px] overflow-scroll rounded-3xl w-[370px] text-black  bg-white dark:bg-[#151515] dark:border-[1px] dark:border-[#A8A8A8] border-[1px] absolute  top-[5px] hidden lg:block left-[175px] transform -translate-x-1/2   z-50`} >
        <div className="absolute top-[-20px] left-1/2 transform -translate-x-1/2 ">
            <img src="/Vector1.svg" alt="" className=""/>
        </div>
        <div className="flex justify-between items-center mb-3">
            <div>
                <p className="text-[18px] font-semibold capitalize dark:text-white">Playlist filters</p>
            </div>
            <div className="flex justify-between items-center gap-1">
                <FilterButtons onClick={()=>clearFilters()}>Clear</FilterButtons>
                <FilterButtons onClick={()=>applyFilters()}>Apply</FilterButtons>
            </div>
        </div>
        {/* <FilterDropdownTitle title="Genre"/>
        <FilterList/> */}
        <div ref={filterScrollRef}>
        <FilterList handleChange={handleChange} title={genresTitle?.length>0 && genresTitle?.length<4?`${genresTitle} `: genresTitle?.length>3?`${genresTitle?.length} selected `:"Genres"} isSelectedFilters={genresTitle?.length>0?true:false} selectKey="filter_genres" selectedFilterData={selectedFilterData} setSelectedFilterData={setSelectedFilterData} selectedFilterName={selectedFilterName}  setSelectedFilterName={setSelectedFilterName}  panel="panel1" expanded={expanded} filterList={filterList?.genres} setExpanded={setExpanded}/>

        <FilterList handleChange={handleChange} title={DecadeTitle?.length>0 && DecadeTitle?.length<4?`${DecadeTitle} `: DecadeTitle?.length>3?`${DecadeTitle?.length} selected `:"Decades"} isSelectedFilters={DecadeTitle?.length>0?true:false} selectKey="filter_decades" selectedFilterData={selectedFilterData} setSelectedFilterData={setSelectedFilterData}  selectedFilterName={selectedFilterName}  setSelectedFilterName={setSelectedFilterName}  panel="panel2" expanded={expanded} filterList={filterList?.decades} setExpanded={setExpanded} />

        <FilterList handleChange={handleChange} title={SourceTitle?.length>0 && SourceTitle?.length<4?`${SourceTitle} `: SourceTitle?.length>3?`${SourceTitle?.length} selected `:"Sources"} isSelectedFilters={SourceTitle?.length>0?true:false} panel="panel3" selectedFilterData={selectedFilterData} setSelectedFilterData={setSelectedFilterData} selectKey="filter_sources"  selectedFilterName={selectedFilterName}  setSelectedFilterName={setSelectedFilterName}  expanded={expanded} filterList={filterList?.sources} setExpanded={setExpanded} />

        <FilterList handleChange={handleChange} title={ArtistTitle?.length>0 && ArtistTitle?.length<4?`${ArtistTitle} `: ArtistTitle?.length>3?`${ArtistTitle?.length} selected `:"Artists"} isSelectedFilters={ArtistTitle?.length>0?true:false} selectKey="filter_artists" selectedFilterData={selectedFilterData} setSelectedFilterData={setSelectedFilterData}  selectedFilterName={selectedFilterName}  setSelectedFilterName={setSelectedFilterName}   panel="panel4" expanded={expanded} filterList={filterList?.artists} setExpanded={setExpanded} />

        <FilterList handleChange={handleChange} title={GeoTitle?.length>0 && GeoTitle?.length<4?`${GeoTitle} `: GeoTitle?.length>3?`${GeoTitle?.length} selected `:"Geos"} selectKey="filter_geos" isSelectedFilters={GeoTitle?.length>0?true:false} selectedFilterData={selectedFilterData} setSelectedFilterData={setSelectedFilterData}  panel="panel5" expanded={expanded}  selectedFilterName={selectedFilterName}  setSelectedFilterName={setSelectedFilterName}  filterList={filterList?.regions} setExpanded={setExpanded} />

        <FilterList handleChange={handleChange}  title={PlaylistTitle?.length>0 && PlaylistTitle?.length<4?`${PlaylistTitle} `: PlaylistTitle?.length>3?`${PlaylistTitle?.length} selected `:"Playlists"} isSelectedFilters={PlaylistTitle?.length>0?true:false}  selectKey="filter_playlists" selectedFilterData={selectedFilterData} setSelectedFilterData={setSelectedFilterData}  selectedFilterName={selectedFilterName}  setSelectedFilterName={setSelectedFilterName}   panel="panel6" expanded={expanded} filterList={filterList?.playlists} setExpanded={setExpanded} />

        <FilterList handleChange={handleChange}  title={AlbumsTitle?.length>0 && AlbumsTitle?.length<4?`${AlbumsTitle} `: AlbumsTitle?.length>3?`${AlbumsTitle?.length} selected `:"Albums"} isSelectedFilters={AlbumsTitle?.length>0?true:false} selectKey="filter_albums" selectedFilterData={selectedFilterData} setSelectedFilterData={setSelectedFilterData}  selectedFilterName={selectedFilterName}  setSelectedFilterName={setSelectedFilterName}  panel="panel7" expanded={expanded} filterList={filterList?.albums} setExpanded={setExpanded} />
        </div>
      </div>

      <div ref={filterPanelRefMobile}  className="lg:hidden block fixed top-0 left-0 !bg-white  !z-[99999999] w-full h-[100vh]">
        <div className="bg-[#D9D9D9] dark:!bg-[#171717] px-[14px] py-[8px]  !z-[99999999] flex justify-between items-center">
        
      {/* <div  className="flex gap-4 justify-start items-center"><div onClick={()=> { op.current.toggle(false);    dispatch(filterStatus(false)) }} className=""><svg xmlns="http://www.w3.org/2000/svg" width="7" height="13" viewBox="0 0 7 13" fill="none"> */}

      <div  className="flex gap-4 justify-start items-center"><div onClick={()=>setIsFilterOpen(false)} className=""><svg xmlns="http://www.w3.org/2000/svg" width="7" height="13" viewBox="0 0 7 13" fill="none">
            <path d="M6 11.3203L1 6.16005L6 0.99979" stroke="#312522" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg></div><p>Filters</p>
            </div>
                
            <div className="flex gap-3 items-center">
                <FilterButtons onClick={()=>clearFilters()}>Clear</FilterButtons>
                <FilterButtons onClick={()=>applyFilters()}>Apply</FilterButtons>
            </div>
        </div>
        <div className="grid grid-cols-12 bg-white dark:bg-[#0D0D0D] h-full ">
            <div className="col-span-3 pt-5">
                <div className="text-center">
                   {filterList?.genres?.length>0 && <p onClick={()=>setOpenFilter("genre")} className={` ${openFilter==="genre"?"text-[#F1B942]":"text-[#A8A8A8]"} text-[14px] font-semibold my-5`}>Genre</p>}

                  { filterList?.decades?.length>0 &&<p onClick={()=>setOpenFilter("decade")} className={` ${openFilter==="decade"?"text-[#F1B942]":"text-[#A8A8A8]"} text-[14px] font-semibold my-5`}>Decade</p>}

                   { filterList?.sources?.length>0 && <p onClick={()=>setOpenFilter("basicsource")} className={` ${openFilter==="basicsource"?"text-[#F1B942]":"text-[#A8A8A8]"} text-[14px] font-semibold my-5`}>Source</p>}

                 { filterList?.artists?.length>0 &&<p onClick={()=>setOpenFilter("Artists")} className={` ${openFilter==="Artists"?"text-[#F1B942]":"text-[#A8A8A8]"} text-[14px] font-semibold my-5`}>Artists</p>}

                    { filterList?.regions?.length>0 &&<p onClick={()=>setOpenFilter("Geos")} className={`  ${openFilter==="Geos"?"text-[#F1B942]":"text-[#A8A8A8]"}  text-[14px] font-semibold my-5`}>Geos</p>}

                    { filterList?.playlists?.length>0 &&<p onClick={()=>setOpenFilter("Playlists")} className={`  ${openFilter==="Playlists"?"text-[#F1B942]":"text-[#A8A8A8]"}  text-[14px] font-semibold my-5`}>Playlists</p>}

                    { filterList?.albums?.length>0 &&<p onClick={()=>setOpenFilter("Albums")} className={`  ${openFilter==="Albums"?"text-[#F1B942]":"text-[#A8A8A8]"}  text-[14px] font-semibold my-5`}>Albums</p>}
                </div>
            </div>
            <div className="col-span-9  relative h-[100vh]">
                <div className=" h-[85vh] py-[12%] dark:!text-white">
                       {openFilter==="genre" && <FilterList  handleChange={handleChange} title="Genere" selectedFilterData={selectedFilterData} setSelectedFilterData={setSelectedFilterData} selectKey="filter_genres" panel="panel1" expanded={expanded} filterList={filterList?.genres} setExpanded={setExpanded} selectedFilterName={selectedFilterName}  setSelectedFilterName={setSelectedFilterName} />}

                        {openFilter==="decade" && <FilterList  handleChange={handleChange}  selectedFilterData={selectedFilterData} setSelectedFilterData={setSelectedFilterData}  title="Decade" selectKey="filter_decades" panel="panel2" expanded={expanded} selectedFilterName={selectedFilterName}  setSelectedFilterName={setSelectedFilterName} filterList={filterList?.decades} setExpanded={setExpanded} />}

                       {openFilter==="basicsource" && <FilterList  handleChange={handleChange} title="Source" panel="panel3" selectedFilterData={selectedFilterData} setSelectedFilterData={setSelectedFilterData} selectKey="filter_sources" expanded={expanded} selectedFilterName={selectedFilterName}  setSelectedFilterName={setSelectedFilterName} filterList={filterList?.sources} setExpanded={setExpanded} />}

                        {openFilter==="Artists" &&<FilterList  handleChange={handleChange} selectedFilterData={selectedFilterData} setSelectedFilterData={setSelectedFilterData}  title="Artist" selectKey="filter_artists" panel="panel4" selectedFilterName={selectedFilterName}  setSelectedFilterName={setSelectedFilterName} expanded={expanded} filterList={filterList?.artists} setExpanded={setExpanded} />}

                       { openFilter==="Geos" &&<FilterList  handleChange={handleChange} selectedFilterData={selectedFilterData} setSelectedFilterData={setSelectedFilterData} selectedFilterName={selectedFilterName}  setSelectedFilterName={setSelectedFilterName}  title="Geo" selectKey="filter_geos" panel="panel5" expanded={expanded} filterList={filterList?.regions} setExpanded={setExpanded} />}

                        {openFilter==="Playlists" &&<FilterList  handleChange={handleChange} selectedFilterData={selectedFilterData} setSelectedFilterData={setSelectedFilterData} selectedFilterName={selectedFilterName}  setSelectedFilterName={setSelectedFilterName}  title="Playlist" selectKey="filter_playlists" panel="panel6" expanded={expanded} filterList={filterList?.playlists} setExpanded={setExpanded} />}

                       {openFilter==="Albums" && <FilterList  handleChange={handleChange} selectedFilterData={selectedFilterData} setSelectedFilterData={setSelectedFilterData}  selectedFilterName={selectedFilterName}  setSelectedFilterName={setSelectedFilterName} title="Albums" selectKey="filter_albums" panel="panel7" expanded={expanded} filterList={filterList?.albums} setExpanded={setExpanded} />}
                </div>
                {/* <div className='lg:hidden  px-3 mt-auto h-[15vh] flex justify-end    w-full  bg-white'>
                    <FilterButtons>Apply</FilterButtons>
                </div> */}
            </div>

        </div>
        </div>
        </div>
    )
}

export default Filters
import React from 'react'

const BioComponent = ({t_bio,profession}) => {
  return (
    <div>
      <div className='flex justify-start flex-wrap items-center gap-x-3 my-4'>
      <p className='lg:text-xl text-sm font-semibold dark:text-white'>Pro Info:</p>
      {profession?.map((item,index)=>{
        return(
          <button key={index} className="bg-[#F1B942] py-2 px-3 rounded-full text-white text-xs capitalize">{item}</button>
        )
      })}
      </div>
    <p className='text-sm font-normal dark:text-[#DDD1CE]'>{t_bio?t_bio:"Bio not found !!"}
    </p>
    </div>
  )
}

export default BioComponent
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux';
import { handleCurrentSongIndex, handleIsRecommended, handlePlayerStatus } from '../../../../../store/playerSlice';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import RecommendIcon from '@mui/icons-material/Recommend';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import QueueMusicOutlinedIcon from '@mui/icons-material/QueueMusicOutlined';
import { useLocation } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useApi } from '../../../../../api/api';
import { recommendDetail, recommendStatus } from '../../../../../store/trackSlice';

const PlayerIframe = ({songUrl, trackData, currentSongIndex,isPlaying,setIsPlaying,recommendations,handleRecommendedSong}) => {
  const dispatch = useDispatch()
  const iframeRef = useRef(null);
  const containerRef = useRef(null);
  const [showRecommend, setShowRecommend] = useState(false);


  // const prevSong = async() => {
  //     dispatch(handleCurrentSongIndex((prevIndex) =>
  //     prevIndex > 0 ? prevIndex - 1 : trackData?.length - 1))
  //     setIsPlaying(true); 
  // };

  const prevSong = async(id) => {
    let newIndex = (currentSongIndex - 1 + trackData?.length) % trackData?.length
    dispatch(handleCurrentSongIndex(newIndex))
    localStorage.setItem('playingCurrentSong',  JSON.stringify(trackData[newIndex]))
    dispatch(handlePlayerStatus(trackData[newIndex]?.e_platform));
    setIsPlaying(true); // Automatically start playing the next song
    localStorage.setItem('isPlaying', true);
  };

  const nextSong = async(id) => {
    let newIndex =  (currentSongIndex + 1) % trackData?.length;
    dispatch(handleCurrentSongIndex(newIndex))
    localStorage.setItem('playingCurrentSong',  JSON.stringify(trackData[newIndex]))
    dispatch(handlePlayerStatus(trackData[newIndex]?.e_platform));
    setIsPlaying(true); // Automatically start playing the next song
    localStorage.setItem('isPlaying', true);
  };

  // useEffect(() => {
  //   // if (!isFirstRender.current && iframeRef.current) {
     
  //   // }

  //   console.log("songUrl?.embed_link",songUrl?.embed_link);
  //   iframeRef.current.src = songUrl?.embed_link;
    
  //   // Update isFirstRender to false after the first render
  //   // isFirstRender.current = false;
  // }, [currentSongIndex]);

    console.log("songUrl?.embed_link", currentSongIndex);

  useEffect(() => {
    // console.log("songUrl?.embed_link", iframeRef.current);
   
     if (iframeRef.current) {
      // console.log("songUrl?.embed_link", songUrl?.embed_link);
      iframeRef.current.src = songUrl?.embed_link;
    }
  }, [currentSongIndex]);


  const handleTouchSkipPrev = (id) => {
    console.log("prevSong called");

    prevSong(id);
  };

  const handleTouchSkipNext = (id) => {
    nextSong(id);
  };

  const toggleRecommend = () => {
    setShowRecommend(!showRecommend);
  };


  useEffect(() => {
      if(showRecommend){
        containerRef.current.scrollTop = 0; // Scroll to the top
      }
  }, [currentSongIndex]);



  // trackData?.length > 0
  return (
    <div className={`fixed w-full h-[95px] bottom-0 bg-black  rounded-lg p-2 z-[999] drop-shadow-3xl shadow-xl`}>
        {true ? (
          <div>
            <iframe
              ref={iframeRef}
              // src={songUrl1?.embed_link}
              title={songUrl?.t_title}
              key={currentSongIndex} // Add key attribute to force reload
              className={`lg:w-full w-full ${songUrl?.e_platform==="DailyMotion"?"lg:!h-20 !h-20":" lg:!h-20 !h-20"}   frame`}
              frameBorder='0'
              allowtransparency='true'
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              autoPlay={isPlaying} // Start playing when isPlaying is true
            ></iframe>
             <button className='absolute top-3 right-20' onClick={()=>prevSong(trackData?.id)} onTouchEnd={()=>handleTouchSkipPrev(trackData?.id)}>
                <SkipPreviousIcon className='!text-white' />
              </button>
              <button  className='absolute top-3 right-10' onClick={()=>nextSong(trackData?.id)}  onTouchEnd={()=>handleTouchSkipNext(trackData?.id)} >
                <SkipNextIcon className='!text-white dark:!text-white' />
              </button>
              <div className={`absolute  lg:top-3 right-32 ${songUrl?.e_platform==="DailyMotion"?"!bottom-4 !right-4":"bottom-4"}`}>
                <RecommendIcon onClick={toggleRecommend} className='ml-3 !text-3xl cursor-pointer lg:!text-base !text-white'/>
              </div>
            <div className=''>
               {showRecommend && (
                <div  className={`absolute text-xs ${songUrl?.e_platform==="DailyMotion"?"-top-[416%]":"-top-[416%]"}  z-[99999999] right-0 px-3   drop-shadow-xl overflow-hidden h-[400px] w-[364px] bg-white dark:bg-[#151515]  rounded-[25px]`}>
                  <div className='py-3 bg-white relative flex justify-start items-center gap-x-4 dark:bg-[#151515] dark:text-white'>
                    <HighlightOffOutlinedIcon onClick={()=>setShowRecommend(false)}  className='absolute !text-[#A8A8A8] cursor-pointer top-5 right-5'/>
                      <div className='bg-[#F1B942] flex justify-center items-center p-4 w-[80px] rounded-lg'>
                      <QueueMusicOutlinedIcon className='!text-white'/>
                      </div>
                      <p className='text-lg font-semibold'>Recommendation</p>
                  </div>
                  <ul className='h-[250px]  overflow-scroll'  ref={containerRef}>
                    {recommendations?.map((RecomSongs,index) => (
                      <div  onClick={() => handleRecommendedSong(RecomSongs,index)} className='bg-[#FAFAF8] cursor-pointer rounded-lg p-3 flex justify-between items-center my-3'>
                          <div className='w-[50px] h-[50px] rounded-lg'>
                            <img src={RecomSongs?.t_image_path} alt='' className='object-cover w-full h-full' />
                          </div>
                          <div className='w-[50%]'>
                            <p className='line-clamp-1'>{RecomSongs?.t_title}</p>
                            <p>{RecomSongs.artist_name}</p>
                          </div>
                          <div>
                            <p>{RecomSongs?.i_duration_display}</p>
                          </div>
                      </div>
                    ))}
                  </ul>
                </div>
        )}
            </div>
          </div>
        ) : (
          <div>No songs available</div>
        )}
      </div>
  )
}

export default PlayerIframe
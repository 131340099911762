
import { useEffect, useState } from "react";
import { Link } from "react-router-dom"
import { useApi } from "../../../../api/api";
import CommonListing from "../../../../view/components/collectionPagesComponents/CommonListing";
import BackToPage from "../../../../view/components/common/BacktoPage/BackToPage";
import H220 from "../../../../view/components/common/Typograpgy/H220";


function DecadesPage(){
    const { handleGetRequest,handlePostRequest } = useApi();
    const [decadesData, setDecadesData] = useState();
    useEffect(()=>{
        ;(async () => {
            const decadesRes = await handleGetRequest(`decades`,false,true)
            setDecadesData(decadesRes?.data)
        })();
    },[])

    const handleDeletePlaylist = async(id) => {
        const playlistLikePayload = {
            playlist_ids:[id]
        }
        const managePlaylistDeleteRes = await handlePostRequest(`${`playlists-delete`}`,playlistLikePayload,true, true)
        const decadesRes = await handleGetRequest(`decades`,false,true)
            setDecadesData(decadesRes?.data)
    }
      
    return(
        <div>
           {/* <BackToPage pageRoute="/" backTo="Home"/> */}
           {decadesData?.length<1?
            <div className="!px-2 lg:px-0">
            <H220 className="">Decades</H220>
            <div className="py-16 bg-white dark:bg-[#151515] drop-shadow-lg w-full mx-auto flex justify-center items-center rounded-xl">
            <p className="lg:text-xl text-lg font-semibold text-center lg:text-left dark:text-white">
                    <Link to="/library" className="text-[#F1B942]">Upload</Link> or  <Link to="/library" className="text-[#F1B942]">Sync</Link> your music service to listen to your favorite decades
                    </p>
            </div>
            </div>
            :
            <CommonListing listing={decadesData} flag="collectionPage" title="Decades" handleDeletePlaylist={handleDeletePlaylist}/>
            }
        </div>
    )
}

export default DecadesPage
import {  useEffect, useRef, useState } from "react"
import Sync from "../../view/components/Sync/Sync"
import Upload from "../../view/components/Upload/Upload"
import { useApi } from "../../api/api"
import { trackDetail } from "../../store/trackSlice"
import { useDispatch, useSelector } from "react-redux"
import BackToPage from "../../view/components/common/BacktoPage/BackToPage"
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDebouncedValue } from '@mantine/hooks';
import { fetchPlaylists, initializeMusicKit } from "../../MusicKitService"
import { handleCreatePlaylistStatus } from "../../store/createPlaylistSlice"
import globalToast from "../../hooks/globalToast"
import { profileDetail } from "../../store/authenticationSlice"
// import { initializeMusicKit, fetchPlaylists } from './MusicKitService';


const Library = () => {
    const [nav,setNav] = useState(1)
    const dispatch = useDispatch()
    const { handleGetRequest, handlePostRequest } = useApi();
    const [syncList, setSyncList] = useState([])
    const [syncPlatform, setSyncPlatform] = useState([])
    const [selectedPlatform, setSelectedPlatform] = useState("")
    const [isSyncCard, setIsSyncCard] = useState(false)
    const [isSyncSpecificPlaylistCard, setisSyncSpecificPlaylistCard] = useState(false)
    const [uploadSongsData, setUploadSongsData] = useState()
    const [sort, setSort] = useState("alphabetic")
    const [type, setType] = useState("all")
    const [searchQuery, setSearchQuery] = useState("")
    const [debounced] = useDebouncedValue(searchQuery, 300);
    const [isliked, setIsliked] = useState("")
    const [songId, setSongId] = useState("")
    const [songDeleteId, setSongDeleteId] = useState("")
    const isFirstRender = useRef(true);
    const isFirstDeleteRender = useRef(true);
    const trackData = useSelector((state) => state.tracks.trackData);
    console.log("trackData",trackData?.length);
    const [uploadStep, setUploadStep] = useState(0);
    const [isFilterApplied, setIsFilterApplied] = useState()
    const [filter, setFilter] = useState()
    const [musicUserToken, setMusicUserToken] = useState(null)
    const [syncSpecificPlaylist, setSyncSpecificPlaylist] = useState([])
    const [selectedPlaylists, setSelectedPlaylists] = useState([]);
    const [playlistTrackCount, setPlaylistTrackCount] = useState();


  console.log("playlistTrackCount",playlistTrackCount);
  

    // useEffect(() => {
    //   const generateDeveloperToken = async () => {
    //     try {
    //       // Step 1: Generate developer token
    //       const response = await fetch('https://curioushit.nevinainfotech.in/api/v1.0/users/apple-developer-token');
    //       const data = await response.json();
         

    // if(data?.status){
    //   console.log("developerToken", data?.data);
    //   window.MusicKit.configure({
    //     developerToken: `Bearer ${data?.data}`, // Assuming the token is provided in the response data
    //     app: {
    //       name: 'app',
    //       build: 'app build'
    //     }
    //   });

    //   const music =  window.MusicKit.getInstance();
    //   console.log("musicUserToken", music);

    //   const token = music.authorize();
    //   setMusicUserToken(token);
    // }
    //       // Step 2: Configure MusicKit with the generated developer token
         
    //     } catch (error) {
    //       console.log("Error generating developer token or configuring MusicKit:", error);
    //     }
    //   };
    
    //   generateDeveloperToken();
    // }, []);

    // console.log("UserToken",musicUserToken);
  
  

    useEffect(() => {
        (async () => {
          const syncedListRes = await handleGetRequest(`sync-result`, false,true);
          if(syncedListRes?.flag){
              console.log("syncedListRes", syncedListRes?.data?.syn_list);
              setSyncList(syncedListRes?.data?.syn_list)
          }
        })();
      }, []);

      useEffect(() => {
        (async () => {
          const syncListRes = await handleGetRequest(`sync`, false,true);
          if(syncListRes?.flag){
              console.log("syncListRes", syncListRes?.data);
              setSyncPlatform(syncListRes?.data)
          }
        })();
      }, []);

      useEffect(() => {
        (async () => {
          const uploadTracksRes = await handleGetRequest(`track?e_platform_name=default&type=${type}&sort_type=${sort}&search_keyword=${debounced}`, false,true);
          if(uploadTracksRes?.flag){
              console.log("uploadTracksRes", uploadTracksRes?.data?.data);
              setUploadSongsData(uploadTracksRes?.data)
              dispatch(trackDetail(uploadTracksRes?.data?.data))
              setUploadStep(uploadTracksRes?.data?.data.length>0?0:1)
            

          }
        })();
      }, [type,debounced,sort]);

      useEffect(() => {
        // Skip the first render
        if (isFirstRender.current) {
          isFirstRender.current = false;
          return;
        }
        
        (async () => {
          const trackLikeRes = await handleGetRequest(`song-favourite?id=${songId}&type=${isliked}`, true,false);
          console.log("trackLikeRes", trackLikeRes?.flag);
          if(trackLikeRes?.flag){


            if(isFilterApplied>0){
              const filterPlayload = {
                apply_special_filter:1,
                page:1,
                per_page:1000,
                type:"all",
                ...filter
                }
              const filterTrackRes = await handlePostRequest(`track`,filterPlayload, false, false);
              console.log("filterTrackRes", filterTrackRes?.data?.data);
              localStorage.setItem('TrackData', JSON.stringify(filterTrackRes?.data?.data))
              dispatch(trackDetail(filterTrackRes?.data?.data))
            }else{
              const albumsSongsRes = await handleGetRequest(`${`track?e_platform_name=default&type=${type}&sort_type=${sort}&search_keyword=${debounced}`}`,false,false)
              console.log("albumsSongsRes",albumsSongsRes?.data?.data);
              localStorage.setItem('TrackData', JSON.stringify(albumsSongsRes?.data?.data))
              dispatch(trackDetail(albumsSongsRes?.data?.data))
            }

            // const albumsSongsRes = await handleGetRequest(`${`track?e_platform_name=default`}`,false)
            //   console.log("albumsSongsRes",albumsSongsRes?.data?.data);
            //   localStorage.setItem('TrackData', JSON.stringify(albumsSongsRes?.data?.data))
            //   dispatch(trackDetail(albumsSongsRes?.data?.data))
          }
        })();
      }, [isliked,songId]);
  
      useEffect(() => {
        // Skip the first render
        if (isFirstDeleteRender.current) {
          isFirstDeleteRender.current = false;
          return;
        }
        
        (async () => {
          const trackDeleteRes = await handleGetRequest(`remove-song/${songDeleteId}`, true,true);
          console.log("trackDeleteRes", trackDeleteRes?.flag);
          if(trackDeleteRes?.flag){
            const albumsSongsRes = await handleGetRequest(`${`track?e_platform_name=default`}`,false,true)
            console.log("albumsSongsRes",albumsSongsRes?.data?.data);
            localStorage.setItem('TrackData', JSON.stringify(albumsSongsRes?.data?.data))

            dispatch(trackDetail(albumsSongsRes?.data?.data))
            if(!albumsSongsRes?.data?.data.length>0){
              setUploadStep(1)
            }
          }
        })();

       
      }, [songDeleteId]);

      const [popupWindow,setPopupWindow] = useState()
      const [callbackUrl,setCallbackUrl] = useState(null)
      console.log("popupWindow",popupWindow);
      const [isPremiumModal,setisPremiumModal] = useState(false)

      const userData = useSelector((state) => state.auth.profileData);


      const connectPlatform = async(platform) => {

        if(!userData?.user?.is_premium_subscriber && userData?.sync_platform_count>1){
          setisPremiumModal(true)
          return
        }

        if(selectedPlatform===""){
          //  toast.error("Please Select Platform");
           globalToast("Please Select Platform",true)

          return
        }
        console.log("connectPlatform");

        // const options = "width=500,height=500 "
        const width = 500;
        const height = 500;
        const left = window.screen.width / 2 - width / 2;
        const top = window.screen.height / 2 - height / 2;
      
        const options = `width=${width},height=${height},left=${left},top=${top}`;
        const connectPlatformRes = await handleGetRequest(`${selectedPlatform}/login`, false,true);
        // const connectPlatformRes = await handleGetRequest(`dailymotion/login`, false);
        const redirectUrlRes = connectPlatformRes?.data?.url;
        console.log("redirectUrlRes", redirectUrlRes);
        setPopupWindow(window.open(redirectUrlRes,"_blank",options))
        };

      useEffect(() => {
        console.log("callbackUrlUseeffect",selectedPlatform);
      
        const handlePostMessage = async (event) => {
        const selectedPlatformCallback = JSON.parse(localStorage.getItem("platform"))

          const {state} = event.data 
          const {code} = event.data 
          const {uid} = event.data 

          const platformsPayload = {
            code:code,
            state:state
          }
      
          if (event.origin === "https://curioushit-frontend.nevinainfotech.in" && selectedPlatformCallback) {
            // Assuming handlePostRequest is defined elsewhere and takes appropriate arguments
            const callbackRes = await handlePostRequest(`${selectedPlatformCallback}/callback`, selectedPlatformCallback==="dailymotion"? event.data: platformsPayload, true, true);
            console.log("callbackRes", callbackRes?.flag);
            if(callbackRes?.flag){
            const syncRes = await handleGetRequest(`${selectedPlatformCallback}/sync?state=${state}${selectedPlatformCallback==="dailymotion"?`&uid=${uid}`:''}`, true,true);
            console.log("syncRes",syncRes);
            if(syncRes?.flag){

              setSyncSpecificPlaylist(syncRes?.data?.playlistSync)
              setIsSyncCard(false)
              setisSyncSpecificPlaylistCard(true)
              const profileDataReq = await handleGetRequest("profile-detail",false, true)
              dispatch(profileDetail(profileDataReq?.data))
              // const syncedListRes = await handleGetRequest(`sync-result`, false,true);
              // if(syncedListRes?.flag){
              //     console.log("syncedListRes", syncedListRes?.data?.syn_list);
              //     setSyncList(syncedListRes?.data?.syn_list)
              // }
              // const syncListRes = await handleGetRequest(`sync`, false,true);
              // if(syncListRes?.flag){
              //     console.log("syncListRes", syncListRes?.data);
              //     setSyncPlatform(syncListRes?.data)
              // }
            }
            }
          }
        };
      
        window.addEventListener("message", handlePostMessage, false);
      
        return () => {
          window.removeEventListener("message", handlePostMessage);
        };
      }, []); // Empty dependency array to run effect only once


      const handleSyncSpecificPlaylistSync = async() => {

        console.log("selectedPlaylists",selectedPlaylists);

           if(!selectedPlaylists?.length>0){
            // alert("select playlist")
            globalToast("Select atleast 1 playlist",true)
              return
            }
        
              const selectedPlatformCallback = JSON.parse(localStorage.getItem("platform"))

              const payload = {
                playlist_ids:selectedPlaylists
              }

              const syncedKeepPlaylistRes = await handlePostRequest(`${selectedPlatformCallback}/keepSelectPlayList`,payload, false,true);
              console.log("syncedKeepPlaylistRes", syncedKeepPlaylistRes);
              if(syncedKeepPlaylistRes?.flag){
                setPlaylistTrackCount(syncedKeepPlaylistRes?.data)
                const syncedListRes = await handleGetRequest(`sync-result`, false,true);
                if(syncedListRes?.flag){
                    console.log("syncedListRes", syncedListRes?.data?.syn_list);
                    setSyncList(syncedListRes?.data?.syn_list)
                }
                const syncListRes = await handleGetRequest(`sync`, false,true);
                if(syncListRes?.flag){
                    console.log("syncListRes", syncListRes?.data);
                    setSyncPlatform(syncListRes?.data)
                }
                setisSyncSpecificPlaylistCard(false)
                setSelectedPlaylists([])
                const profileDataReq = await handleGetRequest("profile-detail",false, true)
                dispatch(profileDetail(profileDataReq?.data))

              }
             
      }

    const deletePlatform = async (platform) => {
      const deletePlatformRes = await handleGetRequest(`sync-delete?platform=${platform}`, true,true);
      console.log("deletePlatformRes", deletePlatformRes);
        const syncedListRes = await handleGetRequest(`sync-result`, false,true);
        if(syncedListRes?.flag){
            console.log("syncedListRes", syncedListRes?.data?.syn_list);
            setSyncList(syncedListRes?.data?.syn_list)
        }
        const syncListRes = await handleGetRequest(`sync`, false,true);
        if(syncListRes?.flag){
            console.log("syncListRes", syncListRes?.data);
            setSyncPlatform(syncListRes?.data)
            const profileDataReq = await handleGetRequest("profile-detail",false, true)
            dispatch(profileDetail(profileDataReq?.data))
        }

      }

      const reSyncPlatform = async (platform,state) => {
        const reSyncRes = await handleGetRequest(`${platform}/sync?state=${state}`, true,true);
        console.log("reSyncRes", reSyncRes);
          const syncedListRes = await handleGetRequest(`sync-result`, false,true);
          if(syncedListRes?.flag){
              console.log("syncedListRes", syncedListRes?.data?.syn_list);
              setSyncList(syncedListRes?.data?.syn_list)
          }
          const syncListRes = await handleGetRequest(`sync`, false,true);
          if(syncListRes?.flag){
              console.log("syncListRes", syncListRes?.data);
              setSyncPlatform(syncListRes?.data)
          }
        
    }

    return(
        <div className="min-h-[300px] px-3 lg:px-0">
        {/* <BackToPage pageRoute="/" backTo="Home"/> */}
            <div className="grid lg:grid-cols-12 grid-cols-3  content-center text-center  text-[#312522] text-xl font-light gap-x-4 my-4">
                <p className={`cursor-pointer ${nav===1?"text-[#F1B942] dark:text-[#DDD1CE] font-bold":"dark:text-[#DDD1CE]"} `} onClick={()=>{setNav(1); setPlaylistTrackCount()}}>Sync</p>
               
                <p className={`cursor-pointer ${nav===2?"text-[#F1B942] dark:text-[#DDD1CE] font-bold":"dark:text-[#DDD1CE]"} `} onClick={()=>setNav(2)}>Uploads</p>
            </div>
            <div className="w-full h-[5px] bg-[#D9D9D9] dark:bg-[#333333] grid  lg:grid-cols-12 grid-cols-3 lg:mb-12">
                <div className={`${nav===1?"bg-[#F1B942]":""}`}></div>
                <div className={`${nav===2?"bg-[#F1B942]":""}`}></div>
                <div className={`${nav===3?"bg-[#F1B942]":""}`}></div>
            </div>
            {nav===1&&<Sync setisPremiumModal={setisPremiumModal} isPremiumModal={isPremiumModal} connectPlatform={connectPlatform} syncList={syncList} syncPlatform={syncPlatform} setSelectedPlatform={setSelectedPlatform} selectedPlatform={selectedPlatform} setIsSyncCard={setIsSyncCard} isSyncCard={isSyncCard} setNav={setNav} deletePlatform={deletePlatform} reSyncPlatform={reSyncPlatform} syncSpecificPlaylist={syncSpecificPlaylist} handleSyncSpecificPlaylistSync={handleSyncSpecificPlaylistSync} setSelectedPlaylists={setSelectedPlaylists} selectedPlaylists={selectedPlaylists} isSyncSpecificPlaylistCard={isSyncSpecificPlaylistCard} setisSyncSpecificPlaylistCard={isSyncSpecificPlaylistCard} playlistTrackCount={playlistTrackCount} setPlaylistTrackCount={setPlaylistTrackCount}/>}

            {(nav===2)&&<Upload setIsFilterApplied={setIsFilterApplied} setFilter={setFilter} setUploadSongsData={setUploadSongsData} uploadSongsData={uploadSongsData} setSort={setSort} type={type} setType={setType} setSearchQuery={setSearchQuery} searchQuery={searchQuery} setIsliked={setIsliked} setSongId={setSongId} setSongDeleteId={setSongDeleteId}  setNav={setNav} setUploadStep={setUploadStep} uploadStep={uploadStep}/>}
        </div>
    )
}

export default Library

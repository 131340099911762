import React from 'react'
import CommonListing from '../../collectionPagesComponents/CommonListing'
import GenresSwiper from '../GenresSwiper'

const AlbumsComponent = ({albumList,handleDeletePlaylist}) => {
  return (
    <div className='dark:text-[#DDD1CE]'>
       <CommonListing listing={albumList} title="albums" flag="profilePage" hidetitle={true} handleDeletePlaylist={handleDeletePlaylist} fromprofile="/collection/albums" />
    </div>
  )
}

export default AlbumsComponent
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
 isLoading:false
};

// Slice
export const loaderSlice = createSlice({
  name: "loader",
  initialState,
  reducers: {
    // updateLoadingState(state, action) {
    //   state.isLoading = action.payload;
    // },
    handleCloseLoader(state){
        state.isLoading = false;
      },
      handleOpenLoader(state){

        state.isLoading = true;
      },
  },
});

export const {handleCloseLoader,handleOpenLoader} = loaderSlice.actions;

export default loaderSlice.reducer;
import React from 'react'
import SearchComponent from '../SearchComponent'
import CommunityLayout from '../CommunityLayout'
import CommunityCardHeader from '../CommunityCardHeader'
import Button1 from '../../common/GlobalButtonLayout/Button1'
import Button2 from '../../common/GlobalButtonLayout/Button2'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Tooltip } from '@mui/material'

const Followers = ({acceptRejectUser,cancelUser}) => {
    const followerUser = useSelector((state) => state.follower.followerUser);
    const followerRequestUser = useSelector((state) => state.follower.followerRequest);
   
  return (
    <div>
    <div className='my-10'>
    {followerRequestUser?.length>0 && <CommunityLayout>
       <CommunityCardHeader title="Pending requests" routeUrl="/community/follower/pending-requests" count={followerRequestUser?.length>4}/>
       <div className='grid lg:grid-cols-1 xl:grid-cols-2 grid-cols-1  gap-x-40 my-5'>
       {followerRequestUser?.map((items,index)=>{
           return(
               <div className={` ${index>3?"hidden":"flex justify-between items-center gap-5"}  my-5`}>
                   <Link to={`/other-user/${items?.id}`} className='flex justify-start gap-x-3 items-center'>
                   <div className='my-1 w-14 h-14 lg:w-16 lg:h-16 lg:mx-auto rounded-full'>
                       <img src={items?.t_image} alt='' className='w-full h-full lg:w-16 lg:h-16 object-cover rounded-full' />
                   </div>
                   <div className='w-20'>
                   <Tooltip title={items?.v_name?items?.v_name:"User Name"}><p className='capitalize line-clamp-1 font-semibold'>{items?.v_name?items?.v_name:"User Name"}</p></Tooltip>
                   </div>
                   </Link>
                   <div className='flex justify-start items-center gap-x-4'>
                        {/* <Button1 onClick={()=>acceptRejectUser(items?.id,"rejected")} className="dark:hover:!text-[#F1B942]">Cancel</Button1>
                        <Button1 onClick={()=>acceptRejectUser(items?.id,"accepted")} className="dark:hover:!text-[#F1B942]">Accept</Button1> */}
                        <div className='my-2'>
                        <Button2 onClick={()=>acceptRejectUser(items?.id,"rejected")} className="!text-center dark:text-[#F1B942] !py-0 !w-20 lg:!w-32">Cancel</Button2>
                      </div>
                      <div className='my-2'>
                        <Button2 onClick={()=>acceptRejectUser(items?.id,"accepted")} className="!text-center dark:text-[#F1B942] !py-0 !w-20  lg:!w-32">Accept</Button2>
                      </div>
                    </div>
               </div>
           )
       })}
       
       </div>
   </CommunityLayout>}

   {followerUser?.length>0 && <CommunityLayout>
       <CommunityCardHeader count={followerUser?.length>6?true:false} title="Your curiousHit followers" routeUrl="/community/follower/curiousHit-follower"/>
       <div className='lg:grid lg:grid-cols-6   gap-x-5 my-5 text-center'>
          {followerUser?.map((items, index)=>{
           return(
            <div className={`grid grid-cols-3 ${index>5?"hidden":" lg:block"} ${items?.is_unfollowed?"!hidden":""} gap-x-5 my-2`}>
            <Link to={`/other-user/${items?.id}`} className='flex justify-start items-center gap-x-4 lg:block col-span-2'>
            <div className='my-1 min-w-14 max-w-14 h-14 lg:min-w-32 lg:max-w-32 lg:h-32 lg:mx-auto rounded-full '>
                <img src={items?.t_image} alt='' className=' w-full h-full object-cover rounded-full' />
            </div>
            <div className='max-w-[100px] lg:max-w-auto lg:mx-auto'>
            <Tooltip title={items?.v_name?items?.v_name:"User Name"}><p className='mt-1 font-semibold lg:text-xl text-sm capitalize line-clamp-1 text-wrap text-left lg:text-center'>{items?.v_name?items?.v_name:"User Name"}</p></Tooltip>

            {items?.remove_date?<p className='mb-1  lg:text-lg text-left lg:text-center  text-xs'>{items?.remove_date}</p>:<p className='mb-1  lg:text-lg  text-left lg:text-center  text-xs'>{items?.total_followers} Followers</p>}
            </div>
            </Link>
            <Button2  onClick={()=>cancelUser(items?.id)}   className="!text-center !w-full dark:text-[#F1B942]">{items?.remove_date?"Invite to follow":"Remove"}</Button2>
            </div>  
           )
          })}
       </div>
   </CommunityLayout>}

   {/* <CommunityLayout>
       <CommunityCardHeader title="Your instagram Friends on CuriousHit !" routeUrl="/community/follower/instagram-friends-follower"/>
       <div className='lg:grid lg:grid-cols-6   gap-x-5 my-5 text-center'>
          {curioushitFollowers.map((items, index)=>{
           return(
               <div className='grid grid-cols-3 gap-x-5 my-2 lg:block'>
                   <div className='flex justify-start items-center gap-x-4 lg:block col-span-2'>
                   <div className='my-1 w-14 h-14 lg:w-32 lg:h-32 lg:mx-auto rounded-full '>
                       <img src='/images/communityimg.png' alt='' className=' w-full h-full object-cover' />
                   </div>
                   <div>
                   <p className='mt-1 font-semibold lg:text-xl text-sm '>Udit Narayan</p>
                   <p className='mb-1  lg:text-lg  text-xs'>12.3K Followers</p>
                   </div>
                   </div>
                   <Button2 className="!text-center dark:text-[#F1B942]">See Profile</Button2>
               </div>
           )
          })}
       </div>
   </CommunityLayout> */}

   {/* <CommunityLayout>
       <CommunityCardHeader title="Your Facebook Friends on CuriousHit !" routeUrl="/community/follower/facebook-friends-follower"/>
       <div className='lg:grid lg:grid-cols-6   gap-x-5 my-5 text-center'>
          {curioushitFollowers.map((items, index)=>{
           return(
               <div className='grid grid-cols-3 gap-x-5 my-2 lg:block'>
                   <div className='flex justify-start items-center gap-x-4 lg:block col-span-2'>
                   <div className='my-1 w-14 h-14 lg:w-32 lg:h-32 lg:mx-auto rounded-full '>
                       <img src='/images/communityimg.png' alt='' className=' w-full h-full object-cover' />
                   </div>
                   <div>
                   <p className='mt-1 font-semibold lg:text-xl text-sm '>Udit Narayan</p>
                   <p className='mb-1  lg:text-lg  text-xs'>12.3K Followers</p>
                   </div>
                   </div>
                   <Button2 className="!text-center dark:text-[#F1B942]">See Profile</Button2>
               </div>
           )
          })}
       </div>
   </CommunityLayout> */}
    </div>
</div>
  )
}

export default Followers
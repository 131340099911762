import React, { useEffect } from 'react'
import CommonListing from '../../collectionPagesComponents/CommonListing'
import { Tooltip } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { handleCurrentSongIndex, handleIsRecommended, handlePlayerStatus } from '../../../../store/playerSlice'
import { handleCountSongs } from '../../../../store/trackLikeSlice'
import ShareRoundedIcon from '@mui/icons-material/ShareRounded';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';


const TracksComponent = ({albumList}) => {
const dispatch = useDispatch()
const countTracks = useSelector((state) => state.likeSongs.countSongs);

  const playSong = (songData,currentSongIndex,id) => {
    dispatch(handleIsRecommended(false))
    localStorage.setItem('playingCurrentSong',  JSON.stringify(songData))
    dispatch(handlePlayerStatus(songData?.e_platform));
    dispatch(handleCurrentSongIndex(currentSongIndex));
    dispatch(handleCountSongs(countTracks.map(item =>
        item.id === id ? { ...item, play_count: item.play_count + 1 } : item
    )))

  }
  return (
    <div className='grid lg:grid-cols-6 grid-cols-2 gap-x-5'>
        {albumList?.map((items,index)=>{
          return(
            <div key={index} className={`${index>5 ? "!hidden":"!block"} cursor-pointer`} >
              <div  className='w-full  h-40 my-3 relative'>
                <img src={items?.t_image_path} alt="" className='w-full h-full rounded-xl object-cover'/>
                <div className="absolute cursor-default h-16 w-full z-[20] bottom-0 bg-gradient-to-t from-neutral-950   bg-opacity-35  flex justify-between items-end pb-2 px-2">
                  <div>
                    <PlayCircleIcon onClick={()=> playSong(items,index,items?.id)} className="!bg-white !text-[#F1B942] !rounded-full !border-none"/>
                  </div>
                  <div>
                  <ShareRoundedIcon className="!text-white !rounded-full !p-1 !bg-[#F1B942] !border-none"/>
                  </div>
              </div>
              </div>
              <Tooltip title={items?.t_title}>
                <p className="text-[#312522] capitalize text-center  text-base font-semibold mb-1 lg:mb-2 line-clamp-1 text-wrap dark:text-[#DDD1CE]">{items?.t_title}</p>
                </Tooltip>
            </div>
          )
        })}
    </div>
  )
}

export default TracksComponent


import { Carousel } from 'primereact/carousel';
import 'primeicons/primeicons.css';

import { useRef } from 'react';


function PlatformSwiper({platforms,setSelectedPlatform,selectedplatform,setpage}){
  const viewportWidth = window.innerWidth;
    const swiperRef = useRef(null);
    const responsiveOptions = [
      {
        breakpoint: '2920px',
        numVisible: 6,
        numScroll: 1
      },
      {
        breakpoint: '1920px',
        numVisible: 6,
        numScroll: 1
    },

      {
          breakpoint: '1400px',
          numVisible: 5,
          numScroll: 1
      },
      {
          breakpoint: '1199px',
          numVisible: 3,
          numScroll: 1
      },
      {
          breakpoint: '767px',
          numVisible: 2,
          numScroll: 1
      },
      {
          breakpoint: '575px',
          numVisible: 2,
          numScroll: 1
      }
  ];
  // ${product?.length>2?"mx-auto":""}
  const productTemplate = (product) => {
    return (
      <div 
      onClick={() => {
            // setSelectedPlatform(platformList);
            setpage(0)
            setSelectedPlatform(product.v_name);
            // setPlaylistData([]);
          }}
          className={`h-[146px] mx-auto w-[130px] ${ (product?.v_name === selectedplatform)?"border-black dark:!border-white dark:!bg-black bg-white":""} border-[2px] p-1 rounded-lg cursor-pointer`}
          style={{
            backgroundColor: product?.v_color_code,
          }}
      >
        <div 
         style={{
          backgroundColor: product?.v_color_code,
        }}
        className='flex justify-center items-center h-full rounded-lg'
        >
          <div>
          <img src={product?.t_image} alt='' className='m-auto'/>
            <p className='text-white text-[14px] font-semibold text-center my-2'> {product?.id === "default"
              ? "Uploads"
              : product?.v_name}</p>
          </div>
        </div>
          </div>
    );
};

const showIcons =viewportWidth>1400?( platforms?.Platforms?.length > 5):viewportWidth>1199?(platforms?.Platforms?.length > 5):viewportWidth>575?( platforms?.Platforms?.length > 2):true
console.log("showIcons",showIcons,viewportWidth);
  return(
    <div className='relative'>
       {platforms?.Platforms?.length>0 && <Carousel  nextIcon={showIcons ? 'pi pi-chevron-right' : 'null'} prevIcon={showIcons ? 'pi pi-chevron-left' : 'null'} value={platforms?.Platforms} numVisible={1} numScroll={1} responsiveOptions={responsiveOptions}  className={`custom-carousel `} circular  itemTemplate={productTemplate} />}

    </div>
  )
}

export default PlatformSwiper;



import React, { useEffect } from 'react'
import H220 from '../../../view/components/common/Typograpgy/H220'
import Button2 from '../../../view/components/common/GlobalButtonLayout/Button2'
import CommunityLayout from '../../../view/components/CommunityComponents/CommunityLayout'
import { useDispatch, useSelector } from 'react-redux'
import { useApi } from '../../../api/api'
import { handleFollowingUserList } from '../../../store/followingSlice'
import { Link } from 'react-router-dom'

const CuriousHitFollowing = () => {
  const { handleGetRequest,handlePostRequest } = useApi();
  const dispatch = useDispatch()
  useEffect(()=>{
    ;(async () => {
        const followingUserRes = await handleGetRequest(`followings`,false,true)
        console.log("followingUserRes",followingUserRes?.data);
        dispatch(handleFollowingUserList(followingUserRes?.data))
    })();
},[])

  const followingUser = useSelector((state) => state.following.followingUser);
  
  const unFollowUser = async(id) => {
    const unFollowUserRes = await handleGetRequest(`unfollow/${id}`,false,true)
    console.log("unFollowUserRes",unFollowUserRes);
    if(unFollowUserRes?.flag){
      const followingUserRes = await handleGetRequest(`followings`,false,true)
            console.log("followingUserRes",followingUserRes?.data);
            dispatch(handleFollowingUserList(followingUserRes?.data))
    }
  }
  return (
    <div>
      <CommunityLayout>
      <H220 className="">Your CuriousHit following</H220>
      <div className='lg:grid lg:grid-cols-6   gap-x-5 my-5 text-center'>
          {followingUser?.map((items, index)=>{
          return(
              <div className={`grid grid-cols-3  gap-x-5 my-2  lg:block`}>
                  <div className='flex justify-start items-center gap-x-4 lg:block col-span-2'>
                  <Link to={`/other-user/${items?.id}`} className="flex lg:block justify-start items-center gap-x-2">
                  <div className='my-1 w-14 h-14 lg:w-32 lg:h-32 lg:mx-auto rounded-full '>
                      <img src={items?.t_image} alt='' className=' w-full h-full object-cover rounded-full' />
                  </div>
                  <div className='max-w-[100px] lg:max-w-auto mx-auto'>
                  <p className='mt-1 font-semibold lg:text-xl text-sm  dark:text-white line-clamp-1 text-left lg:text-center'>{items?.v_name?items?.v_name:"User Name"}</p>
                  <p className='mb-1  lg:text-lg  text-xs dark:text-white capitalize text-left lg:text-center'>{items?.total_followings} Followers</p>
                  </div>
                  </Link>
                  </div>
                  <Button2 onClick={()=>unFollowUser(items?.id)} className="!text-center dark:text-white">Unfollow</Button2>
              </div>
          )
          })}
      </div>
      </CommunityLayout>
    </div>
  )
}

export default CuriousHitFollowing
import { useEffect, useRef, useState } from "react";
import { useApi } from "../../../../api/api";
import { useLocation, useParams } from "react-router";
import CommonDetail from "../../../../view/components/collectionPagesComponents/CommonDetail";
import { trackDetail } from "../../../../store/trackSlice";
import { useDispatch } from "react-redux";
import BackToPage from "../../../../view/components/common/BacktoPage/BackToPage";
import { useDebouncedValue } from '@mantine/hooks';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import globalToast from "../../../../hooks/globalToast";
import { useSearchParams } from "react-router-dom";
import { handleCurrentSongIndex, handleIsRecommended, handlePlayerStatus } from "../../../../store/playerSlice";

function PlaylistDetail(){
  const location = useLocation();
  const parts = location.pathname.split("/");
  const { id } = useParams()
  const paramsid = useParams()
  const { globalid } = useParams()
  const dispatch = useDispatch()
  const { handleGetRequest,handlePostRequest } = useApi();
  const [playlistDetailData, setPlaylistDetailData ] = useState()
  const [searchQuery, setSearchQuery ] = useState("")
  const [debounced] = useDebouncedValue(searchQuery, 300);
  const [type, setType ] = useState("all")
  const [sort, setSort ] = useState("alphabetic")
  const [isliked, setIsliked ] = useState("")
  const [songId, setSongId ] = useState("")
  const [songDeleteId, setSongDeleteId ] = useState("")
  const detailTitles = ["Artists","Tracks","Genres"]
  const isFirstRender = useRef(true);
  const isFirstDeleteRender = useRef(true);
  const [isFilterApplied, setIsFilterApplied] = useState()
  const [filter, setFilter] = useState()
  const [Like, setLike] = useState();

  
  const searchglobalId = location?.search
  const globalId = searchglobalId.includes("globalid=true");
  
  console.log("globalid",globalId);
   
  useEffect(() => {
    ;(async () => {
      const payload = {
        is_global:globalId
      }
        const platformDetailDataReq = await handlePostRequest(`playlist/${id}`, payload, false,true);
        setPlaylistDetailData(platformDetailDataReq?.data)
        // console.log("platformDetailDataReq?.data",platformDetailDataReq?.data?.is_liked);
        setLike(platformDetailDataReq?.data?.is_liked)
      })();
  }, [id]);

  const refreshPlaylist = async() => {
    const payload = {
      is_global:globalId
    }
      const platformDetailDataReq = await handlePostRequest(`playlist/${id}`, payload, false,true);
      setPlaylistDetailData(platformDetailDataReq?.data)
      // console.log("platformDetailDataReq?.data",platformDetailDataReq?.data?.is_liked);
      setLike(platformDetailDataReq?.data?.is_liked)


      const payloadtrack = {
        is_global:globalId
      }
        const playlistSongsRes = await handlePostRequest(`${`playlist-song-data?type=${type}&search_keyword=${debounced}&sort_type=${sort}&perPage=10000000&page=1&v_code=${id}`}`, payloadtrack,false,true)
        localStorage.setItem('TrackData', JSON.stringify(playlistSongsRes?.data?.data))
        dispatch(trackDetail(playlistSongsRes?.data?.data))
}


    useEffect(()=>{
      if(isFilterApplied>0){
        return
      }
        ;(async () => {
          const payload = {
            is_global:globalId
          }
            const playlistSongsRes = await handlePostRequest(`${`playlist-song-data?type=${type}&search_keyword=${debounced}&sort_type=${sort}&perPage=10000000&page=1&v_code=${id}`}`, payload,false,true)
            localStorage.setItem('TrackData', JSON.stringify(playlistSongsRes?.data?.data))
            dispatch(trackDetail(playlistSongsRes?.data?.data))
            if(playlistSongsRes?.flag){

              const params = new URLSearchParams(location.search);
              if (params.get('play') === 'true' && (type==="all" ) ) {
                localStorage.setItem('playingCurrentSong',  JSON.stringify(playlistSongsRes?.data?.data?.length>0&&playlistSongsRes?.data?.data[0]))
                dispatch(handlePlayerStatus(playlistSongsRes?.data?.data?.length>0?playlistSongsRes?.data?.data[0]?.e_platform:false));
                dispatch(handleCurrentSongIndex(playlistSongsRes?.data?.data?.length>0?0:-1))
              }
            }
        })();
    },[type,debounced,sort,id])

    useEffect(() => {
      // Skip the first render
      if (isFirstRender.current) {
        isFirstRender.current = false;
        return;
      }
      
      (async () => {
      //  const trackLikeRes = await handleGetRequest(`song-favourite?id=${songId}&type=${isliked}`, true,false);

        let trackLikeRes
       if(songId){

      trackLikeRes = await handleGetRequest(`song-favourite?id=${songId}&type=${isliked}`, true,false);
       }

        if(songDeleteId){
          const filterPlayload = {
            apply_special_filter:1,
            page:1,
            per_page:1000,
            scope:parts[parts.length - 2],
            filter_id:playlistDetailData?.id,
            type:type,
            search_keyword:debounced,
            ...filter
            }

          const trackDeleteRes = await handleGetRequest(`remove-song/${songDeleteId}`, true,true);
          if(trackDeleteRes?.flag){
          const filterTrackRes = await handlePostRequest(`track`,filterPlayload, false, true);
          console.log("filterTrackRes", filterTrackRes?.data);
          localStorage.setItem('TrackData', JSON.stringify(filterTrackRes?.data?.data))
          dispatch(trackDetail(filterTrackRes?.data?.data))
          }
        }
        if(trackLikeRes?.flag && !songDeleteId){

          if(isFilterApplied>0){
            const filterPlayload = {
              apply_special_filter:1,
              page:1,
              per_page:1000,
              scope:parts[parts.length - 2],
              filter_id:playlistDetailData?.id,
              type:type,
              ...filter
              }
            const filterTrackRes = await handlePostRequest(`track`,filterPlayload, false, true);
            console.log("filterTrackRes", filterTrackRes?.data?.data);
            localStorage.setItem('TrackData', JSON.stringify(filterTrackRes?.data?.data))
            dispatch(trackDetail(filterTrackRes?.data?.data))
          }else{
            const playlistSongsRes = await handleGetRequest(`${`playlist-song-data?type=${type}&search_keyword=${debounced}&sort_type=${sort}&perPage=10000000&page=1&v_code=${id}`}`,false,true)
            console.log("adasdasd",playlistSongsRes);
            if(playlistSongsRes?.code === 211){
              // toast.error(playlistSongsRes?.msg);
              globalToast(playlistSongsRes?.msg,true)
            }
            localStorage.setItem('TrackData', JSON.stringify(playlistSongsRes?.data?.data))
            dispatch(trackDetail(playlistSongsRes?.data?.data))
          }


          // const playlistSongsRes = await handleGetRequest(`${`playlist-song-data?type=${type}&search_keyword=${searchQuery}&sort_type=${sort}&perPage=10000000&page=1&v_code=${id}`}`,false)
          //   localStorage.setItem('TrackData', JSON.stringify(playlistSongsRes?.data?.data))
          //   dispatch(trackDetail(playlistSongsRes?.data?.data))
        }
      })();
    }, [isliked,songId,type,debounced,sort,songDeleteId]);

    useEffect(() => {
      if(isFilterApplied>0){
        return
      }
      // Skip the first render
      if (isFirstDeleteRender.current) {
        isFirstDeleteRender.current = false;
        return;
      }
      
      (async () => {
        const trackDeleteRes = await handleGetRequest(`remove-song/${songDeleteId}`, true,true);
        if(trackDeleteRes?.flag){
            const playlistSongsRes = await handleGetRequest(`${`playlist-song-data?type=${type}&search_keyword=${debounced}&sort_type=${sort}&perPage=10000000&page=1&v_code=${id}`}`,false,true)
            localStorage.setItem('TrackData', JSON.stringify(playlistSongsRes?.data?.data))
            dispatch(trackDetail(playlistSongsRes?.data?.data))

            const payload = {
              is_global:globalId
            }
              const platformDetailDataReq = await handlePostRequest(`playlist/${id}`, payload, false,true);
              setPlaylistDetailData(platformDetailDataReq?.data)
              // console.log("platformDetailDataReq?.data",platformDetailDataReq?.data?.is_liked);
              setLike(platformDetailDataReq?.data?.is_liked)
        }
      })();
    }, [songDeleteId]);

    const [searchParams] = useSearchParams();
    const idsss = searchParams.get('id');  // Extract the 'id' from the URL
    console.log("lkj",id,paramsid,parts,searchParams, idsss);
   if(Like===undefined){
return
   }else{

     return(
         <div>
            {/* <BackToPage pageRoute="/collection/playlists" backTo="Playlists"/> */}
            {/* <BackToPage pageRoute={`${globalId?'/global-search':'/collection/playlists'}`} backTo={`${globalId?"Global Search":"Playlists"}`}/> */}
           <CommonDetail Id={idsss} setIsFilterApplied={setIsFilterApplied} setFilter={setFilter}  fetchPlaylistData={playlistDetailData} searchQuery={searchQuery} setSearchQuery={setSearchQuery} setType={setType} setSort={setSort} sort={sort} type={type} details={playlistDetailData} setSongDeleteId={setSongDeleteId} setIsliked={setIsliked} setSongId={setSongId}  title="Playlists" detailtitles={detailTitles} setLike={setLike} Like={Like} refreshPlaylist={refreshPlaylist} setPlaylistDetailData={setPlaylistDetailData}/>
         </div>
     )
   }
}

export default PlaylistDetail
import { createSlice } from "@reduxjs/toolkit";



export const initialState = {
  queList: [],
  currentSong: 0,
  nonQueueSong: {},
  token: "",
  syncCompleted: "",
  apiStateDataHandler: "",  
  musicState: "false",
  playPaysFunctionToggle: "false",
};

// Slice
export const queueSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    queListHandler: (state, action) => {
      state.queList = action.payload;
    },
    currentSongHandler: (state, action) => {
      state.currentSong = action.payload;
    },
    nonQueueSongHandler: (state, action) => {
      state.nonQueueSong = action.payload;
    },
    tokenHandler: (state, action) => {
      state.token = action.payload;
      // state.token = getStoredToken() || action.payload;
    },
    syncHandler: (state, action) => {
      state.syncCompleted = action.payload;
    },
    apiStateHandler: (state, action) => {
      state.apiStateDataHandler = action.payload;
    },
    musicPlayPauseHandler: (state, action) => {
      state.musicState = action.payload;
    },
    playPaysFunctionHandler: (state, action) => {
      state.playPaysFunctionToggle = action.payload;
    },
  },
});

// Export action creators
// export const {
//   queListHandler,
//   currentSongHandler,
//   tokenHandler,
//   syncHandler,
//   apiStateHandler,
//   nonQueueSongHandler,
// } = queueSlice.actions;

export const queueActions = queueSlice.actions;

// Export reducer
export default queueSlice.reducer;

import { createSlice, configureStore } from "@reduxjs/toolkit";
import counterReducer from "./queue";
import authReducer from "./auth";
import globalSliceReducer from "./globalSlice";
import queueSliceReducer from "./queue";
import loaderReducer from './loader'
import modalReducer from './modalSlice'
import filterReducer from './filterSlice'
import songsIdReducer from './songsIdSlice'
import mobileSideBarReducer from './mobileSideBarSlice'
import authenticationReducer from './authenticationSlice'
import trackReducer from './trackSlice'
import shareModalGlobalReducer from './shareModalGlobalSlice'
import stepperReducer from './stepperSlice'
import scrollValueReducer from './scrollValueSlice'
import trackLikeReducer from './trackLikeSlice'
import globalSearchReducer from './globalSearchSlice'
import fcmTokenReducer from './fcmTokenSlice'
import darkThemeReducer from './darkThemeSlice'
import playerSliceReducer from './playerSlice'
import createPlaylistReducer from "./createPlaylistSlice";
import pushNotificationReducer from "./pushNotificationSlice"
import bluethoothDeviceReducer from "./bluethoothDeviceSlice"
import followingReducer from './followingSlice'
import followerReducer from './followerSlice'
import notificationBellReducer from './notificationBellSlice'

const store = configureStore({
  reducer: {
    counter: counterReducer,
    // auth: authReducer,
    globalSlice: globalSliceReducer,
    queueSlice: queueSliceReducer,
    loader:loaderReducer,
    filter:filterReducer,
    songId:songsIdReducer,
    modal:modalReducer,
    sidebar:mobileSideBarReducer,
    auth:authenticationReducer,
    tracks:trackReducer,
    shareModal:shareModalGlobalReducer,
    stepper:stepperReducer,
    scroll:scrollValueReducer,
    likeSongs:trackLikeReducer,
    search:globalSearchReducer,
    pushToken:fcmTokenReducer,
    dark:darkThemeReducer,
    player:playerSliceReducer,
    createplaylist:createPlaylistReducer,
    push:pushNotificationReducer,
    bluetooth:bluethoothDeviceReducer,
    following:followingReducer,
    follower:followerReducer,
    bell:notificationBellReducer
  },
});

export default store;

import { useCallback, useState } from "react";
import { ComposableMap, Geographies, Geography, ZoomableGroup } from "react-simple-maps";
import CommonListing from "../../collectionPagesComponents/CommonListing";

const GeosComponent = ({setSelectedWorldMapCountry,selectedWorldMapCountry,nav,handleDeletePlaylist}) => {
    const [tooltipContain, setTooltipContent] = useState({
        show: false,
        x: 0,
        y: 0,
        content: "",
    });
    const onWorldMapHandler = useCallback((selectedWorldMap, geo) => {
        const id = selectedWorldMap?.id?.substring(0, 2);
    
    
        setSelectedWorldMapCountry((prev) => {
          const isItemAlreadySelected = prev.includes(id);
        
          if (isItemAlreadySelected) {
            // If item is already selected, remove it from the array
            return prev.filter(item => item !== id);
          } else {
            // If item is not selected, add it to the array
            return [...prev, id];
          }
        });
      }, []);
    return(
      <div>
       {nav===1 ? <div className={`  overflow-y-hidden w-[90%] mx-auto  `}>
        <div id="map-container" className="h-full">
          <ComposableMap
            width={1300}
            height={500}
            projectionConfig={{ scale: 180 }}
          >
            <ZoomableGroup
              zoom={true}
              translateExtent={[
                [0, -500],
                [1300, 500],
              ]}
            >
              <Geographies geography="/features.json">
                {({ geographies }) =>
                  geographies.map((geo) => (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      onMouseEnter={(event) => {
                        const mapContainer =
                          document.getElementById("map-container");
                        const rect = mapContainer.getBoundingClientRect();
                        const x = event.clientX - rect.left;
                        const y = event.clientY - rect.top;
                        setTooltipContent({
                          show: true,
                          x,
                          y,
                          content: geo?.properties?.name,
                        });
                      }}
                      onMouseLeave={() => {
                        setTooltipContent((tooltipValue) => {
                          return { ...tooltipValue, show: false };
                        });
                        setTooltipContent("");
                      }}
                      //   onClick={() => onWorldMapHandler(geo)}
                      // onClick={(event) => {
                      //   // Call the onWorldMapHandler function
                      //   onWorldMapHandler(geo);
                      //   event.target.blur();
                      // }}
                      style={{
                        default: {
                          fill: selectedWorldMapCountry?.includes(
                            geo?.id?.substring(0, 2)
                          )
                            ? "#F1B942"
                            : "#D8D8D8",
                        },
                        hover: {
                          fill: "#D8D8D8",
                          stroke: "#F1B942",
                          cursor: "pointer",
                        },
                        pressed: {
                          fill: "blue",
                        },
                        // Add additional CSS to remove outline/focus border
                        focus: {
                          outline: "none",
                        
                        },
                        
                      }}
                    />
                  ))
                }
              </Geographies>
            </ZoomableGroup>
          </ComposableMap>
          {tooltipContain.show && (
            <div
              style={{
                position: "absolute",
                left: tooltipContain.x, // Adjust the tooltip's left position
                top: tooltipContain.y, // Adjust the tooltip's top position
                backgroundColor: "white",
                padding: "4px",
                borderRadius: "4px",
                boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
              }}
            >
              {tooltipContain.content}
            </div>
          )}
        </div>
        </div>: <div>
          
        <CommonListing listing={selectedWorldMapCountry} title="Geos" flag="profilepagelisting"  hidetitle={true} handleDeletePlaylist={handleDeletePlaylist} fromprofile="/collection/geos" />
          </div>}
      </div>
    )
}

export default GeosComponent
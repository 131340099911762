import React from 'react'
import { Link, useLocation } from 'react-router-dom'

const CommunityTabComponent = () => {
    const location = useLocation()

  return (
    <div>
        <div className="grid lg:grid-cols-10 grid-cols-3  content-center text-center  text-[#312522] text-lg font-light gap-x-4 my-4">
                {/* <p className={`cursor-pointer ${nav===1?"text-[#F1B942]  font-bold":"dark:text-white"}`} onClick={()=>setNav(1)}>Publication</p> */}
                <Link to="/community/following"><p className={`cursor-pointer ${location.pathname.includes("/community/following")?"text-[#F1B942]  font-bold":"dark:text-white"}`}>Following</p></Link>

                <Link to="/community/follower"><p className={`cursor-pointer ${location.pathname.includes("/community/follower") ?"text-[#F1B942]  font-bold":"dark:text-white"}`} >Followers</p></Link>
               
            </div>
            <div className="w-full h-[5px] bg-[#D9D9D9] grid  lg:grid-cols-10 grid-cols-3 ">
                {/* <div className={`${nav===1?"bg-[#F1B942]":""}`}></div> */}
                <div className={`${location.pathname.includes("/community/following")?"bg-[#F1B942]":""}`}></div>
                <div className={`${location.pathname.includes("/community/follower")?"bg-[#F1B942]":""}`}></div>
            </div>
    </div>
  )
}

export default CommunityTabComponent
import { Link } from "react-router-dom"

const CommonProfileCard = ({name,children,className,setIsEdit,edit,editicon}) => {
    return(
        <div className={`rounded-lg bg-white dark:bg-[#151515] mt-5 w-full h-auto drop-shadow-xl p-3 ${className}`}>
            <div className="flex justify-between items-center my-4">
                    <h1 className="text-xl font-semibold dark:text-[#DDD1CE] ">{name  || "User Name :-"} </h1>
                    <div className={`cursor-pointer ${!editicon && 'hidden'}`} onClick={()=>setIsEdit(edit)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                    <path d="M6.88215 2.64722L9.35284 5.11792M10.4935 3.95257C10.8178 3.62821 11.0001 3.18829 11.0001 2.72958C11.0001 2.27086 10.8178 1.83094 10.4935 1.50658C10.1691 1.18222 9.7292 1 9.27049 1C8.81178 1 8.37185 1.18222 8.04749 1.50658L1.11719 8.41218V10.8829H3.58788L10.4935 3.95257Z" stroke="#F1B942" stroke-width="0.823566" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    </div>
                    {/* <div className={` ${!editicon ? 'block': 'hidden'} dark:text-[#DDD1CE]`}>
                        <Link to="#">See All</Link>
                    </div> */}
            </div>
            {children}
        </div>
    )
}

export default CommonProfileCard
import { createSlice } from "@reduxjs/toolkit";

// const stepper = JSON.parse(localStorage.getItem("stepper")) 

const initialState = {
  followerUser:[],
  followerRequest:[]
};

// Slice
export const followerSlice = createSlice({
  name: "follower",
  initialState,
  reducers: {
    handleFollowerUserList(state,action){
        state.followerUser = action.payload;
      },
      handleFollowerRequestList(state,action){
        state.followerRequest = action.payload;
      },
  },
});

export const {handleFollowerUserList,handleFollowerRequestList} = followerSlice.actions;

export default followerSlice.reducer;
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import H324 from '../Typograpgy/H324'
import { useForm } from 'react-hook-form';
import InputField from '../Input/InputField';
import Button1 from '../GlobalButtonLayout/Button1';
import { useApi } from '../../../../api/api';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { useDispatch, useSelector } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';



import { handleCreatePlaylistStatus, handleGeneratePlaylistStatus } from '../../../../store/createPlaylistSlice';

export default function CreatePlaylistModal({openCreatePlaylistTrackModal,setOpenCreatePlaylistTrackModal,id,setSelectedRows,image,vcode,isEdit,title,setCreatePlaylistCode,createPlaylistCode,setSelectAll,setCreatePlaylistId}) {
    const dispatch = useDispatch()
    // const [playlistTitle, setPlaylistTitle] = React.useState()
    console.log("dadfsftitle",openCreatePlaylistTrackModal);
    // console.log("playlistTitle",playlistTitle);
  const theme = useTheme();
//   const [selectedImage, setSelectedImage] = React.useState(null);
  const { handlePostRequest,handleGetRequest } = useApi();

  const { register, handleSubmit, reset, formState: { errors }  } = useForm();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [selectedImage, setSelectedImage] = React.useState("");
  const [imgUrl, setImgUrl] = React.useState(image);
  const [privacyEnabled, setPrivacyEnabled] = React.useState(false);


//   const handleImageChange = (event) => {
//     const file = event.target.files[0];
//     if (file) {
     
//         setImgUrl(URL.createObjectURL(file))
//         const reader = new FileReader();
//         reader.onloadend = () => {
//             setSelectedImage(reader.result);
//         };
//         reader.readAsDataURL(file);
//     }
//     };
const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
        // Create a new Image object
        const img = new Image();
        img.onload = function() {
            // Check if the image meets the required resolution
            setImgUrl(URL.createObjectURL(file));
                const reader = new FileReader();
                reader.onloadend = () => {
                    setSelectedImage(reader.result);
                };
                reader.readAsDataURL(file);
            // if (this.width < 500 && this.height < 500) {
            //     // The image meets the required resolution.
            //     // Proceed with setting the image URL and reading the file as a Data URL.
                
            // } else {
            //     // The image does not meet the required resolution.
            //     // Alert the user or handle it accordingly.
            //     alert("Please select an image with a resolution of 500 X 500 pixels.");
            // }
        };
        // Set the source of the Image object to the selected file
        img.src = URL.createObjectURL(file);
    }
};
  const handleCloseCreatePlaylistTrackModal = () => {
    reset({
        'Playlist Title': '', // Reset the 'Playlist Title' field to an empty string
        Description: '', // Reset the 'Description' field to an empty string
        // Add other fields here with their initial values if needed
     });
    setImgUrl("")
    setSelectedRows([])
    setSelectAll(false)
    // setCreate(false)
    dispatch(handleCreatePlaylistStatus(false))
    dispatch(handleGeneratePlaylistStatus(false))
    setOpenCreatePlaylistTrackModal(false)

  
  };

  

  const onSubmit = async (data) => {

    console.log("assd",data);
    
    const createPlaylistPayload = {
        e_is_visible:"on",
        t_description:data['Description'],
        t_image:selectedImage,
        track_ids:id,
        v_name:data['Playlist Title']
      }

    const editPlaylistPayload = {
        v_code:vcode,
        v_name:data['Playlist Title'],
        e_is_visible:"on",
        t_description:data.Description,
        t_image:selectedImage
    }

   if(!isEdit){
       handleCloseCreatePlaylistTrackModal()
       const generatePlaylistRes = await handlePostRequest(`generate-playlist`,createPlaylistPayload,true, true)
       console.log("generatePlaylistRes",generatePlaylistRes?.data);
       setCreatePlaylistCode(generatePlaylistRes?.data?.v_code)
       setCreatePlaylistId(generatePlaylistRes?.data?.id)
       reset()
       setImgUrl("")
       setSelectedRows([])
    //    setCreate(false)
        dispatch(handleCreatePlaylistStatus(false))
    dispatch(handleGeneratePlaylistStatus(false))

       setOpenCreatePlaylistTrackModal(false);

   }


    if(isEdit){
        const editPlaylistRes = await handlePostRequest(`manage-playlist-update`,editPlaylistPayload,true, true)
        console.log("editPlaylistRes",editPlaylistRes);
        reset()
        setImgUrl("")
        setSelectedRows([])
        // setCreate(false)
        dispatch(handleCreatePlaylistStatus(false))
        dispatch(handleGeneratePlaylistStatus(false))
        setOpenCreatePlaylistTrackModal(false);

    }
  };

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 15,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(12px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#312522' : '#F1B942',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
    isDark ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
      boxSizing: 'border-box',
    },
  }));


  const handleChange = (event,para) => {
    para==="privacy" &&  setPrivacyEnabled(event.target.checked)
};

const isDark = useSelector((state) => state.dark.isDarkValue);

console.log("kyadd",openCreatePlaylistTrackModal);



  return (
    <React.Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={openCreatePlaylistTrackModal}
        // onClose={handleCloseCreatePlaylistTrackModal}
        aria-labelledby="responsive-dialog-title"
        className={`${isDark?"dark":""}`}
      >
        
      <div className='dark:bg-[#0D0D0D] py-3 px-8 lg:min-w-[500px] relative h-[100%] lg:h-auto dark:text-[#FFFFFF]'>
        <HighlightOffOutlinedIcon onClick={()=> handleCloseCreatePlaylistTrackModal()} className='absolute top-3 right-3 cursor-pointer dark:!text-[#A8A8A8] ' />
        <div className='text-center'>
            <H324 className="dark:!text-[#FFFFFF]">Generate your playlist</H324>
        </div>
        <div className='my-3 '>
           <p>Playlist cover:</p> 
           <div>
                    <input
                        accept="image/*"
                        id="icon-button-file"
                        type="file"
                        name="image"
                        onChange={handleImageChange}
                        style={{ display: 'none' }}
                    />
                    <label htmlFor="icon-button-file">
                        <Button component="span">
                        {(image|| imgUrl) ? (
                                <img src={imgUrl?imgUrl:image} alt="Selected" style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
                            ) : (
                                <div className='flex  cursor-pointer flex-col justify-center items-center h-[100px] w-[100px] bg-[#312522] relative'>
                                    {/* <p className='text-white text-lg'>500*500</p> */}
                                    <div className='w-10 h-10 flex justify-center items-center bg-white border-[1px] border-black absolute -top-[6px] -right-[12px] rounded-full'>
                                        <AddIcon className='text-black'/>

                                    </div>
                                </div>
                            )}
                        </Button>
                    </label>
                    </div>
        </div>
        <div>
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="my-2  w-full">
                <p className="w-full">Playlist title:</p>
                
                <div className=" w-full">
                    <InputField 
                        type="text" 
                        placeholder="Enter playlist title" 
                        name="Playlist Title" 
                        register={register} 
                        errors={errors}
                        className="!w-full !bg-[#FAFAF8] !text-left px-3 dark:!bg-[#131313] dark:!border-[#131313] "
                        required={true}
                        styleProp="w-[100%]"
                    />
                    {errors && errors['Playlist Title'] && errors['Playlist Title'].type === 'required' && (
                    <p className="text-red-500 text-xs lg:text-sm">Playlist title is required</p>
                    )}

                    {errors && errors['Playlist Title'] && errors['Playlist Title'].type === 'pattern' && (
                        <p className="text-red-500 text-xs lg:text-sm">{"Playlist Title must meet given criteria"}</p>
                    )}
                </div>
            </div>

            <div className="my-2  w-full">
                <p className="w-full my-2">Description:</p>
                <div className=" w-full">
                    {/* <input 
                        type="text" 
                        placeholder="Enter description" 
                        name="Description" 
                        register={register} 
                        errors={errors}
                        className="!w-full !bg-[#FAFAF8] !text-left px-3 dark:!bg-[#131313] dark:!border-[#131313]"
                        required={true}
                        styleProp="w-[100%]"
                    /> */}

                    <input
                        type="text"
                        placeholder="Enter description"
                        {...register("Description", { required: true })}
                        className={`w-[80%] mx-auto text-left px-3 border-[2px] border-white focus:border-[2px] dark:!border-black bg-[#FAFAF8] rounded-[50px] dark:!bg-[#000000] dark:!text-[#C1C1C1] text-[#312522] text-[16px] font-normal py-2 my-1 ${errors && errors['Description'] && (errors['Description'].type === 'required' || errors['Description'].type === 'pattern') ? "!border-[#D80027] focus:!border-[#D80027]" : "focus:!border-[#F1B942]"}`}
                        style={{ width: "100%" }}
                    />
                      

                    {errors && errors['Description'] && errors['Description'].type === 'required' && (
                    <p className="text-red-500 text-xs lg:text-sm">Description is required</p>
                    )}

                    {errors && errors['Description'] && errors['Description'].type === 'pattern' && (
                        <p className="text-red-500 text-xs lg:text-sm">{"Description must meet given criteria"}</p>
                    )}
                </div>
            </div>

            <div className="flex justify-start items-center  gap-x-3">
                <div className="dark:text-white">
                  <p className="font-semibold">Private</p>
                </div>
                <div>
                  <AntSwitch  
                  checked={privacyEnabled}
                  onChange={(e)=>handleChange(e,"privacy")} 
                   className="dark:!accent-slate-400"
                  inputProps={{ 'aria-label': 'ant design' }} />
                </div>
            </div>
            <div className="flex justify-center items-center mt-[50px] lg:mt-[20px]">
                <Button1 type="submit" className="!w-[40%] mx-auto text-center ml-auto dark:hover:!text-[#F3C25A]">
                    Done    
                </Button1>
                </div>
            </form>
        </div>
      </div>
      </Dialog>
    </React.Fragment>
  );
}



import MenuData from '../../../menu.json'
// import Menu from "./Menu";
import { useDispatch, useSelector } from "react-redux";
import { lazy, Suspense, useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import { handleAuthModalStatus } from '../../../store/authenticationSlice';
// const MenuData = lazy(() => import('../../../menu.json'));
const Menu = lazy(() => import('./Menu'));

function SidebarComponent(){

    // const MobileMenu = useSelector((state) => state.sidebar.isSideBar)
    // const handleInstallPWA = () => {
    //     if ('serviceWorker' in navigator) {
    //       navigator.serviceWorker.ready.then((registration) => {
         

    //         if (registration.waiting) {
    //           registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    //         }
    //         window.location.reload();
    //       });
    //     }
    //  };

    const MobileMenu = useSelector((state) => state.sidebar.isSideBar);
    const [deferredPrompt, setDeferredPrompt] = useState(null);

    useEffect(() => {
        window.addEventListener('beforeinstallprompt', (e) => {
            // Prevent Chrome 67 and earlier from automatically showing the prompt
            e.preventDefault();
            // Stash the event so it can be triggered later.
            setDeferredPrompt(e);
        });
    }, []);

    // const handleInstallPWA = () => {
      
    //     if (deferredPrompt) {
    //         // Show the prompt
    //         deferredPrompt.prompt();
    //         // Wait for the user to respond to the prompt
    //         deferredPrompt.userChoice.then((choiceResult) => {
    //             if (choiceResult.outcome === 'accepted') {
    //                 console.log('User accepted the A2HS prompt');
    //             } else {
    //                 console.log('User dismissed the A2HS prompt');
                   
    //             }
               
    //             setDeferredPrompt(null);
    //         });
    //     }
    // };

    const handleInstallPWA = () => {
        // Check if the app is already installed

        
        if (window.getInstalledRelatedApps) {
            window.getInstalledRelatedApps().then((relatedApps) => {
                console.log("relatedApps",window.getInstalledRelatedApps);
                // If the app is already installed, show an alert
                if (relatedApps.length > 0) {
                    alert('The app is already installed on your device.');
                    return; // Exit the function if the app is already installed
                }
    
                // If the app is not installed and there's a deferred prompt, show the install prompt
                if (deferredPrompt) {
                    // Show the prompt
                    deferredPrompt.prompt();
                    // Wait for the user to respond to the prompt
                    deferredPrompt.userChoice.then((choiceResult) => {
                        if (choiceResult.outcome === 'accepted') {
                            console.log('User accepted the A2HS prompt');
                        } else {
                            console.log('User dismissed the A2HS prompt');
                        }
                        setDeferredPrompt(null);
                    });
                }
            });
        } else {
            // If getInstalledRelatedApps is not supported, proceed with showing the install prompt
            if (deferredPrompt) {
                // Show the prompt
                deferredPrompt.prompt();
                // Wait for the user to respond to the prompt
                deferredPrompt.userChoice.then((choiceResult) => {
                    if (choiceResult.outcome === 'accepted') {
                        console.log('User accepted the A2HS prompt');
                    } else {
                        console.log('User dismissed the A2HS prompt');
                    }
                    setDeferredPrompt(null);
                });
            }
        }
    };

    const isIOS = () => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test(userAgent);
      };
    
      const handleAddToHome = () => {
        if (isIOS() && window.navigator.standalone) {
          // The app is already installed on the home screen
          alert('The app is already installed.');
        } else if (isIOS()) {
          // Prompt the user to add the app to the home screen
          alert('Please tap the Share button and select "Add to Home Screen" to install the app.');
        } else {
          // Handle other platforms or non-iOS devices
          alert('This feature is available only on iOS devices.');
        }
      };
    const dispatch = useDispatch()
  
    return (
        <div className={`  overflow-scroll z-50 ${MobileMenu ? "left-0 duration-500" : "-left-[100%] lg:!left-0 duration-500"} ease-in-out duration-500 w-[60%] lg:w-[17%] h-screen fixed md:right-0 top-0 bg-white shadow-2xl md:justify-center lg:p-4 p-2 md:p-auto rounded-tr-3xl rounded-br-3xl dark:bg-[#151515]`}>
            
            <div className={`${MobileMenu ? "left-0" : "!left-[-100%] lg:!left-0"} duration-500 fixed top-0 w-[60%] lg:w-[17%] p-4 left-0 !z-[9999999999999999] bg-white  ease-in-out  pt-4 rounded-tr-3xl dark:bg-[#151515]`}>
                {/* <img src="/logo/logo.png" alt="logo" className="" /> */}
                <LazyLoadImage
                 alt="logo"
                className='w-full h-full'
                src="/logo/LogoWorld.svg"
                />
            </div>
            
            <div className="mt-20 overflow-y-scroll">
                <Menu menuData={MenuData.menu} />
            </div>
            {!isIOS() &&<button onClick={()=>handleInstallPWA()} className='bg-black text-white px-4 py-3 rounded-3xl mt-5'>Download App <span className='bg-white py-1 px-1 rounded-full mx-1'><DownloadOutlinedIcon className='!text-black'/></span></button>}

            {isIOS() && <button onClick={()=>handleAddToHome()} className='bg-black text-white px-4 py-3 rounded-3xl mt-5'>Download App <span className='bg-white py-1 px-1 rounded-full mx-1'><DownloadOutlinedIcon className='!text-black'/></span></button>}
        </div>
    );
}

export default SidebarComponent;

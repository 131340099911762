import { Link} from "react-router-dom"
import Button1 from "../common/GlobalButtonLayout/Button1"
import H130 from '../common/Typograpgy/H130'
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Button2 from "../common/GlobalButtonLayout/Button2";
import { useSelector } from "react-redux";
import { useState } from "react";
import { Dialog } from 'primereact/dialog'
import CheckIcon from '@mui/icons-material/Check';



const Sync = ({syncList,syncPlatform,setSelectedPlatform,selectedPlatform,setIsSyncCard,isSyncCard,setNav,connectPlatform,deletePlatform,reSyncPlatform,setisPremiumModal,isPremiumModal,syncSpecificPlaylist,handleSyncSpecificPlaylistSync, setSelectedPlaylists,selectedPlaylists,setisSyncSpecificPlaylistCard,isSyncSpecificPlaylistCard,playlistTrackCount,setPlaylistTrackCount}) => {
    const platformSelected = (platform) => {
        setSelectedPlatform(platform)
        localStorage.setItem('platform', JSON.stringify(platform));
    }
    const isDark = useSelector((state) => state.dark.isDarkValue);
    const [isCreateModal, setIsCreateModal] = useState(false)

    const userData = useSelector((state) => state.auth.profileData);
    console.log("userData",userData?.sync_platform_count);
    console.log("userDatasss",!userData?.user?.is_premium_subscriber);
    console.log("userDatasssaaa",!userData?.user?.is_premium_subscriber && userData?.sync_platform_count>1);
    
    // userData?.sync_platform_count
    const checkIsPremium = () => {
        if(!userData?.user?.is_premium_subscriber && userData?.sync_platform_count<2 && userData?.sync_platform_count>0){
          setisPremiumModal(true)
          return
        }
        setIsCreateModal(true)

        setIsSyncCard(true)
      }


     
    console.log("isSyncCard",syncList.length>0);

    const handleConnect =()=>{
        if(!userData?.user?.is_premium_subscriber && userData?.sync_platform_count<2 && userData?.sync_platform_count>0){
            setisPremiumModal(true)
            return
          }
        connectPlatform(selectedPlatform)
    }
   
    const handleCheckboxChange = (event, playlist) => {
        const { checked } = event.target;
        if (checked) {
          setSelectedPlaylists((prev) => [...prev, playlist?.id]);
        } else {
          setSelectedPlaylists((prev) => prev.filter((item) => item.id !== playlist.id));
        }
      };

      const handleRowSelect = (id) => {
        console.log("plllid", id);
    
        setSelectedPlaylists(prev =>
          prev.includes(id) ? prev.filter(item => item !== id) : [...prev, id]
        );
      };

    console.log("selectedplaylist",selectedPlaylists);
    
    
    return(
        <div className="mt-5">
             <Dialog  visible={isPremiumModal} className={`p-7 lg:h-auto w-[90vw] lg:w-[45vw] h-auto text-center !bg-white !opacity-1 ${isDark?"dark":""} dark:!bg-[#151515] drop-shadow-xl rounded-xl `} onHide={() => setIsCreateModal(false)}>
                <HighlightOffIcon onClick={() => setisPremiumModal(false)} className='cursor-pointer absolute top-3 right-3 dark:!text-white'/>
                <div className="flex flex-col justify-center h-full   py-10">
                <h2 className=' text-base lg:text-2xl font-semibold text-center dark:text-white'>Upgrade to premium Services</h2>
                <div className='flex justify-center items-center mt-10'><Link to="/premium"><Button1 className="dark:hover:!text-white">Upgrade</Button1></Link></div>
                </div>
                </Dialog>
            <div className="flex justify-start items-center gap-x-3 mb-10 ">
                {!isSyncCard && <Button1 className="!w-[20%] lg:!w-[10%] text-center dark:hover:!text-[#F1B942]"  onClick={()=>{checkIsPremium(); setPlaylistTrackCount()}}>Sync</Button1>}
                {isSyncCard && <Button2 className="!w-[20%] lg:!w-[10%] dark:!text-[#F1B942] text-center dark:hover:!text-white"  onClick={()=>setIsSyncCard(false)}>Cancel</Button2>}
                <Link to="/collection/manage-playlist" className={`bg-[#F1B942] text-white px-3 py-2 rounded-[50px]`}>Manage Playlists</Link>
            </div>
         
               {syncList?.length>0 ? <div  className={`${syncPlatform?.length>0?"hidden":""} w-full my-3 flex flex-col justify-center items-center drop-shadow-2xl bg-white h-auto py-10 `}>
                    <H130 className="text-center my-4">Syncing Your Music...</H130>
                    <p className="text-base font-normal text-center my-3">15 Tracks ,4 playlists</p>
                    <div className="flex justify-center items-center gap-x-4 ">
                    <Link to="/collection" className="border-[#F1B942] border-[2px] px-4 py-2 rounded-[50px] text-[#F1B942] hover:bg-[#F1B942] hover:text-white">My Collection</Link>
                    <button onClick={()=>{setNav(2)}} className="border-[#F1B942] border-[2px] px-4 py-2 rounded-[50px] text-[#F1B942] hover:bg-[#F1B942] hover:text-white">Upload</button>
                </div>
                </div>
                :
                <div className={`px-3 text-center w-full my-3 flex flex-col justify-center items-center  bg-white h-auto py-10 ${isSyncCard?"hidden":"block"} ${isSyncSpecificPlaylistCard?"hidden":""} dark:bg-[#151515] rounded-2xl`}>
                    <p className="lg:text-[27px] text-lg font-semibold dark:text-[#A1A1A1]">Service Not Synced Yet ! To add the service<span className="text-[#F1B942] mx-2 cursor-pointer" onClick={()=>setIsSyncCard(true)}> click </span>here </p>
                </div>}

            {isSyncCard  && <div className="w-full my-3 flex flex-col justify-center items-center drop-shadow-2xl bg-white h-auto py-10 relative dark:bg-[#151515] rounded-2xl">
               <div>
                    <HighlightOffIcon className="absolute top-3 right-3 cursor-pointer dark:text-[#A1A1A1]" onClick={()=>setIsSyncCard(false)}/>
                    <H130 className="text-center text-xl lg:text-3xl my-4 dark:text-[#A1A1A1]">Sync your music</H130>
                    <div className={`flex justify-between items-center ${syncPlatform?.length<2?"justify-center":"justify-between"} flex-wrap  gap-x-4 gap-y-3 px-2`}>
                        {syncPlatform?.map((item)=>{
                            return(
                                <div className="cursor-pointer rounded-full w-24 h-24 flex text-white text-xs  justify-center text-center items-center flex-col relative" style={{backgroundColor:`${item?.v_color_code}`}} onClick={()=>platformSelected(item?.v_name) }>
                                    <div >
                                    <img src={item?.t_image} alt="" className="mx-auto my-1" />
                                    <p className="px-3">{item?.v_name}</p>
                                    </div>
                                    {item?.v_name===selectedPlatform && <div className="bg-black flex flex-col justify-center items-center absolute top-0 w-full h-full rounded-full bg-opacity-60">
                                    <DoneOutlinedIcon className="!text-6xl"/>
                                    </div>}
                                </div>
                            )
                        })}
                    </div>

                    <div className="flex justify-between items-center w-full mx-auto gap-x-4 mt-10">
                        <Button1 onClick={()=>handleConnect()} className="lg:!w-32 mx-auto text-center !w-[90%] dark:hover:!text-[#F1B942]">Connect</Button1>
                    </div>
                </div>
            </div>}

            

            {(syncSpecificPlaylist?.length>0 && isSyncSpecificPlaylistCard ) && <div className="w-full my-3 flex flex-col justify-center items-center  bg-white h-auto py-10 relative dark:bg-[#151515] rounded-2xl">
               <div className="w-full">
                    {/* <HighlightOffIcon className="absolute top-3 right-3 cursor-pointer dark:text-[#A1A1A1]" onClick={()=>setIsSyncCard(false)}/> */}
                    <H130 className="text-center text-xl lg:text-3xl my-4 dark:text-[#A1A1A1]">Select your Playlists</H130>
                    <div className={` px-2 lg:px-10 py-2 my-5 w-full max-h-72 overflow-y-auto dark:text-white`}>
                       {syncSpecificPlaylist?.map((item, index) => {
                               const isSelected = selectedPlaylists.includes(item.id);
                               return (
                                 <div key={index} className="flex justify-between items-center py-2 px-6 rounded-xl border-[1px] border-[#A8A8A8] w-full my-3 drop-shadow-lg">
                                    <div className="flex justify-start items-center gap-3">
                                    <div className="w-10 h-10 rounded-full ">
                                        <img src={item?.t_image} alt="" className="object-cover rounded-full w-full h-full"/>
                                    </div>
                                    <div>
                                    <p className="text-sm lg:text-xl font-semibold capitalize dark:text-white">{item?.v_name}</p>
                                    <p className="text-xs lg:text-base font-normal dark:text-white">{item?.e_platform} : {item?.v_total_songs} Tracks</p>
                                    </div>
                                    </div>

                                   <label className="relative flex items-center p-1 rounded-full cursor-pointer">
                                     <input
                                       type="checkbox"
                                       className="peer hidden"
                                       id={item.id}
                                       onChange={() => handleRowSelect(item.id)}
                                       checked={isSelected}
                                     />
                                     <CheckIcon className="text-white dark:text-[#151515] peer-checked:!text-white border-[1px] border-black dark:border-white peer-checked:bg-[#F1B942] !text-base !rounded-sm absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4" />
                                   </label>
                                 </div>
                               );
                             })}
                    </div>

                    <div className="flex justify-between items-center w-full mx-auto gap-x-4 mt-10">
                    <Button1 onClick={()=>{handleSyncSpecificPlaylistSync(); setPlaylistTrackCount(); }} className="lg:!w-32 mx-auto text-center !w-[90%] dark:hover:!text-[#F1B942]">Sync</Button1>
                    </div>
                </div>
            </div>}

            {playlistTrackCount && <div className={` w-full my-3 flex flex-col justify-center items-center  bg-white h-auto py-10 dark:bg-[#151515] dark:text-white `}>
                    <H130 className="text-center my-4 dark:text-white">Service synced successfully</H130>
                    <p className="text-base font-normal text-center my-3">{`${playlistTrackCount?.songCount} Tracks ,${playlistTrackCount?.playlistCount} playlists`}</p>
                    <div className="flex justify-center items-center gap-x-4 ">
                    <div onClick={()=>{setIsSyncCard(true); setPlaylistTrackCount()}}  className="border-[#F1B942] border-[2px] px-4 py-2 rounded-[50px] text-[#F1B942] hover:bg-[#F1B942] hover:text-white">Sync  another service</div>
                    <button onClick={()=>{setNav(2); setPlaylistTrackCount()}} className="border-[#F1B942] border-[2px] px-4 py-2 rounded-[50px] text-[#F1B942] hover:bg-[#F1B942] hover:text-white">Upload</button>
                </div>
                </div>}

            {syncList?.length>0 &&<table className={`table-fixed w-full mb-3 drop-shadow-xl mt-10 bg-white dark:!bg-[#151515] rounded-2xl`}>
            <thead className="border-b-[1px] border-[#A8A8A8]">
                <tr className=" text-center text-[#A1A1A1] text-sm font-semibold">
                <th className="px-2 py-3 lg:rounded-tl-xl text-center">Service</th>
                <th className="px-2 py-3 hidden lg:table-cell">Playlists</th>
                <th className="px-2 py-3 hidden lg:table-cell">Tracks</th>
                <th className="px-2 py-3 hidden lg:table-cell">Last sync</th>
                <th className="px-2 py-3 hidden lg:table-cell">Duration</th>
                <th className="px-2 py-3  lg:rounded-tr-xl text-right lg:text-center">Actions</th>
                </tr>
            </thead>
            <tbody>
                {syncList?.length>0 && syncList?.map((items,index)=> {
                    return(
                    <tr key={items?.id} className=""> 
                            <td className="!text-left py-3 flex justify-center items-center gap-x-2 px-3">
                                <div className={`w-[50%] h-[80px] flex flex-col justify-center items-center p-2 rounded-lg`} style={{backgroundColor:`${items?.v_color_code}`}}>
                                    <Link to={`/collection/playlists?platform=${items?.name}`}>
                                        <img  src={`${items?.t_image_path}`} alt='img' className='object-cover w-[30px] mx-auto h-[30px] rounded-xl cursor-pointer' />
                                        <p className="text-white text-xs text-center">{items?.name}</p>
                                    </Link>
                                </div>
                            </td>
                            <td className="!text-left w-[39%] hidden lg:table-cell">
                                <div>
                                    <p className="text-[#312522] line-clamp-1 text-center text-xs font-normal mb-1 lg:mb-2 dark:text-[#DDD1CE]">{items?.ListCount}</p>
                                </div>
                            </td>
                            <td className="!text-left w-[39%]  hidden lg:table-cell">
                                <div>
                                    <p className="text-[#312522] px-2 bg-[#FFE5E5] dark:bg-[#151515] text-center w-2/4 mx-auto rounded-[30px] py-1 text-xs font-normal mb-1 lg:mb-2 line-clamp-1 dark:text-[#DDD1CE]">{items?.SongListCount}</p>
                                </div>
                            </td>
                            <td className="!text-left w-[24%]  hidden lg:table-cell">
                                <div className="flex justify-center gap-x-1 items-center">
                                    <p className="text-[#312522] text-sm font-light dark:text-[#DDD1CE]">{items?.LastSyncDate}</p>
                                </div>
                            </td>

                            <td className="!text-left w-[24%]  hidden lg:table-cell">
                                <div className="flex justify-center gap-x-1 items-center">
                                    <p className="text-[#312522] text-sm font-light dark:text-[#DDD1CE]">{items?.i_duration}</p>
                                </div>
                            </td>
                           
                            <td className="!text-left relative">
                                <div className="flex justify-end pr-2 lg:pr-0 lg:justify-center items-center gap-x-2">
                                    <SyncOutlinedIcon onClick={()=>reSyncPlatform(items?.name,items?.state)}  className="hover:text-[#F1B942] dark:text-[#DDD1CE] cursor-pointer"/>
                                    <DeleteForeverOutlinedIcon onClick={()=>deletePlatform(items?.name)}  className="hover:text-[#F1B942] dark:text-[#DDD1CE] threeDots-icon cursor-pointer" />
                                </div>
                            </td>
                        </tr>
                     )
                })} 
            </tbody>
            </table>}
        </div>
    )
}

export default Sync
import { createSlice } from "@reduxjs/toolkit";

// const stepper = JSON.parse(localStorage.getItem("stepper")) 

const initialState = {
   fcmToken:""
};

// Slice
export const fcmTokenSlice = createSlice({
  name: "pushToken",
  initialState,
  reducers: {
    handleFcmToken(state,action){
        state.likedSongs = action.payload;
      },
  },
});

export const {handleFcmToken} = fcmTokenSlice.actions;

export default fcmTokenSlice.reducer;
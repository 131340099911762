import { useEffect, useState } from "react";
import { useApi } from "../../api/api";
import { useLocation, useParams } from "react-router";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import H220 from "../../view/components/common/Typograpgy/H220";
import Button1 from "../../view/components/common/GlobalButtonLayout/Button1";
import BackToPage from "../../view/components/common/BacktoPage/BackToPage";
import { Link } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


const Success = () => {
    const { id } = useParams()
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const vCode = searchParams.get('v_code');

    const { handlePostRequest } = useApi();
    const [pdfUrl, setPdfUrl] = useState(null);
    useEffect(() => {
        const makeRequest = async () => {
          try {
            const payload = {
                transaction_id:id,
                v_code:vCode
              }
            const callbackinvoiceRes = await handlePostRequest(`payment/invoice`,  payload, false, false);
            console.log("callbackResinvoice", callbackinvoiceRes);
            setPdfUrl(callbackinvoiceRes?.data)
         
          } catch (err) {
            console.log(err);
          }
        };
        makeRequest()
      }, []);

      const handleDownloadPdf = () => {
        // Download PDF logic here
        if (pdfUrl?.v_invoice_path) {
            window.open(pdfUrl?.v_invoice_path, "_blank");
        } else {
            console.error("PDF URL is not available.");
        }
    };

    return(
      <div>
      <BackToPage pageRoute="/" backTo="Previous Page"/>
      {/* <Link to="/"><ArrowBackIcon />Home</Link> */}
      <div className="px-3 lg:px-0">
      <H220 className="!mb-7">Payment completed</H220>
      </div>
      <div className="lg:w-full mx-auto drop-shadow-xl py-5 px-5 bg-white dark:text-[#FFFFFF] text-center rounded-xl mt-10 dark:bg-[#1B1B1B]">
        <CheckCircleOutlineIcon className="!text-8xl text-[#1AB700]"/>
        <H220  className="!text-[#1AB700] !text-4xl !font-semibold my-6">Payment complete</H220>

      
        <p>Thank you, Your payment has been successful.</p>
        <div className="lg:w-2/4 mx-auto">
          <p className="text-black text-2xl font-normal text-left my-5 dark:text-white">Details:</p>
          <div className="py-3 my-2 text-left lg:text-lg text-sm font-normal text-[#888]  flex justify-between items-center">
              <p>{pdfUrl?.duration} Plan <br/>access ends: {pdfUrl?.d_end_date}</p>
              <p>$ {pdfUrl?.v_discounted_price}</p>
          </div>
          <hr className="h-[1px] bg-[#888] "/>
          <div className="py-3 text-left my-2 lg:text-lg text-sm font-normal text-[#888]  flex justify-between items-center">
              <p>Total:</p>
              <p>$ {pdfUrl?.v_discounted_price}</p>
          </div>
          <hr className="h-[1px] bg-[#888]"/>
          <div className="text-center lg:text-xl text-sm font-normal mt-7">
            Thank you for your order.
          </div>
          <Button1 className="!w-2/4 !my-10 !text-center dark:hover:!text-[#F3C25A]" onClick={handleDownloadPdf}>Download invoices</Button1>
        </div>
      </div>
      {/* {pdfUrl && (
          <div>
              <embed src={pdfUrl} type="application/pdf" width="100%" height="600px" />
          </div>
      )} */}
      
  </div>
    )
}

export default Success
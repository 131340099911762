import H220 from "../../view/components/common/Typograpgy/H220"
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import TranslateOutlinedIcon from '@mui/icons-material/TranslateOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import { useEffect, useRef, useState } from "react";
import Button1 from "../../view/components/common/GlobalButtonLayout/Button1";
import { useApi } from "../../api/api";
import BackToPage from "../../view/components/common/BacktoPage/BackToPage";
import { useDispatch, useSelector } from "react-redux";
import { handleDarkTheme } from "../../store/darkThemeSlice";
import SecurityIcon from '@mui/icons-material/Security';
import { profileDetail } from "../../store/authenticationSlice";
import globalToast from "../../hooks/globalToast";

const SettingPage = () => {
    const isFirstRender = useRef(true);
    const isFirstPrivateRender = useRef(true);
    const updateAccountScope = useRef(true);
    const isFirstDarkThemeRender = useRef(true);
    const notificationPrefrencesCalender = useRef(true);
    const notificationPrefrencesCommunity = useRef(true);
    const { handleGetRequest, handlePostRequest } = useApi();
    const profileData = useSelector((state) => state.auth.profileData);

    const dispatch = useDispatch()
    const [notificationEnabled, setNotificationEnabled] = useState(profileData?.is_calendar_notification_enabled);
    const [communityEnabled, setCommunityEnabled] = useState(profileData?.is_community_notification_enabled);
    const [darkThemeEnabled, setDarkThemeEnabled] = useState((localStorage.getItem("isDark"))?JSON.parse(localStorage.getItem("isDark")): false);
    const [privacyEnabled, setPrivacyEnabled] = useState(profileData?.is_private);
    const [languageDropdown, setLanguageDropdown] = useState(false);
    const [privacyDropdown, setPrivacyDropdown] = useState(false);
    const [notificationDropdown, setNotificationDropdown] = useState(false);
    const [language, setLanguage] = useState("en");

    console.log("privacyEnabled",privacyEnabled);


 

    // useEffect(() => {
    //   if (isFirstRender.current) {
    //     isFirstRender.current = false;
    //     return; // Skip the first render
    //   }
    //   (async () => {
    //       const notificationPlayload = {
    //         e_notification_status:notificationEnabled?"Yes":"No"
    //       }
    //     const notificationRes = await handlePostRequest(`users/notification-status`,notificationPlayload, false, false);
    //     console.log("notificationRes", notificationRes);
    //   })();
    // }, [notificationEnabled]);

    useEffect(() => {
      if (updateAccountScope.current) {
        updateAccountScope.current = false;
        return; // Skip the first render
      }
      (async () => {
          const Playload = {
            is_private:privacyEnabled
          }
        const privateRes = await handlePostRequest(`updateAccountScope`,Playload, true, false);
        console.log("privateRes", privateRes);
        const profileDataReq = await handleGetRequest("profile-detail",false, false)
        dispatch(profileDetail(profileDataReq?.data))
      })();
    }, [privacyEnabled]);


    useEffect(() => {
      if (notificationPrefrencesCalender.current) {
        notificationPrefrencesCalender.current = false;
        return; // Skip the first render
      }
      (async () => {
          const Playload = {
            calendar:notificationEnabled
          }
        const calendarNotificationRes = await handlePostRequest(`users/notification-prefrences-change`,Playload, true, false);
        console.log("calendarNotificationRes", calendarNotificationRes);
        const profileDataReq = await handleGetRequest("profile-detail",false, false)
        dispatch(profileDetail(profileDataReq?.data))
      })();
    }, [notificationEnabled]);


    useEffect(() => {
      if (notificationPrefrencesCommunity.current) {
        notificationPrefrencesCommunity.current = false;
        return; // Skip the first render
      }
      (async () => {
          const Playload = {
            community:communityEnabled
          }
        const communityNotificationRes = await handlePostRequest(`users/notification-prefrences-change`,Playload, true, false);
        console.log("communityNotificationRes", communityNotificationRes);
        const profileDataReq = await handleGetRequest("profile-detail",false, false)
        dispatch(profileDetail(profileDataReq?.data))
      })();
    }, [communityEnabled]);


    // useEffect(() => {
     
    //   (async () => {
    //     const privateRes = await handleGetRequest(`getAccountScope`,false, false);
    //     console.log("privateRes", privateRes);
    //     setPrivacyEnabled(privateRes?.data?.is_private)

    //     const notificationRes = await handleGetRequest(`users/notification-prefrences-get`, false, false);
    //     console.log("notificationRes", notificationRes);
    //     setNotificationEnabled(notificationRes?.data?.calendar);
    //     setCommunityEnabled(notificationRes?.data?.community);

    //   })();
    // }, []);

    useEffect(() => {
      if (isFirstRender.current) {
        isFirstRender.current = false;
        return; // Skip the first render
      }
      (async () => {
          const languagePlayload = {
            e_language:language
          }
        const languageRes = await handlePostRequest(`users/language-change`,languagePlayload, false, false);
        console.log("languageRes", languageRes);
        const profileDataReq = await handleGetRequest("profile-detail",false, false)
        dispatch(profileDetail(profileDataReq?.data))
      })();
    }, [language]);

    // useEffect(() => {
    //   (async () => {
    //     const settingRes = await handleGetRequest(`users/settings`, false, false);
    //     console.log("settingRes", settingRes);
    //     if(settingRes?.flag){
    //       setNotificationEnabled(settingRes?.data?.e_notification_status==="Yes"?true:false)
    //       // setCommunityEnabled(settingRes?.data?.e_notification_status==="Yes"?true:false)
    //       setLanguage(settingRes?.data?.e_language)
    //     }
    //   })();
    // }, []);

    useEffect(()=>{
      if (isFirstDarkThemeRender.current) {
        isFirstDarkThemeRender.current = false;
        return; // Skip the first render
      }
      if(!darkThemeEnabled){
        globalToast("Light theme activated.")
      }else{
        globalToast("Dark theme activated.")
      }
    },[darkThemeEnabled])



    const handleChange = (event,para) => {
        para==="notification" && setNotificationEnabled(event.target.checked);
        para==="communitynotification" && setCommunityEnabled(event.target.checked);
        para==="dark" &&  setDarkThemeEnabled(event.target.checked)
        para==="privacy" &&  setPrivacyEnabled(event.target.checked)
    };

    useEffect(()=>{
      dispatch(handleDarkTheme(darkThemeEnabled))
    },[darkThemeEnabled])

    console.log("darkThemeEnabled",darkThemeEnabled);

    const isDark = useSelector((state) => state.dark.isDarkValue);


    const AntSwitch = styled(Switch)(({ theme }) => ({
        width: 28,
        height: 16,
        padding: 0,
        display: 'flex',
        '&:active': {
          '& .MuiSwitch-thumb': {
            width: 15,
          },
          '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
          },
        },
        '& .MuiSwitch-switchBase': {
          padding: 2,
          '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              opacity: 1,
              backgroundColor: theme.palette.mode === 'dark' ? '#312522' : '#F1B942',
            },
          },
        },
        '& .MuiSwitch-thumb': {
          boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
          width: 12,
          height: 12,
          borderRadius: 6,
          transition: theme.transitions.create(['width'], {
            duration: 200,
          }),
        },
        '& .MuiSwitch-track': {
          borderRadius: 16 / 2,
          opacity: 1,
          backgroundColor:
           isDark ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
          boxSizing: 'border-box',
        },
      }));

      console.log("darkThemeEnabled",darkThemeEnabled);
    return(
        <div className="px-3 lg:px-0">
          {/* <BackToPage pageRoute={`/`} backTo="Home"/> */}
            <H220 className="!px-1">Settings</H220>


            {/* notification setting */}


            <div className={`bg-white py-4 px-2 w-full flex justify-between items-center mt-8   dark:bg-[#151515] ${notificationDropdown?"rounded-t-lg":"rounded-lg drop-shadow-lg"}`}>
                <div className=" flex justify-between items-center">
                    <NotificationsOutlinedIcon className="dark:text-[#DDD1CE] mx-4"/>
                    <p className=" text-lg text-[#312522] font-semibold dark:text-[#DDD1CE]">Notification</p>
                </div>
                <div>
                <KeyboardArrowDownOutlinedIcon className="cursor-pointer dark:!text-[#DDD1CE]" onClick={()=>setNotificationDropdown(pre=>!pre)}/>
                </div>
            </div>
            <div className={` overflow-y-auto bg-white dark:bg-[#151515] px-5 w-full mb-3 rounded-b-lg  ${notificationDropdown?"lg:max-h-[250px] max-h-[350px] lg:py-10":"max-h-0"} duration-500 `}>
              <div className="my-4 flex justify-start  gap-x-3">
                <div className="dark:text-white   ">
                  <p className="font-semibold mb-3">Calendar notification</p>
                  <p className="text-base dark:text-white">Lorem ipsum dolor sit amet consectetur. Imperdiet mattis est gravida lectus viverra blandit. Lorem fringilla sagittis cursus dignissim justo habitant semper ullamcorper urna.</p>
                </div>
                <div>
                  <AntSwitch  
                  checked={notificationEnabled}
                   className="dark:accent-slate-400"
                  onChange={(e)=>handleChange(e,"notification")} 
                  inputProps={{ 'aria-label': 'ant design' }} />
                </div>
              </div>
              <div className="my-4 flex justify-start  gap-x-3 ">
                <div className="dark:text-white  ">
                  <p className="font-semibold mb-3">Community notification</p>
                  <p className="text-base dark:text-white">Lorem ipsum dolor sit amet consectetur. Imperdiet mattis est gravida lectus viverra blandit. Lorem fringilla sagittis cursus dignissim justo habitant semper ullamcorper urna.</p>
                </div>
                <div>
                <AntSwitch  
                    checked={communityEnabled}
                     className="dark:accent-slate-400"
                    onChange={(e)=>handleChange(e,"communitynotification")} 
                    inputProps={{ 'aria-label': 'ant design' }} />
                </div>
              </div>
            </div>

            {/* Languages setting */}

            <div className={`bg-white py-4 px-2 w-full flex justify-between items-center mt-3  dark:bg-[#151515]  ${languageDropdown?"rounded-t-lg":"rounded-lg drop-shadow-lg "}`}>
                <div className=" flex justify-between items-center">
                    <TranslateOutlinedIcon className="dark:text-[#DDD1CE] mx-4"/>
                    <p className=" text-lg text-[#312522] font-semibold dark:text-[#DDD1CE]">Language</p>
                </div>
                <div>
                <div className="flex justify-start items-center gap-x-2">
                <p className="dark:text-white">{language==="en"?"English":language==="ar"?"Arabic":"French"}</p>
                <KeyboardArrowDownOutlinedIcon className="cursor-pointer dark:!text-[#DDD1CE]" onClick={()=>setLanguageDropdown(pre=>!pre)}/>
                  </div>
                </div>
            </div>
            <div className={`flex justify-start items-end gap-x-2 overflow-hidden rounded-b-lg bg-white dark:bg-[#151515] px-2 w-full mb-3  ${languageDropdown?"max-h-[100px] lg:py-10":"max-h-0"} duration-500`}>
              <Button1 onClick={()=>setLanguage("en")} className={`text-center !lg:w-[10%] w-[50%] my-3 ${language==="en"?"!bg-[#F1B942]":"!bg-white !text-black !border-[#F1B942] border-[1px]"}`}>English</Button1>
              <Button1 onClick={()=>setLanguage("ar")} className={`text-center !lg:w-[10%] w-[50%] my-3 ${language==="ar"?"!bg-[#F1B942]":"!bg-white !text-black !border-[#F1B942] border-[1px]"}`}>Arabic</Button1>
              <Button1 onClick={()=>setLanguage("fr")} className={`text-center !lg:w-[10%] w-[50%] my-3 ${language==="fr"?"!bg-[#F1B942]":"!bg-white !text-black !border-[#F1B942] border-[1px]"}`}>French</Button1>
            </div>

            {/* Dark/Light */}

            <div className="bg-white dark:bg-[#151515] py-4 px-2 w-full flex justify-between items-center my-3 rounded-lg drop-shadow-lg">
                <div className=" flex justify-between items-center">
                    <DarkModeOutlinedIcon className="dark:text-[#DDD1CE] mx-4"/>
                    <p className=" text-lg text-[#312522] font-semibold dark:text-[#DDD1CE]">Dark/Light</p>
                </div>
                <div>
                <AntSwitch  
                    checked={darkThemeEnabled}
                     className="dark:accent-slate-400"
                    onChange={(e)=>handleChange(e,"dark")} 
                    inputProps={{ 'aria-label': 'ant design' }} />
                </div>
            </div>
            <div className={`bg-white py-4 px-2 w-full flex justify-between items-center mt-3  dark:bg-[#151515] ${privacyDropdown?"rounded-t-lg":"rounded-lg drop-shadow-lg" }`}>
                <div className=" flex justify-between items-center">
                    <SecurityIcon className="dark:text-[#DDD1CE] mx-4"/>
                    <p className=" text-lg text-[#312522] font-semibold dark:text-[#DDD1CE]">Account privacy</p>
                </div>
                <div className="flex justify-start items-center gap-x-2">
                <p className="dark:text-white">{!privacyEnabled?"Public":"Private"}</p>
                <KeyboardArrowDownOutlinedIcon className="cursor-pointer dark:!text-[#DDD1CE]" onClick={()=>setPrivacyDropdown(pre=>!pre)}/>
                </div>
            </div>

            {/* Private Setting */}

            <div className={`overflow-y-auto bg-white dark:bg-[#151515] px-5 w-full mb-3   ${privacyDropdown?"max-h-[150px] lg:py-10":"max-h-0"} duration-500 `}>
              <div className="flex justify-start  gap-x-3">
                <div className="dark:text-white   ">
                  <p className="font-semibold mb-2">Private account</p>
                  <p className="text-base dark:text-white">We want to make it easy for you to manage and understand your Privacy Settings, so you can use the Curioushit service in the way you want.</p>
                </div>
                <div>
                  <AntSwitch  
                  checked={privacyEnabled}
                   className="dark:accent-slate-400"
                    onChange={(e)=>handleChange(e,"privacy")} 
                  inputProps={{ 'aria-label': 'ant design' }} />
                </div>
              </div>
            </div>
        </div>
    )
}

export default SettingPage
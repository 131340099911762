import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import H324 from '../Typograpgy/H324'
import Button2 from '../GlobalButtonLayout/Button2';
import { useSelector } from 'react-redux';

export default function DeleteTrackModal({openDeleteTrackModal,setOpenDeleteTrackModal,setSongDeleteId,deleteId}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleCloseDeleteTrackModal = () => {
    setOpenDeleteTrackModal(false);
  };

  const isDark = useSelector((state) => state.dark.isDarkValue);


  return (
    <React.Fragment>
      <Dialog
        open={openDeleteTrackModal}
        onClose={handleCloseDeleteTrackModal}
        aria-labelledby="responsive-dialog-title "
        className={`${isDark?"dark":""}`}
      >
      <div className='py-4 px-4  dark:bg-[#0D0D0D]'>
        <div className='text-center dark:bg-[#0D0D0D]'>
            <H324 className="dark:text-[#FFFFFF] text-base lg:text-xl" >Are you sure you want to delete this track?</H324>
            <div className='flex justify-center items-center gap-x-4 my-3'>
              <Button2 className="text-center dark:!text-[#F3C25A] dark:hover:!text-white" onClick={()=>{setSongDeleteId(deleteId);setOpenDeleteTrackModal()}}>Yes</Button2>
              <Button2 className="text-center dark:!text-[#F3C25A] dark:hover:!text-white" onClick={()=>setOpenDeleteTrackModal()}>No</Button2>
            </div>
        </div>
        <div>
            
        </div>
      </div>
      </Dialog>
    </React.Fragment>
  );
}